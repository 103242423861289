import React, {useState} from "react";
import {Link} from "react-router-dom";
import {IPartnerCard} from "../../types/partner";

const PartnersPageHeader: React.FC<{
    partners: IPartnerCard[] | null,
    setSearchTerm: (term: string) => void;
    searchTerm: string;
    selectedCategory: string;
    setSelectedCategory: (term: string) => void;
}> = ({setSearchTerm, searchTerm, setSelectedCategory}) => {

    const projectDestination = "/project";

    const [dropdownText, setDropdownText] = useState("All categories");

    const handleCategorySelect = (category: string) => {
        setSelectedCategory(category);
        setDropdownText(category === "" ? "All categories" : category);
    };

    return (
        <header className="app-header -compact -sticky">
            <div className="container-fluid">
                <div className="row justify-content-between mb-4">
                    <div className="col-md-4">
                        <div className="d-flex align-items-center my-3">
                            <svg className="me-3 flex-shrink-0" fill="none" xmlns="http://www.w3.org/2000/svg"
                                 width="56" height="56" viewBox="0 0 56 56">
                                <rect x=".5" y=".5" width="55" height="55" rx="7.5" fill="#FCFCFC"></rect>
                                <path
                                    d="M31 38.75h-6c-5.43 0-7.75-2.32-7.75-7.75v-6c0-5.43 2.32-7.75 7.75-7.75h6c5.43 0 7.75 2.32 7.75 7.75v6c0 5.43-2.32 7.75-7.75 7.75Zm-6-20c-4.61 0-6.25 1.64-6.25 6.25v6c0 4.61 1.64 6.25 6.25 6.25h6c4.61 0 6.25-1.64 6.25-6.25v-6c0-4.61-1.64-6.25-6.25-6.25h-6Z"
                                    fill="#07031B"></path>
                                <path
                                    d="M30.83 28.68c-.41 0-.75-.34-.75-.75v-3.49h-3.49c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4.24c.41 0 .75.34.75.75v4.24c0 .41-.34.75-.75.75Z"
                                    fill="#07031B"></path>
                                <path
                                    d="M25.17 30.09c-.19 0-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l5.66-5.66c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-5.66 5.66c-.14.15-.34.22-.53.22ZM28 34.23c-2.11 0-4.23-.34-6.24-1.01a.753.753 0 0 1-.47-.95c.13-.39.56-.61.95-.47 3.72 1.24 7.81 1.24 11.53 0 .39-.13.82.08.95.47.13.39-.08.82-.47.95-2.02.68-4.14 1.01-6.25 1.01Z"
                                    fill="#07031B"></path>
                                <rect x=".5" y=".5" width="55" height="55" rx="7.5" stroke="#D2D2D3"></rect>
                            </svg>
                            <h2 className="m-0 lh-1">Share your project in our partner’s environments</h2>
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <Link className="btn-close btn btn-outline-gray" to={projectDestination}></Link>
                    </div>
                </div>
                <div className="row mb-4 justify-content-between">
                    <div className="col-md-6">
                        <input type="text"
                               className="form-control form-control-lg form-control-icon -search"
                               placeholder="Search..."
                               value={searchTerm}
                               onChange={(e) => setSearchTerm(e.target.value)}/>
                    </div>
                    <div className="col-auto">
                        <div className="d-flex align-items-center">
                            <div className="text-secondary me-1">
                                Category
                            </div>
                            <div className="dropdown">
                                <button className="btn btn-sm btn-white dropdown-toggle ff-body" type="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    {dropdownText}
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <button className="dropdown-item" onClick={() => handleCategorySelect("")}>
                                            All categories
                                        </button>
                                    </li>
                                    <li>
                                        <button className="dropdown-item"
                                                onClick={() => handleCategorySelect("Asset management")}>
                                            Asset management
                                        </button>
                                    </li>
                                    <li>
                                        <button className="dropdown-item"
                                                onClick={() => handleCategorySelect("Collaboration platform")}>
                                            Collaboration platform
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mt-3 mb-0"/>
            </div>
        </header>
    );
};

export default PartnersPageHeader;

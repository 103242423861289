export const WAIT_FILE_UPLOAD = "Please wait for files to finish uploading";
export const SEND_FOR_QUOTE = "Send for quote";
export const GENERATE = "Generate";
export const SAVE_AS_DRAFT = "Save as draft";
export const QUOTE_APPROVAL = "To approve a quote, you need to have a valid payment method set up.";
export const PRODUCT_APPROVAL = "To approve a product, you need to have a valid payment method set up.";
export const AI_PROJECT_CONFIRMATION_HEADER = "Ready to generate your 3D model?";
export const AI_IMAGE_PROJECT_CONFIRMATION_BODY = "Your image(s) will now be automatically converted into a 3D model.\nYou can download the final model(s) in your Completed projects folder in a few minutes. \n\nThis generation will cost {0} tokens.";

export const AI_TEXT_PROJECT_CONFIRMATION_BODY ="Your text prompt(s) will be automatically converted into 3D model(s).\nYou can download the final model(s) in your Completed projects folder in a few minutes. \n\nThis generation will cost {0} tokens.";;
export const READY_TO_DOWNLOAD_CONFIRMATION_BODY ="Your 3D model specs: \n\n{0}\n\n This download will cost you {1} tokens.";
export const DESIGNER_PROJECT_CONFIRMATION_HEADER = "Are you sure you want to send this project for a quote?";
export const DESIGNER_PROJECT_CONFIRMATION_BODY = "We will review the request and provide you the production time and cost soon under the Pending quotes folder.";
export const ASSET_GENERATION_COST = "One 3D asset generation will cost {0} tokens.";

import React from "react";
import LoadingButton from "../product/LoadingButton";
import {SEND_FOR_QUOTE, WAIT_FILE_UPLOAD} from "../../common-texts";
import { ReactComponent as IconSend } from "../../assets/icons/icon-send.svg"; 

interface IAddNewProductHeader {
    projectName?: string,
    addProduct: () => void,
    cancel: () => void
    filesUploading: boolean;
}

const AddNewProductHeader: React.FC<IAddNewProductHeader> = ({projectName, addProduct, cancel, filesUploading}) => {


    return <header className="view-header">
        <div className="container-fluid">
            <div className="row justify-content-between">
                <div className="col-md-auto">
                    <div className="title">
                        <div className="spacer"></div>
                        <h6>{projectName}</h6>
                    </div>
                </div>
            </div>
            <div className="row justify-content-between">
                <div className="col-md-auto">
                    <div className="title">
                        <button className="btn btn-outline-gray btn-icon btn-sm me-3" onClick={() => cancel()}>
                            <i className="bi bi-caret-left-fill"></i>
                        </button>
                        <h1>Add new products</h1>
                    </div>
                </div>
                <div className="col-md-auto">
                    <button onClick={() => cancel()} className="btn btn-gray me-2">
                        Cancel
                    </button>
                    <LoadingButton
                        buttonText={SEND_FOR_QUOTE}
                        btnClassName={"btn-dark"}
                        loaderVariant={"light"}
                        onClick={addProduct}
                        loading={filesUploading}
                        loadingTooltip={WAIT_FILE_UPLOAD}
                        Icon={IconSend} />
                </div>
            </div>
        </div>
    </header>
}

export default AddNewProductHeader;

import React from "react";

const RolesExplained: React.FC = () => {
    return (
        <>
            <h4>User roles explained</h4>
            <span className="badge badge-gray">User</span>
            <p className="mt-2">
                User role is issued for clients to grant access to the system.
            </p>
            <span className="badge badge-green">Company</span>
            <p className="mt-2">
                Company role is issued for internal Alpha AR employees.
            </p>
            <span className="badge badge-pink">Admin</span>
            <p className="mt-2">
                Admin roles is issued to administrators of the system.
            </p>
        </>
    );
};

export default RolesExplained;
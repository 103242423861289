import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import {AddProductCommentRequest, CommentDTO} from "../../types/product/product-comments-dtos";
import Comment from "../Comment";
import {FieldError, FormProvider, UseFormReturn} from "react-hook-form";
import {useDropzone} from "react-dropzone";
import InputError from "../InputError";
import {Overlay, Popover} from "react-bootstrap";
import {handleDropzoneFiles} from "../../utils/DropzoneUtils";

const PLACEHOLDER_TEXT = "Type here...";
const MOUSE_LEAVE_TIMEOUT = 500;

const ProductComments: React.FC<{
    addCommentRequestMethods: UseFormReturn<AddProductCommentRequest>;
    addComment: () => void;
    comments: CommentDTO[];
    isReplyVisible: boolean;
}> = ({comments, addCommentRequestMethods, addComment, isReplyVisible}) => {
    const [files, setFiles] = useState<File[]>([]);
    const [commentCount, setCommentCount] = useState<number>(0);
    const textTarget = useRef(null);
    const [showAttachmentPopover, setShowAttachmentPopover] = useState<boolean>(false);
    const [hideable, setHideable] = useState<boolean>(true);

    const [checkShow, setCheckShow] = useState<boolean>(false);
    const hideableRef = useRef(hideable);
    useLayoutEffect(() => {
        hideableRef.current = hideable
    }, [hideable]);

    const target = useRef(null);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop: (acceptedFiles) => {
            const newFiles = handleDropzoneFiles(files, acceptedFiles)
            addCommentRequestMethods.setValue("files", newFiles);
            setFiles(newFiles);
        }
    });

    const remove = (removeFile: File) => {
        const temp = files.filter(file => file !== removeFile);
        if (temp.length === 0) {
            setShowAttachmentPopover(false);
        }
        addCommentRequestMethods.setValue("files", temp);
        setFiles(temp);
    }

    useEffect(() => {
        if (!addCommentRequestMethods.getValues("files")) return;
        addCommentRequestMethods.trigger("files").then();
    }, [files, addCommentRequestMethods])

    const emptyUserInput = useCallback(() => {
        if (!comments) return;
        if (comments.length > commentCount) {
            setFiles([]);
            const commentText = document.getElementById("comment-text-input")
            if (commentText) {
                commentText.innerText = "";
            }
            addCommentRequestMethods.reset();
        }
        setCommentCount(comments.length);
    }, [addCommentRequestMethods, comments, commentCount])

    useEffect(() => {
        emptyUserInput();
    }, [emptyUserInput])

    const handleCommentAdd = () => {
        addCommentRequestMethods.setValue("files", files);
        let commentText = document.getElementById("comment-text-input");
        if (commentText) {
            addCommentRequestMethods.setValue("text", commentText.innerText);
        } else {
            addCommentRequestMethods.setValue("text", "");
        }
        addComment();
    }

    const handleOnFocus = () => {
        addCommentRequestMethods.setError("text", {});
        addCommentRequestMethods.setError("files", {});
    }

    function getHasFileError() {
        const fileError = addCommentRequestMethods.formState.errors?.files as FieldError | undefined;
        if (fileError) {
            return fileError.message;
        }
    }

    useEffect(() => {
        if (hideableRef.current) {
            setShowAttachmentPopover(false);
        }
    }, [checkShow]);

    const handleMouseEnterButton = () => {
        setHideable(false)
        if (files.length > 0) {
            setShowAttachmentPopover(true);
        }
    }

    const handleMouseEnterToPopover = () => {
        setHideable(false)
    }

    const handleMouseLeaveButton = () => {
        setHideable(true);
        if (files.length > 0) {
            setTimeout(() => {
                setCheckShow(!checkShow);
            }, MOUSE_LEAVE_TIMEOUT);
        }
    }

    const handleMouseLeavePopover = () => {
        setHideable(true)
        setTimeout(() => {
            setCheckShow(!checkShow);
        }, MOUSE_LEAVE_TIMEOUT);
    }

    const getFileRows = () => {
        return <>
            {files.length > 0 && files.map((file, index) => {
                return <div key={index}
                            className={`d-flex justify-content-between ${files.length === index + 1 ? "" : "mb-2"} `}>
                    <div className={`text-success text-truncate me-5`}>{file.name}</div>
                    <div onClick={() => remove(file)}
                         className="btn-xs btn btn-white me-2 float-end col-1">
                        <i className="bi bi-x-lg"/>
                    </div>
                </div>
            })}
        </>
    }

    return (<>
            <div className="title">Comments</div>
            <div className="comments">
                {Array.isArray(comments) && comments.length > 0 &&
                    comments.slice(0).reverse().map((comment, key) => {
                        return <Comment comment={comment} key={"comment-" + key} index={key}/>
                    })
                }
            </div>
            {isReplyVisible &&
                <FormProvider {...addCommentRequestMethods}>
                    <form className="reply" ref={textTarget}>
                        <label hidden htmlFor="text"><b>Comment Text</b></label>
                        <InputError errorMessage={addCommentRequestMethods.formState.errors?.text?.message}/>
                        <InputError errorMessage={getHasFileError()}/>
                        <span
                            onFocus={() => handleOnFocus()}
                            id={"comment-text-input"}
                            className="textarea message form-control"
                            contentEditable
                            placeholder={PLACEHOLDER_TEXT}
                        />
                        <div className="actions">
                            <div className="row justify-content-between">
                                <div
                                    className="col-auto">{/*spacer, add cancel/delete comment button here in future*/}</div>
                                <div className="col-auto" onClick={(e) => e.stopPropagation()}>
                                    <button {...getRootProps()}
                                            ref={target}
                                            onMouseEnter={() => handleMouseEnterButton()}
                                            onMouseLeave={() => handleMouseLeaveButton()}
                                            type={"button"}
                                            className={`btn btn-white btn-icon btn-sm px-2 me-1 ${files.length > 0 && "-has-notification"}`}
                                            id={`product-attachment-button`}>
                                        <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.2 17.38a2.75 2.75 0 0 1-2.75-2.74c0-.73.29-1.43.81-1.94l1.41-1.41c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-1.41 1.41a1.234 1.234 0 0 0 0 1.76c.49.49 1.28.49 1.77 0l2.22-2.22a3.253 3.253 0 0 0-4.6-4.6l-2.42 2.42a2.657 2.657 0 0 0 0 3.78c.29.29.29.77 0 1.06-.29.29-.77.29-1.06 0A4.151 4.151 0 0 1 6.01 13c0-1.12.43-2.17 1.22-2.96l2.42-2.42a4.762 4.762 0 0 1 6.72 0 4.762 4.762 0 0 1 0 6.72l-2.22 2.22c-.54.55-1.24.82-1.95.82Z" fill="#07031B"/><path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12S6.07 1.25 12 1.25 22.75 6.07 22.75 12 17.93 22.75 12 22.75Zm0-20C6.9 2.75 2.75 6.9 2.75 12S6.9 21.25 12 21.25s9.25-4.15 9.25-9.25S17.1 2.75 12 2.75Z" fill="currentColor"/></svg>
                                        <input hidden {...getInputProps()}/>
                                    </button>
                                    <Overlay show={showAttachmentPopover} placement={"top"} target={target.current}
                                             onHide={() => setHideable(true)}>
                                        {({_placement, _arrowProps, show: _show, _popper, ...props}) => (
                                            <Popover
                                                onMouseLeave={() => handleMouseLeavePopover()}
                                                onMouseEnter={() => handleMouseEnterToPopover()}
                                                {...props}
                                                className={"popover"}
                                                style={{
                                                    position: 'absolute',
                                                    ...props.style,
                                                }}
                                            >
                                                <Popover.Body>
                                                    {getFileRows()}
                                                </Popover.Body>
                                            </Popover>
                                        )}
                                    </Overlay>
                                    <button className="btn btn-white btn-icon px-2 btn-sm" type={"button"}
                                            onClick={() => handleCommentAdd()}>
                                        <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5.41 21.75c-1.12 0-1.83-.38-2.28-.83-.88-.88-1.5-2.75.48-6.72l.87-1.73c.11-.23.11-.71 0-.94L3.61 9.8c-1.99-3.97-1.36-5.85-.48-6.72.87-.88 2.75-1.51 6.71.48l8.56 4.28c2.13 1.06 3.3 2.54 3.3 4.16s-1.17 3.1-3.29 4.16l-8.56 4.28c-1.94.97-3.38 1.31-4.44 1.31Zm0-18c-.54 0-.96.13-1.22.39-.73.72-.44 2.59.76 4.98l.87 1.74c.32.65.32 1.63 0 2.28l-.87 1.73c-1.2 2.4-1.49 4.26-.76 4.98.72.73 2.59.44 4.99-.76l8.56-4.28c1.57-.78 2.46-1.81 2.46-2.82 0-1.01-.9-2.04-2.47-2.82L9.17 4.9c-1.52-.76-2.83-1.15-3.76-1.15Z" fill="#0A083A"/><path d="M10.84 12.75h-5.4c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5.4c.41 0 .75.34.75.75s-.34.75-.75.75Z" fill="currentColor"/></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            }
        </>
    )
}

export default ProductComments;

import React from "react";

const PrefilledTextArea: React.FC<{
    name: string;
    label: string;
    value?: string;
    className?: string;
}> = ({name, label, value, className}) => {
    if (!value) value = "";
    return (
        <div className={`mb-3 ${className ? className : ""}`}>
            <label className={"form-label"}>{label}</label>
            <textarea disabled value={value} className="form-control form-control-lg" rows={4} id={name}/>
        </div>
    );
};

export default PrefilledTextArea;

import React from "react";

const PrefilledDropdown: React.FC<{
    label: string;
    name: string;
    value?: string | number;
}> = ({value, label, name}) => {
    if (!value) value = "";
    return (
        <div className="mb-3">
            <label className="form-label">{label}</label>
            <select id={name} className="form-select form-select-lg" disabled value={value}>
                <option key={value}> {value}</option>
            </select>
        </div>
    );
};

export default PrefilledDropdown;

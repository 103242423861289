import React, {useRef, useState} from "react";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import {Carousel, CarouselItem} from "react-bootstrap";
import ImageThumbnail from "./new-project/ImageThumbnail";
import {CarouselRef} from "react-bootstrap/Carousel";

const ThumbnailCarousel: React.FC<{
    thumbnails: File[],
    selectedImageIndex: number,
    onClick: (index: number) => void;
}> = ({thumbnails, selectedImageIndex, onClick}) => {
    const [index, setIndex] = useState<number>(0);
    const thumbnailCount = 4;
    const itemCount = thumbnails.length / thumbnailCount;
    const ref = useRef<CarouselRef>(null);

    const getCarouselItems = () => {
        let thumbnailSets = [];
        let temp: File[] = [];

        thumbnails.forEach(it => {
            if (temp.length < thumbnailCount) {
                temp.push(it);
            } else {
                thumbnailSets.push(temp);
                temp = [];
                temp.push(it);
            }
        })
        if (temp.length > 0) {
            thumbnailSets.push(temp);
        }
        return Array.isArray(thumbnails) && thumbnails.length > 0 &&
            thumbnailSets.map((thumbnailSet, outerIndex) => (
                <CarouselItem key={outerIndex}>
                    <div className={"carousel-item-inner px-2"}>
                        {thumbnailSet.map((thumbnail, innerIndex) => (
                            <ImageThumbnail
                                index={(outerIndex * thumbnailCount) + innerIndex}
                                key={"image-" + ((outerIndex * thumbnailCount) + innerIndex)}
                                onClick={() => onClick((outerIndex * thumbnailCount) + innerIndex)}
                                selected={((outerIndex * thumbnailCount) + innerIndex) === selectedImageIndex}
                                image={thumbnail}/>
                        ))}
                    </div>
                </CarouselItem>
            ));
    }

    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };

    const handleNext = () => {
        if (!ref) return;
        if (!ref.current) return;
        ref.current.next();
    }
    const handlePrev = () => {
        if (!ref) return;
        if (!ref.current) return;
        ref.current.prev();
    }

    return (
        <div className={"thumbnail-gallery -contained -carousel"}>
            <Carousel
                ref={ref}
                controls={false}
                variant={"dark"}
                interval={null}
                indicators={false}
                activeIndex={index}
                onSelect={handleSelect}
            >
                {thumbnails.length > 0 && getCarouselItems()}
            </Carousel>
            <button
                onClick={() => handlePrev()}
                className={`prev btn btn-outline-gray btn-icon btn-sm ${itemCount > 1 ? "" : "disabled"}`}>
                <i className="bi bi-caret-left-fill"></i>
            </button>
            <button
                onClick={() => handleNext()}
                className={`next btn btn-outline-gray btn-icon btn-sm ${itemCount > 1 ? "" : "disabled"}`}>
                <i className="bi bi-caret-right-fill"></i>
            </button>
        </div>
    );
}

export default ThumbnailCarousel;

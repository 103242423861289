import React, {ReactElement, useCallback, useEffect, useState} from "react";
import attachmentClient, {formatErrorResponse} from "../../services/attachment.client";
import {AttachmentInfo} from "../../types/attachment/attachment-response";
import {useAlerts} from "../../store/AlertProvider";
import {AsyncAttachmentProps} from "./ExistingAsyncAttachment";
import {useUploadQueue} from "../../store/UploadQueueProvider";
import axios from "axios";

const NewAsyncAttachment: React.FC<{
    attachment: AttachmentInfo;
    remove: (image: AttachmentInfo) => void;
    updateFile: (image: AttachmentInfo) => void;
    displayElement: ReactElement<AsyncAttachmentProps>;
}> = ({remove, attachment, updateFile, displayElement}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const {addErrorResponseAlert} = useAlerts();
    const {enqueueUpload} = useUploadQueue();

    const uploadAttachment = useCallback(async (attachment: AttachmentInfo, controller: AbortController) => {
        setLoading(true);
        if (attachment.url) {
            try {
                const res = await attachmentClient.uploadAttachmentByUrl(attachment.url, attachment.fileType, controller.signal, false);
                updateFile({
                    ...attachment,
                    file: undefined,
                    base64: res.base64,
                    attachmentUuid: res.attachmentUuid,
                    uploaded: true
                });
            } catch (err) {
                if (axios.isAxiosError(err)) {
                    if (err.code !== "ERR_CANCELED") {
                        if (err.response) {
                            addErrorResponseAlert(formatErrorResponse(err.response));
                        }
                    }
                }
                remove(attachment);
            } finally {
                setLoading(false);
            }
        } else if (attachment.file) {
            try {
                const res = await attachmentClient.uploadAttachment(attachment.file, controller.signal, false);
                updateFile({
                    ...attachment,
                    file: undefined,
                    base64: res.base64,
                    attachmentUuid: res.attachmentUuid,
                    uploaded: true
                });
            } catch (err) {
                if (axios.isAxiosError(err)) {
                    if (err.code !== "ERR_CANCELED") {
                        if (err.response) {
                            addErrorResponseAlert(formatErrorResponse(err.response));
                        }
                    }
                }
                remove(attachment);
            } finally {
                setLoading(false);
            }
        }
    }, [addErrorResponseAlert, remove, updateFile]);

    useEffect(() => {
        if (!attachment.uploaded && !loading) {
            setLoading(true);
            const controller = new AbortController();
            enqueueUpload(attachment, () => uploadAttachment(attachment, controller));
        }
    }, [attachment, loading, enqueueUpload, uploadAttachment]);

    const handleRemove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        if (attachment.attachmentUuid) {
            attachmentClient.deleteAttachment(attachment.attachmentUuid)
                .then(() => remove(attachment))
                .catch((err) => {
                    addErrorResponseAlert(formatErrorResponse(err.response))
                })
                .finally(() => setLoading(false))
        } else {
            remove(attachment)
        }

        event.stopPropagation();
    }

    useEffect(function deleteAttachmentWhenFileOverWritten() {
        if (attachment.file && attachment.attachmentUuid) {
            updateFile({
                ...attachment,
                attachmentUuid: undefined,
                base64: undefined,
                uploaded: false
            })
            attachmentClient.deleteAttachment(attachment.attachmentUuid)
                .catch((err) => {
                    addErrorResponseAlert(formatErrorResponse(err.response))
                })
        }
    }, [loading, addErrorResponseAlert, attachment, updateFile])

    return (
        <>
            {React.cloneElement(displayElement, {attachment: attachment, loading: loading, handleRemove: handleRemove})}
        </>
    );
};

export default NewAsyncAttachment;

import React from "react";
import CompanyResponse from "../../types/company/company-response";
import {Dropdown} from "react-bootstrap";

const CompanyListItem: React.FC<{
    company: CompanyResponse;
    editCompany: (company: CompanyResponse) => void;
    generateReport: (company: CompanyResponse) => void;
}> = ({company, editCompany, generateReport}) => {

    const getAutomaticInvoicingText = (isAutomaticInvoicing: boolean) => isAutomaticInvoicing ? "Automatic" : "Manual";
    const getBusinessTypeText = (isCompany: boolean) => isCompany ? "Business" : "Private";

    return (
        <div className="project" style={{cursor: "default"}}>
            <div className="row justify-content-center">
                <div className="col-2 d-flex flex-column pb-0 pe-3 ms-1">
                    <label className={"fs-xs pb-2 text-secondary"}>Company name</label>
                    <span className={"text-truncate"} title={company.name}>{company.name}</span>
                </div>
                <div className="col-1-5 d-flex flex-column px-3">
                    <label className={"fs-xs pb-2 text-secondary"}>Trello</label>
                    <span>{company.trelloBoardId}</span>
                </div>
                <div className="col-1-5 d-flex flex-column px-3">
                    <label className={"fs-xs pb-2 text-secondary"}>Country</label>
                    <span className={"text-truncate"} title={company.country}>{company.country}</span>
                </div>
                <div className="col-1 d-flex flex-column px-3">
                    <label className={"fs-xs pb-2 text-secondary"}>Reg nr</label>
                    <span title={company.registrationNumber}
                          className={"text-truncate"}>{company.registrationNumber}</span>
                </div>
                <div className="col-1-5 d-flex flex-column px-3">
                    <label className={"fs-xs pb-2 text-secondary"}>VAT nr</label>
                    <span title={company.vatNumber} className={"text-truncate"}>{company.vatNumber}</span>
                </div>
                <div className="col-1-5 d-flex flex-column px-3">
                    <label className={"fs-xs pb-2 text-secondary"}>Address</label>
                    <span className={"text-truncate"} title={company.address}>{company.address}</span>
                </div>
                <div className="col-2 d-flex flex-column px-3">
                    <label className={"fs-xs pb-2 text-secondary"}>Billing email</label>
                    <span className={"text-truncate"} title={company.billingEmail}>{company.billingEmail}</span>
                </div>
                <div className="col d-flex flex-column px-0 me-4 align-self-center">
                    <div className="actions">
                        <Dropdown align={"end"}>
                            <Dropdown.Toggle variant="white" className="btn-icon">
                                <i className="bi bi-three-dots-vertical"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => generateReport(company)}>Generate report</Dropdown.Item>
                                <Dropdown.Item onClick={() => editCompany(company)}>Edit</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="row justify-content-start mt-3">
                <div className="col-4 d-flex flex-column pb-0 pe-3 ms-1">
                    <label className={"fs-xs pb-2 text-secondary"}>Enabled services:</label>
                    <div className={"d-flex flex-row"}>
                        {company.services.aiGeneratedModelsService &&
                            <span className={"text-truncate me-4"}>AI model service</span>
                        }
                        {company.services.designerModelsService &&
                            <span className={"text-truncate"}>Designer model service</span>
                        }
                    </div>
                </div>
                <div className="col-1-5 d-flex flex-column px-3">
                    <label className={"fs-xs pb-2 text-secondary"}>Business type</label>
                    <span className={"text-truncate"}
                          title={getBusinessTypeText(company.isCompany)}>{getBusinessTypeText(company.isCompany)}</span>
                </div>
                <div className="col-1-5 d-flex flex-column px-3">
                    <label className={"fs-xs pb-2 text-secondary"}>Billing</label>
                    <span className={"text-truncate"}
                          title={getAutomaticInvoicingText(company.automaticInvoicing)}>{getAutomaticInvoicingText(company.automaticInvoicing)}</span>
                </div>
            </div>
        </div>
    );
}

export default CompanyListItem;

import React from "react";
import {ReactComponent as IconTickCircle} from "../../../../assets/icons/icon-tick-circle.svg";
import {ReactComponent as IconCloseCircle} from "../../../../assets/icons/icon-close-circle.svg";
import {ReactComponent as IconShieldTick} from "../../../../assets/icons/icon-shield-tick.svg";
import {ReactComponent as IconTickFilled} from "../../../../assets/icons/icon-tick-circle-filled.svg";
import {ReactComponent as IconCloseCircleFilled} from "../../../../assets/icons/icon-close-circle-filled.svg";
import imageSneakerDiagonal from "../../../../assets/sneaker-samples/sneaker-sample-diagonal.png";
import imageSneakerFront from "../../../../assets/sneaker-samples/sneaker-sample-front.png";
import imageSneakerTop from "../../../../assets/sneaker-samples/sneaker-sample-top.png";
import imageSneakerSide from "../../../../assets/sneaker-samples/sneaker-sample-side.png";
import imageSneakerCorrectFrame from "../../../../assets/sneaker-samples/sneaker-correct-sample.png";
import IconProvider from "../../../IconProvider";

const AiSampleImages: React.FC = () => {

    return (
        <div className="row justify-content-between px-4">
            <div className="col-sm-12 col-lg-6 p-0 pe-lg-2">
                <div className="row correct-image-sample ai-image-sample p-4 m-0 h-100">
                    <div className="row">
                        <p className="p-0">
                            <IconProvider Icon={IconTickCircle} size={28} className={"me-2"}/>
                            Perspectives to upload
                        </p>
                    </div>
                    <div className="p-0 d-flex">
                        <div className="align-self-end me-3 w-40">
                            <div className="sample-sneaker-container pt-2">
                                <img src={imageSneakerSide} className="sample-sneaker-img"
                                     alt="sample sneaker from side view"></img>
                                <IconProvider Icon={IconTickFilled} size={36} className="sample-sneaker-tick"/>
                            </div>
                        </div>

                        <img src={imageSneakerCorrectFrame} className="correct-sneaker-img"
                             alt="correct sneaker capture example"></img>

                        <div className="d-flex flex-column justify-content-center">
                            <span className="d-flex align-items-center"><IconProvider Icon={IconShieldTick}
                                                                                      size={24}/><p className="m-0">IDEALLY SQUARE IMAGE</p></span>
                            <span className="d-flex align-items-center"><IconProvider Icon={IconShieldTick}
                                                                                      size={24}/><p className="m-0">CENTERED IN THE FRAME</p></span>
                        </div>
                    </div>

                </div>
            </div>

            <div className="col-sm-12 col-lg-6 p-0 ps-lg-2 mt-sm-2 mt-lg-0">
                <div className="incorrect-image-sample ai-image-sample p-4 m-0 h-100 d-flex flex-column justify-content-between">
                    <div className="row"><p><IconProvider Icon={IconCloseCircle} size={28} className={"me-2"}/>
                        Perspectives to NOT upload</p></div>
                    <div className="row">
                        <div className="col-4 align-self-end">
                            <div className="sample-sneaker-container">
                                <img src={imageSneakerDiagonal} className="sample-sneaker-img"
                                     alt="sample sneaker from diagonal view"></img>
                                <IconProvider Icon={IconCloseCircleFilled} size={36} className="sample-sneaker-tick"/>
                            </div>
                        </div>

                        <div className="col-4 align-self-end">
                            <div className="sample-sneaker-container">
                                <img src={imageSneakerFront} className="sample-sneaker-img"
                                     alt="sample sneaker from front view"></img>
                                <IconProvider Icon={IconCloseCircleFilled} size={36} className="sample-sneaker-tick"/>
                            </div>
                        </div>
                        <div className="col-4 ps-0 align-self-end">
                            <div className="sample-sneaker-container">
                                <img src={imageSneakerTop} className="sample-sneaker-img pt-4"
                                     alt="sample sneaker from top view"></img>
                                <IconProvider Icon={IconCloseCircleFilled} size={36} className="sample-sneaker-tick"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AiSampleImages;

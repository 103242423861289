import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ProductInfoResponse from "../../types/product/product-info-response";
import {FileInputHooks} from "../../utils/FileUtils";
import {FormProvider, UseFormReturn} from "react-hook-form";
import DownloadableImageThumbnails from "../product/DownloadableImageThumbnails";
import NewInput from "../NewInput";
import ProductOfferUpdateRequest from "../../types/product/product-offer-update-request";
import {Attachment, ProductThumbnailsResponse} from "../../types/attachment/attachment-response";
import PreFilledProduct from "../PreFilledProduct";

import ModelsAndAttachmentForm from "../ModelsAndAttachmentForm";
import {ModelsAttachmentsCommentsForm} from "../../types/models-attachments-comments-form";

interface IClientUpload {
    product: ProductInfoResponse | undefined;
    productOfferSaveRequestMethods: UseFormReturn<ProductOfferUpdateRequest>;
    clientModelAttachmentUploadSaveRequestMethods: UseFormReturn<ModelsAttachmentsCommentsForm>;
    versionNumber: string | null;
    versionModels: Attachment[];
    versionAttachments: Attachment[];
    areAttachmentsAllowed?: boolean
}

const ClientUploadForm: React.FC<IClientUpload> = ({
                                                       versionNumber,
                                                       versionModels,
                                                       versionAttachments,
                                                       product,
                                                       productOfferSaveRequestMethods,
                                                       clientModelAttachmentUploadSaveRequestMethods,
                                                       areAttachmentsAllowed
                                                   }) => {
    const {productUuid} = useParams();
    const [downloadedFiles, setDownloadedFiles] = useState<ProductThumbnailsResponse>();
    const [productLoaded, setProductLoaded] = useState<boolean>(false);
    const productOfferErrors = productOfferSaveRequestMethods.formState.errors;

    useEffect(() => {
        if (productLoaded || !product) return;
        productOfferSaveRequestMethods.setValue("price", product.price);
        productOfferSaveRequestMethods.setValue("productionTime", product.productionTime);
        setProductLoaded(true);
    }, [product, productLoaded, setProductLoaded, productOfferSaveRequestMethods]);

    FileInputHooks(productUuid, setDownloadedFiles);

    return (
        <>
            <main className="view-content -with-footer container">
                <FormProvider {...productOfferSaveRequestMethods}>
                    <form className="bg-white dropzone">
                        <div className="row">
                            <div className="col-md-5 offset-md-1">
                                <h3 className="mb-4">Product {product?.modelName}</h3>
                            </div>
                            <div className="col-md-5 text-end">{/*spacer*/}</div>
                            <div className="col-md-5 offset-md-1">
                                <PreFilledProduct product={product}/>
                                <NewInput name={"price"}
                                          label={"Price (€)"}
                                          errorMessage={productOfferErrors?.price?.message}/>
                                <NewInput name={"productionTime"}
                                          label={"Production time (days)"}
                                          errorMessage={productOfferErrors?.productionTime?.message}/>
                            </div>
                            <div className="col-md-5">
                                <div className="thumbnail-gallery">
                                    <DownloadableImageThumbnails files={downloadedFiles} name={`product.files`}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>

                <ModelsAndAttachmentForm versionModels={versionModels}
                                         versionAttachments={versionAttachments}
                                         versionNumber={versionNumber}
                                         saveRequestMethods={clientModelAttachmentUploadSaveRequestMethods}
                                         isClientUpload={true}
                                         areAttachmentsAllowed={areAttachmentsAllowed}
                                         attachmentLabel={"Attachments (accessible/downloadable to client) \n (max 400mb) split larger files"}
                />
            </main>
        </>
    );
};

export default ClientUploadForm;

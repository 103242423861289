import React, {createContext, FC, useCallback, useContext, useState} from "react";
import AlphaAlert, {AlertType} from "../types/alpha-alert";
import GenericErrorResponse from "../types/generic-error-response";
import NewAlert from "../components/NewAlert";

interface IAlphaAlert {
    addErrorResponseAlert: (error: GenericErrorResponse) => void;
    addSuccessAlert: (body: string) => void;
    addErrorAlert: (body: string) => void;
    addWarningAlert: (body: string) => void;
    addInfoAlert: (body: string) => void;
}

interface AlertProviderProps {
    children?: React.ReactNode;
}

const AlertContext = createContext<IAlphaAlert | null>(null);

export const AlertProvider: FC<AlertProviderProps> = ({children}) => {
    const [alerts, setAlerts] = useState<AlphaAlert[]>([]);
    const addAlert = useCallback((body: string, type: AlertType) => {
        setAlerts((prev) => [...prev, {
            body: body,
            type: type,
            id: prev.length + 1,
        }]);
    }, []);

    const addErrorResponseAlert = useCallback((error: GenericErrorResponse) => {
        addAlert(error.message, AlertType.DANGER);
    }, [addAlert]);

    const addErrorAlert = useCallback((body: string) => {
        addAlert(body, AlertType.DANGER);
    }, [addAlert]);

    const addSuccessAlert = useCallback((body: string) => {
        addAlert(body, AlertType.SUCCESS);
    }, [addAlert]);

    const addWarningAlert = useCallback((body: string) => {
        addAlert(body, AlertType.WARNING);
    }, [addAlert]);

    const addInfoAlert = useCallback((body: string) => {
        addAlert(body, AlertType.INFO);
    }, [addAlert]);

    return (
        <AlertContext.Provider
            value={{addErrorAlert, addErrorResponseAlert, addSuccessAlert, addWarningAlert, addInfoAlert}}>
            {children}
            <div className={"container app-footer"} style={{backgroundColor: "transparent", zIndex: "9999"}}>
                {alerts.map((alert, index) => {
                    return (
                        <NewAlert alert={alert} key={index}/>
                    );
                })}
            </div>
        </AlertContext.Provider>
    );
}

export const useAlerts = () => {
    const context = useContext(AlertContext);

    if (!context) {
        throw new Error('Missing AlertContext.');
    }

    return context;
}

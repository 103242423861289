import React, {createContext, FC, useContext} from "react";
import {AlphaPayment} from "../types/payment-price";
import {QueryObserverResult, useQuery} from "react-query";
import PaymentTokenClient from "../services/payment-token.client";
import {useAuthContext} from "./AuthProvider";

interface IPaymentTokenProvider {
    tokens: number,
    prices: Map<AlphaPayment, number>,
    refetchTokenAmount: () => Promise<QueryObserverResult<number>>
}

interface ProviderProps {
    children?: React.ReactNode;
}

const PaymentTokenContext = createContext<IPaymentTokenProvider | null>(null);

export const PaymentTokenProvider: FC<ProviderProps> = ({children}) => {
    const {user} = useAuthContext();

    const {
        data: prices
    } = useQuery(['paymentPrices', user?.email], PaymentTokenClient.getTokenPrices, {
        staleTime: Infinity,
        enabled: user != null
    });

    const {
        data: tokens, refetch: refetchTokenAmount
    } = useQuery(['companyPaymentTokenAmount', user?.email], PaymentTokenClient.getCompanyPaymentTokenAmount, {
        staleTime: Infinity,
        enabled: user != null
    });

    return <PaymentTokenContext.Provider
        value={{
            tokens: tokens || 0,
            prices: prices || new Map<AlphaPayment, number>(), refetchTokenAmount
        }}>{children}</PaymentTokenContext.Provider>;
};

export const usePaymentToken = () => {
    const context = useContext(PaymentTokenContext);

    if (!context) {
        throw new Error('Missing ClassificationContext.');
    }

    return context;
}

import React, {createContext, FC, useContext, useEffect, useState} from "react";
import jwtDecode, {JwtPayload} from "jwt-decode";
import {UserAccount} from "../types/user/user-account";
import {UserRole} from "../types/user/user-role";
import ProjectClient from "../services/project.client";
import CompanyClient from "../services/company.client";

interface IAuthProvider {
    user: UserAccount | null;
    setUser: (newValue: UserAccount | null) => void;
    hasRole: (role: UserRole) => boolean;
    emptyPendingQuote: () => void;
}

interface AuthProviderProps {
    children?: React.ReactNode;
}

const AuthContext = createContext<IAuthProvider | null>(null);


export const useAuthContext = (): IAuthProvider => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("Missing AuthContext.");
    }

    return context;
};

export const AuthProvider: FC<AuthProviderProps> = ({children}) => {
    const accessTokenString = localStorage.getItem("access_token") || "";
    const refreshTokenString = localStorage.getItem("refresh_token");

    let jwtPayload;
    if (accessTokenString && refreshTokenString) {
        const expires = jwtDecode<JwtPayload>(refreshTokenString).exp!;
        jwtPayload = new UserAccount(jwtDecode<JwtPayload>(accessTokenString), expires);
    } else {
        jwtPayload = null;
    }
    const userAccount = jwtPayload;
    const [user, setUser] = useState(userAccount);

    const hasRole = (role: UserRole): boolean => {
        if (!user) return false;
        if (!Array.isArray(user.roles) || user.roles.length < 1) return false;
        return user.roles.includes(role);
    }

    useEffect(() => {
        if (!user) return;
        if (user.pendingQuotes !== null) return;
        ProjectClient.getProjectsThatNeedCustomerQuoteApproval().then((res) => {
            setUser({...user, pendingQuotes: res});
        });
    }, [user, setUser]);

    useEffect(() => {
        if (!user) return;
        if (user.paymentFailure !== null) return;
        CompanyClient.getCompanyPaymentStatus().then((res) => {
            setUser({...user, paymentFailure: res});
        });
    }, [user, setUser]);

    useEffect(() => {
        if (!user) return;
        if (user.automaticInvoicing !== null) return;
        CompanyClient.isCompanyAutomaticInvoicingEnabled().then((res) => {
            setUser({...user, automaticInvoicing: res});
        });
    }, [user, setUser]);

    const reFetchPendingQuotes = () => {
        if (!user) return;
        ProjectClient.getProjectsThatNeedCustomerQuoteApproval().then((res) => {
            setUser({...user, pendingQuotes: res});
        });
    }

    return <AuthContext.Provider
        value={{
            user: user,
            setUser: setUser,
            hasRole,
            emptyPendingQuote: reFetchPendingQuotes
        }}>{children}</AuthContext.Provider>;
};

import React from "react";
import {Modal} from "react-bootstrap";
import LoadingButton from "../product/LoadingButton";

const ConfirmationModal: React.FC<{
    handleClose: () => void;
    confirmationText?: string;
    closeModalText?: string;
    handleConfirmation: () => void;
    header: string;
    body?: string;
    showModal: boolean;
    loading?: boolean;
    id?: string;
    size?: "lg" | "sm" | "xl";
    onTop?: boolean;
}> = ({
          handleClose,
          handleConfirmation,
          header,
          body,
          showModal,
          id,
          size,
          closeModalText = "Cancel",
          confirmationText = "Yes",
          onTop = false,
          loading = false
      }) => {
    return (
        <Modal size={size} show={showModal}
               className={`modal fade ${onTop ? "on-top-modal" : ""}`}
               backdropClassName={`${onTop ? "on-top-backdrop" : ""}`} id={id}>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" id="addNewUserModal">
                        {header}
                    </h4>
                    <button
                        onClick={handleClose}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
                <div className="modal-body" style={{wordWrap: "break-word", whiteSpace: "pre-wrap"}}>
                    {body}
                    <div className="modal-footer mt-5">
                        <button
                            onClick={handleClose}
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            {closeModalText}
                        </button>
                        <LoadingButton
                            onClick={handleConfirmation}
                            loading={loading}
                            loadingTooltip={""}
                            buttonText={confirmationText} />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;

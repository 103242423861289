import {useCallback, useEffect, useState} from "react";
import {AttachmentInfo} from "../../types/attachment/attachment-response";

import {fileToAttachmentInfo} from "../../utils/DropzoneUtils";
import {useFormContext} from "react-hook-form";

export function BulkImport(name?: string) {
    const [attachments, setAttachments] = useState<AttachmentInfo[]>([]);
    const {trigger, setValue, getValues} = useFormContext();
    const [triggerCount, setTriggerCount] = useState<number>(0);

    const [attachmentUuidsName] = useState(name ? name + ".files" : "files");

    const removeAttachment = useCallback((attachment: AttachmentInfo) => {
        setAttachments((currentAttachments) => {
            return currentAttachments.filter(file => file.referenceUuid !== attachment.referenceUuid)
        });
        setTriggerCount((prev) => {return prev + 1});
    }, []);

    useEffect(() => {
        if (triggerCount === 0) return;
        trigger(attachmentUuidsName).then();
    }, [triggerCount, attachmentUuidsName, trigger])

    const updateFiles = (newFiles: AttachmentInfo[]) => {
        setAttachments(newFiles);
        setTriggerCount((prev) => {return prev + 1});
    }

    const updateFile = useCallback((attachment: AttachmentInfo) => {
        setAttachments((currentAttachments) => currentAttachments.map(currentAttachment =>
            currentAttachment.referenceUuid === attachment.referenceUuid
                ? attachment
                : currentAttachment
        ));
        setTriggerCount((prev) => {return prev + 1});
    }, []);

    useEffect(function setFileHandlerFields() {
        setValue(attachmentUuidsName, attachments.map(attachment => attachment.file));
    }, [attachments, name, setValue, attachmentUuidsName])

    return {
        updateFiles,
        updateFile,
        attachments,
        setValue,
        getValues,
        removeFile: removeAttachment
    }
}

export function handleFileHandlerDropzone(acceptedFiles: File[], addErrorAlert: (body: string) => void, updateFiles: (files: AttachmentInfo[]) => void) {
    let files: AttachmentInfo[] = [];
    for (let acceptedFile of acceptedFiles) {
        let attachment = fileToAttachmentInfo(acceptedFile, addErrorAlert);
        if (attachment) {
            files.push(attachment);
        }
    }
    updateFiles(files);
}

import CryptoJS from "crypto-js"

export function decryptBase64(input: string): string | undefined {
    const jwt_token = localStorage.getItem("access_token");
    if (!jwt_token) return;
    let key = jwt_token.substring(49, 49 + 16);

    let decrypted = CryptoJS.AES.decrypt(input, CryptoJS.enc.Utf8.parse(key), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Base64)
}

import React, {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import ProjectClient from "../../services/project.client";
import ProjectResponse from "../../types/project-response";
import ProjectHeader from "../../components/new-project/project/ProjectHeader";
import {ProductStatus} from "../../types/product/product-status";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import {useAlerts} from "../../store/AlertProvider";
import ProductClient, {formatErrorResponse} from "../../services/product.client";
import ProductCard from "../../components/product/ProductCard";
import {ProjectStatus} from "../../types/project/project-status";
import LoadingComponent from "../../components/common/LoadingComponent";
import {useAuthContext} from "../../store/AuthProvider";
import {redirectToStripe} from "../../utils/PayementUtil";
import PaymentClient from "../../services/company.client";
import ProblemWithPaymentModal from "./ProblemWithPaymentModal";
import {QUOTE_APPROVAL} from "../../common-texts";
import {useMutation} from "react-query";
import Pagination from "../../components/Pagination";

const REJECT_CONFIRMATION_MODAL_BODY = "The project cannot be restored after it’s been rejected."

const ProjectPage: React.FC = () => {
    const {projectUuid} = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [project, setProject] = React.useState<ProjectResponse>();
    const [showDeleteProductModal, setShowDeleteProductModal] = React.useState<boolean>(false);
    const [showRestoreProductModal, setShowRestoreProductModal] = React.useState<boolean>(false);
    const [showApproveAllQuotesModal, setShowApproveAllQuotesModal] = React.useState<boolean>(false);
    const [showPaymentFailedModal, setShowPaymentFailedModal] = React.useState<boolean>(false);
    const [showRejectAllQuotesModal, setShowRejectAllQuotesModal] = React.useState<boolean>(false);
    const [totalBalance, setTotalBalance] = useState<number>();
    const [idToDelete, setIdToDelete] = React.useState<string>("");
    const [idToRestore, setIdToRestore] = React.useState<string>("");
    const [reloadData, setReloadData] = React.useState<boolean>(false);
    const {addSuccessAlert, addErrorAlert, addErrorResponseAlert} = useAlerts();
    const [areAllProductsApprovable, setAreAllProductsApprovable] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(true);
    const activeTab = searchParams.get("productStatuses");
    const {emptyPendingQuote} = useAuthContext();

    const [productPageNumber, setProductPageNumber] = useState(0);
    const [productSearchName, setProductSearchName] = useState("");
    const productListLoadingInterval = useRef<NodeJS.Timeout | null>(null);

    const approveAllQuotesMutation = useMutation({
        mutationFn: async () => {
            if (!projectUuid) {
                addErrorAlert("No project id provided");
                return;
            }
            await ProjectClient.approveAllProjectOffers(projectUuid);
            await fetchProject();
        },
        onSuccess: () => {
            emptyPendingQuote();
            setShowApproveAllQuotesModal(false);
            addSuccessAlert("Project's quotes approved");
            setReloadData(true);
        },
        onError: () => {
            setShowApproveAllQuotesModal(false);
            setShowPaymentFailedModal(true);
        }
    })

    const fetchProject = useCallback(async () => {
        setLoading(true);
        const query = {
            page: productPageNumber,
            name: productSearchName,
            productStatuses: searchParams.getAll("productStatuses")
        };
        ProjectClient.getProject(projectUuid!, query).then((res) => {
            setProject(res);
            setLoading(false);
        });
        if (productListLoadingInterval.current){
            clearInterval(productListLoadingInterval.current);
        }
         productListLoadingInterval.current = setInterval(() => {
            ProjectClient.getProject(projectUuid!, query).then((res) => {
                setProject(res);
            });
        }, 60000)
    }, [projectUuid, searchParams, productSearchName, productPageNumber]);

    useEffect(() => {
        fetchProject().then()
    }, [projectUuid, searchParams, fetchProject]);

    useEffect(() => {
        if (!project) return;
        if ((activeTab && activeTab !== ProductStatus.PENDING_QUOTE) || project.products?.content?.length < 1) {
            setAreAllProductsApprovable(false);
            return;
        }
        setAreAllProductsApprovable(project.areAllProductsApprovable);
    }, [project, activeTab])

    useEffect(() => {
        if (totalBalance) return;
        PaymentClient.getCompanyBalance().then(res => {
            setTotalBalance(res);
        })
    }, [totalBalance]);


    const fetchAreProductsApprovable = async () => {
        if (!project) return;
        await ProjectClient.getProjectProductsAreApprovable(projectUuid!)
            .then((res) => setProject({...project, areAllProductsApprovable: res}))
    }

    const getApproveQuoteText = () => {
        return "You're about to send this project to production and can no longer cancel. The fee will be automatically added to your balance after the 3D asset has been approved.";
    }


    const deleteInQuote = async (productUuid: string) => {
        await ProductClient.deleteProduct(productUuid)
            .then(() => {
                changeProductStatus(productUuid, ProductStatus.DELETED);
                addSuccessAlert("Product successfully deleted");
                fetchAreProductsApprovable();
                emptyPendingQuote();
                setReloadData(true);
            })
            .catch((res) =>
                addErrorResponseAlert(formatErrorResponse(res.response))
            );
        setShowDeleteProductModal(false);
    };

    const restoreDeletedProduct = async (productUuid: string) => {
        await ProductClient.restoreProduct(productUuid)
            .then(() => {
                changeProductStatus(productUuid, ProductStatus.PENDING_QUOTE);
                addSuccessAlert("Product successfully restored");
                emptyPendingQuote();
                setReloadData(true);
            })
            .catch((res) =>
                addErrorResponseAlert(formatErrorResponse(res.response))
            );
        setShowRestoreProductModal(false);
    }

    function changeProductStatus(productUuid: string, status: ProductStatus) {
        project!.products.content.find((product) => {
            return product.uuid === productUuid;
        })!.status = status;
    }

    const rejectAllQuotes = async () => {
        if (!projectUuid) {
            addErrorAlert("No project Id provided");
            return;
        }
        await ProjectClient.rejectAllProjectOffers(projectUuid).then(
            () => emptyPendingQuote()
        );
        setShowRejectAllQuotesModal(false)
        addSuccessAlert("Project's quotes deleted");
        setReloadData(true);
        navigate("/")
        await fetchProject()
    }

    const deleteProduct = (productUuid: string) => {
        setIdToDelete(productUuid);
        setShowDeleteProductModal(true);
    };

    const restoreProduct = (productUuid: string) => {
        setIdToRestore(productUuid);
        setShowRestoreProductModal(true);
    };

    function showProducts() {
        return <div className="container">
            <div className="row">
                {project?.products?.content?.filter(product => {
                    if (!searchParams.get("productStatuses")) {
                        return product.status !== ProductStatus.DELETED;
                    } else {
                        return product.status === searchParams.get("productStatuses")
                    }
                }).map((product, index) => {
                    return (<ProductCard
                            key={index}
                            product={product}
                            index={index}
                            isRestorable={project?.status === ProjectStatus.PENDING_QUOTES}
                            restoreProduct={restoreProduct}
                            deleteProduct={deleteProduct}/>
                    );
                })}
            </div>
        </div>;
    }

    function showLoading() {
        return (
            <div className="text-center align-content-center">
                <LoadingComponent/>
            </div>
        );
    }

    const [query, setQuery] = useState(productSearchName || "");
    const handleQueryAndSearch = (event: { target: { value: any; }; }) => {
        const value = event.target.value;
        setQuery(value);

        if (value.length >= 3) {
            setProductSearchName && setProductSearchName(value);
        } else {
            setProductSearchName && setProductSearchName("");
        }
    };

    return (
        <>
            <ConfirmationModal
                header="Delete product?"
                body={`Are you sure you want to delete the product?`}
                handleClose={() => setShowDeleteProductModal(false)}
                handleConfirmation={() => deleteInQuote(idToDelete)}
                showModal={showDeleteProductModal}
            />
            <ConfirmationModal
                header="Restore product?"
                body={`Are you sure you want to restore the product?`}
                handleClose={() => setShowRestoreProductModal(false)}
                handleConfirmation={() => restoreDeletedProduct(idToRestore)}
                showModal={showRestoreProductModal}
            />
            <ConfirmationModal
                header="Reject all quotes?"
                body={REJECT_CONFIRMATION_MODAL_BODY}
                handleClose={() => setShowRejectAllQuotesModal(false)}
                handleConfirmation={() => rejectAllQuotes()}
                showModal={showRejectAllQuotesModal}
            />
            <ConfirmationModal
                header="Approve all quotes?"
                body={getApproveQuoteText()}
                handleClose={() => setShowApproveAllQuotesModal(false)}
                handleConfirmation={() => approveAllQuotesMutation.mutate()}
                showModal={showApproveAllQuotesModal}
                loading={approveAllQuotesMutation.isLoading}
            />
            <ProblemWithPaymentModal
                handleClose={() => setShowPaymentFailedModal(false)}
                handleConfirmation={() => redirectToStripe(addErrorResponseAlert)}
                showModal={showPaymentFailedModal}
                bodyText={QUOTE_APPROVAL}
            />

            <ProjectHeader projectUuid={projectUuid!} projectName={project?.name} aiMethod={project?.aiMethod} reloadData={reloadData}
                           setReloadFalse={() => setReloadData(false)} productSearchName={productSearchName}/>
            <main className="view-content">
                <div className="projects-header mb-4">

                    <div className="container">
                        <div className="row">
                            <div className={"col-md-6"}>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}
                                     className="col-md-12">
                                    <input className={"form-control form-control-lg float-start"} value={query}
                                           onChange={handleQueryAndSearch} placeholder={"Search..."}/>
                                    <span style={{display: "contents"}}>
                                            <i className="bi bi-search"
                                               style={{position: "relative", right: "30px"}}></i>
                                        </span>
                                </div>
                            </div>
                            {(areAllProductsApprovable && project?.status === ProjectStatus.PENDING_QUOTES) &&
                                <div className={"col text-end"}>
                                    {areAllProductsApprovable &&
                                        <>
                                            <button type="button"
                                                    onClick={() => setShowRejectAllQuotesModal(true)}
                                                    className="btn btn-gray">
                                                Reject all quotes
                                            </button>
                                            <button type="button"
                                                    onClick={() => setShowApproveAllQuotesModal(true)}
                                                    className="btn btn-success ms-2">
                                                Approve all quotes
                                            </button>
                                        </>
                                    }
                                    {project?.status === ProjectStatus.PENDING_QUOTES &&
                                        <button type="button"
                                                onClick={() => navigate("/project/" + projectUuid + "/add-new-product")}
                                                className="btn btn-dark btn-icon ms-2">
                                            <i className="bi bi-plus-circle me-2"/> Add new products
                                        </button>
                                    }
                                </div>
                            }
                        </div>
                    </div>

                </div>
                <div className="projects-list">
                    {loading ? showLoading() : showProducts()}
                </div>

                {project?.products?.content && (
                    <Pagination page={{
                        page: project?.products.pageable?.pageNumber || 0,
                        totalPages: project?.products?.totalPages || 0,
                        totalElements: project?.products?.totalElements || 0
                    }} setCurrentPage={setProductPageNumber}/>
                )}
            </main>
        </>
    );
};

export default ProjectPage;

import * as Yup from "yup";
import {getProductsValidation} from "./new-product-validation";
import {ProductionType} from "../types/project/production-type";
import {AiMethod} from "../types/project/ai-method-type";

export const MAX_FILE_SIZE: number = 9437184;

export function checkMaximumFileSize(files: FileList) {
    return !Array.from(files).some((file) => file.size > MAX_FILE_SIZE);
}

export const newProjectValidationSchema = Yup.object().shape({
    projectName: Yup.string().required("This field is required!"),
    endPoint: Yup.string()
        .when("productionType", {
            is: isProductionTypeOf(ProductionType.DESIGNER),
            then: Yup.string().required("This field is required!").nullable(),
            otherwise: Yup.string().nullable()
        }),
    productionType: Yup.string().required("This field is required!"),
    specification: Yup.string()
        .when("productionType", {
            is: isProductionTypeOf(ProductionType.DESIGNER),
            then: Yup.string().required("This field is required!").nullable(),
            otherwise: Yup.string().nullable()
        }),
    products: Yup.array()
        .when(["productionType", "aiMethod"], {
            is: (checkTypeProduction: ProductionType, checkTypeAiMethod: AiMethod) => {
                return !(checkTypeProduction === ProductionType.AI_GENERATED && checkTypeAiMethod === AiMethod.ANYTHING)
            },
            then: Yup.array()
                .when("productionType", {
                    is: isProductionTypeOf(ProductionType.AI_GENERATED),
                    then: getProductsValidation(ProductionType.AI_GENERATED, false)
                })
                .when("productionType", {
                    is: isProductionTypeOf(ProductionType.DESIGNER),
                    then: getProductsValidation(ProductionType.DESIGNER, false)
                }),
            otherwise: getProductsValidation(ProductionType.AI_GENERATED, true)
        }),
    aiMethod: Yup.string()
        .when("productionType", {
            is: isProductionTypeOf(ProductionType.AI_GENERATED),
            then: Yup.string().required("This field is required!").nullable(),
            otherwise: Yup.string().nullable()
        }),
    triangleCount: Yup.number()
        .transform(nullIfNan)
        .when(["productionType", "aiMethod"], {
            is: isProductionTypeOfAndAiMethodOf(ProductionType.AI_GENERATED, AiMethod.MESH),
            then: Yup.number().required("This field is required!").nullable(),
            otherwise: Yup.number().nullable(),
        }),
    unProcessedAttachments: Yup.number().max(0, "Can't save project while attachments are still processing")
});

function nullIfNan(input: number) {
    if (isNaN(input)) {
        return null;
    }
    return input;
}


function isProductionTypeOf(checkType: ProductionType) {
    return (productionType: string) => productionType === checkType;
}


function isAiMethodOf(checkType: AiMethod) {
    return (aiMethod: string) => aiMethod === checkType;
}

function isProductionTypeOfAndAiMethodOf(checkTypeProduction: ProductionType, checkTypeAiMethod: AiMethod) {
    return isProductionTypeOf(checkTypeProduction) && isAiMethodOf(checkTypeAiMethod);
}

import ProjectRequest from "../types/project/project-request";
import NewProjectSaveResponse from "../types/project/new-project-save-response";
import axios, {AxiosResponse} from "axios";
import ProjectResponse from "../types/project-response";
import GenericErrorResponse from "../types/generic-error-response";
import ProductCounterResponse from "../types/product/product-counter-response";
import ProjectCounterResponse from "../types/project/project-counter-response";
import EditDraftResponse from "../types/edit-draft-response";
import ProjectInfoResponse from "../types/project/project-info-response";
import {AddNewProductsResponse, ProductRequest} from "../types/product/add-new-products-dtos";
import {ProjectStatus} from "../types/project/project-status";
import {ProductionType} from "../types/project/production-type";
import PageableProjectResponse from "../types/user/pageable-projects-response";

const PROJECT_ENDPOINT = process.env.REACT_APP_API_URL + "project/";

export class ProjectClient {
    createProject(request: ProjectRequest, status: ProjectStatus): Promise<NewProjectSaveResponse> {
        return axios
            .post(PROJECT_ENDPOINT,
                request,
                {
                    params: {projectStatus: status}
                })
            .then((res) => {
                return res.data;
            });
    }

    getProjectCounts(name?: string): Promise<ProjectCounterResponse[]> {
        let query = name ? '?name=' + name : '';
        return axios
            .get(PROJECT_ENDPOINT + "counts" + query)
            .then((res) => res.data);
    }

    getProjectsThatNeedCustomerQuoteApproval(): Promise<number> {
        return axios
            .get(PROJECT_ENDPOINT + "pendingApproval")
            .then((res) => res.data);
    }

    getProjectProductCount(projectUuid: string): Promise<ProductCounterResponse[]> {
        return axios
            .get(PROJECT_ENDPOINT + projectUuid + "/products/count")
            .then((res) => res.data);
    }

     getProject = (projectUuid: string, query?: any): Promise<ProjectResponse> => {
        let qParams = new URLSearchParams();
        qParams.append('page', query?.page || 0);
        qParams.append('size', query?.size || 16);
        qParams.append('name', query?.name || '');

        query?.productStatuses.forEach((status: any) => {
            qParams.append('productStatuses', status);
        })
        return axios.get(PROJECT_ENDPOINT + projectUuid + "/products", {params: qParams}).then((res) => {
            return res.data;
        });
    };

    getProjectInfo(projectUuid: string, status?: string): Promise<ProjectInfoResponse> {
        let endpoint = PROJECT_ENDPOINT + projectUuid;
        if (status) {
            endpoint += "?projectStatuses=" + status
        }
        return axios
            .get(endpoint)
            .then((res) => {
                return res.data;
            });
    }

    getProjectProductsAreApprovable(projectUuid: string): Promise<boolean> {
        let endpoint = PROJECT_ENDPOINT + projectUuid + "/approvable";
        return axios
            .get(endpoint)
            .then((res) => {
                return res.data;
            });
    }

    editDraft(projectUuid: string, request: ProjectRequest, status: ProjectStatus): Promise<NewProjectSaveResponse> {
        return axios
            .post(PROJECT_ENDPOINT + projectUuid + "/edit",
                request,
                {
                    params: {projectStatus: status}
                })
            .then((res) => {
                return res.data;
            });
    }

    deleteProject(projectUuid: string) {
        return axios
            .delete(PROJECT_ENDPOINT + projectUuid)
            .then((res) => res.data)
            .catch((res) => formatErrorResponse(res.response));
    }

    getPaginatedProjectsResponse = (query?: any): Promise<PageableProjectResponse> => {
        let qParams = new URLSearchParams();
        qParams.append('page', query?.page || 0);
        qParams.append('size', query?.size || 16);
        qParams.append('name', query?.name || '');

        query?.projectStatuses.forEach((status: any) => {
            qParams.append('projectStatuses', status);
        })
        return axios.get(PROJECT_ENDPOINT + "searchAllProjects", {params: qParams}).then((res) => {
            return res.data;
        });
    };

    getDraftEdit(projectUuid: string): Promise<EditDraftResponse> {
        return axios
            .get(PROJECT_ENDPOINT + projectUuid + "/edit")
            .then((res) => {
                return res.data;
            });
    }

    approveAllProjectOffers(projectUuid: string) {
        return axios
            .post(PROJECT_ENDPOINT + projectUuid + "/offer/approve/all",
                null)
            .then((res) => {
                return res.data;
            })
    }

    rejectAllProjectOffers(projectUuid: string) {
        return axios
            .post(PROJECT_ENDPOINT + projectUuid + "/offer/reject/all",
                null)
            .then((res) => {
                return res.data;
            })
    }


    addProducts(projectUuid: string, formValue: ProductRequest[]): Promise<AddNewProductsResponse> {
        return axios
            .put(PROJECT_ENDPOINT + projectUuid + "/add/product",
                formValue)
            .then((res) => {
                return res.data;
            })
    }

    getProjectDefaultName(): Promise<string> {
        return axios
            .get(PROJECT_ENDPOINT + "generateDefaultName")
            .then((res) => {
                return res.data;
            });
    }

    getProjectsGeneratedCount(): Promise<Map<ProductionType, number>> {
        return axios
            .get(PROJECT_ENDPOINT + "projectsGeneratedCount")
            .then((res) => {
                const map = new Map<ProductionType, number>();
                for (const [key, value] of Object.entries(res.data)) {
                    map.set(key as ProductionType, value as number)
                }
                return map;
            });
    }
}

export const formatErrorResponse = (response: AxiosResponse): GenericErrorResponse => {
    return {
        status: response.data.status,
        message: response.data.detail
    };
};

export default new ProjectClient();

import PrefilledInput from "./common/PrefilledInput";
import PrefilledTextArea from "./common/PrefilledTextArea";
import React from "react";
import ProductInfoResponse from "../types/product/product-info-response";
import {ProductionType} from "../types/project/production-type";
import {AiMethod} from "../types/project/ai-method-type";

const PreFilledProduct: React.FC<{ product: ProductInfoResponse | undefined }> = ({product}) => {
    return (
        <>
            <PrefilledInput
                className={product && product.productionType && product.productionType === ProductionType.AI_GENERATED ? "d-none" : ""}
                name="brandname"
                label="Brand name"
                value={product?.brandName}/>
            {product?.category?.name === AiMethod.ANYTHING ? (
                <PrefilledInput
                    name="modelName"
                    label="Asset name"
                    value={product?.modelName}
                />
            ) : (
                <PrefilledInput
                    name="modelName"
                    label="Product name"
                    value={product?.modelName}
                />
            )}

            <PrefilledInput
                className={product && product.productionType && product.productionType === ProductionType.AI_GENERATED ? "d-none" : ""}
                name="idNumber"
                label="Product ID/SKU number"
                value={product?.idNumber}
            />
            <PrefilledInput
                className={product && product.productionType && product.productionType === ProductionType.AI_GENERATED ? "d-none" : ""}
                name="url"
                label="Product URL"
                value={product?.url}
            />
            <PrefilledInput
                name="category"
                label="Category"
                value={product?.category.value}
            />
            <PrefilledTextArea
                className={product && product.productionType && product.productionType === ProductionType.AI_GENERATED ? "d-none" : ""}
                name="notes"
                label="Notes (dimensions, file size, polycount etc.)"
                value={product?.notes}
            />
        </>

    );
};

export default PreFilledProduct;

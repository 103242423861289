import React from 'react';
import {FormProvider, UseFormReturn} from "react-hook-form";
import NewInput from "../NewInput";
import UserChangePassword from "../../types/user/user-change-password";
import RequestResult from "../RequestResult";

const AccountForm: React.FC<{
    updateMethods: UseFormReturn<UserChangePassword>;
    isSuccessful: boolean;
    errorMessage: string;
    hasOldPassword: boolean;
}> = ({updateMethods, isSuccessful, errorMessage, hasOldPassword}) => {
    const errors = updateMethods.formState.errors;

    return (
        <FormProvider {...updateMethods}>
            <form className="bg-white">
                <main className="view-content -with-footer container">
                    <div className="row">
                        <div className="col-md-5 offset-md-1">
                            <h4 className="pb-3">Change your password</h4>
                            {hasOldPassword &&
                                <NewInput name={"oldPassword"} errorMessage={errors?.oldPassword?.message}
                                          label="Old password" type="password"/>}
                            <NewInput name={"newPassword"} errorMessage={errors?.newPassword?.message}
                                      label="New password" type="password"/>
                            <NewInput name={"newPasswordAgain"} errorMessage={errors?.newPasswordAgain?.message}
                                      label="New password again" type="password"/>

                            <RequestResult successfulMessage="Password updated." successful={isSuccessful}
                                           error={errorMessage}/>
                        </div>
                    </div>
                </main>
            </form>
        </FormProvider>
    );
};

export default AccountForm;

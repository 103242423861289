import React, {useCallback, useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation, useMatch, useNavigate, useSearchParams} from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import AuthService from "./services/auth.service";
import EventBus from "./common/EventBus";
import {useAuthContext} from "./store/AuthProvider";
import ProjectPage from "./pages/project/ProjectPage";
import NewProjectPage from "./pages/project/NewProjectPage";
import EditDraftPage from "./pages/project/EditDraftPage";
import ProjectDetailsPage from "./pages/project/ProjectDetailsPage";
import {UserRole} from "./types/user/user-role";
import ClientUploadPage from "./pages/admin/ClientUploadPage";
import ProductDetailsPage from "./pages/product/ProductDetailsPage";
import ProductPage from "./pages/product/ProductPage";
import AddNewProductPage from "./pages/project/AddNewProductPage";
import AppHeader from "./components/AppHeader";
import InternalUploadPage from "./pages/admin/InternalUploadPage";
import PendingQuoteHeader from "./components/PendingQuoteHeader";
import EmptyPage from "./pages/EmptyPage";
import LoggedOutHeader from "./components/LoggedOutHeader";
import PaymentFailedPage from "./pages/PaymentFailedPage";
import AdminDashboardPage from "./pages/admin/AdminDashboardPage";
import SignupPage from "./pages/SignupPage";
import AccountPage from "./pages/AccountPage";
import {addJwtInterceptor, addRequestInterceptor} from "./services/axios.interceptor.service";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import AuthCodeSignIn from "./pages/AuthCodeSignIn";
import CompanySetup from "./pages/CompanySetup";
import VerifyAccount from "./pages/VerifyAccount";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent} from "firebase/analytics";
import {firebaseConfig} from "./utils/FirebaseUtil";
import Page from "./pages/Page";
import PartnersPage from "./pages/PartnersPage";

const App: React.FC = () => {
    const {user, setUser, hasRole} = useAuthContext();
    const [headerVisible, setHeaderVisible] = useState<boolean>(true);
    const [headerCompact, setHeaderCompact] = useState<boolean>(false);
    const navigate = useNavigate();
    const productMatch = useMatch("/product/:productUuid");
    const internalUploadMatch = useMatch("admin/internal-upload/:productUuid");
    const clientUploadMatch = useMatch("admin/client-upload/:productUuid");
    const productDetailsMatch = useMatch("/product/:productUuid/details");
    const companySetupMatch = useMatch("/company-setup");
    const partnersMatch = useMatch("/partners");
    const [searchParams] = useSearchParams();
    const modelNumber: string | null = searchParams.get("model");
    const {pathname} = useLocation();
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics();

    getAnalytics(app);
    addRequestInterceptor();
    addJwtInterceptor();

    //TODO: Throw useAlert
    const logOut = useCallback(() => {
        AuthService.logout();
        setUser(null);
        navigate("/login");
    }, [navigate, setUser]);

    useEffect(() => {
        function isNotOnPublicPage() {
            return pathname !== '/callback' && pathname !== '/signup' && !pathname.includes('/verify-account') && !pathname.includes('/recover-password') && pathname !== '/forgot';
        }

        if (!user && isNotOnPublicPage()) {
            logOut();
        }
        if (user && !isNotOnPublicPage()) {
            navigate("/");
        }
        EventBus.on("logout", logOut);
        return () => {
            EventBus.remove("logout", logOut);
        };
    }, [user, logOut, pathname, navigate]);

    useEffect(() => {
        if (productMatch) {
            setHeaderVisible(false);
            setHeaderCompact(false);
        } else {
            setHeaderVisible(true);
            setHeaderCompact(false);
        }
    }, [productMatch, modelNumber]);

    useEffect(() => {
        if (productDetailsMatch) {
            setHeaderVisible(true);
            setHeaderCompact(false);
        }
    }, [productDetailsMatch])

    useEffect(() => {
        if (user?.paymentFailure) {
            setHeaderVisible(false);
            setHeaderCompact(false);
        }
    }, [user?.paymentFailure])

    useEffect(() => {
        if ((internalUploadMatch || clientUploadMatch) && modelNumber != null) {
            setHeaderVisible(false);
            setHeaderCompact(true);
        }
    }, [internalUploadMatch, clientUploadMatch, productMatch, modelNumber]);


    useEffect(() => {
            function userHasCompany() {
                return user?.company && user?.company?.length > 0;
            }

            if (user != null && !userHasCompany() && !companySetupMatch) {
                navigate("/company-setup");
            }
        }
        , [companySetupMatch, navigate, pathname, user, user?.company])

    useEffect(() => {
            if (companySetupMatch) {
                setHeaderVisible(false);
            } else {
                setHeaderVisible(true);
            }
        }
        , [companySetupMatch])

    useEffect(() => {
        if (user) {
            logEvent(analytics, "SuccessfullyLoggedIn", {
                userEmail: user.email
            });
        }
    }, [user, analytics]);

    useEffect(() => {
        if (partnersMatch) {
            setHeaderVisible(false);
            setHeaderCompact(false);
        } else {
            setHeaderVisible(true);
            setHeaderCompact(false);
        }
    }, [partnersMatch])


    const adminRoutes = <>
        <Route path="/" element={<Page title="Projects"><Home/></Page>}/>
        <Route path="/admin/:pageName" element={<Page><AdminDashboardPage/></Page>}/>
        <Route path="/admin/users" element={<Page title="Users"><AdminDashboardPage/></Page>}/>
        <Route path="/admin/companies" element={<Page title="Companies"><AdminDashboardPage/></Page>}/>
        <Route path="/admin/client-upload/:productUuid" element={<Page title="Client upload"><ClientUploadPage/></Page>}/>
        <Route path="/admin/internal-upload/:productUuid" element={<Page title="Internal upload"><InternalUploadPage/></Page>}/>
        <Route path="/product/:productUuid/details" element={<Page title="Product info"><ProductDetailsPage/></Page>}/>
        <Route path="/partners" element={<Page title="Partners"><PartnersPage/></Page>}/>
    </>

    const userRoutes = <>
        <Route path="/" element={<Page title="Projects"><Home/></Page>}/>
        <Route path="/company-setup" element={<Page><CompanySetup/></Page>}/>
        <Route path="/account" element={<Page title="Account"><AccountPage/></Page>}/>
        <Route path="/new-project" element={<Page title="New project"><NewProjectPage/></Page>}/>
        <Route path="/edit-draft/:projectUuid" element={<Page><EditDraftPage/></Page>}/>
        <Route path="/project/:projectUuid" element={<Page title="Products"><ProjectPage/></Page>}/>
        <Route path="/project/:projectUuid/details" element={<Page title="Project info"><ProjectDetailsPage/></Page>}/>
        <Route path="/project/:projectUuid/add-new-product" element={<Page title="New product"><AddNewProductPage/></Page>}/>
        <Route path="/product/:productUuid" element={<Page title="Product detail view"><ProductPage/></Page>}/>
        <Route path="/product/:productUuid/details" element={<Page title="Product info"><ProductDetailsPage/></Page>}/>
        <Route path="/partners" element={<Page title="Partners"><PartnersPage/></Page>}/>
    </>

    const unpaidRoutes = <>
        <Route path="/" element={<Page><PaymentFailedPage logOut={logOut}/></Page>}/>
    </>

    const companyEmployeeRoutes = <>
        <Route path="/admin/internal-upload/:productUuid" element={<Page><InternalUploadPage/></Page>}/>
        <Route path="/product/:productUuid/details" element={<Page><ProductDetailsPage/></Page>}/>
        <Route path="/" element={<Page><EmptyPage/></Page>}/>
        <Route path="/partners" element={<Page title="Partners"><PartnersPage/></Page>}/>
    </>

    function hasDarkTheme() {
        return ['/login', '/signup', '/forgot', '/callback', '/company-setup'].includes(pathname) || pathname.includes('/recover-password') || pathname.includes('/verify-account');
    }

    return (
        <div
            className={`app-container ${hasDarkTheme() ? "-dark" : ""}`}>
            {headerVisible && user?.pendingQuotes! > 0 && <PendingQuoteHeader amount={user?.pendingQuotes!}/>}
            {user
                ? (headerVisible && <AppHeader compact={headerCompact} logOut={logOut} user={user}/>)
                : <LoggedOutHeader compact={headerCompact}/>
            }
            <Routes>
                <Route path="/callback" element={<Page><AuthCodeSignIn/></Page>}/>
                <Route path="/signup" element={<Page title="Sign up"><SignupPage/></Page>}/>
                <Route path="/login" element={<Page title="Sign in"><Login/></Page>}/>
                <Route path="/forgot" element={<Page title="Forgot password"><ForgotPasswordPage/></Page>}/>
                <Route path="/recover-password/:token" element={<Page><ResetPasswordPage/></Page>}/>
                <Route path="/verify-account/:token" element={<Page><VerifyAccount/></Page>}/>
                <Route path="/partners" element={<Page title="Partners"><PartnersPage/></Page>}/>
                {hasRole(UserRole.ROLE_USER) && !user?.paymentFailure &&
                    userRoutes
                }
                {hasRole(UserRole.ROLE_USER) && user?.paymentFailure &&
                    unpaidRoutes
                }
                {hasRole(UserRole.ROLE_COMPANY) &&
                    companyEmployeeRoutes
                }
                {hasRole(UserRole.ROLE_ADMIN) &&
                    <>
                        {userRoutes}
                        {adminRoutes}
                    </>
                }
                <Route path="*" element={<Navigate to="/"/>}>
                    {/* Keep this as the last route  TODO: maybe add a 404 page*/}
                </Route>
            </Routes>
        </div>
    );
};

export default App;

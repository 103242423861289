import React from "react";
import LoadingButton from "../../product/LoadingButton";
import {GENERATE, SAVE_AS_DRAFT, SEND_FOR_QUOTE, WAIT_FILE_UPLOAD} from "../../../common-texts";
import {ProductionType} from "../../../types/project/production-type";
import { ReactComponent as IconSend } from "../../../assets/icons/icon-send.svg"; 

const NewProjectFooter: React.FC<{
    saveAsDraft: () => void;
    sendForQuote: () => void;
    filesUploading: boolean;
    productionType?: ProductionType;
    showCancelModal: () => void;
    uploadDisabled: boolean;
}> = ({saveAsDraft, sendForQuote, filesUploading, productionType, showCancelModal, uploadDisabled= false}) => {

    return (
        <div className="app-footer -padded">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <hr className="mt-0"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <button onClick={showCancelModal} className="btn btn-white">
                            Cancel
                        </button>
                    </div>
                    <div className="col-md-6 text-end">
                        <LoadingButton
                            buttonText={SAVE_AS_DRAFT}
                            btnClassName={"btn-secondary me-2"}
                            onClick={saveAsDraft}
                            loading={filesUploading}
                            loadingTooltip={WAIT_FILE_UPLOAD}/>
                        <LoadingButton
                            buttonText={productionType && productionType === ProductionType.AI_GENERATED ? GENERATE : SEND_FOR_QUOTE}
                            btnClassName={"btn-primary"}
                            onClick={sendForQuote}
                            loading={filesUploading}
                            loadingTooltip={WAIT_FILE_UPLOAD}
                            disabled={uploadDisabled}
                            Icon={IconSend}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewProjectFooter;

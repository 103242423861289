import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ProductDetails from "../../components/product/ProductDetails";
import ProductClient from "../../services/product.client";
import ProductInfoResponse from "../../types/product/product-info-response";
import ProductDetailsHeader from "../../components/product/ProductDetailsHeader";
import {AiMethod} from "../../types/project/ai-method-type";

const ProductDetailsPage: React.FC = () => {
    const {productUuid} = useParams();
    const [product, setProduct] = useState<ProductInfoResponse>();

    useEffect(() => {
        if (!productUuid) return;
        ProductClient.getProductInfo(productUuid).then((res) => {
            setProduct(res);
        });
    }, [productUuid])

    return (
        <>
            {product?.category?.name === AiMethod.ANYTHING ? (
            <ProductDetailsHeader
                title={`${product?.brandName} · ${product?.modelName}`}
                subTitle={"Asset"}
                price={product?.price}
            />
            ):(
                <ProductDetailsHeader
                    title={`${product?.brandName} · ${product?.modelName}`}
                    subTitle={"Product"}
                    price={product?.price}
                />
            )}
            <ProductDetails product={product}/>
        </>
    )
}

export default ProductDetailsPage;

import React from "react";
import LoadingButton from "../product/LoadingButton";

const AcccountHeader: React.FC<{
    title: string;
    onCancel: () => void;
    onUpdate: () => void;
    isLoading: boolean;
}> = ({title, onCancel, onUpdate, isLoading}) => {
    return (
        <>
            <header className="view-header">
                <div className="container-fluid">
                    <div className="subtitle-spacer"/>
                    <div className="row justify-content-between">
                        <div className="col-md-auto">
                            <div className="title">
                                <button className="btn btn-outline-gray btn-icon btn-sm me-3" onClick={onCancel}>
                                    <i className="bi bi-caret-left-fill"></i>
                                </button>
                                <h1>{title}</h1>
                            </div>
                        </div>
                        <div className="col-md-auto">
                            <LoadingButton
                                buttonText={"Update"}
                                btnClassName={"btn-primary"}
                                onClick={onUpdate}
                                loading={isLoading}
                            />
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default AcccountHeader;

import React, {Fragment, useCallback, useEffect, useRef} from "react";
import InputError from "./InputError";
import {Menu, MenuItem, Typeahead} from "react-bootstrap-typeahead";
import _ from "lodash";
import {Controller, Noop, useFormContext} from "react-hook-form";
import {AiMethod} from "../types/project/ai-method-type";
import ProductCategoryResponse from "../types/product/product-category-response";

const CLASSIFICATION_PARENT_FIELD_NAME= "parentName"

// TODO: typescript when updated to to latest version use this: https://www.npmjs.com/package/@types/react-bootstrap-typeahead
const ProductCategoryDropdown: React.FC<{
    categories: ProductCategoryResponse[];
    label: string;
    name: string;
    errorMessage?: string;
    requiredMethod?: AiMethod;
}> = ({categories, label, name, errorMessage, requiredMethod}) => {
    const {control, setValue} = useFormContext();
    // TODO: typescript when updated to to latest version use this: https://www.npmjs.com/package/@types/react-bootstrap-typeahead
    const ref = useRef<any>(null);

    function setInputText() {
        const value = ref.current.props.value
        if (value && value.length > 0) {
            ref.current.setState({...value, text: value[0].value})
        }
    }

    useEffect(() => {
        setInputText();
    },[])

    const getValidCategories = useCallback((inputCategories: ProductCategoryResponse[]) => {
        if (requiredMethod) {
            return inputCategories
                .filter(category => category.supportedAiMethods
                    .find(method => method === requiredMethod) !== undefined);
        } else {
            return inputCategories;
        }
    }, [requiredMethod]);

    useEffect(function clearCategoryIfNotValid() {
        if (!ref || !requiredMethod) return;
        const currentValue = ref.current.props.value
        if (!currentValue || currentValue.length < 1) return;
        if (getValidCategories(categories).find(category => category.id === currentValue[0].id) === undefined) {
            ref.current.clear();
            setValue(name, []);
        }
    }, [requiredMethod, name, setValue, categories, getValidCategories])

    useEffect(function chooseCategoryIfOnlyOneChoice() {
        if (!ref || !requiredMethod) return;
        const filteredCategories = getValidCategories(categories).filter(category => category.parentName !== 'Popular');

        if (filteredCategories.length === 1) {
            const value = ref.current.props.value
            ref.current.setState({...value, text: filteredCategories[0].value})
            setValue(name, filteredCategories);
        }
    }, [requiredMethod, name, setValue, categories, getValidCategories])

    const getMenu = (inputCategories: ProductCategoryResponse[], menuProps: any) => {
        const parents = _.groupBy(getValidCategories(inputCategories), CLASSIFICATION_PARENT_FIELD_NAME);
        let index = 0;
        const items = Object.keys(parents).map((parent: string) => (
            <Fragment key={parent}>
                <>
                    {index !== 0 && <Menu.Divider/>}
                    <Menu.Header>{parent}</Menu.Header>

                    {parents[parent].map((child) => {
                        const item = (
                            <MenuItem key={index + "-" + child.id} option={child} position={index}>
                                {child.value}
                            </MenuItem>
                        );
                        index += 1;
                        return item;
                    })}
                </>
            </Fragment>
        ))

        delete menuProps.newSelectionPrefix;
        delete menuProps.paginationText;
        delete menuProps.renderMenuItemChildren;

        return <Menu key={"menu"} {...menuProps}>
            {items}
        </Menu>
    }

    const handleBlur = (fieldBlur: Noop) => {
        if (ref.current) {
            const currentValue = ref.current.props.value;
            if (!Array.isArray(currentValue) || (currentValue && currentValue.length === 0)) {
                ref.current.clear();
                setValue(name, []);
            }
        }
        fieldBlur();
    }

    return (
        <Controller
            control={control}
            name={name}
            render={({field}) => (
                <div className="mb-3">
                    <label className="form-label">{label}</label>
                    <Typeahead
                        {...field}
                        onBlur={() => handleBlur(field.onBlur)}
                        options={categories}
                        labelKey={"value"}
                        onInputChange={field.onChange}
                        ref={(localRef) => {
                            field.ref(localRef);
                            ref.current = localRef;
                        }}
                        id={name}
                        inputProps={{className: "form-select form-select-lg"}}
                        // @ts-ignore
                        renderMenu={(results, menuProps) => getMenu(results, menuProps)}
                    />
                    <InputError errorMessage={errorMessage}/>
                </div>
            )}
        />
    );
}

export default ProductCategoryDropdown;

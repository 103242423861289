import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import axios from "axios";
import GenerateImage from "../file-handler/GenerateImage";
import SelectImage from "../file-handler/SelectImage";
import ConfirmationModal from "./ConfirmationModal";
import {AttachmentInfo} from "../../types/attachment/attachment-response";
import {v4 as uuidv4} from "uuid";
import {useAlerts} from "../../store/AlertProvider";
import {formatErrorResponse} from "../../services/product.client";
import {AiMethod} from "../../types/project/ai-method-type";


const GenerateImageModal: React.FC<{
    handleClose: (isUploaded: boolean) => void;
    confirmationText?: string;
    closeModalText?: string;
    handleConfirmation: () => void;
    showModal?: boolean;
    updateFiles: (files: AttachmentInfo[]) => void;
    aiMethodType: AiMethod;
}> = ({
          handleClose,
          showModal,
          closeModalText = "Cancel",
          confirmationText = "Import",
          updateFiles,
          aiMethodType,
      }) => {
    const [prompt, setPrompt] = useState("");
    const [header, setHeader] = useState("");
    const [body, setBody] = useState("");
    const [loading, setLoading] = useState(false);
    const [hideGenerateImage, setHideGenerateImage] = useState(false);
    const [hideSelectImage, setHideSelectImage] = useState(true);
    const [imageUrls, setImageUrls] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);

    const {addErrorResponseAlert} = useAlerts();

    const getGeneratedImages = () => {
        setLoading(true)
        axios.post(process.env.REACT_APP_API_URL + "open-ai/" + aiMethodType, {
            prompt: prompt,
        }).then((res) => {
            setImageUrls(res.data);
            setHideGenerateImage(true);
            setHideSelectImage(false);
            setLoading(false);
        }, (err) => {
            addErrorResponseAlert(formatErrorResponse(err.response));
        });
    };

    useEffect(() => {
        if (!hideGenerateImage) {
            if (aiMethodType === AiMethod.SNEAKERS) {
                setHeader("Generate Image")
                setBody("Insert text to create sneaker image. The text should be as specific as possible and describing the sneaker.")
            } else if (aiMethodType === AiMethod.SOFA_BETA) {
                setHeader("Generate Image")
                setBody("Insert text to create sofa image. The text should be as specific as possible and describing the sofa.")
            }
        }
        if (!hideSelectImage) {
            setHeader("Select image to import")
            setBody("")
        }
    }, [hideGenerateImage, hideSelectImage, showModal, aiMethodType])


    const {addErrorAlert} = useAlerts();

    const isPromptValidated = () => {
        if (!prompt) {
            addErrorAlert("Image textual description is mandatory.");
            return false;
        } else if (prompt.length > 930) {
            addErrorAlert("Textual description cannot be more that 931 characters.");
            return false;
        } else {
            return true;
        }
    }

    const generateImageHandler = () => {
        if (isPromptValidated()) {
            getGeneratedImages();
        }
    };

    const onPromptChangeHandler = (e: any) => {
        if (e.target.value.length > 931) {
            addErrorAlert("Textual description cannot be more that 931 characters.");
        }
        setPrompt(e.target.value);
    }

    const closeHandler = (isUploaded: boolean = false) => {
        handleClose(isUploaded);
        setHideGenerateImage(false);
        setHideSelectImage(true);
        setShowConfirmationModal(false);

        resetStates();
    }

    const resetStates = () => {
        setPrompt("");
        setLoading(false);
        setHideGenerateImage(false);
        setHideSelectImage(true);
        setImageUrls([]);
        setShowConfirmationModal(false);
    }

    const confirmationHandler = () => {
        if (hideGenerateImage && !hideSelectImage) {
            setShowConfirmationModal(true);
        } else {
            closeHandler();
        }
    }

    const selectImageHandler = (imageUrl: string) => {

        if (!imageUrl) {
            addErrorAlert("At least one image must be chosen");
        } else {
            let attachment: AttachmentInfo = {
                fileName: "",
                fileType: "png",
                referenceUuid: uuidv4(),
                uploaded: false,
                url: imageUrl
            };
            updateFiles([attachment]);
            closeHandler(true);
        }

    }
    return (
        <Modal show={showModal}
               className={"modal fade"}>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" id="bulkUploadModal">
                        {header}
                    </h4>
                    <button
                        onClick={confirmationHandler}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
                <div className="modal-body" style={{wordWrap: "break-word", whiteSpace: "pre-wrap"}}>
                    {body}
                    {!hideGenerateImage &&
                        <GenerateImage
                            hide={hideGenerateImage}
                            handleClose={closeHandler}
                            closeModalText={closeModalText}
                            confirmationText={confirmationText}
                            loading={loading}
                            generateImageHandler={generateImageHandler}
                            onPromptChangeHandler={onPromptChangeHandler}></GenerateImage>
                    }
                    {!hideSelectImage &&
                        <SelectImage
                            hide={hideSelectImage}
                            handleClose={closeHandler}
                            confirmationHandler={confirmationHandler}
                            closeModalText={closeModalText}
                            confirmationText={confirmationText}
                            loading={loading}
                            selectImageHandler={selectImageHandler}
                            imageUrls={imageUrls}></SelectImage>
                    }

                    <ConfirmationModal
                        header="Are you sure?"
                        body={
                            "Are you sure you want to cancel? Generated images will be lost."}
                        handleClose={() => setShowConfirmationModal(false)}
                        handleConfirmation={() => closeHandler()}
                        showModal={showConfirmationModal}
                    />
                </div>

            </div>
        </Modal>
    );
};

export default GenerateImageModal;


import React, {useState} from "react";
import CompanyResponse from "../../types/company/company-response";
import CompanyListItem from "./CompanyListItem";
import UserListItem from "./UserListItem";
import ConfirmationModal from "../modals/ConfirmationModal";
import UserClient from "../../services/user.client";
import {formatErrorResponse} from "../../services/attachment.client";
import {useAlerts} from "../../store/AlertProvider";
import {useQueryClient} from "react-query";
import Pagination from "../Pagination";
import PageableUserResponse from "../../types/user/pageable-user-response";
import PageableCompanyResponse from "../../types/user/pageable-company-response";


const AdminList: React.FC<{
    pageableUserResponse?: PageableUserResponse;
    pageableCompanyResponse?: PageableCompanyResponse;
    editCompany?: (company: CompanyResponse) => void;
    generateReport?: (company: CompanyResponse) => void;
    setUserPageNumber?: (page: number) => void;
    setCompanyPageNumber?: (page: number) => void
}> = ({pageableUserResponse = undefined, pageableCompanyResponse = undefined, editCompany, generateReport, setUserPageNumber, setCompanyPageNumber}) => {
    const [userUuid, setUserUuid] = useState<string>();
    const [showUserDeactivateModal, setShowUserDeactivateModal] = useState<boolean>(false);
    const {addErrorResponseAlert, addSuccessAlert} = useAlerts();
    const queryClient = useQueryClient();

    const handleShowUserDeactivateModal = (uuid: string) => {
        setUserUuid(uuid);
        setShowUserDeactivateModal(!showUserDeactivateModal);
    }

    const handleDeactivateConfirmation = () => {
        if (!userUuid) {
            setShowUserDeactivateModal(false);
            return;
        }
        UserClient.deactivateUser(userUuid).then(
            () => {
                queryClient.invalidateQueries('getUsers').then();
                addSuccessAlert("User deactivated");
            }
        ).catch((err) => {
            addErrorResponseAlert(formatErrorResponse(err.response))
        });
        setShowUserDeactivateModal(false);
    }

    return (
        <>
            <div className="projects-list -list">
                <div className="container">
                    {pageableUserResponse?.content?.map((user) => {
                        return (
                            <UserListItem key={user.email} user={user}
                                          handleShowUserDeactivateModal={handleShowUserDeactivateModal}/>
                        );
                    })}
                    {pageableUserResponse?.content && (
                        <Pagination page={{
                            page: pageableUserResponse?.pageable?.pageNumber || 0,
                            totalPages: pageableUserResponse?.totalPages || 0,
                            totalElements: pageableUserResponse?.totalElements || 0
                        }} setCurrentPage={setUserPageNumber} />
                    )}
                    {pageableCompanyResponse?.content?.map((company) => {
                        return <CompanyListItem company={company} key={company.uuid} editCompany={editCompany!}
                                                generateReport={generateReport!}/>
                    })}
                    {pageableCompanyResponse?.content && (
                        <Pagination page={{
                            page: pageableCompanyResponse?.pageable?.pageNumber || 0,
                            totalPages: pageableCompanyResponse?.totalPages || 0,
                            totalElements: pageableCompanyResponse?.totalElements || 0
                        }} setCurrentPage={setCompanyPageNumber} />
                    )}
                </div>
            </div>
            <ConfirmationModal
                header="Are you sure you want to deactivate this user?"
                handleClose={() => setShowUserDeactivateModal(false)}
                handleConfirmation={() => handleDeactivateConfirmation()}
                showModal={showUserDeactivateModal}
            />
        </>
    );
};

export default AdminList;

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ProductInfoResponse from "../../types/product/product-info-response";
import {UseFormReturn} from "react-hook-form";
import DownloadableImageThumbnails from "../product/DownloadableImageThumbnails";
import {Attachment, ProductThumbnailsResponse} from "../../types/attachment/attachment-response";
import PreFilledProduct from "../PreFilledProduct";
import {FileInputHooks} from "../../utils/FileUtils";
import ModelsAndAttachmentForm from "../ModelsAndAttachmentForm";
import {ModelsAttachmentsCommentsForm} from "../../types/models-attachments-comments-form";
import {VersionInfo} from "../../types/version-info";
import VersionClient from "../../services/version.client";
import PreFilledVersionInfo from "./PreFilledVersionInfo";

interface IClientUpload {
    product: ProductInfoResponse | undefined;
    saveRequestMethods: UseFormReturn<ModelsAttachmentsCommentsForm>;
    versionNumber: string | null;
    versionModels: Attachment[];
    versionAttachments: Attachment[];
}

const InternalUploadForm: React.FC<IClientUpload> = ({
                                                         product,
                                                         saveRequestMethods,
                                                         versionNumber,
                                                         versionModels,
                                                         versionAttachments
                                                     }) => {
    const {productUuid} = useParams();
    const [downloadedFiles, setDownloadedFiles] = useState<ProductThumbnailsResponse>();
    const [versionInfo, setVersionInfo] = useState<VersionInfo>();
    FileInputHooks(productUuid, setDownloadedFiles);

    useEffect(() => {
        if (!productUuid || !versionNumber) {
            setVersionInfo(undefined);
            return;
        }
        VersionClient.getVersionInfo(productUuid, parseInt(versionNumber), "INTERNAL").then(
            res => setVersionInfo(res)
        );
    }, [productUuid, versionNumber]);

    return (
        <>
            <main className="view-content -with-footer container">
                <div className="row">
                    <div className="col-md-5 offset-md-1">
                        <h3 className="mb-4">Product {product?.modelName}</h3>
                    </div>
                    <div className="col-md-5 text-end"/>
                    <div className="col-md-5 offset-md-1">
                        {versionInfo != null &&
                            <PreFilledVersionInfo versionInfo={versionInfo}/>}
                        <PreFilledProduct product={product}/>
                    </div>
                    <div className="col-md-5">
                        <div className="thumbnail-gallery">
                            <DownloadableImageThumbnails files={downloadedFiles} name={`product.files`}/>
                        </div>
                    </div>
                </div>

                <ModelsAndAttachmentForm versionModels={versionModels}
                                         versionAttachments={versionAttachments}
                                         versionNumber={versionNumber}
                                         saveRequestMethods={saveRequestMethods}
                                         attachmentLabel={"Attachments (max 400mb) split larger files"}
                />
            </main>
        </>
    );
};

export default InternalUploadForm;

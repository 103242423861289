import * as Yup from "yup";

export const productOfferUpdateValidation = Yup.object().shape({
    productionTime: Yup.number()
        .typeError("Production time must be a number")
        .required("Production time is required!"),
    price: Yup.number()
        .typeError("Price must be a number")
        .required("Price is required")
});

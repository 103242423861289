import React from "react";
import UserResponse from "../../types/user/userResponse";
import {Dropdown} from "react-bootstrap";

const UserListItem: React.FC<{ user: UserResponse, handleShowUserDeactivateModal: (userUuid: string) => void }>
    = ({user, handleShowUserDeactivateModal}) => {

    return (
            <div className="project" style={{cursor: "default"}}>
                <div className="row justify-content-center">
                    <div className="col-3 text-truncate align-self-center">
                                    <span title={user.firstName + " " + user.lastName}>
                                        {user.firstName + " " + user.lastName}
                                    </span>
                    </div>
                    <div className="col-3 text-truncate align-self-center">
                                    <span title={user.email}>
                                        {user.email}
                                    </span>
                    </div>
                    <div className="col-2 align-self-center"><span
                        className="justify-content-start">{user.role}</span></div>
                    <div title={user.company} className="col-2 text-truncate align-self-center">
                        <span>{user.company}</span>
                    </div>
                    <div className="col d-flex flex-column px-0 me-4 align-self-center">
                        <div className="actions">
                            <Dropdown align={"end"}>
                                <Dropdown.Toggle variant="white" className="btn-icon">
                                    <i className="bi bi-three-dots-vertical"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleShowUserDeactivateModal(user.uuid)}>Deactivate</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default UserListItem;

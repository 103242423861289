import React, {useEffect, useState} from "react";
import {FormProvider, useFieldArray, UseFormReturn} from "react-hook-form";
import {AddNewProductsRequest} from "../../types/product/add-new-products-dtos";
import NewProduct from "../NewProduct";
import {handleAppendBulkUploadResult, handleAppendNewProduct} from "../../utils/SharedUseEffects";
import BulkUploadModal from "../modals/BulkUploadModal";
import {formatErrorResponse} from "../../services/project.client";
import AttachmentClient from "../../services/attachment.client";
import {useAlerts} from "../../store/AlertProvider";
import {ProductionType} from "../../types/project/production-type";

interface IAddNewProduct {
    removeProduct: (index: number) => {};
    saveRequestMethods: UseFormReturn<AddNewProductsRequest>;
    onSubmit: () => {};
}

const AddNewProduct: React.FC<IAddNewProduct> = ({saveRequestMethods, onSubmit, removeProduct}) => {
    const control = saveRequestMethods.control;
    const [initialised, setInitialised] = useState<boolean>(false);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const isBulkUploadVisible = saveRequestMethods.getValues().productionType !== ProductionType.AI_GENERATED;
    const {fields, append, remove} = useFieldArray({
        name: "products",
        control
    });
    const {addErrorResponseAlert} = useAlerts();

    // Workaround so fields aren't appended twice on init
    useEffect(() => {
        setInitialised(true);
    }, [setInitialised])

    useEffect(() => {
        if (initialised && fields.length < 1) {
            handleAppendNewProduct(control, append)
        }
    }, [initialised, append, fields, control])

    function checkErrors(index: number) {
        const productErrors = saveRequestMethods.formState.errors?.products;
        if (Array.isArray(productErrors)) {
            return saveRequestMethods.formState.errors?.products?.at(index);
        }
        return undefined;
    }

    const handleProductRemove = (index: number) => {
        removeProduct(index)
        remove(index);
    }

    const handleAddProductButton = () => {
        handleAppendNewProduct(control, append);
    }


    const handleBulkUploadButton = () => {
        setShowUploadModal(true);
    }

    async function handleBulkUpload() {
        if (saveRequestMethods.getValues().bulkImport.files[0]) {
            let file = saveRequestMethods.getValues().bulkImport.files[0];
            AttachmentClient.bulkUploadFromFile(file).then((res) => {
                handleAppendBulkUploadResult(res, append);
                setShowUploadModal(false);
            }).catch((res) => {
                addErrorResponseAlert(formatErrorResponse(res.response))
            });
        }
    }

    return (
        <FormProvider {...saveRequestMethods} >
            <form onSubmit={() => onSubmit()} className="bg-white dropzone">
                <main className="view-content -with-footer container">
                    {fields.map((field, index) => {
                        return (
                            <NewProduct
                                remove={handleProductRemove}
                                isRemovable={fields.length >= 2}
                                index={index}
                                errors={checkErrors(index)}
                                key={field.id}
                                myKey={field.id}
                                productUuid={field.productUuid}
                                name={`products[${index}]`}
                            />
                        );
                    })}
                    <div className="row mt-2">
                        <div className="col-md-10 offset-md-1 text-center">
                            <button
                                type="button"
                                className="btn btn-secondary w-100 py-4"
                                onClick={() => handleAddProductButton()}
                            >
                                <i className="bi bi-plus-circle-fill me-2"></i> Add an additional product
                            </button>
                            {isBulkUploadVisible &&
                                <button
                                    type="button"
                                    className="text-center btn btn-link"
                                    onClick={() => handleBulkUploadButton()}
                                >
                                    Or bulk upload
                                </button>
                            }
                        </div>
                    </div>
                </main>
            </form>

            <BulkUploadModal header={"Bulk import"}
                             body={"To bulk import products, download the template below and fill in the table. To add images, add as many image headers to the Excel as needed."}
                             limit={"Limit per one Excel is 250 products. If you need to import more, then use multiple Excel files."}
                             showModal={showUploadModal}
                             handleClose={() => setShowUploadModal(false)}
                             handleConfirmation={() => handleBulkUpload()}
                             errors={saveRequestMethods.formState.errors}/>
        </FormProvider>
    )
}

export default AddNewProduct

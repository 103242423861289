import axios from 'axios';
import AuthService from "./auth.service";
import EventBus from "../common/EventBus";

export const refreshToken = async (err: any) => {
    const refreshToken: string | null = localStorage.getItem("refresh_token");
    if (!refreshToken) {
        return;
    }
    const formData = new FormData();
    formData.append('refresh_token', refreshToken);

    return axios
        .post(process.env.REACT_APP_AUTH_SERVER_URL + "oauth/token?grant_type=refresh_token", formData, {
            auth: {username: 'self-service', password: ''}
        }).then(response => {
            localStorage.setItem("access_token", response.data.access_token);
            localStorage.setItem("refresh_token", response.data.refresh_token);
            return axios.request(err.response.config);
        }).catch(error => {
            EventBus.dispatch("logout");
            AuthService.logout();
            return Promise.reject(error);
        })
}

export function addRequestInterceptor() {
    return axios.interceptors.request.use(function (config) {
        const token = localStorage.getItem("access_token");
        if (token != null && config.headers != null) {
            config.headers.Authorization = "Bearer " + token;
        }

        return config;
    });
}

export function addJwtInterceptor() {


    return axios.interceptors.response.use((res) => {
        return res;
    }, async (err) => {
        const originalConfig = err.config;
        // Reject promise if usual error
        if (err.response?.status !== 401) {
            return Promise.reject(err);
        }
        if (!err.url?.includes("grant_type=refresh_token") && !originalConfig._retry) {
            originalConfig._retry = true;
            return refreshToken(err);
        }
        return Promise.reject(err);
    });
}

import React, {useEffect} from "react";
import NewDropdown from "../../NewDropdown";
import {FieldErrors, useFormContext, useWatch} from "react-hook-form";
import NewInput from "../../NewInput";
import NewTextArea from "../../NewTextArea";
import {useClassifications} from "../../../store/ClassificationProvider";
import ProjectFileHandler from "../../file-handler/ProjectFileHandler";
import ProjectRequest from "../../../types/project/project-request";
import {getError} from "../../../utils/ValidationUtil";
import {PRODUCTION_TYPE_TRANSLATIONS, ProductionType} from "../../../types/project/production-type";
import AiExplained from "./ai/AiExplained";
import {AI_METHOD_TRANSLATIONS, AiMethod} from "../../../types/project/ai-method-type";
import AiSampleImages from "./ai/AiSampleImages";

const NewProjectDetails: React.FC<{
    projectUuid?: string;
    loaded: boolean;
    errors: FieldErrors<ProjectRequest>
        | undefined;
}> = ({errors, loaded}) => {
    const {projectEndPoints, triangleCounts, productionTypes} = useClassifications();
    const methods = useFormContext();
    const productionType = useWatch({name: "productionType"})
    const aiMethod = useWatch({name: "aiMethod"})

    useEffect(function resetAiMethodFieldForDesignerType() {
        if (productionType === ProductionType.DESIGNER && aiMethod?.length > 0) {
            methods.resetField('aiMethod');
        }
    }, [aiMethod, methods, productionType])

    return (<>
            <div className="row">
                <div className="col-md-5 offset-md-1">
                    <div className="mb-3">
                        <NewInput name={"projectName"} label={"Project name"}
                                  errorMessage={errors?.projectName?.message}/>
                    </div>
                    <div className="mb-0">
                        {productionTypes.length > 0 &&
                            <NewDropdown
                                name="productionType"
                                errorMessage={errors?.productionType?.message}
                                label="Production type"
                                values={productionTypes}
                                translations={PRODUCTION_TYPE_TRANSLATIONS}
                                disabled={productionTypes?.length === 1}
                            />
                        }
                    </div>
                    <div className="mb-0">
                        {projectEndPoints.length > 0 && productionType === ProductionType.DESIGNER &&
                            <NewDropdown
                                errorMessage={errors?.endPoint?.message}
                                label="End point"
                                name="endPoint"
                                values={projectEndPoints.map((endPoint) => endPoint.value)}
                            />
                        }
                    </div>
                    {productionType && productionType === ProductionType.AI_GENERATED &&
                        <>
                            <div className="mb-0">
                                <NewDropdown
                                    errorMessage={errors?.aiMethod?.message}
                                    label="Category"
                                    name="aiMethod"
                                    values={[AiMethod.SNEAKERS.valueOf(), AiMethod.SOFA_BETA.valueOf(), AiMethod.ANYTHING.valueOf()]}
                                    translations={AI_METHOD_TRANSLATIONS}
                                />
                            </div>
                            {aiMethod && aiMethod === AiMethod.MESH
                                ? <div className="mb-0">
                                    <NewDropdown
                                        errorMessage={errors?.triangleCount?.message}
                                        label="Expected triangle count"
                                        name="triangleCount"
                                        values={triangleCounts}
                                    />
                                </div>
                                : <></>
                            }
                        </>
                    }
                </div>

                <div className="col-md-5">
                    {productionType && productionType === ProductionType.DESIGNER &&
                        <>
                            <div className="mb-3">
                                <NewTextArea
                                    name={"specification"}
                                    label={"Project specification"}
                                    errorMessage={errors?.specification?.message}
                                />
                            </div>
                        </>
                    }
                    <div
                        className={`mb-0 ${productionType && productionType === ProductionType.DESIGNER ? "" : "d-none"}`}>
                        <ProjectFileHandler loaded={loaded} errorMessage={getError(errors?.attachmentUuids)}/>
                    </div>
                    {productionType && productionType === ProductionType.AI_GENERATED &&
                        <div className="mb-3">
                            <AiExplained/>
                        </div>
                    }
                </div>
            </div>
            <div className="row">
                {productionType && productionType === ProductionType.AI_GENERATED && aiMethod === AiMethod.SNEAKERS &&
                    <div className="col-md-10 offset-md-1">
                        <AiSampleImages/>
                    </div>
                }
            </div>
        </>
    );
};

export default NewProjectDetails;

import React from "react";
import projectSkeleton from "../../assets/project-skeleton.svg";

const EmptySkeleton: React.FC<{}> = () => {
    return (
        <div className="col-3">
            <div className="project-skeleton">
                <img src={projectSkeleton} alt="Empty project"/>
            </div>
        </div>
    );
};

export default EmptySkeleton;

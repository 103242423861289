import {Attachment} from "../types/attachment/attachment-response";
import React from "react";
import {b64toBlob} from "./FileUtils";
import AttachmentClient from "../services/attachment.client";
import {AlphaModel} from "../components/model/ModelViewer";
import {decryptBase64} from "./AttachmentUtil";

type optionalAttachmentArray = Attachment[] | undefined;

export function setCommentReplyVisibleIfLatestVersion(versionNumber: string | null, versions: number[] | undefined, setIsReplyVisible: (value: (((prevState: boolean) => boolean) | boolean)) => void) {
    if (!versionNumber || !versions || versions.length === 0) {
        setIsReplyVisible(false);
        return;
    }
    setIsReplyVisible(versionNumber === versions[0].toString());
}

export function fetchModel(
    versionModels: Attachment[] | undefined,
    modelNumber: string, addErrorAlert: (body: string) => void,
    setModelNumber: (s: React.SetStateAction<Partial<{ [key in keyof { model: string }]: any }>>) => void,
    setModel: (value: (((prevState: (AlphaModel | undefined)) => (AlphaModel | undefined)) | AlphaModel | undefined)) => void,
    setIsModelLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void

) {
    if (!modelNumber || !versionModels || versionModels.length === 0) return;
    if (versionModels.find(versionModel => versionModel.attachmentUuid.toString() === modelNumber) === undefined) {
        addErrorAlert("Invalid model id, navigating to first model of version");
        setModelNumber({model: versionModels[0].attachmentUuid.toString()});
        return;
    }
    setModel(undefined);
    setIsModelLoading(true);
    AttachmentClient.getAttachmentImage(modelNumber).then(async (res) => {
        let decryptedBase64 = decryptBase64(res.base64);
        if (!decryptedBase64) decryptedBase64 = res.base64;
        const model: AlphaModel =
            {
                modelId: modelNumber,
                blob: (b64toBlob(decryptedBase64, "application/octet-stream"))
            }
        setModel(model);
    }).finally(() => setIsModelLoading(false));
}

export function fetchVersionModelsAndAttachments(
    productUuid: string | undefined,
    version: string | undefined,
    setVersionModels: (value: (((prevState: optionalAttachmentArray) => optionalAttachmentArray) | optionalAttachmentArray)) => void,
    modelNumberRef: React.MutableRefObject<any>,
    setModelNumber: (s: React.SetStateAction<Partial<{ [key in keyof { model: string }]: any }>>) => void,
    setVersionAttachments: (value: (((prevState: optionalAttachmentArray) => optionalAttachmentArray) | optionalAttachmentArray)) => void
) {
    if (!productUuid || !version) return;
    AttachmentClient.getClientModels(productUuid, version, ["GLB", "GLTF"]).then(res => {
        setVersionModels(res);
        if (!modelNumberRef.current) {
            setModelNumber({model: res[0].attachmentUuid});
        }
    });
    AttachmentClient.getClientAttachments(productUuid, version).then(res => {
        setVersionAttachments(res);
    });
}

export function setDefaultVersionWhenNoneProvided(
    versionNumber: string | undefined,
    versions: number[] | undefined,
    setVersionNumber: (s: React.SetStateAction<Partial<{ [key in keyof { version: string }]: any }>>) => void,
    setVersionModels: (value: (((prevState: optionalAttachmentArray) => optionalAttachmentArray) | optionalAttachmentArray)) => void
) {
    if (!versions || versions.length === 0) return;
    if (!versionNumber) {
        setVersionNumber({version: versions[0]});
        setVersionModels(undefined);
        setVersionModels([]);
    }
}

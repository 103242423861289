import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import NewInput from "../components/NewInput";
import {yupResolver} from "@hookform/resolvers/yup";
import RequestResult from "../components/RequestResult";
import LoadingButton from "../components/product/LoadingButton";
import {ALERT_LIFETIME} from "../components/NewAlert";
import ResetPassword from "../types/user/reset-password";
import {resetPasswordValidation} from "../validation/reset-password-validation";
import AuthService from "../services/auth.service";
import {LoginState} from "../types/navigation";

const ResetPasswordPage: React.FC = () => {
    const {token} = useParams();
    const [formError, setFormError] = useState<string>("");
    const methods = useForm<ResetPassword>({
            resolver: yupResolver(resetPasswordValidation)
        }
    );

    const onSubmit: SubmitHandler<ResetPassword> = (data) => handleSubmit(data);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleSubmit = (formValue: ResetPassword) => {
        if (!token) {
            return;
        }
        setLoading(true);
        AuthService.resetPassword({token: token, newPassword: formValue.password}).then(
            (res) => {
                setFormError("");
                methods.reset();
                navigate(`/login`, {state: {message: "Password reset was successful"} as LoginState});
                setLoading(false);
            },
            (err) => {
                setLoading(false);
                setFormError(err.response?.data?.detail);
            }
        );
    };

    useEffect(function clearErrorAfterLifeTimeEnd() {
        const timeOut = setTimeout(() => {
            setFormError("");
        }, ALERT_LIFETIME);
        return () => {
            clearTimeout(timeOut)
        }
    }, [formError]);

    return (
        <main className="view-content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <FormProvider {...methods}>
                            <div className="card">
                                <div className="card-body p-5">
                                    <h1 className="text-center mb-5">Reset password</h1>
                                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                                        <NewInput
                                            name="password"
                                            label="Your new password"
                                            type="password"
                                            inputClassName="form-control-icon -password"
                                            errorMessage={methods.formState.errors?.password?.message}
                                        />
                                        <NewInput
                                            name="passwordAgain"
                                            label="Confirm Password"
                                            type="password"
                                            inputClassName="form-control-icon -password"
                                            errorMessage={methods.formState.errors?.passwordAgain?.message}
                                        />
                                        <div
                                            className="text-center d-flex justify-content-between pt-2 align-items-center">
                                            <Link to={"/login"}>
                                                Sign in
                                            </Link>
                                            <LoadingButton
                                                onClick={() => methods.handleSubmit(onSubmit)}
                                                buttonText="Reset password"
                                                loading={loading}
                                                loadingTooltip={""}
                                                btnClassName={"btn-light px-4"}
                                                loaderVariant={"dark"}
                                            />
                                        </div>
                                        <div className="mt-2"><RequestResult successfulMessage="" successful={false}
                                                                             error={formError}/></div>

                                    </form>
                                </div>
                            </div>
                        </FormProvider>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ResetPasswordPage;

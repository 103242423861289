import React from "react";
import {Spinner} from "react-bootstrap";

const LoadingComponent: React.FC<{className?: string}> = ({className= ""}) => {
    return <Spinner
        className={`spinner ${className}`}
        animation={"border"}
    />
};

export default LoadingComponent;

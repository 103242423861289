import React from "react";
import {useSearchParams} from "react-router-dom";
import VersionTabsContainer from "../VersionTabsContainer";

const InternalUploadHeader: React.FC<{
    title: string, productUuid: string, versions: number[] | undefined, saveNewVersion: () => void, canSave?: boolean
}> = ({title, productUuid, versions, saveNewVersion, canSave}) => {
    const [searchParams] = useSearchParams();
    const versionNumber = searchParams.get("version");

    return (
        <header className="view-header">
            <div className="container-fluid">
                <div className="subtitle-spacer"/>
                <div className="row justify-content-between">
                    <div className="col-md-auto">
                        <div className="title">
                            <h1>{title}</h1>
                        </div>
                    </div>
                    {canSave && (
                        <div className="col-md-auto">
                            <button onClick={() => saveNewVersion()} className="btn btn-black me-2">
                                Save new version
                            </button>
                        </div>
                    )}
                </div>
                <VersionTabsContainer destinationPrefix={"/admin/internal-upload/"} versions={versions}
                                      selectedVersionNumber={versionNumber} productUuid={productUuid}/>
            </div>
        </header>
    );
};

export default InternalUploadHeader;

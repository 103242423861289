import React, {useState} from "react";
import ProductImageView from "./ProductImageView";
import ProductComments from "./ProductComments";
import {UseFormReturn} from "react-hook-form";
import {AddProductCommentRequest, CommentDTO} from "../../types/product/product-comments-dtos";
import ModelViewer, {AlphaModel} from "../model/ModelViewer";
import ProductInfoResponse from "../../types/product/product-info-response";
import {useAuthContext} from "../../store/AuthProvider";
import {UserRole} from "../../types/user/user-role";
import LoadingComponent from "../common/LoadingComponent";
import IconProvider from "../IconProvider";
import { ReactComponent as IconTickCircle } from "../../assets/icons/icon-tick-circle.svg"; 

const Product: React.FC<{
    product: ProductInfoResponse | undefined;
    productUuid?: string,
    addCommentRequestMethods: UseFormReturn<AddProductCommentRequest>;
    addComment: () => void;
    comments: CommentDTO[];
    model: AlphaModel | undefined;
    isReplyVisible: boolean;
    toggleModelViewerStatus: () => void;
    approveProduct: () => void;
    toggle3DViewerOnOff: () => void;
    isModelViewVisible: boolean;
    isApproveProductButtonVisible: boolean;
    isCommentsVisible: boolean;
    isModelLoading: boolean;
    isGalleryVisible: boolean;
}> = ({
          product,
          productUuid,
          addCommentRequestMethods,
          addComment,
          comments,
          model,
          isReplyVisible,
          toggle3DViewerOnOff,
          approveProduct,
          isApproveProductButtonVisible,
          isModelViewVisible,
          isCommentsVisible,
          isModelLoading,
          isGalleryVisible
      }) => {
    const [commentsVisibleToggle, setCommentsVisibleToggle] = useState<boolean>(true);
    const {hasRole} = useAuthContext();

    return (
        <>
            <main
                className={`view-content project-details
                ${(commentsVisibleToggle && isCommentsVisible) ? " -comments-visible" : ""}
                ${isModelViewVisible ? " -large-model-visible" : ""}
                ${isGalleryVisible ? " -gallery-visible" : ""}
                `} >
                {isGalleryVisible &&
                    <div className="gallery">
                        {hasRole(UserRole.ROLE_ADMIN) && (
                            <button className="btn btn-outline-gray btn-sm btn-icon btn-toggle-large-model js-toggle-large-model"
                                    onClick={() => toggle3DViewerOnOff()}>
                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="me-2" viewBox="0 0 16 16"><path d="M14.667 6.5a.504.504 0 0 1-.5-.5V4.667c0-1.72-1.114-2.834-2.833-2.834H4.667c-1.72 0-2.833 1.114-2.833 2.834V6c0 .273-.227.5-.5.5a.504.504 0 0 1-.5-.5V4.667c0-2.294 1.54-3.834 3.833-3.834h6.667c2.293 0 3.833 1.54 3.833 3.834V6c0 .273-.227.5-.5.5ZM11.334 15.167H4.667c-2.293 0-3.834-1.54-3.834-3.834V10c0-.273.227-.5.5-.5.274 0 .5.227.5.5v1.333c0 1.72 1.114 2.834 2.834 2.834h6.667c1.72 0 2.833-1.114 2.833-2.834V10c0-.273.226-.5.5-.5.273 0 .5.227.5.5v1.333c0 2.294-1.54 3.834-3.833 3.834Z" fill="#07031B" /><path d="M8 8.72a.497.497 0 0 1-.253-.067l-3.534-2.04a.501.501 0 0 1 .5-.866l3.28 1.9 3.26-1.887a.498.498 0 0 1 .68.18c.14.24.054.547-.18.687L8.247 8.653A.525.525 0 0 1 8 8.72Z" fill="#07031B" /><path d="M8 12.347a.504.504 0 0 1-.5-.5V8.22c0-.273.227-.5.5-.5s.5.227.5.5v3.627c0 .273-.227.5-.5.5Z" fill="#07031B" /><path d="M8 12.5c-.387 0-.767-.087-1.073-.253L4.793 11.06c-.64-.353-1.133-1.2-1.133-1.933v-2.26c0-.727.5-1.574 1.133-1.934l2.134-1.186c.613-.34 1.533-.34 2.146 0l2.134 1.186c.64.354 1.133 1.2 1.133 1.934v2.26c0 .726-.5 1.573-1.133 1.933l-2.134 1.187A2.277 2.277 0 0 1 8 12.5Zm-.587-7.873L5.28 5.813c-.327.18-.62.687-.62 1.054v2.26c0 .373.293.873.62 1.053l2.133 1.187c.307.166.867.166 1.174 0l2.133-1.187c.327-.18.62-.687.62-1.053v-2.26c0-.374-.293-.874-.62-1.054L8.587 4.627c-.307-.167-.867-.167-1.174 0Z" fill="#07031B" /></svg>
                                {product?.modelViewerEnabled ? "Turn 3D viewer off" : "Turn 3D viewer on"}
                            </button>
                        )}
                        <ProductImageView productUuid={productUuid}/>
                    </div>
                }


                <div className="model-large product-model-view">
                    {isModelViewVisible && model && <ModelViewer model={model}/>}
                    {isModelLoading && <LoadingComponent className={"loading"}/>}
                    {product?.modelViewerEnabled && isApproveProductButtonVisible &&
                        <button onClick={approveProduct} className="btn btn-icon btn-primary btn-approve">
                            <IconProvider Icon={IconTickCircle} size={16} className={"me-2"} /> Approve product
                        </button>
                    }
                </div>

                <div className="comments-thread">
                    {isCommentsVisible &&
                        <button className="btn btn-outline-gray btn-icon btn-sm btn-toggle-collapse js-toggle-comments-collapse"
                                onClick={() => setCommentsVisibleToggle(!commentsVisibleToggle)}
                                aria-label="Hide comments">
                            <i className="bi bi-caret-right-fill"/>
                        </button>
                    }
                    <ProductComments isReplyVisible={isReplyVisible}
                                    addCommentRequestMethods={addCommentRequestMethods}
                                    comments={comments}
                                    addComment={addComment}/>
                </div>
            </main>
        </>
    );
};

export default Product;

import React from "react";
import {useNavigate} from "react-router-dom";

const Tab: React.FC<{ name: string; count?: number; destination: string; active?: boolean; badgeColor?: string }> =
    ({
         name,
         count,
         destination,
         active,
         badgeColor
     }) => {
        const navigate = useNavigate();
        return (
            <li className="nav-item" onClick={() => navigate(destination)}>
            <span role="button" className={`nav-link text-decoration-none ${active ? "active" : ""}`}>
                <span className={`title ${active ? "text-decoration-underline" : ""}`}>{name}</span>
                <span className={`badge ${badgeColor ? badgeColor : "badge-gray"} ms-2`}>{count}</span>
            </span>
            </li>
        );
    };
export default Tab;

import React, {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import ProjectCard from "../components/new-project/ProjectCard";
import EmptyProject from "../components/new-project/EmptyProject";
import HomeHeader from "../components/new-project/project/HomeHeader";
import {ProjectStatus} from "../types/project/project-status";
import LoadingComponent from "../components/common/LoadingComponent";
import {useAuthContext} from "../store/AuthProvider";
import PageableProjectResponse from "../types/user/pageable-projects-response";
import projectClient from "../services/project.client";
import Pagination from "../components/Pagination";

const Home: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [reloadData, setReloadData] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const {user} = useAuthContext();

    const [projectPageNumber, setProjectPageNumber] = useState(0);
    const [projectSearchName, setProjectSearchName] = useState("");
    const [pageableProjectResponse, setPageableProjectResponse] = useState<PageableProjectResponse>();

    useEffect(() => {
        setLoading(true);
        const fetchProjects = () => {
            projectClient.getPaginatedProjectsResponse({
                page: projectPageNumber,
                name: projectSearchName,
                projectStatuses: searchParams.getAll("projectStatuses")
            }).then((res) => {
                setLoading(false);
                setReloadData(true);
                setPageableProjectResponse(res);
            });
        };
        fetchProjects();

        const intervalId = setInterval(fetchProjects, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, [searchParams, projectSearchName, projectPageNumber]);

    const removeProject = (projectUuid: string) => {
        loadProjects();
    };

    function loadProjects() {
        setLoading(true);
        projectClient.getPaginatedProjectsResponse({
            page: projectPageNumber,
            name: projectSearchName,
            projectStatuses: searchParams.getAll("projectStatuses")
        }).then((res) => {
            setLoading(false);
            setReloadData(true);
            setPageableProjectResponse(res);
        });
    }

    function showProjects() {
        return <> {pageableProjectResponse && pageableProjectResponse.content && pageableProjectResponse.content.length > 0 && (
            <div className="container">
                <div className="row">
                    {pageableProjectResponse.content.filter(project => {
                        if (searchParams.get("projectStatuses") !== ProjectStatus.DELETED) {
                            return project.status !== ProjectStatus.DELETED;
                        } else {
                            return true;
                        }
                    }).map((project, index) => {
                        return (
                            <ProjectCard key={index} index={index} project={project}
                                         onDelete={removeProject} aiMethod={project.aiMethod}/>
                        );
                    })}
                </div>
            </div>
        )}
            {(pageableProjectResponse?.content?.filter(project => project.status !== ProjectStatus.DELETED).length === 0
                    && !searchParams.get("projectStatuses")) &&
                <EmptyProject/>
            }
        </>;
    }

    function showLoading() {
        return (
            <div className="text-center align-content-center">
                <LoadingComponent/>
            </div>
        );
    }

    const getIfAnyProjectHasProductReadyForQc = (): boolean => {
        if (!pageableProjectResponse || !pageableProjectResponse.content) return false;
        for (let project of pageableProjectResponse.content) {
            if (project.status === ProjectStatus.IN_PRODUCTION && project.anyProductReadyForQCOrHasNoClientComments) {
                return true;
            }
        }
        return false;
    }

    const [query, setQuery] = useState(projectSearchName || "");
    const [debouncedQuery, setDebouncedQuery] = useState("");
    const debounceDelay = 300;

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (debouncedQuery.length >= 3) {
                setProjectSearchName && setProjectSearchName(debouncedQuery);
            } else {
                setProjectSearchName && setProjectSearchName("");
            }
        }, debounceDelay);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [debouncedQuery]);

    const handleQueryAndSearch = (event: { target: { value: any; }; }) => {
        const value = event.target.value;
        setQuery(value);
        setDebouncedQuery(value);
    };

    return (
        <>
            <HomeHeader pendingQuoteAmount={user ? user.pendingQuotes : null}
                        reloadData={reloadData}
                        projectSearchName={projectSearchName}
                        setReloadFalse={() => setReloadData(false)}
                        anyProductReadyForQCOrHasNoClientComments={getIfAnyProjectHasProductReadyForQc()}/>
            <main className="view-content">
                <div className="projects-header mb-4">
                    <div className="container">
                        <div className="row">
                            <div className="align-items-center h-100">
                                <div className="col">
                                    <Link to={"/new-project"}>
                                        <button className="btn btn-dark btn-icon float-end">
                                            <i className="bi bi-plus-circle me-2"></i> Add new project
                                        </button>
                                    </Link>
                                </div>
                                <div className="col-md-6">
                                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}
                                         className="col-md-12">
                                        <input className={"form-control form-control-lg float-start"} value={query}
                                               onChange={handleQueryAndSearch} placeholder={"Search..."}/>
                                        <span style={{display: "contents"}}>
                                            <i className="bi bi-search"
                                               style={{position: "relative", right: "30px"}}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="projects-list">
                    {loading ? showLoading() : showProjects()}
                </div>
                {pageableProjectResponse?.content && (
                    <Pagination page={{
                        page: pageableProjectResponse?.pageable?.pageNumber || 0,
                        totalPages: pageableProjectResponse?.totalPages || 0,
                        totalElements: pageableProjectResponse?.totalElements || 0
                    }} setCurrentPage={setProjectPageNumber}/>
                )}
            </main>
        </>
    );
};

export default Home;

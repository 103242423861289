import Tab from "./new-project/project/Tab";
import React from "react";

const VersionTabsContainer: React.FC<{ versions: number[] | undefined, selectedVersionNumber: string | null, productUuid: string, destinationPrefix: string }>
    = ({
           versions,
           selectedVersionNumber,
           productUuid,
           destinationPrefix
       }) => {
    return <div className="row mt-4">
        <div className="col">
            <ul className="nav view-nav mb-n3">
                <Tab name="New version" active={selectedVersionNumber === null}
                     destination={destinationPrefix + productUuid}/>
                {versions?.map((version, index) => (
                    <Tab key={index} name={"Version " + version}
                         active={selectedVersionNumber === version.toString()}
                         destination={destinationPrefix + productUuid + "?version=" + version}/>
                ))}
            </ul>
        </div>
    </div>;
};

export default VersionTabsContainer;

import {useFormContext, useWatch} from "react-hook-form";
import {useDropzone} from "react-dropzone";
import {handleDropzoneFiles} from "./DropzoneUtils";
import {ProductThumbnailsResponse} from "../types/attachment/attachment-response";
import {useEffect} from "react";
import AttachmentClient from "../services/attachment.client";

export const base64FilesToJSFiles = (files: { fileName: string; fileType: string; base64: string; }[]): File[] => {
    let fileList: File[] = [];
    for (let file of files) {
        fileList.push(base64FileToJSFile({fileName: file.fileName, fileType: file.fileType, base64: file.base64}));
    }
    return fileList;
};

export const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
};

export const base64FileToJSFile = (file: { fileName: string; fileType: string; base64: string; }): File => {
    let bstr = window.atob(file.base64);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], file.fileName, {type: file.fileType});
};

export function InternalFileInputActions(name: string) {
    const {setValue, getValues} = useFormContext();
    const values = useWatch({name}) as File[];

    const {getRootProps, getInputProps} = useDropzone({
        onDrop: (acceptedFiles) => {
            let existingFiles: File[] = getValues(name);
            setValue(name, handleDropzoneFiles(existingFiles, acceptedFiles));
        }
    });

    function remove(index: number) {
        values.splice(index, 1);
        if (!values || values.length < 1) {
            setValue(name, undefined);
            return;
        }
        setValue(name, values);
    }

    return {getValues, values, getRootProps, getInputProps, remove};
}

export function FileInputHooks(productUuid: string | undefined, setDownloadedFiles: (value: (((prevState: (ProductThumbnailsResponse | undefined)) => (ProductThumbnailsResponse | undefined)) | ProductThumbnailsResponse | undefined)) => void) {
    useEffect(() => {
        if (!productUuid) {
            return;
        }
        AttachmentClient.getProductThumbnails(productUuid)
            .then((res) => {
                setDownloadedFiles(res);
            });
    }, [productUuid, setDownloadedFiles]);
}

export function appendFilesAttachmentsToFormData(models: File[], attachments: File[], comment: string | null, modelFormData: FormData) {
    Array.from(models).forEach((file) => {
        modelFormData.append("modelFile", file, file.name);
    });

    if (attachments) {
        Array.from(attachments).forEach((file) => {
            modelFormData.append("attachmentFile", file, file.name);
        });
    }
    if (comment) {
        modelFormData.append("comment", comment);
    }
}

export function getModelsAndAttachmentFilesSize(models: File[], attachments: File[]): number {
    return getFilesArraySize(models) + getFilesArraySize(attachments);
}

function getFilesArraySize(files: File[]): number {
    let filesSize = 0;
    if (files) {
        files.forEach((file) => {
            filesSize = filesSize + file.size;
        });
    }
    return filesSize;
}

export function userDownloadFile(blobPart: BlobPart, fileName: string) {
    const url = window.URL.createObjectURL(new Blob([blobPart]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}



import React from "react";
import FileHandlerListItem from "./FileHandlerListItem";
import BulkUploadFileInput from "../new-project/file-input/BulkUploadFileInput";
import {BulkImport} from "./BulkImport";
import AsyncAttachmentMockUpload from "./AsyncAttachmentMockUpload";

const BulkImportFileHandler: React.FC<{
    errorMessage?: string;
}> = ({errorMessage}) => {

    const {
        updateFiles,
        updateFile,
        attachments,
        removeFile
    } = BulkImport("bulkImport")

    return (
        <>
            <BulkUploadFileInput updateFiles={updateFiles}
                                 name={"bulkImport"}
                                 errorMessage={errorMessage}/>
            <div className={`attachments-list ${(attachments && attachments.length > 0) ? "mt-3" : ""} `}>
                {attachments.map((attachment) => {
                    return <AsyncAttachmentMockUpload
                        key={attachment.referenceUuid}
                        attachment={attachment}
                        remove={removeFile}
                        updateFile={updateFile}
                        displayElement={<FileHandlerListItem/>}/>
                })
                }
            </div>
        </>
    );
};

export default BulkImportFileHandler;
export enum ProductStatus {
    DRAFT = "DRAFT",
    PENDING_QUOTE = "PENDING_QUOTE",
    IN_PRODUCTION = "IN_PRODUCTION",
    READY_FOR_QC = "READY_FOR_QC",
    IN_EDITING = "IN_EDITING",
    APPROVED = "APPROVED",
    DELETED = "DELETED",
    ERROR = "ERROR",
}

export enum BadgeStatus {
    SHAREABLE = "SHAREABLE",
}

export const PRODUCT_STATUS_TRANSLATIONS: { [key in ProductStatus]: string } = {
    [ProductStatus.DRAFT]: "Draft",
    [ProductStatus.PENDING_QUOTE]: "Pending quotes",
    [ProductStatus.IN_PRODUCTION]: "In production",
    [ProductStatus.READY_FOR_QC]: "Ready for QC",
    [ProductStatus.IN_EDITING]: "In editing",
    [ProductStatus.APPROVED]: "Approved",
    [ProductStatus.DELETED]: "Deleted",
    [ProductStatus.ERROR]: "ERROR"
};

export const BADGE_STATUS_TRANSLATIONS: { [key in BadgeStatus]: string } = {
    [BadgeStatus.SHAREABLE]: "Files & shareable"
}

export const ALLOWED_CLIENT_STATUSES = [ProductStatus.IN_PRODUCTION, ProductStatus.READY_FOR_QC, ProductStatus.IN_EDITING];
export const NOT_ALLOWED_INTERNAL_STATUS = ProductStatus.DRAFT;
export const APPROVABLE_PRODUCT_STATUSES = [ProductStatus.IN_PRODUCTION, ProductStatus.IN_EDITING, ProductStatus.READY_FOR_QC];
export const VIEWABLE_PRODUCT_STATUSES = [
    ProductStatus.IN_PRODUCTION,
    ProductStatus.IN_EDITING,
    ProductStatus.READY_FOR_QC,
    ProductStatus.APPROVED,
    ProductStatus.IN_PRODUCTION,
    ProductStatus.PENDING_QUOTE,
    ProductStatus.DELETED
];

import React from "react";
import {useNavigate, useParams} from "react-router-dom";

const ProjectDetailsHeader: React.FC<{
    title: string;
    subTitle?: string;
}> = ({title, subTitle}) => {
    const navigate = useNavigate();
    const {projectUuid} = useParams();

    return (
        <>
            <header className="view-header">
                <div className="container-fluid">
                    <div className="col-md-auto">
                        <div className={"title"}>
                            <div className="spacer"></div>
                            <div className="sub-title">
                                <h6>{subTitle}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-md-auto">
                            <div className="title">
                                <button className="btn btn-outline-gray btn-icon btn-sm me-3"
                                        onClick={() => navigate("/project/" + projectUuid)}>
                                    <i className="bi bi-caret-left-fill"></i>
                                </button>
                                <h1>{title}</h1>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            </header>
        </>
    );
};

export default ProjectDetailsHeader;

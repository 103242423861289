import React from "react";
import {Variant} from "react-bootstrap/types";
import {Spinner} from "react-bootstrap";
import IconProvider from "../IconProvider";

const LoadingButton: React.FC<{
    className?: string;
    onClick: () => void,
    btnClassName?: string;
    loading: boolean;
    loadingTooltip?: string;
    loaderVariant?: Variant;
    buttonText: string;
    buttonTextWhileLoading?: string;
    disabled?: boolean;
    style?:boolean;
    Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
}> = ({onClick, btnClassName = "btn-primary", loading, loadingTooltip = "", loaderVariant = "light", className, buttonText, buttonTextWhileLoading, disabled = false, Icon}) => {

    function getButtonText () {
        if (buttonTextWhileLoading && loading) {
            return buttonTextWhileLoading;
        }
        return buttonText;
    }

    return (
        <div className={`d-inline-block ${className ? className : ""}`}
             title={loading ? loadingTooltip : ""}>
            <button onClick={onClick} disabled={loading || disabled} className={`btn ${btnClassName ? btnClassName : ""} ${Icon ? "btn-icon" : ""}`}>
                {Icon && !loading && <IconProvider Icon={Icon} size={16} className={"me-2"} />}
                {loading && <Spinner animation="border" variant={loaderVariant} size={"sm"} className={"me-2"}/>}
                <span>{getButtonText()}</span>
            </button>
        </div>
    );
}

export default LoadingButton;

import React from "react";
import {Link, useParams} from "react-router-dom";
import logo from "../../assets/logo-alpha.svg";
import {Dropdown} from "react-bootstrap";
import {truncate} from "../../utils/TextUtil";
import {Attachment} from "../../types/attachment/attachment-response";
import AttachmentClient from "../../services/attachment.client";
import ProductInfoResponse from "../../types/product/product-info-response";
import {ProductStatus} from "../../types/product/product-status";
import {useAuthContext} from "../../store/AuthProvider";
import {UserRole} from "../../types/user/user-role";
import LoadingButton from "./LoadingButton";
import IconProvider from "../IconProvider";
import {ReactComponent as IconDownload} from "../../assets/icons/icon-download.svg";
import {ReactComponent as Icon3dRotate} from "../../assets/icons/icon-3d-rotate.svg";
import {ReactComponent as IconTickCircle} from "../../assets/icons/icon-tick-circle.svg";
import {usePaymentToken} from "../../store/PaymentTokenProvider";
import {AlphaPayment} from "../../types/payment-price";

const ProductHeader: React.FC<{
    title?: string;
    product?: ProductInfoResponse
    versions?: number[];
    versionNumber: string | null;
    modelNumber: string | null;
    versionModels?: Attachment[];
    versionAttachments?: Attachment[];
    approveProduct: () => void;
    isApproveProductButtonVisible: boolean;
    downloadModels: () => void;
    showUnlockDownloadModel: () => void;
    isDownloading: boolean;
    isAiProject: boolean;
    isMoveToEditingButtonVisible: boolean;
    confirmationHandler: () => void;
}> = ({
          title,
          product,
          versions,
          versionNumber,
          modelNumber,
          versionModels,
          versionAttachments,
          approveProduct,
          isApproveProductButtonVisible,
          downloadModels,
          showUnlockDownloadModel,
          isDownloading,
          isAiProject,
          isMoveToEditingButtonVisible,
          confirmationHandler,

      }) => {
    const {productUuid} = useParams();
    const projectDestination = "/project/" + product?.projectUuid;
    const detailsDestination = "/product/" + productUuid + "/details";
    const homeDestination = "/";

    const {hasRole} = useAuthContext();
    const {prices, tokens} = usePaymentToken();

    const downloadAttachment = async (attachment: Attachment) => {
        await AttachmentClient.getAttachmentFile(attachment);
    };

    const isLatestVersion = (): boolean => {
        if (!versions || versions.length === 0) return false;
        if (!versionNumber) return false;
        return versions[0].toString() === versionNumber;
    }

    const isDownloadButtonVisible = isLatestVersion() && hasRole(UserRole.ROLE_USER) && product?.status === ProductStatus.APPROVED;
    const isModelSelectVisible = product?.modelViewerEnabled && versionModels && versionModels.length > 0 && !isAiProject;
    const isAttachmentsVisible = !isAiProject;
    const isVersionSelectVisible = versions && versions.length > 0 && !isAiProject;
    const isShareButtonVisible = isLatestVersion() && hasRole(UserRole.ROLE_USER) && product?.status === ProductStatus.APPROVED;

    function manyModelSelection() {
        return <Dropdown className="d-inline-block me-2">
            <Dropdown.Toggle variant="white">
                {
                    truncate(versionModels!.find(model => model.attachmentUuid.toString() === modelNumber)?.fileName, 30) || "Models"
                }
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {versionModels!.map((model, index) => (
                    <Dropdown.Item key={index} as={Link}
                                   to={"/product/" + productUuid + "?version=" + versionNumber + "&model=" + model.attachmentUuid}>
                        {truncate(model.fileName, 30)}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>;
    }

    function oneModelSelection() {
        return <Dropdown id="versionSelect" style={{pointerEvents: "none"}}
                         className="d-inline-block me-2">
            <div className="btn btn-outline-gray btn-icon">
                <IconProvider Icon={Icon3dRotate} size={16} className={"me-2"}/>
                {truncate(versionModels!.find(model => model.attachmentUuid.toString() === modelNumber)?.fileName, 30) || "Models"}
            </div>
        </Dropdown>;
    }

    return (
        <header className="app-header -compact pb-1">
            <div className="container-fluid">
                <div className="row justify-content-between align-items-center">
                    <div className="col-sm-1">
                        <Link to={homeDestination}>
                            <img src={logo} width="143" alt="Alpha AR"/>
                        </Link>
                    </div>
                    <div className="col-md-auto">
                        <div className="dropdown versionselect">
                            {isVersionSelectVisible && (versions.length === 1
                                    ? <Dropdown id="versionSelect" style={{pointerEvents: "none"}}>
                                        <div className="btn btn-xs btn-transparent p-0 ">
                                            Version {versionNumber}
                                        </div>
                                    </Dropdown>
                                    : <Dropdown id="versionSelect">
                                        <Dropdown.Toggle variant="transparent" className="btn-xs p-0 ">
                                            Version {versionNumber}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {versions.map((version, index) => (
                                                version.toString() === versionNumber
                                                    ? <Dropdown.Item key={index} as={Link}
                                                                     onClick={(e) => e.preventDefault()}
                                                                     to={"/product/" + productUuid + "?version=" + version}>
                                                        {version}
                                                    </Dropdown.Item>
                                                    : <Dropdown.Item key={index} as={Link}
                                                                     to={"/product/" + productUuid + "?version=" + version}>
                                                        {version}
                                                    </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                            )}
                        </div>
                        <div className="title">
                            <h4 className="mb-0">{title}</h4>
                            <Link to={detailsDestination}
                                  className="details btn btn-outline-gray btn-xs ms-3">Details</Link>
                        </div>
                    </div>
                    <div className="col-md text-end">
                        <div className="btn btn-icon me-2 pe-none"><span className="fw-bolder">{tokens} tokens</span>
                        </div>
                        {isShareButtonVisible &&
                            <Link to={"/partners"}>
                                <button className="btn btn-outline-gray me-2">
                                    <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         viewBox="0 0 24 24">
                                        <g fill="currentColor">
                                            <path
                                                d="M15 22.75H9c-5.43 0-7.75-2.32-7.75-7.75V9c0-5.43 2.32-7.75 7.75-7.75h6c5.43 0 7.75 2.32 7.75 7.75v6c0 5.43-2.32 7.75-7.75 7.75zm-6-20C4.39 2.75 2.75 4.39 2.75 9v6c0 4.61 1.64 6.25 6.25 6.25h6c4.61 0 6.25-1.64 6.25-6.25V9c0-4.61-1.64-6.25-6.25-6.25H9z"/>
                                            <path
                                                d="M14.83 12.68c-.41 0-.75-.34-.75-.75V8.44h-3.49c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4.24c.41 0 .75.34.75.75v4.24c0 .41-.34.75-.75.75z"/>
                                            <path
                                                d="M9.17 14.09c-.19 0-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l5.66-5.66c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L9.7 13.87c-.14.15-.34.22-.53.22zM12 18.23c-2.11 0-4.23-.34-6.24-1.01a.753.753 0 0 1-.47-.95c.13-.39.56-.61.95-.47 3.72 1.24 7.81 1.24 11.53 0 .39-.13.82.08.95.47.13.39-.08.82-.47.95-2.02.68-4.14 1.01-6.25 1.01z"/>
                                        </g>
                                    </svg>
                                    Share
                                </button>
                            </Link>
                        }
                        {versions && versions.length > 0 &&
                            <>
                                {isDownloadButtonVisible && product.downloadUnlocked &&
                                    <LoadingButton onClick={downloadModels}
                                                   className={"me-2"}
                                                   btnClassName={"btn-primary"}
                                                   buttonText={"Download models"}
                                                   buttonTextWhileLoading={"Downloading"}
                                                   loading={isDownloading}
                                                   loaderVariant={"light"}
                                                   Icon={IconDownload}
                                    />
                                }
                                {isDownloadButtonVisible && !product.downloadUnlocked &&
                                    <LoadingButton onClick={showUnlockDownloadModel}
                                                   className={"me-2"}
                                                   btnClassName={"btn-primary"}
                                                   buttonText={`Download | ${prices.get(AlphaPayment.DOWNLOAD_MODEL)} tokens`}
                                                   buttonTextWhileLoading={"Downloading"}
                                                   loading={isDownloading}
                                                   loaderVariant={"light"}
                                                   Icon={IconDownload}
                                    />
                                }
                                {isModelSelectVisible && (versionModels.length === 1
                                    ? oneModelSelection()
                                    : manyModelSelection())}
                                {isAttachmentsVisible &&
                                    <Dropdown className="d-inline-block me-2">
                                        <Dropdown.Toggle
                                            variant="outline-gray"
                                            className={`${versionAttachments && versionAttachments.length > 0 ? "" : "disabled"}`}>
                                            Attachments
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {versionAttachments && versionAttachments.map((attachment, index) => (
                                                <Dropdown.Item key={index}
                                                               onClick={() => downloadAttachment(attachment)}>
                                                    {truncate(attachment.fileName, 20)}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                                {isApproveProductButtonVisible &&
                                    <button onClick={approveProduct} className="btn btn-primary btn-icon me-2">
                                        <IconProvider Icon={IconTickCircle} size={16} className={"me-2"}/> Approve
                                        product
                                    </button>
                                }
                                {isMoveToEditingButtonVisible &&
                                    <button onClick={confirmationHandler} className="btn btn-primary me-2">
                                        Move to editing
                                    </button>
                                }
                            </>
                        }
                        <Link className="btn-close btn btn-outline-gray" to={projectDestination}/>
                    </div>
                </div>
                <hr className="mt-3 mb-0"/>
            </div>
        </header>
    );
};

export default ProductHeader;

import React from "react";
import LoadingButton from "../product/LoadingButton";
import {SEND_FOR_QUOTE, WAIT_FILE_UPLOAD} from "../../common-texts";
import { ReactComponent as IconSend } from "../../assets/icons/icon-send.svg"; 

interface IAddNewProductFooter {
    addProduct: () => void,
    cancel: () => void,
    filesUploading: boolean
}

const AddNewProductFooter: React.FC<IAddNewProductFooter> = ({addProduct, cancel, filesUploading}) => {
    return (
        <div className="app-footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <hr className="mt-0" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5 offset-md-1"/>
                    <div className="col-md-5 text-end">
                        <button onClick={() => cancel()} className="btn btn-white me-2">
                            Cancel
                        </button>
                        <LoadingButton
                            buttonText={SEND_FOR_QUOTE}
                            btnClassName={"btn-dark"}
                            loaderVariant={"light"}
                            onClick={addProduct}
                            loading={filesUploading}
                            loadingTooltip={WAIT_FILE_UPLOAD}
                            Icon={IconSend} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddNewProductFooter;

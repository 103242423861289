import * as Yup from "yup";

const requiredString = "This field is required!";
export const generateReportValidation = Yup.object().shape({
    startDate: Yup.date()
        .required(requiredString)
        .typeError(requiredString),
    endDate: Yup.date()
        .required(requiredString).min(Yup.ref('startDate'),
            "End date can't be before start date"
        ).typeError(requiredString)
});

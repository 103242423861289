import axios, {AxiosResponse} from "axios";
import GenericErrorResponse from "../types/generic-error-response";
import ProductInfoResponse from "../types/product/product-info-response";
import ProductOfferUpdateRequest from "../types/product/product-offer-update-request";
import {CommentDTO} from "../types/product/product-comments-dtos";

const PRODUCT_ENDPOINT = process.env.REACT_APP_API_URL + "product/";

export class ProductClient {
    getProductInfo(productUuid: string): Promise<ProductInfoResponse> {
        return axios
            .get(PRODUCT_ENDPOINT + productUuid)
            .then(res => {
                return res.data
            });
    }

    getProductComments(productUuid: string): Promise<CommentDTO[]> {
        return axios
            .get(PRODUCT_ENDPOINT + productUuid + "/comment")
            .then(res => {
                return res.data
            })
            .catch(res => formatErrorResponse(res.response));
    }

    deleteProduct(productUuid: string) {
        return axios
            .delete(PRODUCT_ENDPOINT + productUuid)
            .then((res) => res.data);
    }

    updateProductOffer(productUuid: string, request: ProductOfferUpdateRequest) {
        return axios
            .patch(PRODUCT_ENDPOINT + productUuid + "/offer",
                request)
            .then((res) => res.data)
            .catch((res) => formatErrorResponse(res.response))
    }

    async restoreProduct(productUuid: string) {
        return axios
            .patch(PRODUCT_ENDPOINT + productUuid + "/restore",
                null)
            .then((res) => res.data);
    }

    async addComment(productUuid: string, formData: FormData): Promise<CommentDTO> {
        return axios
            .post(PRODUCT_ENDPOINT + productUuid + "/comment",
                formData)
            .then((res) => res.data);
    }

    toggleModelViewer(productUuid: string, isEnabled: boolean) {
        return axios.patch(PRODUCT_ENDPOINT + productUuid + "/setModelViewer", {}, {
            params: {isEnabled: isEnabled}
        }).then(res => {
            return res.data;
        });
    }

    approveProduct(productUuid: string) {
        return axios
            .patch(PRODUCT_ENDPOINT + productUuid + "/approve",
                null)
            .then(res => {
                return res.data;
            });
    }

    moveToEditing(productUuid: string) {
        return axios.post(PRODUCT_ENDPOINT + productUuid + "/moveToEditing", null).then(res => {
            return res.data;
        })
    }

    unlockProductDownload(productUuid: string): Promise<void> {
        return axios.post(PRODUCT_ENDPOINT + productUuid + "/unlockDownload", null);
    }
}

export const formatErrorResponse = (response: AxiosResponse): GenericErrorResponse => {
    return {
        status: response.data.status,
        message: response.data.detail
    }
}

export default new ProductClient();

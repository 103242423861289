import React from "react";
import {useFormContext} from "react-hook-form";
import InputError from "./InputError";

const NewDropdown: React.FC<{
    values?: string[] | number[];
    label: string;
    name: string;
    errorMessage?: string;
    disabled?: boolean;
    translations?: { [key: string]: string };
}> = ({values, label, name, errorMessage, disabled = false, translations}) => {
    const {register} = useFormContext();

    return (
        <div className="mb-3">
            <label className="form-label">{label}</label>
            <select disabled={disabled} className="form-select form-select-lg" {...register(name)}>
                <option/>
                {values?.map((value) => (
                    <option value={value} key={value}>{translations ? translations[value] : value}</option>
                ))}
            </select>
            <InputError errorMessage={errorMessage}></InputError>
        </div>
    );
};

export default NewDropdown;

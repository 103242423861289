import React from "react";

const PrefilledInput: React.FC<{
    name: string;
    label: string;
    type?: string;
    errorMessage?: string;
    value?: string;
    className?: string;
}> = ({name, label, type = "text", value, className}) => {
    if (!value) value = "";
    return (
        <>
            <div className={`mb-3 ${className ? className : ""}`}>
                <label htmlFor={label} className="form-label">
                    {label}
                </label>
                <input type={type} disabled className="form-control form-control-lg" id={name} value={value}/>
            </div>
        </>
    );
};

export default PrefilledInput;

import InputError from "./InputError";
import CompanyResponse from "../types/company/company-response";
import {Option} from "react-bootstrap-typeahead/types/types";
import {useEffect, useRef} from "react";
import {useFormContext} from "react-hook-form";
import {Typeahead} from "react-bootstrap-typeahead";

const CompanyDropdown: React.FC<{
    companies: CompanyResponse[];
    label: string;
    name: string;
    errorMessage?: string;
}> = ({companies, label, name, errorMessage}) => {
    const {setValue} = useFormContext();
    // TODO: typescript when updated to to latest version use this: https://www.npmjs.com/package/@types/react-bootstrap-typeahead
    const ref = useRef<any>(null);

    function setInputText() {
        const value = ref.current.props.value
        if (value && value.length > 0) {
            ref.current.setState({...value, text: value[0].name})
        }
    }

    useEffect(() => {
        setInputText();
    },[])

        const onChange = (options: Option[]) => {
            if (options.length > 0) {
                const option = options[0] as CompanyResponse;
                setValue(name, option.name);
            } else {
                setValue(name, undefined);
            }
        }

        return (
                <div className="mb-3">
                    <label className="form-label">{label}</label>
                    <Typeahead
                        options={companies}
                        labelKey={"name"}
                        onChange={onChange}
                        ref={(localRef) => {
                            ref.current = localRef;
                        }}
                        id={name}
                        inputProps={{className: "form-select form-select-lg"}}
                    />
                    <InputError errorMessage={errorMessage}/>
                </div>
    );
}

export default CompanyDropdown;

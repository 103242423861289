import React, {useCallback, useEffect} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import ProjectClient from "../../../services/project.client";
import ProductCounterResponse from "../../../types/product/product-counter-response";
import {PRODUCT_STATUS_TRANSLATIONS, ProductStatus} from "../../../types/product/product-status";
import Tab from "./Tab";
import {useClassifications} from "../../../store/ClassificationProvider";
import {isOnlyAiGeneratedProductionType} from "../../../utils/ProjectUtils";
import {AiMethod} from "../../../types/project/ai-method-type";

const STATUS_TABS: ProductStatus[] = [
    ProductStatus.PENDING_QUOTE,
    ProductStatus.IN_PRODUCTION,
    ProductStatus.READY_FOR_QC,
    ProductStatus.IN_EDITING,
    ProductStatus.APPROVED,
    ProductStatus.DELETED
];

const AI_STATUS_PRODUCT_TABS: ProductStatus[] = [
    ProductStatus.IN_PRODUCTION,
    ProductStatus.APPROVED,
];

const ProjectHeader: React.FC<{ projectUuid: string; projectName?: string; aiMethod?: AiMethod; setReloadFalse: () => void; reloadData: boolean, productSearchName: string}>
    = ({
           projectUuid,
           projectName,
           aiMethod,
           reloadData,
           setReloadFalse
    }) => {
    const navigate = useNavigate();
    const [productsCount, setProductsCount] = React.useState<ProductCounterResponse[]>([]);
    const [totalProductsCountExceptDeleted, setTotalProductsCountExceptDeleted] = React.useState<number>();
    const destinationConstant = "/project/" + projectUuid + "?productStatuses=";
    const detailsDestination = "/project/" + projectUuid + "/details"
    const [searchParams] = useSearchParams();
    const activeTab = searchParams.get("productStatuses");
    const {productionTypes} = useClassifications();

    const fetchProductCount = useCallback(() => {
        ProjectClient.getProjectProductCount(projectUuid)
            .then((res) => setProductsCount(res));
    }, [projectUuid])

    useEffect(() => {
        function getProjectProductCount(projectUuid: string) {
            ProjectClient.getProjectProductCount(projectUuid)
                .then((res) => setProductsCount(res));
        }
        const projectProductCountInterval = setInterval(() => getProjectProductCount(projectUuid), 60000);
        return () => {
            clearInterval(projectProductCountInterval);
        }
    }, [projectUuid])

    useEffect(() => {
        if (!reloadData) return;
        fetchProductCount()
        setReloadFalse()
    }, [reloadData, setReloadFalse, fetchProductCount])

    useEffect(() => {
        if (productsCount.length > 0) return;
        fetchProductCount()
    }, [fetchProductCount, productsCount])

    useEffect(() => {
        if (productsCount.length === 0) return;
        let totalSum = 0;
        productsCount.filter(productCount => productCount.status !== ProductStatus.DELETED).forEach((projectCount) => {
            totalSum += projectCount.count;
        });
        setTotalProductsCountExceptDeleted(totalSum);
    }, [productsCount]);

    function findByStatus(byStatus: ProductStatus) {
        return productsCount?.find((productCount) => productCount.status === byStatus)?.count;
    }


    return (
        <header className="view-header">
            <div className="container-fluid">
                <div className="subtitle-spacer"/>
                {/* TODO: rework header so this hack isnt needed*/}
                <div className="row justify-content-between">
                    <div className="col-md-auto">
                        <div className="title">
                            <button className="btn btn-outline-gray btn-icon btn-sm me-3" onClick={() => navigate("/")}>
                                <i className="bi bi-caret-left-fill"></i>
                            </button>
                            <h1>{projectName}</h1>
                            <Link to={detailsDestination}
                                  className="details btn btn-outline-gray btn-xs ms-3">Details</Link>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col">
                        <ul className="nav view-nav mb-n3">
                            <Tab
                                active={activeTab === null}
                                name={aiMethod === AiMethod.ANYTHING ? "All Assets" : "All Products"}
                                count={totalProductsCountExceptDeleted}
                                destination={"/project/" + projectUuid}
                            />
                            {(isOnlyAiGeneratedProductionType(productionTypes) ? AI_STATUS_PRODUCT_TABS: STATUS_TABS).map((statusTab) => (
                                <Tab
                                    key={statusTab}
                                    name={PRODUCT_STATUS_TRANSLATIONS[statusTab]}
                                    count={findByStatus(statusTab)}
                                    destination={destinationConstant + statusTab}
                                    active={activeTab === statusTab}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
};
export default ProjectHeader;

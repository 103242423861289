import {ProjectStatus} from "../types/project/project-status";
import {Link} from "react-router-dom";
import React from "react";

const PendingQuoteHeader: React.FC<{
    amount: number
}> = ({amount}) => {
    const pendingQuoteText = amount === 1 ? "You have a new pending quote" : "You have new pending quotes";
    const viewProjectText = amount === 1 ? "View project" : "View projects";

    return (
        <header className={"app-header -alert"}>
            <div className="container-fluid">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-2"/>
                    <div className="col-md text-center text-white">
                        {pendingQuoteText}
                    </div>
                    <div className="col-md-2 text-end">
                        <Link to={"/?projectStatuses=" + ProjectStatus.PENDING_QUOTES}>
                            <button
                                className="btn btn-sm btn-white">{viewProjectText}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default PendingQuoteHeader

import ProductInfoResponse from "../types/product/product-info-response";
import AttachmentClient from "../services/attachment.client";
import ProductClient from "../services/product.client";
import {Attachment} from "../types/attachment/attachment-response";
import {
    Control,
    FieldArray,
    FieldArrayMethodProps,
    FieldValues,
    UnpackNestedValue,
    UseFormReturn
} from "react-hook-form";
import {ModelsAttachmentsCommentsForm} from "../types/models-attachments-comments-form";
import {AddNewProductsRequest, ProductRequest} from "../types/product/add-new-products-dtos";
import ProjectRequest from "../types/project/project-request";

export function getInternalVersionNumbers(productUuid: string | undefined, versions: number[] | undefined, setVersions: (value: (((prevState: number[] | undefined) => number[]) | number[])) => void) {
    if (versions) return;
    AttachmentClient.getInternalVersionNumbers(productUuid!).then((res) => {
        setVersions(res);
    });
}

export function getClientVersionNumbers(productUuid: string | undefined, versions: number[] | undefined, setVersions: (value: (((prevState: number[] | undefined) => number[]) | number[])) => void) {
    if (versions) return;
    AttachmentClient.getClientVersionNumbers(productUuid!).then((res) => {
        setVersions(res);
    });
}

export function getProduct(productUuid: string | undefined, setProduct: (value: (((prevState: (ProductInfoResponse | undefined)) => (ProductInfoResponse | undefined)) | ProductInfoResponse | undefined)) => void) {
    if (!productUuid) return;
    ProductClient.getProductInfo(productUuid).then((res) => {
        setProduct(res);
    });
}


export function getInternalModelsAndAttachments(versionNumber: string | null, productUuid: string | undefined, setVersionModels: (value: (((prevState: Attachment[]) => Attachment[]) | Attachment[])) => void, setVersionAttachments: (value: (((prevState: Attachment[]) => Attachment[]) | Attachment[])) => void, saveRequestMethods: UseFormReturn<ModelsAttachmentsCommentsForm, any>) {
    if (versionNumber) {
        AttachmentClient.getInternalModels(productUuid!, versionNumber).then(res => {
            setVersionModels(res);
        });
        AttachmentClient.getInternalAttachments(productUuid!, versionNumber).then(res => {
            setVersionAttachments(res);
        });
    } else {
        saveRequestMethods.reset();
    }
}

export function getClientModelsAndAttachments(versionNumber: string | null, productUuid: string | undefined, setVersionModels: (value: (((prevState: Attachment[]) => Attachment[]) | Attachment[])) => void, setVersionAttachments: (value: (((prevState: Attachment[]) => Attachment[]) | Attachment[])) => void, saveRequestMethods: UseFormReturn<ModelsAttachmentsCommentsForm, any>) {
    if (versionNumber) {
        AttachmentClient.getClientModels(productUuid!, versionNumber).then(res => {
            setVersionModels(res);
        });
        AttachmentClient.getClientAttachments(productUuid!, versionNumber).then(res => {
            setVersionAttachments(res);
        });
    } else {
        saveRequestMethods.reset();
    }
}


export function handleAppendNewProduct(control: Control<AddNewProductsRequest, any> | Control<ProjectRequest, any>, append: (value: (Partial<UnpackNestedValue<FieldArray<FieldValues, string>>> | Partial<UnpackNestedValue<FieldArray<FieldValues, string>>>[]), options?: FieldArrayMethodProps) => void) {
    let previousFieldCategory = [];
    const productFields = control._getWatch("products")
    if (productFields?.length > 0) {
        previousFieldCategory = productFields[productFields.length - 1].category
    }
    append({
        productUuid: "",
        brandName: "",
        modelName: "",
        idNumber: "",
        url: "",
        notes: "",
        category: previousFieldCategory,
        attachmentUuids: [],
        unProcessedAttachments: 0,
        notSavedAttachmentUuids: [],
        attachmentInfoList: [],
        removedAttachmentUuids: [],
        modelGenerationText: ""
    } as ProductRequest)
}

export function checkIfAnyFileUploading(request: ProjectRequest | AddNewProductsRequest, setIsAnyFileUploading: (value: (((prevState: boolean) => boolean) | boolean)) => void) {
    if ("unProcessedAttachments" in request && request.unProcessedAttachments > 0) {
        setIsAnyFileUploading(true);
        return;
    }
    if (!request.products) {
        setIsAnyFileUploading(false);
        return;
    }
    for (let product of request.products) {
        if (product.unProcessedAttachments > 0) {
            setIsAnyFileUploading(true);
            return;
        }
    }
    setIsAnyFileUploading(false);
}

export function handleAppendBulkUploadResult(res: ProductRequest[], append: (value: (Partial<UnpackNestedValue<FieldArray<FieldValues, string>>> | Partial<UnpackNestedValue<FieldArray<FieldValues, string>>>[]), options?: FieldArrayMethodProps) => void) {
    for (let productRequest of res) {
        append({
            productUuid: "",
            brandName: productRequest.brandName,
            modelName: productRequest.modelName,
            idNumber: productRequest.idNumber,
            url: productRequest.url,
            notes: productRequest.notes,
            category: productRequest.category,
            attachmentUuids: productRequest.attachmentUuids,
            attachmentInfoList: productRequest.attachmentInfoList,
            unProcessedAttachments: 0,
            notSavedAttachmentUuids: [],
            removedAttachmentUuids: [],
            modelGenerationText: productRequest.modelGenerationText
        } as ProductRequest)
    }
}

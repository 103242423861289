import React from "react";
import {useWatch} from "react-hook-form";
import {AiMethod} from "../../../../types/project/ai-method-type";

const AiExplained: React.FC = () => {
    const aiMethod = useWatch({name: "aiMethod"})

    function getMeshAiText() {
        return <>
            <h4>AI explained</h4>
            <p className="mt-2">
                Mesh AI method automatically renders your 2D image into a 3D digital asset. This method
                takes between a few seconds to few minutes to receive a downloadable .glb file, which is
                compatible with any Augmented Reality (AR) or 3D environment.
                <br/>
                <br/>
                Make sure the image you upload is in .png, .jpg or .jpeg format, and has a white background,
                to guarantee the best quality.
            </p>
        </>
    }

    function getSneakersAiText() {
        return <>
            <h4>AI explained</h4>
            <p className="mt-2">
                Sneakers AI method automatically renders your sneakers 2D image into a 3D digital asset.
                This method takes between few seconds to few minutes to receive a downloadable .glb file,
                which is compatible with any Augmented Reality (AR) or 3D environment.
                <br/>
                <br/>
                Make sure the image you upload is in .png, .jpg or .jpeg format, and has a white background,
                to guarantee the best quality.
            </p>
        </>
    }

    function getSofaBetaAiText() {
        return <>
            <h4>AI explained</h4>
            <p className="mt-2">
                Sofa (Beta) AI method automatically renders your sofas 2D image into a 3D digital asset.
                This method takes between few seconds to few minutes to receive a downloadable .glb file,
                which is compatible with any Augmented Reality (AR) or 3D environment.
                <br/>
                <br/>
                Make sure the image you upload is in .png, .jpg or .jpeg format, and has a white background,
                to guarantee the best quality.
                <br/>
                <br/>
                <b>Please note, this category is in beta. The results might be unpredictable and vary in quality.</b>
            </p>
        </>
    }

    function getAnythingAiText() {
        return <>
            <h4>AI explained</h4>
            <p className="mt-2">
                This method automatically converts text prompts into 3D models. Depending on the complexity,
                it usually takes seconds to a few minutes to render the your text prompt into a 3D model.
                You'll then be able to download it in .glb formats immediately.

                <br/>
                <br/>
                <b>Please note, this AI model is category agnostic and has not been trained on any specific object separately. Therefore, the quality of the model will be standard.</b>
            </p>
        </>
    }

    return (
        <>

            {aiMethod && aiMethod === AiMethod.MESH
                ? getMeshAiText()
                : <></>
            }
            {aiMethod && aiMethod === AiMethod.SNEAKERS
                ? getSneakersAiText()
                : <></>
            }
            {aiMethod && aiMethod === AiMethod.SOFA_BETA
                ? getSofaBetaAiText()
                : <></>
            }
            {aiMethod && aiMethod === AiMethod.ANYTHING
                ? getAnythingAiText()
                : <></>
            }
        </>
    );
};

export default AiExplained;

import React from "react";
import {VersionInfo} from "../../types/version-info";
import PrefilledInput from "../common/PrefilledInput";
import {DateUtil} from "../../utils/DateUtil";

const PreFilledVersionInfo: React.FC<{ versionInfo: VersionInfo }> = ({versionInfo}) => {
    return (
        <>
            <PrefilledInput
                name="uploadTime"
                label="Version upload time"
                value={DateUtil.getDateWithTime(versionInfo.createdAt)}/>

            <PrefilledInput
                name="uploader"
                label="Version uploader"
                value={versionInfo.uploaderEmail}/>
        </>

    );
};

export default PreFilledVersionInfo;

import React, {ReactNode} from "react";

const PageFooter: React.FC<{
    onCancel: () => void,
    children: ReactNode
}> = ({onCancel, children}) => {

    return (
        <>
            <div className="app-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <hr className="mt-0" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 offset-md-1">
                            <button onClick={onCancel} className="btn btn-white">
                                Cancel
                            </button>
                        </div>
                        <div className="col-md-5 text-end">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageFooter;

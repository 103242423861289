import React, {ReactElement, useEffect, useState} from "react";
import attachmentClient, {formatErrorResponse} from "../../services/attachment.client";
import {AttachmentInfo} from "../../types/attachment/attachment-response";
import {useAlerts} from "../../store/AlertProvider";

export interface AsyncAttachmentProps {
    attachment?: AttachmentInfo;
    loading?: boolean;
    handleRemove?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ExistingAsyncAttachment: React.FC<{
    attachment: AttachmentInfo;
    remove: (image: AttachmentInfo) => void;
    updateFile: (image: AttachmentInfo) => void;
    requiresThumbnailDownload?: boolean;
    displayElement: ReactElement<AsyncAttachmentProps>;
}> = ({remove, attachment, updateFile, requiresThumbnailDownload = false, displayElement}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const {addErrorResponseAlert} = useAlerts();

    const handleRemove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        remove(attachment);

        event.stopPropagation();
    }

    useEffect(function downloadThumbnailOnce() {
        if (!attachment.productUuid || !attachment.attachmentUuid || attachment.base64 || !requiresThumbnailDownload) return;

        setLoading(true)
        const controller = new AbortController();
        attachmentClient.getThumbnail(attachment.attachmentUuid, controller.signal)
            .then(res => {
                updateFile({
                    ...attachment,
                    base64: res.base64,
                })
                setLoading(false);
            })
            .catch(err => {
                if (err.code !== "ERR_CANCELED") {
                    addErrorResponseAlert(formatErrorResponse(err.response));
                }
            })
        return () => {
            controller.abort()
        }
    }, [requiresThumbnailDownload, addErrorResponseAlert, attachment, updateFile]);

    return (
        <>
            {React.cloneElement(displayElement, {attachment: attachment, loading: loading, handleRemove: handleRemove})}
        </>
    );
};

export default ExistingAsyncAttachment;

import React from "react";
import Tab from "../new-project/project/Tab";
import {AdminPage} from "../../types/admin-page";
import PageableUserResponse from "../../types/user/pageable-user-response";
import PageableCompanyResponse from "../../types/user/pageable-company-response";

const AdminHeader: React.FC<{
    pageName: string | undefined;
    pageableUserResponse?: PageableUserResponse;
    pageableCompanyResponse?: PageableCompanyResponse;
}> = ({pageableUserResponse, pageableCompanyResponse, pageName}) => {

    return (
        <>
            <header className="view-header">
                <div className="container-fluid">
                    <div className="subtitle-spacer"/>
                    <div className="row justify-content-between">
                        <div className="col-md-auto">
                            <div className="title">
                                <h1>Admin dashboard</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col">
                            <ul className="nav view-nav mb-n3">
                                <Tab
                                    key={"1"}
                                    name={"Users"}
                                    count={pageableUserResponse ? pageableUserResponse.totalElements : 0}
                                    destination={"/admin/users"}
                                    active={pageName === AdminPage.USERS}
                                />
                                <Tab
                                    key={"2"}
                                    name={"Companies"}
                                    count={pageableCompanyResponse ? pageableCompanyResponse.totalElements : 0}
                                    destination={"/admin/companies"}
                                    active={pageName === AdminPage.COMPANIES}
                                />
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default AdminHeader;

import React from "react";
import {IPartnerCard} from "../../types/partner";

const PartnerDetails: React.FC<{
    partner: IPartnerCard | null,
    detailsVisible: boolean,
    setDetailsVisible: (detailsVisible: boolean) => void,
}> = ({partner, setDetailsVisible}) => {

    const paragraphs = partner?.longDescription.split("|||");

    return (
        (partner &&
        <div className="partner-details">
            <div className="row justify-content-end mb-4">
                <div className="col-auto">
                    <button className="btn-close btn btn-outline-gray js-toggle-partner-details"
                       onClick={() => setDetailsVisible(false)}></button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="logo">
                        <img src={partner.imagePath} height="80" alt=""/>
                    </div>
                    <div className="actions">
                        {partner.viewTutorialLink &&
                        <button className="btn btn-outline-gray btn-icon w-100 mb-3 mt-2" onClick={() => window.open(partner.viewTutorialLink, '_blank')}>
                            <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2" width="16"
                             height="16" viewBox="0 0 16 16">
                            <path
                                d="M10.5 15.167h-4c-3.62 0-5.167-1.547-5.167-5.167V6C1.333 2.38 2.88.833 6.5.833h4c3.62 0 5.167 1.547 5.167 5.167v4c0 3.62-1.547 5.167-5.167 5.167Zm-4-13.334C3.427 1.833 2.333 2.927 2.333 6v4c0 3.073 1.094 4.167 4.167 4.167h4c3.073 0 4.167-1.094 4.167-4.167V6c0-3.073-1.094-4.167-4.167-4.167h-4Z"
                                fill="#07031B"></path>
                            <path
                                d="M7.673 10.913c-.28 0-.54-.066-.773-.2-.533-.306-.84-.933-.84-1.726V7.013c0-.786.307-1.42.84-1.726.533-.307 1.227-.26 1.913.14l1.714.986c.68.394 1.073.974 1.073 1.587s-.393 1.193-1.073 1.587l-1.714.986c-.386.227-.78.34-1.14.34Zm.007-4.826c-.107 0-.2.02-.273.066-.214.127-.34.44-.34.86v1.974c0 .42.12.733.34.86.213.126.546.073.913-.14l1.713-.987c.367-.213.574-.473.574-.72s-.207-.513-.574-.72L8.32 6.293c-.24-.14-.46-.206-.64-.206Z"
                                fill="currentColor"></path>
                        </svg>
                            View tutorial
                    </button>}
                        <button className="btn btn-primary btn-icon w-100 mt-2" onClick={() => window.open(partner?.websiteLink, '_blank')}>
                            <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2" width="16"
                                 height="16" viewBox="0 0 16 16">
                                <path
                                    d="M8.5 15.167A7.173 7.173 0 0 1 1.333 8 7.173 7.173 0 0 1 8.5.833 7.173 7.173 0 0 1 15.667 8 7.173 7.173 0 0 1 8.5 15.167Zm0-13.334A6.174 6.174 0 0 0 2.333 8c0 3.4 2.767 6.167 6.167 6.167S14.667 11.4 14.667 8 11.9 1.833 8.5 1.833Z"
                                    fill="currentColor"></path>
                                <path
                                    d="M10.167 8.5h-4a.504.504 0 0 1-.5-.5c0-.273.226-.5.5-.5h4c.273 0 .5.227.5.5s-.227.5-.5.5Z"
                                    fill="currentColor"></path>
                                <path
                                    d="M8.833 10.5a.494.494 0 0 1-.353-.147.503.503 0 0 1 0-.706L10.127 8 8.48 6.353a.503.503 0 0 1 0-.706.503.503 0 0 1 .707 0l2 2a.503.503 0 0 1 0 .706l-2 2c-.1.1-.227.147-.354.147Z"
                                    fill="currentColor"></path>
                            </svg>
                                {partner.websiteName}
                        </button>
                    </div>
                </div>
                <div className="col-md-8">
                    <h4>{partner.partnerName}</h4>
                    <div className="description">
                        {paragraphs && paragraphs.map((paragraph, index) => (
                            <p key={index}>{paragraph}</p>
                        ))}
                    </div>
                </div>
            </div>
        </div>

        )

    );
};

export default PartnerDetails;

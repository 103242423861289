import {AttachmentInfo} from "../types/attachment/attachment-response";
import {MAX_FILE_SIZE} from "../validation/new-project-validation";
import {v4 as uuidv4} from 'uuid';

export function handleDropzoneFiles(existingFiles: File[], newFiles: File[]) {
    let filesToAdd: File[];
    if (Array.isArray(existingFiles) && existingFiles.length > 0) {
        const newFileNames: string[] = newFiles.map((it) => it.name);
        filesToAdd = existingFiles.filter((existingFile) => {
            for (const newFileName of newFileNames) {
                if (existingFile.name === newFileName) {
                    return false;
                }
            }
            return true;
        });
        newFiles.forEach((file) => filesToAdd.push(file));
        existingFiles = [];
    } else {
        existingFiles = [];
        filesToAdd = newFiles;
    }
    filesToAdd.forEach((file) => existingFiles.push(file));
    return existingFiles;
}

export function fileToAttachmentInfo (file: File, addErrorAlert: (body: string) => void): AttachmentInfo | undefined {
    if (file.size > MAX_FILE_SIZE) {
        addErrorAlert("File " + file.name + " is too big");
        return;
    }

    return {
        file: file,
        fileName: file.name,
        fileType: file.type,
        attachmentUuid: undefined,
        referenceUuid: uuidv4(),
        uploaded: false,
        url: ''
    }
}

export function handleAsyncDropzoneFileList(attachmentsInDropzone: AttachmentInfo[], newAttachments: AttachmentInfo[]) {
    let combinedAttachmentsList: AttachmentInfo[] = []

    attachmentsInDropzone.forEach(attachmentInDropzone => {
        combinedAttachmentsList.push(overWriteFile(attachmentInDropzone, newAttachments));
    })

    newAttachments.forEach(newAttachment => {
        if (isNonExistingFile(newAttachment, attachmentsInDropzone)) {
            combinedAttachmentsList.push(newAttachment);
        }
    });

    return combinedAttachmentsList;
}

function isAttachmentAlreadyBoundToProjectOrProduct(attachment: AttachmentInfo) {
    return attachment.productUuid || attachment.projectUuid
}

function isNonExistingFile (newAttachment: AttachmentInfo, attachmentsInDropzone: AttachmentInfo[]) {
    for (const attachmentInDropzone of attachmentsInDropzone) {
        if (isSameFileNameAndNotBoundToProject(newAttachment, attachmentInDropzone)) {
            return false;
        }
    }

    return true
}

function overWriteFile(attachmentInDropzone: AttachmentInfo, newAttachments: AttachmentInfo[]) {
    for (let newAttachment of newAttachments) {
        if (isSameFileNameAndNotBoundToProject(newAttachment, attachmentInDropzone)) {
            return {...attachmentInDropzone,
                file: newAttachment.file
            }
        }
    }

    return attachmentInDropzone;
}

function isSameFileNameAndNotBoundToProject (newAttachment: AttachmentInfo, attachmentInDropzone: AttachmentInfo) {
    return newAttachment.fileName === attachmentInDropzone.fileName && !isAttachmentAlreadyBoundToProjectOrProduct(attachmentInDropzone);
}


import React from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import AuthService from "../services/auth.service";
import {useAuthContext} from "../store/AuthProvider";
import jwtDecode from "jwt-decode";
import {JwtCustomPayload} from "../common/JwtCustomPayload";
import {UserAccount} from "../types/user/user-account";
import {useQuery} from 'react-query';
import LoadingComponent from "../components/common/LoadingComponent";
import {LoginState} from "../types/navigation";

const AuthCodeSignIn: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const code = searchParams.get("code");
    const {setUser} = useAuthContext();

    useQuery("signInWithAuthCode", () => AuthService.signInWithAuthCode(code!), {
        onSuccess: data => {
            const expires = jwtDecode<JwtCustomPayload>(data.access_token).exp!;
            const userAccount = new UserAccount(jwtDecode<JwtCustomPayload>(data.access_token), expires);
            if (userAccount.status !== "ACTIVE") {
                navigate(`/login`, {state: {errorMessage: "Your login request was unable to complete. Please contact Alpha3D."} as LoginState});
            } else {
                setUser(userAccount);
                if (userAccount.company == null || userAccount.company.length === 0) {
                    navigate("/company-setup");
                } else {
                    navigate("/");
                }
            }
        },
        onError: error => {
            navigate("/");
        }
    });

    return (
        <div className="text-center align-content-center">
            <LoadingComponent/>
        </div>
    );
};

export default AuthCodeSignIn;

import React from "react";
import LoadingButton from "../../product/LoadingButton";
import {GENERATE, SAVE_AS_DRAFT, SEND_FOR_QUOTE, WAIT_FILE_UPLOAD} from "../../../common-texts";
import {ProductionType} from "../../../types/project/production-type";
import { ReactComponent as IconSend } from "../../../assets/icons/icon-send.svg"; 

const NewProjectHeader: React.FC<{
    saveAsDraft: () => void;
    confirmProject: () => void;
    title: string;
    filesUploading: boolean;
    productionType?: ProductionType;
    showCancelModal: () => void;
    uploadDisabled: boolean;
}> = ({
          saveAsDraft,
          confirmProject,
          title,
          filesUploading,
          productionType,
          showCancelModal,
          uploadDisabled = false
      }) => {

    return (
        <>
            <header className="view-header">
                <div className="container-fluid">
                    <div className="subtitle-spacer"/>
                    <div className="row justify-content-between">
                        <div className="col-md-auto">
                            <div className="title">
                                <button className="btn btn-outline-gray btn-icon btn-sm me-3" onClick={showCancelModal}>
                                    <i className="bi bi-caret-left-fill"></i>
                                </button>
                                <h1>{title}</h1>
                            </div>
                        </div>
                        <div className="col-md-auto">
                            <LoadingButton
                                buttonText={SAVE_AS_DRAFT}
                                btnClassName={"btn-white me-2"}
                                onClick={saveAsDraft}
                                loading={filesUploading}
                                loadingTooltip={WAIT_FILE_UPLOAD}/>
                            <LoadingButton
                                buttonText={productionType && productionType === ProductionType.AI_GENERATED ? GENERATE : SEND_FOR_QUOTE}
                                btnClassName={"btn-primary btn-icon"}
                                onClick={confirmProject}
                                loading={filesUploading}
                                loadingTooltip={WAIT_FILE_UPLOAD}
                                disabled={uploadDisabled}
                                Icon={IconSend}
                            />
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default NewProjectHeader;

import * as Yup from "yup";

export const resetPasswordValidation = Yup.object().shape({
    password: Yup.string()
        .test(
            "Len",
            "The password must be between 8 and 30 characters.",
            (val: any) => val.toString().length >= 8 && val.toString().length <= 30
        )
        .required("This field is required!")
        .test("uppercase", "The password must contain one uppercase character.", (val: any) => {
            return /[A-Z]/.test(val.toString());
        })
        .test("lowercase", "The password must contain one lowercase character.", (val: any) => {
            return /[a-z]/.test(val.toString());
        })
        .test("special character", "The password must contain one special character.", (val: any) => {
            return /[!@#$%^&*(),.?":{}|<>]/.test(val.toString());
        })
        .test("number", "The password must contain one number.", (val: any) => {
            return /\d/.test(val.toString());
        }),
    passwordAgain: Yup.string()
        .required("This field is required!")
        .test('passwords-match', 'Inserted passwords do not match.', function (value) {
            return this.parent.password === value
        }),
});

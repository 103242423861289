import React from "react";
import {Link, useLocation, useMatch, useNavigate, useParams} from "react-router-dom";
import logo from "../../assets/logo-alpha.svg";
import {Attachment} from "../../types/attachment/attachment-response";
import {Dropdown} from "react-bootstrap";
import {truncate} from "../../utils/TextUtil";
import AttachmentClient from "../../services/attachment.client";
import ProductInfoResponse from "../../types/product/product-info-response";
import {ReactComponent as Icon3dRotate} from "../../assets/icons/icon-3d-rotate.svg";
import IconProvider from "../IconProvider";

const ModelViewHeader: React.FC<{
    product?: ProductInfoResponse;
    versionModels: Attachment[];
    versionAttachments: Attachment[];
    versions: number[] | undefined;
    modelNumber: string | null;
    versionNumber: string | null;
}> = ({product, versionModels, versionAttachments, versions, modelNumber, versionNumber}) => {
    const {productUuid} = useParams();
    const location = useLocation();
    const internalUploadDirection = location.pathname + location.search.replace("&model=" + modelNumber, "");
    const versionSwitch = internalUploadDirection.replace("?version=" + versionNumber, "?version=");

    const internalUploadMatch = useMatch("admin/internal-upload/:productUuid");
    const from = `${internalUploadMatch ? "internal-upload" : "client-upload"}`;
    const detailsDestination = `/product/${productUuid}/details?version=${versionNumber}&model=${modelNumber}&from=${from}`;

    const homeDestination = "/";
    const navigate = useNavigate();

    const downloadAttachment = async (attachment: Attachment) => {
        await AttachmentClient.getAttachmentFile(attachment);
    };

    const getHeader = () => {
        if (!product) return "";
        return `${product.brandName} · ${product.modelName}`;
    };

    return (
        <header id="model-view-header" className="app-header -compact">
            <div className="container-fluid">
                <div className="row justify-content-between align-items-center">
                    <div className="col-sm-1">
                        <Link to={homeDestination}>
                            <img src={logo} width="143" alt="Alpha AR"/>
                        </Link>
                    </div>
                    <div className="col-md">
                        <div className="dropdown versionselect">
                            <Dropdown id="versionSelect">
                                <Dropdown.Toggle variant="transparent" className="btn-xs p-0 ">
                                    Version {versionNumber}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {versions?.map((version, index) => (
                                        <Dropdown.Item key={index}
                                                       onClick={() => navigate(versionSwitch + version + "&model=")}>
                                            {version}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="title">
                            <h4 className="mb-0">{getHeader()}</h4>
                            <Link to={detailsDestination}
                                  className="details btn btn-outline-gray btn-xs ms-3">Details</Link>
                        </div>
                    </div>

                    <div className="col-md text-end">
                        <Dropdown className="d-inline-block me-2">
                            <Dropdown.Toggle variant="outline-gray" className="btn-icon">
                                <IconProvider Icon={Icon3dRotate} size={16} className={"me-2"}/>
                                {
                                    truncate(versionModels.find(model => model.attachmentUuid.toString() === modelNumber)?.fileName, 30) || "Models"
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {versionModels.map((model, index) => (
                                    <Dropdown.Item
                                        key={index}>
                                        <div key={index} onClick={() => {
                                            navigate(internalUploadDirection + "&model=" + model.attachmentUuid);
                                        }}>
                                            {truncate(model.fileName, 30)}
                                        </div>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown className="d-inline-block me-2">
                            <Dropdown.Toggle
                                variant="outline-gray"
                                className={`${versionAttachments.length > 0 ? "" : "disabled"}`}>
                                Attachments
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {versionAttachments.map((attachment, index) => (
                                    <Dropdown.Item key={index} onClick={() => downloadAttachment(attachment)}>
                                        {truncate(attachment.fileName, 20)}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Link className="btn-close btn btn-white btn-outline-gray ms-3" to={internalUploadDirection}/>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default ModelViewHeader;

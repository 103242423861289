import React from "react";
import ProjectFileInput from "../new-project/file-input/ProjectFileInput";
import ExistingAsyncAttachment from "./ExistingAsyncAttachment";
import NewAsyncAttachment from "./NewAsyncAttachment";
import FileHandlerListItem from "./FileHandlerListItem";
import {useFileHandler} from "./FileHandler";

const ProjectFileHandler: React.FC<{
    errorMessage?: string;
    loaded: boolean
}> = ({errorMessage, loaded}) => {

    const {
        removeExistingAttachment,
        updateFiles,
        updateFile,
        attachments,
        removeFile
    } = useFileHandler(undefined, loaded)

    return (
        <>
            <ProjectFileInput updateFiles={updateFiles}
                              name={"attachmentUuids"}
                              errorMessage={errorMessage}/>
            <div className={`attachments-list ${(attachments && attachments.length > 0) ? "mt-3" : ""} `}>
                {attachments.map((attachment) => {
                    if (attachment.projectUuid) {
                        return <ExistingAsyncAttachment
                            key={attachment.referenceUuid}
                            attachment={attachment}
                            remove={removeExistingAttachment}
                            updateFile={updateFile}
                            displayElement={<FileHandlerListItem/>}/>
                    } else {
                        return <NewAsyncAttachment
                            key={attachment.referenceUuid}
                            attachment={attachment}
                            remove={removeFile}
                            updateFile={updateFile}
                            displayElement={<FileHandlerListItem/>}/>
                    }
                })}
            </div>
        </>
    );
};

export default ProjectFileHandler;

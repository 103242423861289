import React from "react";
import ProjectPageResponse from "../../types/project-page-response";
import {Dropdown} from "react-bootstrap";
import ConfirmationModal from "../modals/ConfirmationModal";
import ProjectClient from "../../services/project.client";
import {useAlerts} from "../../store/AlertProvider";
import {useNavigate} from "react-router-dom";
import {DateUtil} from "../../utils/DateUtil";
import {ProjectStatus} from "../../types/project/project-status";
import ProjectStatusBadge from "./ProjectStatusBadge";
import {AiMethod} from "../../types/project/ai-method-type";

const ProjectCard: React.FC<{
    project: ProjectPageResponse;
    index: number;
    onDelete: (projectUuid: string) => void;
    aiMethod: AiMethod;
}> = ({project, index, onDelete, aiMethod}) => {
    const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);
    const {addSuccessAlert, addErrorResponseAlert} = useAlerts();
    const navigate = useNavigate();
    const productCount = project?.productCount;

    const deleteDraft = async (projectUuid: string) => {
        let deleteProjectResponse = await ProjectClient.deleteProject(projectUuid);
        setShowConfirmationModal(false);
        if (deleteProjectResponse) {
            addErrorResponseAlert(deleteProjectResponse);
        } else {
            onDelete(projectUuid);
            addSuccessAlert("Draft successfully deleted");
        }
    };

    const handleNavigate = () => {
        if (project.status === ProjectStatus.DRAFT) {
            navigate(`/edit-draft/${project.uuid}`);
        } else {
            navigate(`/project/${project.uuid}`);
        }
    }

    const getProjectCardOverrideColor = () => {
        if (!project) return;
        switch (project.status) {
            case ProjectStatus.PENDING_QUOTES:
                return project.areAllProductsApprovable ? "badge-pink" : undefined;
            case ProjectStatus.IN_PRODUCTION:
                return project.anyProductReadyForQCOrHasNoClientComments ? "badge-pink" : "badge-white";
            default:
                return undefined;
        }
    }

    return (
        <div className="col-3">
            <ConfirmationModal
                header="Delete draft?"
                body={
                    "Are you sure you want to delete the draft?\n" +
                    "When deleting a draft project, it cannot be restored."
                }
                handleClose={() => setShowConfirmationModal(false)}
                handleConfirmation={() => deleteDraft(project?.uuid)}
                showModal={showConfirmationModal}
            />
            <div className={`project ${project?.status === ProjectStatus.COMPLETED ? "-approved" : ""}`}
                 onClick={() => handleNavigate()}>
                <div className="actions" onClick={(e) => e.stopPropagation()}>
                    <Dropdown align={"end"}>
                        <Dropdown.Toggle variant="white" className="btn-icon" id={`projectCardDropdownButton-${index}`}>
                            <i className="bi bi-three-dots-vertical"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {project.status === ProjectStatus.DRAFT && (
                                <Dropdown.Item onClick={() => setShowConfirmationModal(true)}>Delete</Dropdown.Item>
                            )}
                            <Dropdown.Item onClick={() => handleNavigate()}>View</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <img className="cover" src={`data:image/jpeg;base64,${project?.thumbnail}`} alt="project thumbnail"/>
                {(aiMethod === AiMethod.SOFA_BETA || aiMethod === AiMethod.SNEAKERS) &&
                    <span
                        className="badge text-bg-secondary count">{productCount > 1 ? productCount + " products" : productCount + " product"}</span>
                }
                {aiMethod === AiMethod.ANYTHING &&
                    <span
                        className="badge text-bg-secondary count">{productCount > 1 ? productCount + " assets" : productCount + " asset"}</span>
                }
                <ProjectStatusBadge
                    status={project?.status}
                    className={"status"}
                    overrideColor={getProjectCardOverrideColor()}
                />
                <div className="id">{project?.endPoint}</div>
                <div className="title">{project?.name}</div>
                <div className="date">
                    {DateUtil.getDateOnlyShort(project?.creationDate)}
                    <span className="mx-1">·</span>
                    {DateUtil.getTimeOnly(project?.creationDate)}
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;

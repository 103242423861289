import axios from "axios";
import CompanyResponse from "../types/company/company-response";
import PageableUserResponse from "../types/user/pageable-user-response";
import PageableCompanyResponse from "../types/user/pageable-company-response";

export class GenericClient {
    getUsers = (query?: any): Promise<PageableUserResponse> => {
        let q = {
            page: query?.page || 0,
            size: query?.size || 25,
            fullName: query?.fullName || ""
        }
        return axios.get(process.env.REACT_APP_API_URL + "user/search-user", {params: q}).then((res) => {
            return res.data;
        });
    };

    getCompanies = (): Promise<CompanyResponse[]> => {
        return axios.get(process.env.REACT_APP_API_URL + "company").then((res) => {
            return res.data;
        });
    };

    getAllRealCompanies = (): Promise<CompanyResponse[]> => {
        return axios.get(process.env.REACT_APP_API_URL + "company/allRealCompanies").then((res) => {
            return res.data;
        });
    };

    getAllCompaniesResponse = (): Promise<CompanyResponse[]> => {
        return axios.get(process.env.REACT_APP_API_URL + "company").then((res) => {
            return res.data;
        });
    };
    getPaginatedCompaniesResponse = (query?: any): Promise<PageableCompanyResponse> => {
        let q = {
            page: query?.page || 0,
            size: query?.size || 10,
            name: query?.name || ""
        }
        return axios.get(process.env.REACT_APP_API_URL + "company/searchAllCompanies", {params: q}).then((res) => {
            return res.data;
        });
    };
}

export default new GenericClient();

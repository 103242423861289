import React from "react";
import {Spinner} from "react-bootstrap";
import {AsyncAttachmentProps} from "./ExistingAsyncAttachment";

const IMAGE_SRC_PREFIX = "data:image/jpeg;base64,"

const FileHandlerImageThumbnail: React.FC<AsyncAttachmentProps> = ({attachment, loading, handleRemove}) => {
    return (
        <div className={`thumbnail ${loading ? "" : "-has-content"}`}>
            <div className="actions">
                {!loading &&
                    <button type="button" onClick={handleRemove} className={`btn btn-white shadow d-inline-block`}>
                        <i className="bi bi-x-lg"/>
                    </button>
                }
            </div>
            {loading && <Spinner animation="border" className={"loading"} />}
            {attachment && attachment.base64 && <img src={IMAGE_SRC_PREFIX + attachment.base64} alt={attachment.fileName}/>}
        </div>
    );
};

export default FileHandlerImageThumbnail;

import * as Yup from "yup";

export const companyCreationValidation = Yup.object().shape({
    isCompany: Yup.boolean(),
    name: Yup.string().required("This field is required!"),
    trelloBoardId: Yup.string().nullable().when("services.designerModelsService", {
        is: true,
        then: Yup.string().required("This field is required")
    }),
    country: Yup.string().nullable().when("isCompany", {
        is: true,
        then: Yup.string().required("This field is required")
    }),
    registrationNumber: Yup.string().nullable().when("isCompany", {
        is: true,
        then: Yup.string().required("This field is required")
    }),
    address: Yup.string().nullable().when("isCompany", {
        is: true,
        then: Yup.string().required("This field is required")
    }),
    billingEmail: Yup.string().required("This field is required!").email("This is not a valid email."),
    vatNumber: Yup.string().nullable(),
    services: Yup.object({
        designerModelsService: Yup.boolean(),
        aiGeneratedModelsService: Yup.boolean()
    })
        .test(
            'OK',
            'At least one service must be checked',
            a => (a.designerModelsService || a.aiGeneratedModelsService) as boolean
        )
});

import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import "@google/model-viewer";
import hdrFile from "../../assets/hdr-studio.hdr";

export interface AlphaModel {
    blob: Blob;
    modelId: string;
}

const ModelViewer: React.FC<{ model: AlphaModel }>
    = ({model}) => {
    const [blobUrl, setBlobUrl] = useState("");
    const modelRef = useRef(model);

    useLayoutEffect(() => {
        if (model.modelId === modelRef.current.modelId) return;
        modelRef.current = model
    }, [model]);

    useEffect(() => {
        setBlobUrl(URL.createObjectURL(modelRef.current.blob))
    }, [modelRef])

    useEffect(() => {
        const revokeUrl = () => {
            URL.revokeObjectURL(blobUrl);
        }
        const modelViewer = document.querySelector("model-viewer");
        if (!modelViewer) return;
        modelViewer.addEventListener("load", revokeUrl);
        return () => {
            modelViewer.removeEventListener("load", revokeUrl);
        };
    })

    return (
        <model-viewer
            camera-controls={true}
            enable-pan={true}
            seamless-poster={true}
            src={blobUrl}
            environment-image={hdrFile}
            ar />
    );
};

export default ModelViewer;

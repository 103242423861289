import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import AttachmentClient from "../../services/attachment.client";
import {base64FilesToJSFiles} from "../../utils/FileUtils";
import ProductInfoResponse from "../../types/product/product-info-response";
import ImageThumbnail from "../new-project/ImageThumbnail";
import {ProductThumbnailsResponse} from "../../types/attachment/attachment-response";
import PreFilledProduct from "../PreFilledProduct";
import {AiMethod} from "../../types/project/ai-method-type";

const ProductDetails: React.FC<{ product: ProductInfoResponse | undefined }> = ({product}) => {
    const {productUuid} = useParams();
    const [downloadedFiles, setDownloadedFiles] = useState<ProductThumbnailsResponse>();
    const [thumbnails, setThumbnails] = useState<File[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!productUuid) {
            setLoaded(true);
            return;
        }
        AttachmentClient.getProductThumbnails(productUuid)
            .then((res) => {
                setDownloadedFiles(res);
            })
    }, [productUuid])

    useEffect(() => {
        if (!downloadedFiles || !downloadedFiles.thumbnails) return;
        if (loaded) return;

        setThumbnails(base64FilesToJSFiles(downloadedFiles.thumbnails));
        setLoaded(true);
    }, [downloadedFiles, loaded, thumbnails])


    const downloadImage = async (index: number) => {
        if (!downloadedFiles) return;
        await AttachmentClient.getAttachmentFile(downloadedFiles.thumbnails[index]);
    }

    return (
        <main className="view-content -with-footer container">
            <div className="row">
                <div className="col-md-5 offset-md-1">
                    <h3 className="mb-4">{product?.category?.name === AiMethod.ANYTHING ? 'Asset Details' : 'Product Details'}</h3>
                </div>
                <div className="col-md-5 text-end">
                    {/*spacer*/}</div>
                <div className="col-md-5 offset-md-1">
                    <PreFilledProduct product={product}/>
                </div>
                <div className="col-md-5">
                    <div className="thumbnail-gallery">
                        {Array.isArray(thumbnails) && thumbnails.length > 0 && thumbnails.map((file, index) => (
                            <ImageThumbnail
                                onClick={() => downloadImage(index)}
                                download={() => downloadImage(index)}
                                index={index}
                                key={"image-" + index}
                                image={file}/>
                        ))}
                    </div>
                </div>
            </div>
        </main>
    )


}

export default ProductDetails;

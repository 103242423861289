import React from "react";
import {Modal} from "react-bootstrap";
import LoadingButton from "../product/LoadingButton";

const GetMoreTokensModal: React.FC<{
    handleClose: () => void;
    handleConfirmation: () => void;
    showModal: boolean;
    cost: number;
    tokens: number;
}> = ({
          handleClose,
          handleConfirmation,
          showModal,
          cost,
          tokens
      }) => {
    return (
        <Modal size="lg" show={showModal}
               className={`modal fade`}>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" id="addNewUserModal">
                        Out of tokens
                    </h4>
                    <button
                        onClick={handleClose}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
                <div className="modal-body" style={{wordWrap: "break-word", whiteSpace: "pre-wrap"}}>
                    This download will cost you {cost} tokens. You currently have {tokens} tokens.
                    <div className="modal-footer mt-5">
                        <button
                            onClick={handleClose}
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Cancel
                        </button>
                        <LoadingButton
                            onClick={handleConfirmation}
                            loading={false}
                            loadingTooltip={""}
                            buttonText="Get more tokens"/>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default GetMoreTokensModal;

import React from "react";
import InputError from "../../InputError";
import iconUpload from "../../../assets/icon-upload.svg";
import {useDropzone} from "react-dropzone";
import {AttachmentInfo} from "../../../types/attachment/attachment-response";
import {useAlerts} from "../../../store/AlertProvider";
import {handleFileHandlerDropzone} from "../../file-handler/FileHandler";
import {MAX_FILE_SIZE} from "../../../validation/new-project-validation";
import {useController} from "react-hook-form";

const ProductFileInput: React.FC<{
    name: string;
    errorMessage?: string;
    updateFiles: (files: AttachmentInfo[]) => void;
    webpEnabled?: boolean;
}> = ({name, errorMessage, updateFiles, webpEnabled}) => {
    const SUBTITLE = "Please drag and drop here png or jpg images";
    const SUBTITLE_WEBP = "Please drag and drop here PNG, JPG or WEBP images";

    const {addErrorAlert} = useAlerts();
    const {
        field: {ref},
    } = useController({name});

    function getAcceptedMimeTypes() {
        const baseMimeTypes = {
            "image/jpg": [".jpg", ".JPG", ".jpeg", ".JPEG"],
            "image/png": [".png", ".PNG"],
        }

        if (webpEnabled) {
            return {...baseMimeTypes, "image/webp": [".webp"]}
        }

        return baseMimeTypes;
    }

    const {getRootProps, getInputProps} = useDropzone({
        maxSize: MAX_FILE_SIZE,
        accept: getAcceptedMimeTypes(),
        onDrop: (acceptedFiles: File[]) => {
            handleFileHandlerDropzone(acceptedFiles, addErrorAlert, updateFiles);
        }
    })

    return (
        <div className="file-upload mb-3" style={{marginTop: "2rem"}}>
            <label className="w-100" htmlFor={name}>
                <div {...getRootProps()} ref={ref} className="droparea text-center">
                    <img className="icon" src={iconUpload} width="48" height="48" alt=""/>
                    <div className="title">
                        Click <span className="text-decoration-underline">here</span> to upload an image
                    </div>
                    <input hidden {...getInputProps()}/>
                    <div className="subtitle">{webpEnabled ? SUBTITLE_WEBP : SUBTITLE}</div>
                    <InputError errorMessage={errorMessage}></InputError>
                </div>
            </label>
        </div>
    );
};

export default ProductFileInput;
import React, {ReactNode, useEffect} from "react";

const Page: React.FC<{ title?: string, children: ReactNode }> = ({title, children}) => {
    useEffect(() => {
        document.title = title != null ? title + " | Alpha3D" : "Alpha3D";
    }, [title]);


    return <>{children}</>;
};

export default Page;

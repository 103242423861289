import * as Yup from "yup";
import {ClassificationResponse} from "../types/classificationResponse";
import {ProductionType} from "../types/project/production-type";

export function getProductsValidation(type: ProductionType, anythingValidation: boolean) {
    return Yup.array()
        .of(
            Yup.object().shape({
                brandName:  Yup
                    .string()
                    .when(type, {
                        is: () => {
                            return type === ProductionType.AI_GENERATED.toString();
                        },
                        then: Yup.string().nullable(),
                        otherwise: Yup.string().required("This field is required!").nullable(),
                    }),
                modelName:  Yup
                    .string()
                    .when(type, {
                        is: () => anythingValidation,
                        then: Yup.string().nullable(),
                        otherwise: Yup.string().required("This field is required!").nullable(),
                    }),
                idNumber:  Yup
                    .string()
                    .when(type, {
                        is: () => {
                            return type === ProductionType.AI_GENERATED.toString();
                        },
                        then: Yup.string().nullable(),
                        otherwise: Yup.string().required("This field is required!").nullable(),
                    }),
                notes: Yup
                    .string()
                    .when(type, {
                        is: () => {
                            return type === ProductionType.AI_GENERATED.toString();
                        },
                        then: Yup.string().nullable(),
                        otherwise: Yup.string().required("This field is required!").nullable(),
                    }),
                url:  Yup
                    .string()
                    .when(type, {
                        is: () => {
                            return type === ProductionType.AI_GENERATED.toString();
                        },
                        then: Yup.string().nullable(),
                        otherwise: Yup.string().required("This field is required!").nullable(),
                    }),
                category: Yup.array<ClassificationResponse>()
                    .test("required", "This field is required!", (category) => category ? category.length > 0 : false)
                    .nullable(),
                attachmentUuids: Yup.array().when(type, {
                    is: () => !anythingValidation,
                    then: productAttachmentValidation(type)
                }),
                modelGenerationText: Yup.string().when(type, {
                    is: () => anythingValidation,
                    then: Yup.string().required("This field is required!")
                }),
                unProcessedAttachments: Yup.number().max(0, "Can't save project while attachments are still processing")
            })
        )
        .test("minLength", "At least one product must be added", (products) => {
            if (products !== undefined) {
                return products.length > 0;
            }
            return false;
        });
}

function productAttachmentValidation(type: ProductionType) {
    return Yup
        .array()
        .when(type, {
            is: () => {
                return type === ProductionType.DESIGNER.toString()
            },
            then: Yup.array().min(1, "At least one image is required for product")
        })
        .when(type, {
            is: () => {
                return type === ProductionType.AI_GENERATED.toString()
            },
            then: Yup.array()
                .min(1, "One image is required for AI product")
                .max(1, "Only one image is allowed")
        });
}

export const newProductValidationSchema = Yup.object().shape({
    products: getProductsValidation(ProductionType.DESIGNER, false)
});

import React from "react";

const RequestResult: React.FC<{
    error?: string;
    successful?: boolean;
    successfulMessage: string;
}> = ({error, successful, successfulMessage}) => {

    return (
        <>
            {error ? (
                <div style={{display: "block"}} className="alert alert-danger">
                    {error}
                </div>
            ) : (
                ""
            )}
            {successful ? (
                <div style={{display: "block"}} className="alert alert-success ">
                    {successfulMessage}
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default RequestResult;

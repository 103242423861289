import React, {useState} from "react";
import NewProjectDetails from "./project/NewProjectDetails";
import NewProduct from "../NewProduct";
import InputError from "../InputError";
import {FormProvider, useFieldArray, UseFormReturn, useWatch} from "react-hook-form";
import ProjectRequest from "../../types/project/project-request";
import ProjectClient, {formatErrorResponse} from "../../services/project.client";
import {useAlerts} from "../../store/AlertProvider";
import {useNavigate} from "react-router-dom";
import {handleAppendBulkUploadResult, handleAppendNewProduct} from "../../utils/SharedUseEffects";
import {PRODUCTION_TYPE_TRANSLATIONS, ProductionType} from "../../types/project/production-type";
import {useClassifications} from "../../store/ClassificationProvider";
import BulkUploadModal from "../modals/BulkUploadModal";
import AttachmentClient from "../../services/attachment.client";
import PaymentTokenInfoComponent from "../PaymentTokenInfoComponent";
import CreateSubscriptionModal from "../modals/CreateSubscriptionModal";
import {useQuery} from "react-query";
import EditDraftResponse from "../../types/edit-draft-response";
import {AxiosError} from "axios";
import {AiMethod} from "../../types/project/ai-method-type";


const NewProjectForm: React.FC<{
    setShowModal: (show: boolean) => void;
    saveRequestMethods: UseFormReturn<ProjectRequest>;
    projectUuid?: string;
    onProductRemove: (index: number, productUuid?: string) => void;
    hasSubscription: boolean
}> = ({setShowModal, saveRequestMethods, projectUuid, onProductRemove, hasSubscription}) => {
    const navigate = useNavigate();
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [showCreateSubModal, setShowCreateSubModal] = useState<boolean>(false);
    const isBulkUploadVisible = saveRequestMethods.getValues().productionType !== ProductionType.AI_GENERATED;

    const {addErrorResponseAlert, addErrorAlert} = useAlerts();
    const control = saveRequestMethods.control;
    const {fields, append, remove} = useFieldArray({
        name: "products",
        control
    });
    const {productionTypes} = useClassifications();
    const aiMethod = useWatch({control: saveRequestMethods.control, name: "aiMethod"});

    function checkErrors(index: number) {
        const productErrors = saveRequestMethods.formState.errors?.products;
        if (Array.isArray(productErrors)) {
            return saveRequestMethods.formState.errors?.products?.at(index);
        }

        return undefined;
    }

    const getNoProductsError = () => {
        if (fields && fields.length > 0) return "";
        return saveRequestMethods.getFieldState("products").error?.message ?? "";
    }

    function addPrefillData(prefillData: EditDraftResponse) {
        saveRequestMethods.setValue("aiMethod", prefillData.aiMethod);
        saveRequestMethods.setValue("triangleCount", prefillData.triangleCount);
        saveRequestMethods.setValue("specification", prefillData.specification);
        saveRequestMethods.setValue("endPoint", prefillData.endPoint);
        saveRequestMethods.setValue("projectName", prefillData.name);
        saveRequestMethods.setValue("attachmentInfoList", prefillData.attachmentInfoList);
        saveRequestMethods.setValue("removedProductUuids", []);
        saveRequestMethods.setValue("removedAttachmentUuids", []);

        if (productionTypes.find(productionType => productionType === prefillData.productionType) === undefined) {
            const newProductionType = productionTypes[0];
            addErrorAlert("Previous draft production type is no longer valid, changed production type to: " +
                PRODUCTION_TYPE_TRANSLATIONS[newProductionType])
            saveRequestMethods.setValue("productionType", newProductionType);
        } else {
            saveRequestMethods.setValue("productionType", prefillData.productionType);
        }

        for (let product of prefillData.products) {
            product.removedAttachmentUuids = []
            append(product);
        }
    }

    const {
        isLoading: isLoadingPreFillData
    } = useQuery<EditDraftResponse, AxiosError>(
        'getDraftEdit',
        () => ProjectClient.getDraftEdit(projectUuid!),
        {
            enabled: productionTypes.length > 0 && !!projectUuid,
            onError: (error: AxiosError) => {
                addErrorResponseAlert(formatErrorResponse(error.response!));
                navigate("/");
            },
            onSuccess: data => addPrefillData(data)
        },
    )

    const handleProductRemove = (index: number, productUuid?: string) => {
        onProductRemove(index, productUuid);
        remove(index);
    };

    const handleAddProductButton = () => {
        handleAppendNewProduct(control, append);
    }

    const handleBulkUploadButton = () => {
        setShowUploadModal(true);
    }

    function isProductValidAtIndex(index: number): boolean {
        const products = saveRequestMethods.getValues("products");
        if (Array.isArray(products)) {
            const product = products[index];
            if (!product.brandName || !product.modelName || !product.idNumber || !product.url || !product.notes || product.attachmentUuids.length === 0) {
                return false;
            }
        }
        return true;
    }

    async function handleBulkUpload() {
        if (saveRequestMethods.getValues().bulkImport.files[0]) {
            if (fields.length > 0 && !isProductValidAtIndex(0)) {
                handleProductRemove(0);
            }
            let file = saveRequestMethods.getValues().bulkImport.files[0];
            AttachmentClient.bulkUploadFromFile(file).then((res) => {
                handleAppendBulkUploadResult(res, append);
                setShowUploadModal(false);
            }).catch((res) => {
                addErrorResponseAlert(formatErrorResponse(res.response))
            });
        }
    }

    return (
        <FormProvider {...saveRequestMethods}>
            {productionTypes.includes(ProductionType.AI_GENERATED) &&
                <PaymentTokenInfoComponent setShowCreateSubModal={setShowCreateSubModal} hasSubscription={hasSubscription}></PaymentTokenInfoComponent>}
            <CreateSubscriptionModal showModal={showCreateSubModal}
                                     handleClose={() => setShowCreateSubModal(false)}/>

            <form onSubmit={() => setShowModal(true)} className="bg-white dropzone">
                <main className="view-content -with-footer">
                    <div className="container">
                        <NewProjectDetails
                            projectUuid={projectUuid}
                            errors={saveRequestMethods.formState.errors}
                            loaded={!isLoadingPreFillData}
                        />
                    </div>
                    {fields.map((field, index) => {
                        return (
                            <NewProduct
                                remove={handleProductRemove}
                                index={index}
                                errors={checkErrors(index)}
                                key={field.id}
                                myKey={field.id}
                                productUuid={field.productUuid}
                                name={`products[${index}]`}
                                isRemovable={fields.length >= 2}
                            />
                        );
                    })}
                    <div className="container">
                        <div className="row mt-2">
                            <div className="col-md-10 offset-md-1 text-center">
                                <button
                                    type="button"
                                    className="btn btn-outline-gray w-100 py-4"
                                    onClick={() => handleAddProductButton()}
                                >
                                    <i className="bi bi-plus-circle-fill me-2"></i> {productionTypes.includes(ProductionType.AI_GENERATED) && aiMethod !== AiMethod.ANYTHING
                                    ? "Add new product to this project"
                                    : "Add new asset to this project"
                                }
                                </button>
                                {isBulkUploadVisible &&
                                    <button
                                        type="button"
                                        className="text-center btn btn-link mt-3"
                                        onClick={() => handleBulkUploadButton()}
                                    >
                                        Or bulk upload
                                    </button>
                                }

                                <InputError errorMessage={getNoProductsError()}/>
                            </div>
                        </div>
                    </div>
                </main>
            </form>
            <BulkUploadModal header={"Bulk import"}
                             body={"To bulk import products, download the template below and fill in the table. To add images, add as many image headers to the Excel as needed."}
                             limit={"Limit per one Excel is 250 products. If you need to import more, then use multiple Excel files."}
                             showModal={showUploadModal}
                             handleClose={() => setShowUploadModal(false)}
                             handleConfirmation={() => handleBulkUpload()}
                             errors={saveRequestMethods.formState.errors}/>
        </FormProvider>
    );
};
export default NewProjectForm;

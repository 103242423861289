import React from "react";
import {useFormContext} from "react-hook-form";
import InputError from "./InputError";

const NewInput: React.FC<{
    name: string;
    label: string;
    type?: string;
    errorMessage?: string;
    disabled?: boolean;
    inputClassName?: string;
    className?: string;
    maxLength?: number,
}> = ({name, label, type = "text", errorMessage, disabled = false, inputClassName, className, maxLength}) => {
    const {register} = useFormContext();

    return (
        <div className={`mb-3 ${className ? className : ""}`}>
            <label htmlFor={label} className="form-label">
                {label}
            </label>
            <input disabled={disabled} type={type}
                   className={"form-control form-control-lg " + inputClassName} {...register(name)}
                   id={name}
                   maxLength={maxLength}/>
            <InputError errorMessage={errorMessage}/>
        </div>
    );
};

export default NewInput;

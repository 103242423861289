import React from "react";
import {useFormContext} from "react-hook-form";
import InputError from "./InputError";

const NewInput: React.FC<{
    name: string;
    label: string;
    errorMessage?: string;
    disabled?: boolean;
}> = ({name, label, errorMessage, disabled = false}) => {
    const {register} = useFormContext();

    return (
        <>
            <div className="mb-3">
                <label htmlFor={label} className="form-label">
                    {label}
                </label>
                <input disabled={disabled} type="date"
                       className="form-control form-control-lg" {...register(name, {valueAsDate: true,})} id={name}/>
                <InputError errorMessage={errorMessage}/>
            </div>
        </>
    );
};

export default NewInput;

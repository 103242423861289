import React from "react";
import {
    BADGE_STATUS_TRANSLATIONS,
    BadgeStatus,
    PRODUCT_STATUS_TRANSLATIONS,
    ProductStatus
} from "../../types/product/product-status";

interface IProductStatus {
    productStatus?: ProductStatus;
    badgeExtraStatus?: BadgeStatus;
    className?: string;
    overrideColor?: string;
}

const ProductStatusBadge: React.FC<IProductStatus> = ({productStatus, badgeExtraStatus, className, overrideColor}) => {

    const BADGE_COLOR: { [key in ProductStatus]: string } = {
        [ProductStatus.DRAFT]: "badge-white",
        [ProductStatus.PENDING_QUOTE]: "badge-pink",
        [ProductStatus.IN_PRODUCTION]: "badge-black",
        [ProductStatus.READY_FOR_QC]: "badge-white",
        [ProductStatus.IN_EDITING]: "badge-black",
        [ProductStatus.APPROVED]: "badge-green",
        [ProductStatus.DELETED]: "badge-white",
        [ProductStatus.ERROR]: "badge-red",
    };

    return (

        <div className="d-flex">
            {productStatus &&
                <span
                    className={`badge ${overrideColor ?? BADGE_COLOR[productStatus]} ${className} ${badgeExtraStatus ? "me-2" : ""}`}>
                    {`${PRODUCT_STATUS_TRANSLATIONS[productStatus]}`}
                </span>
            } {
            badgeExtraStatus &&
            <span className={`badge ${overrideColor ?? "badge-green"} ${className}`}>
                    {`${BADGE_STATUS_TRANSLATIONS[badgeExtraStatus]}`}
            </span>
        }
        </div>
    );
}

export default ProductStatusBadge;

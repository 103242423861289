import React from "react";

const InputError: React.FC<{ errorMessage: string | undefined }> = ({
                                                                        errorMessage,
                                                                    }) => {
    return (
        <div>
            {errorMessage ? (
                <div style={{display: "block"}} className="invalid-feedback">
                    {errorMessage}
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default InputError;

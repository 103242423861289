import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import UserCreationRequest from "../../types/user/user-creation-request";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {internalUserCreationValidation} from "../../validation/internal-user-creation-validation";
import AuthService from "../../services/auth.service";
import AdminAction from "./AdminAction";
import AdminList from "./AdminList";
import AdminUserModal from "./modals/AdminUserModal";
import {ALERT_LIFETIME} from "../NewAlert";
import {useQueryClient} from "react-query";
import PageableUserResponse from "../../types/user/pageable-user-response";
import CompanyResponse from "../../types/company/company-response";

const UserDashBoard: React.FC<{
    pageableUserResponse: PageableUserResponse | undefined,
    companies: CompanyResponse[] | undefined;
    setUserPageNumber?: (page: number) => void
    setUserSearchFullName?: (fullName: string) => void
    userSearchFullName?: string
}> = ({pageableUserResponse, companies, setUserPageNumber, setUserSearchFullName, userSearchFullName}) => {
    const [show, setShow] = useState(false);
    const [formError, setFormError] = useState<string>("");
    const [successful, setSuccessful] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const methods = useForm<UserCreationRequest>({
        resolver: yupResolver(internalUserCreationValidation)
    });
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSubmit: SubmitHandler<UserCreationRequest> = (data) => handleNewUser(data);

    const handleNewUser = (formValue: UserCreationRequest) => {
        setFormError("");
        setSuccessful(false);

        AuthService.registerNewUser(formValue).then(
            (res) => {
                setSuccessful(true);
                methods.reset();
                queryClient.invalidateQueries('getUsers').then()
            },
            (err) => {
                setFormError(err.response.data.detail);
            }
        );
    };

    useEffect(() => {
        if (!formError) return;
        setTimeout(() => {
            setFormError("");
        }, ALERT_LIFETIME);
    }, [formError]);

    useEffect(() => {
        if (!successful) return;
        setTimeout(() => {
            setSuccessful(false);
        }, ALERT_LIFETIME);
    }, [successful]);

    return (
        <>
            <main className="view-content">
                <AdminAction text="Add new user" handleShow={handleShow} searchEnabled={true} searchQuery={userSearchFullName} setSearchQuery={setUserSearchFullName} />
                <AdminList setUserPageNumber={setUserPageNumber}
                           pageableUserResponse={pageableUserResponse}
                />
            </main>

            <Modal show={show} className="modal fade modal-lg" id="addNewUserModal">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <AdminUserModal
                            handleClose={handleClose}
                            formError={formError}
                            successful={successful}
                            errors={methods.formState.errors}
                            companies={companies}
                            clearCompanyValue={() => methods.setValue("company", "")}
                        />
                    </form>
                </FormProvider>
            </Modal>
        </>
    );
};
export default UserDashBoard;

import iconDownload from "../assets/icon-download.svg";
import React from "react";

const FileListItem: React.FC<{
    fileName: string;
    downloadAttachment?: () => void;
    removeAttachment?: () => void;
}>
    = ({fileName, downloadAttachment, removeAttachment}) => {

    return <div className="attachment">
        <div className={"filename"}>
            {fileName}
        </div>
        <div className={"actions"}>
            {removeAttachment &&
                <button type="button" onClick={removeAttachment} className={`btn btn-white btn-icon p-1 px-2 shadow ms-2`}>
                    <i className="bi bi-trash3"></i>
                </button>
            }
            {downloadAttachment &&
                <button onClick={downloadAttachment} className={"btn btn-white btn-icon shadow p-1 ms-2"}>
                    <img src={iconDownload} width={"24"} height={"24"} alt=""/>
                </button>
            }
        </div>
    </div>
}

export default FileListItem;

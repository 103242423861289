import logo from "../assets/logo-alpha-white.svg";
import {Link} from "react-router-dom";
import React from "react";

const LoggedOutHeader: React.FC<{
    compact: boolean,
}> = ({compact = false}) => {
    return (
        <header className={`app-header -logged-out ${compact ? "-compact" : ""}`}>
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col-md-auto">
                        <a href="https://www.alpha3d.io/">
                            <img src={logo} width="173" alt="Alpha AR"/>
                        </a>
                    </div>
                    <div className="col-md-auto">
                        <a href={"https://www.alpha3d.io/privacy-policy/"} rel="noreferrer" target={"_blank"}>
                            <button className="btn btn-link me-2">Privacy Policy</button>
                        </a>
                        <a href={"https://www.alpha3d.io/terms-of-service/"} rel="noreferrer" target={"_blank"}>
                            <button className="btn btn-link me-2">Terms of Service</button>
                        </a>
                        <Link to={"/login"}>
                            <button className="btn btn-light">Log in</button>
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default LoggedOutHeader

import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import AuthService from "../services/auth.service";
import {LoginState} from "../types/navigation";

const VerifyAccount: React.FC = () => {
    const {token} = useParams();
    const navigate = useNavigate();

    if (token) {
        AuthService.verifyAccount(token).then(
            () => {
                navigate(`/login`, {state: {message: "E-mail verified. You can now log in using your credentials."} as LoginState});
            }
        ).catch((err) => {
            navigate(`/login`);
        })
    } else {
        navigate(`/login`);
    }


    return (
        <main className="view-content">
        </main>
    );
};

export default VerifyAccount;

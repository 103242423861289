export enum ProjectStatus {
    DRAFT = "DRAFT",
    PENDING_QUOTES = "PENDING_QUOTES",
    IN_PRODUCTION = "IN_PRODUCTION",
    COMPLETED = "COMPLETED",
    DELETED = "DELETED",
    ERROR = "ERROR",
}

export const PROJECT_STATUS_TRANSLATIONS: { [key in ProjectStatus]: string } = {
    [ProjectStatus.DRAFT]: "Draft",
    [ProjectStatus.PENDING_QUOTES]: "Pending quotes",
    [ProjectStatus.IN_PRODUCTION]: "In production",
    [ProjectStatus.COMPLETED]: "Completed",
    [ProjectStatus.DELETED]: "Deleted",
    [ProjectStatus.ERROR]: "Error",
};

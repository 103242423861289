import React, {ReactElement, useEffect, useState} from "react";
import {AttachmentInfo} from "../../types/attachment/attachment-response";
import {useAlerts} from "../../store/AlertProvider";
import {AsyncAttachmentProps} from "./ExistingAsyncAttachment";

const AsyncAttachmentMockUpload: React.FC<{
    attachment: AttachmentInfo;
    remove: (image: AttachmentInfo) => void;
    updateFile: (image: AttachmentInfo) => void;
    displayElement: ReactElement<AsyncAttachmentProps>;
    isUploadEnabled?: boolean;
}> = ({remove, attachment, updateFile, displayElement, isUploadEnabled = true}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const {addErrorResponseAlert} = useAlerts();

    useEffect(function uploadAttachment() {
        if (!attachment.file || attachment.uploaded || !isUploadEnabled) return;
        setLoading(true);
        const controller = new AbortController();
        setLoading(false);
        updateFile({
            ...attachment,
            uploaded: true
        })
        return () => {
            controller.abort()
        }
    }, [addErrorResponseAlert, attachment, remove, updateFile, isUploadEnabled]);

    const handleRemove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        remove(attachment)
        event.stopPropagation();
    }

    return (
        <>
            {React.cloneElement(displayElement, {attachment: attachment, loading: loading, handleRemove: handleRemove})}
        </>
    );
};

export default AsyncAttachmentMockUpload;

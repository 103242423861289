import React, {useEffect, useState} from "react";
import "@google/model-viewer";
import ModelViewer, {AlphaModel} from "./ModelViewer";
import ModelViewHeader from "../internal-upload/ModelViewHeader";
import {Attachment} from "../../types/attachment/attachment-response";
import ProductInfoResponse from "../../types/product/product-info-response";
import {b64toBlob} from "../../utils/FileUtils";
import AttachmentClient from "../../services/attachment.client";
import {useAlerts} from "../../store/AlertProvider";
import {useNavigate} from "react-router-dom";
import {decryptBase64} from "../../utils/AttachmentUtil";

const ModelView: React.FC<{
    productUuid: string | undefined;
    product?: ProductInfoResponse
    versionModels: Attachment[];
    versionAttachments: Attachment[];
    versions: number[] | undefined;
    modelNumber: string | null;
    versionNumber: string | null;
    urlPrefix: string;
}>
    = ({
           productUuid,
           product,
           versionModels,
           versionAttachments,
           versions,
           modelNumber,
           versionNumber,
           urlPrefix
       }) => {
    const [model, setModel] = useState<AlphaModel>();
    const {addErrorAlert} = useAlerts();
    const navigate = useNavigate();

    useEffect(() => {
        if (!modelNumber || versionModels.length < 1) return;
        if (versionModels.find(versionModel => versionModel.attachmentUuid.toString() === modelNumber) === undefined) {
            addErrorAlert("Invalid model id, navigating to first model of version");
            navigate(urlPrefix + productUuid + "?version=" + versionNumber + "&model=");
            return;
        }
        setModel(undefined);
        AttachmentClient.getAttachmentImage(modelNumber).then((res) => {
            let decryptedBase64 = decryptBase64(res.base64);
            if (!decryptedBase64) decryptedBase64 = res.base64;
            setModel({modelId: modelNumber, blob: b64toBlob(decryptedBase64, "application/octet-stream")});
        });
    }, [modelNumber, versionModels, navigate, addErrorAlert, productUuid, versionNumber, urlPrefix]);

    return (
        <>
            <ModelViewHeader product={product} versionModels={versionModels} versionAttachments={versionAttachments}
                             versions={versions}
                             modelNumber={modelNumber}
                             versionNumber={versionNumber}/>

            <div className="p-3 internal-upload-model-view">
                {model && (
                    <ModelViewer model={model}/>
                )}
            </div>
        </>
    );
};

export default ModelView;

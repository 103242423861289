import React from "react";
import ProductStatusBadge from "../product/ProductStatusBadge";
import {ProductStatus} from "../../types/product/product-status";
import {useSearchParams} from "react-router-dom";
import VersionTabsContainer from "../VersionTabsContainer";

const ClientUploadHeader: React.FC<{
    productUuid: string;
    sendOffer: () => void;
    updateOffer: () => void;
    addNewVersion: () => void;
    title: string;
    versions: number[] | undefined;
    status?: ProductStatus;
    isAttachmentButtonVisible?: boolean;
}> = ({title, productUuid, status, sendOffer, updateOffer, versions, addNewVersion, isAttachmentButtonVisible}) => {
    const [searchParams] = useSearchParams();
    const versionNumber = searchParams.get("version");

    return (
        <header className="view-header">
            <div className="container-fluid">
                <div className="subtitle-spacer"/>
                <div className="row justify-content-between">
                    <div className="col-md-auto">
                        <div className="title">
                            <h1>{title}</h1>
                            <ProductStatusBadge className="ms-3" productStatus={status}/>
                        </div>
                    </div>
                    <div className="col-md-auto">
                        {status && status === ProductStatus.IN_PRODUCTION
                            ? <button onClick={() => updateOffer()} className="btn btn-black me-2">
                                Update offer
                            </button>
                            : <button onClick={() => sendOffer()} className="btn btn-black me-2">
                                Send offer
                            </button>
                        }
                        {!versionNumber && status && isAttachmentButtonVisible && (
                            <button onClick={() => addNewVersion()} className="btn btn-primary">
                                Send files to client
                            </button>
                        )}
                    </div>
                </div>
                <VersionTabsContainer destinationPrefix={"/admin/client-upload/"} versions={versions}
                                      selectedVersionNumber={versionNumber} productUuid={productUuid}/>
            </div>
        </header>
    );
};

export default ClientUploadHeader;

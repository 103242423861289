import PageInfo from "../types/user/page-info";
const pageCalculation = (page: PageInfo) => {
    const SHOW_PAGE_NUMBER_PREV = 2;

    let prevStart = SHOW_PAGE_NUMBER_PREV >= page.page ? 1 : page.page - SHOW_PAGE_NUMBER_PREV;
    let prevEnd = page.totalPages - page.page > SHOW_PAGE_NUMBER_PREV ? (page.page + SHOW_PAGE_NUMBER_PREV + 1) : page.totalPages;
    let prevPageNumbers = range(prevStart, prevEnd);

    let nextStart = page.totalPages - page.page > SHOW_PAGE_NUMBER_PREV ? page.totalPages - SHOW_PAGE_NUMBER_PREV + 1 : page.totalPages;
    let nextPageNumbers = range(nextStart, page.totalPages);

    let filteredNextPageNumbers = nextPageNumbers.filter(n => !prevPageNumbers.includes(n));

    let hasDot = prevPageNumbers.length > 0
        && filteredNextPageNumbers.length > 0
        && prevPageNumbers[prevPageNumbers.length - 1] + 1 !== filteredNextPageNumbers[0];
    return {
        prevPageNumbers: prevPageNumbers,
        nextPageNumbers:
        filteredNextPageNumbers,
        hasDot: hasDot
    };
}

const range = (min: number, max: number) => Array.from({ length: max - min + 1 }, (_, i) => min + i);

export default pageCalculation;
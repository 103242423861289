import React from "react";
import {useFormContext} from "react-hook-form";
import InputError from "./InputError";

const CheckBox: React.FC<{
    name: string;
    label: string;
    errorMessage?: string;
    disabled?: boolean;
}> = ({name, label, errorMessage, disabled = false}) => {
    const {register} = useFormContext();

    return (
        <>
            <div className="form-check mb-3">
                <input disabled={disabled} type={"checkbox"} className="form-check-input" {...register(name)} id={name}/>
                <label htmlFor={label} className="form-check-label">
                    {label}
                </label>
                <InputError errorMessage={errorMessage}/>
            </div>
        </>
    );
};

export default CheckBox;

import {ProductionType} from "../types/project/production-type";
import {
    AI_IMAGE_PROJECT_CONFIRMATION_BODY,
    AI_PROJECT_CONFIRMATION_HEADER, AI_TEXT_PROJECT_CONFIRMATION_BODY,
    DESIGNER_PROJECT_CONFIRMATION_BODY,
    DESIGNER_PROJECT_CONFIRMATION_HEADER
} from "../common-texts";
import {ProjectStatus} from "../types/project/project-status";
import {AiMethod} from "../types/project/ai-method-type";

export function getProjectConfirmationHeader (productionType?: ProductionType): string {
    if (productionType === ProductionType.DESIGNER) {
        return DESIGNER_PROJECT_CONFIRMATION_HEADER;
    } else if (productionType === ProductionType.AI_GENERATED) {
        return AI_PROJECT_CONFIRMATION_HEADER;
    }
    return "";
}

export function getProjectConfirmationBody(productionType: ProductionType, costTokens: number, aiMethod: AiMethod | null): string {
    if (productionType === ProductionType.DESIGNER) {
        return DESIGNER_PROJECT_CONFIRMATION_BODY;
    } else if (productionType === ProductionType.AI_GENERATED) {
        if (aiMethod === AiMethod.ANYTHING) {
            return AI_TEXT_PROJECT_CONFIRMATION_BODY.replace("{0}", costTokens.toString());
        }
        return AI_IMAGE_PROJECT_CONFIRMATION_BODY.replace("{0}", costTokens.toString());
    }
    return "";
}

export function getProjectStatusForProductionType(productionType: ProductionType): ProjectStatus {
    if (productionType === ProductionType.DESIGNER) {
        return ProjectStatus.PENDING_QUOTES;
    } else {
        return ProjectStatus.IN_PRODUCTION;
    }
}

export function isOnlyAiGeneratedProductionType(productionTypes: ProductionType[]): boolean {
    return productionTypes.includes(ProductionType.AI_GENERATED) && productionTypes.length === 1;
}

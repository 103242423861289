import React from "react";
import {Link} from "react-router-dom";
import EmptySkeleton from "./EmptySkeleton";

const EmptyProject: React.FC<{}> = () => {
    return (
        <>
            <div className="empty-message">
                <h2 className="mb-4">Welcome to Alpha3D. As a sign up gift,
                    we added to your account 500 free tokens.</h2>
                <Link to={"/new-project"}>
                    <button className="btn btn-dark btn-icon">
                        <i className="bi bi-plus-circle me-2"></i> Add new project
                    </button>
                </Link>
            </div>
            <div className="container">
                <div className="row">
                    <EmptySkeleton/>
                    <EmptySkeleton/>
                    <EmptySkeleton/>
                    <EmptySkeleton/>
                    <EmptySkeleton/>
                    <EmptySkeleton/>
                    <EmptySkeleton/>
                    <EmptySkeleton/>
                </div>
            </div>
        </>
    );
};

export default EmptyProject;

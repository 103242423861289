import React from "react";
import {IPartnerCard} from "../../types/partner";

const PartnerList: React.FC<{
    partner: IPartnerCard,
    detailsVisible: boolean,
    setDetailsVisible: (detailsVisible: boolean) => void,
    setSelectedPartner: (selectedPartner: IPartnerCard) => void,
}> = ({partner, detailsVisible, setDetailsVisible, setSelectedPartner}) => {

    const handlePartnerClick = () => {
        setDetailsVisible(!detailsVisible);
        setSelectedPartner(partner);
    }

    return (
        <div className="col">
            <div className="partner js-toggle-partner-details" onClick={handlePartnerClick}>
                <div className="logo mt-3">
                    <img src={partner.imagePath} height="80" alt="" />
                </div>
                <div className="name mt-4">
                    <h4>{partner.partnerName}</h4>
                </div>
                <div className="description">{partner.shortDescription}</div>
                <div className="actions">
                    <button className="btn btn-link btn-sm text-uppercase">
                        Read more
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PartnerList;

import axios from "axios";
import {ProductionType} from "../types/project/production-type";
import {userDownloadFile} from "../utils/FileUtils";
import CompanyResponse from "../types/company/company-response";

const COMPANY_ENDPOINT = process.env.REACT_APP_API_URL + "company/";

export class CompanyClient {
    getCompanyBalance(): Promise<number> {
        return axios
            .get(COMPANY_ENDPOINT + "balance")
            .then(res => {
                return res.data
            })
    }

    getCompanyPaymentStatus(): Promise<boolean> {
        return axios
            .get(COMPANY_ENDPOINT + "payment")
            .then(res => {
                return res.data
            })
    }

    getCompanyProductionTypes(): Promise<ProductionType[]> {
        return axios
            .get(COMPANY_ENDPOINT + "productionTypes")
            .then((res) => {
                return res.data;
            })
    }

    isCompanyAutomaticInvoicingEnabled(): Promise<boolean> {
        return axios
            .get(COMPANY_ENDPOINT + "automaticInvoicing")
            .then((res) => {
                return res.data;
            })
    }

    generateInvoiceReport(companyUuid: string, startDate: Date, endDate: Date, name: string) {
        return axios
            .get(COMPANY_ENDPOINT + companyUuid + "/generate-report",
                {
                    params: {
                        startDate: startDate.toISOString().substring(0, 10),
                        endDate: endDate.toISOString().substring(0, 10)
                    },
                    responseType: "blob"
                })
            .then(res => {
                userDownloadFile(res.data, this.getReportName(name, startDate, endDate));
            });
    }

    getCurrentCompany(): Promise<CompanyResponse> {
        return axios
            .get(COMPANY_ENDPOINT + "current")
            .then((res) => {
                return res.data;
            })
    }

    private getReportName(name: string, startDate: Date, endDate: Date) {
        return `report_${name.replaceAll(" ", "-")}_${startDate.toISOString().substring(0, 10)}_${endDate.toISOString().substring(0, 10)}.xlsx`;
    }
}

export default new CompanyClient();

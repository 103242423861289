import React from "react";
import RequestResult from "../../RequestResult";
import {FieldErrors} from "react-hook-form";
import GenerateReportRequest from "../../../types/company/generate-report-request";
import LoadingButton from "../../product/LoadingButton";
import DateInput from "../../DateInput";

const AdminGenerateReportModal: React.FC<{
    handleClose: () => void;
    handleSubmit: () => void;
    formError: string;
    successful: boolean;
    loading?: boolean;
    errors: FieldErrors<GenerateReportRequest> | undefined;
}> = ({
          handleClose,
          handleSubmit,
          successful,
          formError,
          errors,
          loading = false
      }) => {

    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title ms-2" id="addNewCompanyModal">
                        Generate report
                    </h4>
                    <button
                        onClick={handleClose}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body ms-2">
                    <DateInput name="startDate" errorMessage={errors?.startDate?.message}
                              label="Start date"/>
                    <DateInput name="endDate" errorMessage={errors?.endDate?.message} label="End date"/>
                    <RequestResult successfulMessage="Generating report." successful={successful} error={formError}/>
                </div>
                <div className="modal-footer">
                    <button onClick={handleClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                        Cancel
                    </button>
                    <LoadingButton
                        onClick={handleSubmit}
                        buttonText="Generate"
                        loading={loading}
                        loadingTooltip={""}
                    />
                </div>
            </div>
        </>
    );
};

export default AdminGenerateReportModal;

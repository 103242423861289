import {FormProvider, UseFormReturn} from "react-hook-form";
import React from "react";
import VersionAttachments from "./internal-upload/VersionAttachments";
import {Attachment} from "../types/attachment/attachment-response";
import ModelFileInput from "./internal-upload/ModelFileInput";
import AttachmentFileInput from "./internal-upload/AttachmentFileInput";
import NewTextArea from "./NewTextArea";
import {ModelsAttachmentsCommentsForm} from "../types/models-attachments-comments-form";
import {getError} from "../utils/ValidationUtil";

const ModelsAndAttachmentForm: React.FC<{
    saveRequestMethods: UseFormReturn<ModelsAttachmentsCommentsForm>,
    versionNumber: string | null,
    versionModels: Attachment[];
    versionAttachments: Attachment[];
    isClientUpload?: boolean;
    areAttachmentsAllowed?: boolean;
    attachmentLabel?: string;
}>
    = ({
           saveRequestMethods,
           versionNumber,
           versionModels,
           versionAttachments,
           isClientUpload = false,
           areAttachmentsAllowed = true,
           attachmentLabel = "Attachments"
       }) => {
    const errors = saveRequestMethods.formState.errors;

    function lookingAtVersion() {
        return <div className="mb-3">
            <VersionAttachments models={versionModels} attachments={versionAttachments}/>
        </div>;
    }

    function addingNewModels() {
        return <>
            {areAttachmentsAllowed &&
                <>
                    <div className="mb-3">
                        <label className="form-label">3D models</label>
                        <ModelFileInput name={"models"}
                                        errorMessage={getError(errors?.models)}/>
                    </div>
                    <div className="mt-3">
                        <label className="form-label" style={{whiteSpace: "pre-line"}}>{attachmentLabel}</label>
                        <AttachmentFileInput name={"attachments"}
                                             errorMessage={getError(errors?.attachments)}/>
                    </div>
                    {isClientUpload &&
                        <div className="mt-3">
                            <NewTextArea name={"comment"} label={"Comment"}/>
                        </div>
                    }
                </>
            }
        </>;
    }
    return <FormProvider {...saveRequestMethods}>
        <form className="bg-white dropzone">
            <div className="row">
                <div className="col-md-5 offset-md-1">
                    {versionNumber
                        ? lookingAtVersion()
                        : addingNewModels()}
                </div>
            </div>
        </form>
    </FormProvider>;
};

export default ModelsAndAttachmentForm;

import React, {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import ProductClient from "../../services/product.client";
import ProductInfoResponse from "../../types/product/product-info-response";
import ClientUploadForm from "../../components/client-upload/ClientUploadForm";
import ClientUploadHeader from "../../components/client-upload/ClientUploadHeader";
import {useForm} from "react-hook-form";
import ProductOfferUpdateRequest from "../../types/product/product-offer-update-request";
import {yupResolver} from "@hookform/resolvers/yup";
import {productOfferUpdateValidation} from "../../validation/product-offer-update-validation";
import {useAlerts} from "../../store/AlertProvider";
import ClientUploadFooter from "../../components/client-upload/ClientUploadFooter";
import {modelAndAttachmentValidation} from "../../validation/model-and-attachment-validation";
import {Attachment} from "../../types/attachment/attachment-response";
import {getClientModelsAndAttachments, getClientVersionNumbers, getProduct} from "../../utils/SharedUseEffects";
import ModelView from "../../components/model/ModelView";
import {appendFilesAttachmentsToFormData, getModelsAndAttachmentFilesSize} from "../../utils/FileUtils";
import AttachmentClient from "../../services/attachment.client";
import LoadingModal, {LoadingModalEffects} from "../../components/modals/LoadingModal";
import {ALLOWED_CLIENT_STATUSES, ProductStatus} from "../../types/product/product-status";
import {ModelsAttachmentsCommentsForm} from "../../types/models-attachments-comments-form";

const ADDING_VERSION = "Adding new client version";
const PROCESSING_FILES = "Processing files";
const UPLOADING_FILES = "Uploading files";

const ClientUploadPage: React.FC = () => {
    const [product, setProduct] = useState<ProductInfoResponse>();
    const {productUuid} = useParams();
    const {addErrorResponseAlert, addSuccessAlert} = useAlerts();
    const [searchParams] = useSearchParams();
    const versionNumber: string | null = searchParams.get("version");
    const modelNumber: string | null = searchParams.get("model");
    const [versions, setVersions] = useState<number[]>();
    const navigate = useNavigate();
    const [versionModels, setVersionModels] = useState<Attachment[]>([]);
    const [versionAttachments, setVersionAttachments] = useState<Attachment[]>([]);
    const areAttachmentsAllowed = !!ALLOWED_CLIENT_STATUSES.find(allowedStatus => product?.status === allowedStatus)

    const productOfferSaveRequestMethods = useForm<ProductOfferUpdateRequest>({
        resolver: yupResolver(productOfferUpdateValidation)
    });

    const clientModelAttachmentUploadSaveRequestMethods = useForm<ModelsAttachmentsCommentsForm>({
        resolver: yupResolver(modelAndAttachmentValidation)
    });

    const {
        showLoadingModal, setShowLoadingModal,
        loadingModalHeader, setLoadingModalHeader,
        loadingModalBody, setLoadingModalBody,
        progress, setProgress,
        maxProgress, setMaxProgress,
        emptyLoadingModal
    } = LoadingModalEffects();

    useEffect(() => {
        getProduct(productUuid, setProduct);
    }, [productUuid]);

    useEffect(() => {
        getClientVersionNumbers(productUuid, versions, setVersions);
    }, [setVersions, productUuid, versions]);

    useEffect(() => {
        getClientModelsAndAttachments(versionNumber, productUuid, setVersionModels, setVersionAttachments, clientModelAttachmentUploadSaveRequestMethods);
    }, [versionNumber, clientModelAttachmentUploadSaveRequestMethods, productUuid]);

    const versionChangedByUserFetchNewModelsAndShowFirstModel = useCallback(() => {
        setVersionModels([]);
        setVersionAttachments([]);
        AttachmentClient.getClientAttachments(productUuid!, versionNumber!).then(res => {
            setVersionAttachments(res);
        });
        AttachmentClient.getClientModels(productUuid!, versionNumber!, ["GLB", "GLTF"]).then(res => {
            setVersionModels(res);
            navigate("/admin/client-upload/" + productUuid + "?version=" + versionNumber + "&model=" + res.at(0)!.attachmentUuid);
        });
    }, [navigate, productUuid, versionNumber]);


    useEffect(() => {
        if (modelNumber === "" && versionNumber) {
            versionChangedByUserFetchNewModelsAndShowFirstModel();
        }
    }, [versionNumber, modelNumber, versionChangedByUserFetchNewModelsAndShowFirstModel]);

    // same endpoint, but which button is displayed depends on product status
    const sendOffer = async (formValue: ProductOfferUpdateRequest) => {
        await extracted(formValue, "Product offer successfully sent");
    };

    const updateOffer = async (formValue: ProductOfferUpdateRequest) => {
        await extracted(formValue, "Product offer successfully updated");
    };

    const saveModelsAndAttachments = async () => {
        setLoadingModalHeader(ADDING_VERSION);
        setLoadingModalBody(PROCESSING_FILES);
        setShowLoadingModal(true);
        const modelFormData = new FormData();
        const models = clientModelAttachmentUploadSaveRequestMethods.getValues("models");
        const attachments = clientModelAttachmentUploadSaveRequestMethods.getValues("attachments");
        const comment = clientModelAttachmentUploadSaveRequestMethods.getValues("comment");
        appendFilesAttachmentsToFormData(models, attachments, comment, modelFormData);
        let filesSize = getModelsAndAttachmentFilesSize(models, attachments);
        setMaxProgress(filesSize);
        setLoadingModalBody(UPLOADING_FILES);

        await AttachmentClient.saveClientModelsAndAttachments(productUuid!, modelFormData, setProgress)
            .then(() => {
                const newVersionNumber = versions?.at(0) ? (versions.at(0)! + 1) : 1;
                versions?.unshift(newVersionNumber);
                setVersions(versions);
                clientModelAttachmentUploadSaveRequestMethods.reset();
                addSuccessAlert("Models successfully saved");
                emptyLoadingModal();
                setProduct({...product!, status: ProductStatus.READY_FOR_QC})
                navigate("/admin/client-upload/" + productUuid + "?version=" + newVersionNumber);
            })
            .catch(res => addErrorResponseAlert(res));
    };


    async function extracted(formValue: ProductOfferUpdateRequest, successMessage: string) {
        if (!productUuid) return;
        const response = await ProductClient.updateProductOffer(productUuid, formValue);
        if (response) {
            addErrorResponseAlert(response);
        } else {
            addSuccessAlert(successMessage);
        }
    }

    return (
        <>
            <LoadingModal header={loadingModalHeader}
                          body={loadingModalBody}
                          showModal={showLoadingModal}
                          currentProgress={progress}
                          maxProgress={maxProgress}/>
            {modelNumber != null ? showModel() : showUpload()}
        </>
    );

    function showModel() {
        return <ModelView productUuid={productUuid}
                          product={product}
                          modelNumber={modelNumber}
                          versionModels={versionModels}
                          versionAttachments={versionAttachments}
                          versionNumber={versionNumber}
                          versions={versions}
                          urlPrefix={"/admin/client-upload/"}
        />;
    }

    function showUpload() {
        return <>
            <ClientUploadHeader
                addNewVersion={clientModelAttachmentUploadSaveRequestMethods.handleSubmit(saveModelsAndAttachments)}
                productUuid={productUuid!}
                title={"Client upload"}
                status={product?.status}
                sendOffer={productOfferSaveRequestMethods.handleSubmit(sendOffer)}
                updateOffer={productOfferSaveRequestMethods.handleSubmit(updateOffer)}
                versions={versions}
                isAttachmentButtonVisible={areAttachmentsAllowed}
            />
            <ClientUploadForm
                versionNumber={versionNumber}
                versionModels={versionModels}
                versionAttachments={versionAttachments}
                productOfferSaveRequestMethods={productOfferSaveRequestMethods}
                clientModelAttachmentUploadSaveRequestMethods={clientModelAttachmentUploadSaveRequestMethods}
                product={product}
                areAttachmentsAllowed={areAttachmentsAllowed}
            />
            <ClientUploadFooter
                status={product?.status}
                sendOffer={productOfferSaveRequestMethods.handleSubmit(sendOffer)}
                updateOffer={productOfferSaveRequestMethods.handleSubmit(updateOffer)}
                addNewVersion={clientModelAttachmentUploadSaveRequestMethods.handleSubmit(saveModelsAndAttachments)}
                isAttachmentButtonVisible={areAttachmentsAllowed}
            />
        </>;
    }

};

export default ClientUploadPage;

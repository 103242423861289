import axios from "axios";

export class UserClient {
    deactivateUser = (userUuid: string): Promise<void> => {
        return axios.put(process.env.REACT_APP_API_URL + "user/deactivate", userUuid, {headers: {'Content-Type': 'application/json'}}).then();
    };

    cancelRegistration = (): Promise<void> => {
        return axios.post(process.env.REACT_APP_API_URL + "user/cancel-registration");
    }

    getAccountSettings = (): Promise<any> => {
        return axios.get(process.env.REACT_APP_API_URL + "user/account-settings").then((res) => {
            return res.data;
        });
    }
}

export default new UserClient();

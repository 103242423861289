import {useEffect, useState} from "react";
import pageCalculation from "../utils/PaginationUtil";
import PageInfo from "../types/user/page-info";
import {range} from "lodash";

interface PaginationProps {
    page: PageInfo;
    setCurrentPage: ((page: number) => void) | undefined;
}

const Pagination = ({page, setCurrentPage}:PaginationProps) => {

    const numOfPages = range(1, page.totalPages+1)

    const {prevPageNumbers, nextPageNumbers, hasDot} = pageCalculation(page);

    const [currentButton, setCurrentButton] = useState(page.page + 1);

    useEffect(() => {
        setCurrentButton(page.page + 1);
        window.scrollTo(0, 0);
    }, [page.page]);

    const handlePageChange = (newPage: number) => {
        setCurrentButton(newPage);
        setCurrentPage && setCurrentPage(newPage - 1);
    }


    return (
        <>
            {page.totalElements > 0 && (
                <div className="container mt-2">
                    <ul className="pagination align-items-center justify-content-center">
                        <li className={`${currentButton === 1 ? 'page-item disabled' : 'page-item'}`}>
                            <button className="page-link"
                               onClick={() => handlePageChange(currentButton - 1)}>
                                <i className="bi bi-caret-left-fill"></i>
                            </button>

                        </li>
                        {
                            prevPageNumbers.map((page, index) => {
                                return (
                                    <li key={index} className={`${currentButton === page ? 'page-item active' : 'page-item'}`}>
                                        <span className="page-link" onClick={()=> handlePageChange(page)}>{page}</span>
                                    </li>
                                )
                            })

                        }
                        {
                            hasDot && (
                                <li className="page-item">
                                    <span className="page-link" >...</span>
                                </li>
                            )
                        }
                        {
                            nextPageNumbers.map((page, index) => {
                                return (
                                    <li key={index} className={`${currentButton === page ? 'page-item active' : 'page-item'}`}>
                                        <span className="page-link" onClick={()=> handlePageChange(page)}>{page}</span>
                                    </li>
                                )
                            })

                        }
                        <li className={`${currentButton === numOfPages.length ? 'page-item disabled' : 'page-item'}`}>
                            <button className="page-link"
                               onClick={() => handlePageChange(currentButton + 1)}>
                                <i className="bi bi-caret-right-fill"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </>
    )
}
export default Pagination;
export enum AiMethod {
    MESH = "MESH",
    SNEAKERS = "SNEAKERS",
    SOFA_BETA = "SOFA_BETA",
    ANYTHING = "ANYTHING"
}

export const AI_METHOD_TRANSLATIONS: { [key in AiMethod]: string } = {
    [AiMethod.MESH]: "Mesh",
    [AiMethod.SNEAKERS]: "Sneakers (image/text to 3D)",
    [AiMethod.SOFA_BETA]: "Sofa (image/text to 3D) (Beta)",
    [AiMethod.ANYTHING]: "Anything (text to 3D)",
};

import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import NewInput from "../components/NewInput";
import {yupResolver} from "@hookform/resolvers/yup";
import RequestResult from "../components/RequestResult";
import LoadingButton from "../components/product/LoadingButton";
import * as Yup from "yup";
import {ALERT_LIFETIME} from "../components/NewAlert";
import AuthService from "../services/auth.service";

const ForgotPasswordPage: React.FC = () => {
    const [formError, setFormError] = useState<string>("");
    const [successfulMessage, setSuccessfulMessage] = useState<string>("");
    const methods = useForm<{ email: string }>({
            resolver: yupResolver(Yup.object().shape({
                email: Yup.string().email("This is not a valid email.").required("This field is required!")
            }))
        }
    );

    const onSubmit: SubmitHandler<{ email: string }> = (data) => handleSubmit(data);
    const [loading, setLoading] = useState<boolean>(false);
    const EMAIL_SENT_MESSAGE = "If given e-mail exists, then an email is sent to you to reset your password.";

    const handleSubmit = (formValue: { email: string }) => {
        setLoading(true);
        AuthService.initRecoverPassword(formValue.email).then(
            (res) => {
                setFormError("");
                setSuccessfulMessage(EMAIL_SENT_MESSAGE);
                methods.reset();
                setLoading(false);
            },
            (err) => {
                setLoading(false);
                setSuccessfulMessage("");
                setFormError(err.response?.data?.detail);
            }
        );
    };

    useEffect(function clearErrorAfterLifeTimeEnd() {
        const timeOut = setTimeout(() => {
            setFormError("");
        }, ALERT_LIFETIME);
        return () => {
            clearTimeout(timeOut)
        }
    }, [formError]);

    return (
        <main className="view-content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <FormProvider {...methods}>
                            <div className="card">
                                <div className="card-body p-5">
                                    <h1 className="text-center mb-5">Recover password</h1>
                                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                                        <NewInput
                                            name="email"
                                            label="Your email"
                                            errorMessage={methods.formState.errors?.email?.message}
                                        />
                                        <div
                                            className="text-center d-flex justify-content-between pt-2 align-items-center">
                                            <Link to={"/login"}>
                                                Sign in
                                            </Link>
                                            <LoadingButton
                                                onClick={() => methods.handleSubmit(onSubmit)}
                                                buttonText="Reset password"
                                                loading={loading}
                                                loadingTooltip={""}
                                                btnClassName={"btn-light px-4"}
                                                loaderVariant={"dark"}
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <RequestResult successfulMessage={successfulMessage}
                                                           successful={successfulMessage.length > 0}
                                                           error={formError}/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </FormProvider>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ForgotPasswordPage;

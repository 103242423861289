import React, {useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";

import ProjectClient from "../../services/project.client";
import ProjectRequest from "../../types/project/project-request";
import {useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup";
import {newProjectValidationSchema,} from "../../validation/new-project-validation";
import NewProjectHeader from "../../components/new-project/project/NewProjectHeader";
import NewProjectFooter from "../../components/new-project/project/NewProjectFooter";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import NewProjectForm from "../../components/new-project/NewProjectForm";
import AttachmentClient from "../../services/attachment.client";
import {useAlerts} from "../../store/AlertProvider";
import LoadingModal, {LoadingModalEffects} from "../../components/modals/LoadingModal";
import {AxiosResponse} from "axios";
import {formatErrorResponse} from "../../services/product.client";
import NewProjectSaveResponse from "../../types/project/new-project-save-response";
import {ProductRequest} from "../../types/product/add-new-products-dtos";
import {ProjectStatus} from "../../types/project/project-status";
import {checkIfAnyFileUploading} from "../../utils/SharedUseEffects";
import {useClassifications} from "../../store/ClassificationProvider";
import {createProjectRequest} from "../../utils/ValidationUtil";
import {
    getProjectConfirmationBody,
    getProjectConfirmationHeader,
    getProjectStatusForProductionType
} from "../../utils/ProjectUtils";
import {ProductionType} from "../../types/project/production-type";

import {SubscriptionInfo} from "../../types/subscription-info";
import SubscriptionClient from "../../services/subscription.client";
import {AiMethod} from "../../types/project/ai-method-type";
import {useQuery} from "react-query";
import AnalyticsService from "../../services/analytics.service";
import {getAnalytics} from "firebase/analytics";
import {AlphaPayment} from "../../types/payment-price";
import {usePaymentToken} from "../../store/PaymentTokenProvider";
import GetMoreTokensModal from "../../components/modals/GetMoreTokensModal";
import CreateSubscriptionModal from "../../components/modals/CreateSubscriptionModal";
import {redirectToStripe} from "../../utils/PayementUtil";


const SENDING_QUOTE = "Creating new project";
const SAVING_DRAFT = "Creating new draft";
const SAVING_PROJECT_INFO = "Saving project info";
const SAVING_DRAFT_INFO = "Saving draft info";

const NewProjectPage: React.FC = () => {
    const navigate = useNavigate();
    const {productionTypes} = useClassifications();
    const saveRequestMethods = useForm<ProjectRequest>({
        resolver: yupResolver(newProjectValidationSchema),
        defaultValues: {
            products: [{
                brandName: "",
                modelName: "",
                idNumber: "",
                url: "",
                notes: "",
                category: [],
                attachmentUuids: [],
                unProcessedAttachments: 0,
                modelGenerationText: ""
            }]
        }
    });

    const onValidate: SubmitHandler<ProjectRequest> = () => {
        setShowConfirmationModal(true);
    };

    const onDraft: SubmitHandler<ProjectRequest> = (data) => sendForDraft(data);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [showOutOfTokensModal, setShowOutOfTokensModal] = useState<boolean>(false);
    const [showCreateSubModal, setShowCreateSubModal] = useState<boolean>(false);
    const {addSuccessAlert, addErrorResponseAlert} = useAlerts();
    const request: ProjectRequest = saveRequestMethods.watch();
    const [isAnyFileUploading, setIsAnyFileUploading] = useState<boolean>(false)
    const analytics = getAnalytics();
    const {prices, tokens, refetchTokenAmount} = usePaymentToken();

    useQuery('projectDefaultName', ProjectClient.getProjectDefaultName, {
        onSuccess: (data: string) => {
            saveRequestMethods.setValue("projectName", data);
        },
        refetchOnWindowFocus: false
    })

    const {data: projectsGeneratedCount} = useQuery('projectsGeneratedCount', ProjectClient.getProjectsGeneratedCount);
    const {data: subscriptionInfo} = useQuery<SubscriptionInfo>('subscriptionInfo', SubscriptionClient.getSubscriptionInfo, {placeholderData: {activeSubscription: ""}});

    useEffect(() => {
        checkIfAnyFileUploading(request, setIsAnyFileUploading);
        if (AiMethod.ANYTHING === request.aiMethod) {
            request.products = request.products.map(p => {
                p.modelName = p.modelGenerationText;
                return p;
            });
        }
    }, [request])

    useEffect(() => {
        let products: ProductRequest[] = saveRequestMethods.getValues("products")
        products = products.map(p => {
            p.modelName = '';
            p.modelGenerationText = '';
            return p;
        });
        saveRequestMethods.setValue('products', products);
    }, [request.aiMethod, saveRequestMethods])

    useEffect(function setProductionTypeDefaultValue() {
        if (productionTypes.includes(ProductionType.AI_GENERATED)) {
            saveRequestMethods.setValue("productionType", ProductionType.AI_GENERATED);
            saveRequestMethods.setValue("aiMethod", AiMethod.ANYTHING);
        } else if (productionTypes.length === 1) {
            saveRequestMethods.setValue("productionType", productionTypes[0]);
        }
    }, [productionTypes, saveRequestMethods])

    const {
        showLoadingModal, setShowLoadingModal,
        loadingModalHeader, setLoadingModalHeader,
        loadingModalBody, setLoadingModalBody,
        emptyLoadingModal
    } = LoadingModalEffects();

    const handleFail = (err: { response: AxiosResponse; }) => {
        emptyLoadingModal();
        addErrorResponseAlert(formatErrorResponse(err.response));
        return true;
    }

    const createProject = async (formValue: ProjectRequest, status: ProjectStatus): Promise<NewProjectSaveResponse | undefined> => {
        let failed = false;
        let request = createProjectRequest(formValue);
        const createProjectResponse = await ProjectClient.createProject(request, status)
            .then(res => {
                    AnalyticsService.logCreateProjectEvent(analytics, formValue.productionType, res.productUuids.length, projectsGeneratedCount, formValue.aiMethod);
                    return res;
                }
            )
            .catch(res => {
                failed = handleFail(res);
            });
        if (failed || !createProjectResponse) return undefined;
        return createProjectResponse;
    }

    const confirmProject = async (formValue: ProjectRequest) => {
        setShowConfirmationModal(false);
        setLoadingModalHeader(SENDING_QUOTE);
        setLoadingModalBody(SAVING_PROJECT_INFO);
        setShowLoadingModal(true);

        if (request.productionType === ProductionType.AI_GENERATED && getCost() > tokens) {
            emptyLoadingModal();
            setShowOutOfTokensModal(true);
            return;
        }
        let response: NewProjectSaveResponse | undefined = await createProject(formValue, getProjectStatusForProductionType(request.productionType));
        if (!response) return;

        emptyLoadingModal();
        if (request.productionType === ProductionType.DESIGNER) {
            addSuccessAlert("Project sent for quote");
        } else if (request.productionType === ProductionType.AI_GENERATED) {
            refetchTokenAmount();
            addSuccessAlert("Project ordered");
        }
        navigate("/");
    };

    const sendForDraft = async (formValue: ProjectRequest) => {
        setShowConfirmationModal(false);
        setLoadingModalHeader(SAVING_DRAFT);
        setLoadingModalBody(SAVING_DRAFT_INFO);
        setShowLoadingModal(true);

        let response: NewProjectSaveResponse | undefined = await createProject(formValue, ProjectStatus.DRAFT);
        if (!response) return;

        emptyLoadingModal();
        addSuccessAlert("Project saved as draft");
        navigate("/");
    };

    function handleClose() {
        setShowConfirmationModal(false);
    }

    async function handleCancel() {
        let products: ProductRequest[] = saveRequestMethods.getValues("products")
        let stragglingAttachmentUuids: string[] = [];
        products.forEach(product => {
            product.notSavedAttachmentUuids?.forEach(attachmentUuid => {
                stragglingAttachmentUuids.push(attachmentUuid);
            })
        })

        await AttachmentClient.deleteStragglingThumbnails(stragglingAttachmentUuids);
        navigate("/");
    }

    function handleOutOfTokens() {
        setShowOutOfTokensModal(false);
        if (subscriptionInfo?.activeSubscription) {
            redirectToStripe(addErrorResponseAlert);
            return;
        }
        setShowCreateSubModal(true);
    }

    async function handleProductRemove(index: number) {
        let removedProduct: ProductRequest = saveRequestMethods.getValues("products")[index];
        let stragglingAttachmentUuids: string[] = [];
        removedProduct.notSavedAttachmentUuids.forEach(notSavedAttachmentUuid => {
            stragglingAttachmentUuids.push(notSavedAttachmentUuid);
        });

        await AttachmentClient.deleteStragglingThumbnails(stragglingAttachmentUuids);
    }

    const getCost = () => {
        if (prices.get(AlphaPayment.GENERATE_MODEL) === undefined || request.products === undefined) {
            return 0;
        }
        return prices.get(AlphaPayment.GENERATE_MODEL)! * request.products.length;
    }

    return (
        <>
            <NewProjectHeader
                filesUploading={isAnyFileUploading}
                showCancelModal={() => setShowCancelModal(true)}
                confirmProject={saveRequestMethods.handleSubmit(onValidate)}
                saveAsDraft={saveRequestMethods.handleSubmit(onDraft)}
                title={"Add new project"}
                productionType={request.productionType}
                uploadDisabled={false}
            />
            <LoadingModal header={loadingModalHeader}
                          body={loadingModalBody}
                          showModal={showLoadingModal}/>
            <ConfirmationModal
                size={"lg"}
                header={getProjectConfirmationHeader(request.productionType)}
                body={getProjectConfirmationBody(request.productionType, getCost(), request.aiMethod)}
                handleClose={handleClose}
                handleConfirmation={saveRequestMethods.handleSubmit(confirmProject)}
                showModal={showConfirmationModal}
            />
            <ConfirmationModal
                header="Do you want to discard project?"
                handleClose={() => setShowCancelModal(false)}
                handleConfirmation={handleCancel}
                showModal={showCancelModal}
            />
            <GetMoreTokensModal handleClose={() => setShowOutOfTokensModal(false)}
                                handleConfirmation={handleOutOfTokens}
                                showModal={showOutOfTokensModal} cost={getCost()} tokens={tokens}/>
            <CreateSubscriptionModal showModal={showCreateSubModal}
                                     handleClose={() => setShowCreateSubModal(false)}/>
            <NewProjectForm
                onProductRemove={handleProductRemove}
                saveRequestMethods={saveRequestMethods}
                setShowModal={setShowConfirmationModal}
                hasSubscription={subscriptionInfo?.activeSubscription != null}
            />
            <NewProjectFooter
                filesUploading={isAnyFileUploading}
                showCancelModal={() => setShowCancelModal(true)}
                saveAsDraft={saveRequestMethods.handleSubmit(onDraft)}
                sendForQuote={saveRequestMethods.handleSubmit(onValidate)}
                productionType={request.productionType}
                uploadDisabled={false}
            />
        </>
    );
};
export default NewProjectPage;

import React from "react";
import {DateUtil} from "../utils/DateUtil";
import {CommentDTO} from "../types/product/product-comments-dtos";
import AttachmentClient from "../services/attachment.client";
import {Attachment} from "../types/attachment/attachment-response";
import iconDownload from "../assets/icon-download.svg";

const imageTypes = ["image/jpg", "image/jpeg", "image/png", "image/webp"];

export const Comment: React.FC<{
    comment: CommentDTO,
    index: number
}> = ({comment, index}) => {

    const downloadAttachment = async (attachment: Attachment) => {
        await AttachmentClient.getAttachmentFile(attachment);
    }

    function showImage(attachment: Attachment) {
        return <div className="attachment-image">
            <div className="actions">
                <button type="button" onClick={() => downloadAttachment(attachment)}
                        className={"btn btn-white shadow d-inline-block"}>
                    <img src={iconDownload} alt="" className={"p-1"}/>
                </button>
            </div>
            <img className={"image px-0 mb-1"} src={"data:image/jpeg;base64," + attachment.base64}
                 alt={attachment.fileName}/>
        </div>;
    }

    return (
        <div className="comment">
            <header className="header">
                <div className="meta">
                    <div className="time">
                        {DateUtil.getDateOnly(comment.createdAt)}
                        <span className="mx-1">·</span>
                        {DateUtil.getTimeAndTimezone(comment.createdAt)}
                    </div>
                    <div className="author">
                        {comment.authorEmail}
                        <span className="mx-1"/>
                        <span className={"badge badge-gray badge-small"}>Version {comment.versionNumber}</span>
                    </div>
                </div>
                <div className="actions">
                </div>
            </header>
            <div className="content">
                <span className="text textarea">
                    {comment.text}
                </span>
                {Array.isArray(comment.attachments) && comment.attachments.length > 0 &&
                    comment.attachments.map((attachment, key) => {
                        return <div key={key} className={"row align-items-center text-success attachment my-2 mx-0"}>
                            {imageTypes.includes(attachment.fileType)
                                ? showImage(attachment)
                                : <>
                                    <div className="col">
                                        {attachment.fileName}
                                    </div>
                                    <div id={`download-comment-${index}-attachment-${key}-button`}
                                         onClick={() => downloadAttachment(attachment)}
                                         className="btn-xs btn btn-white me-3 float-end col-1">
                                        <i className="bi-download"/>
                                    </div>
                                </>
                            }
                        </div>
                    })
                }
            </div>
        </div>
    );
}
export default Comment;

import axios from "axios";
import UserCreationRequest from "../types/user/user-creation-request";
import CompanyRequest from "../types/company/company-request";
import UserResponse from "../types/user/userResponse";
import CompanyResponse from "../types/company/company-response";
import ExternalUserCreationRequest from "../types/user/external-user-creation-request";
import UserChangePasswordRequest from "../types/user/user-change-password-request";
import ResetPasswordRequest from "../types/user/reset-password-request";
import NewUserCompanyCreationRequest from "../types/user/new-user-company-creation-request";

//TODO: useContext?
export class AuthService {
    registerNewUser(request: UserCreationRequest): Promise<UserResponse> {
        return axios
            .post(process.env.REACT_APP_API_URL + "user", request)
            .then((response) => response.data);
    }

    externalRegisterNewUser(request: ExternalUserCreationRequest): Promise<UserResponse> {
        return axios
            .post(process.env.REACT_APP_API_URL + "user/external", request)
            .then((response) => response.data);
    }

    registerNewUserCompany(request: NewUserCompanyCreationRequest): Promise<UserResponse> {
        return axios
            .post(process.env.REACT_APP_API_URL + "company/createUserCompany", request)
            .then((response) => response.data);
    }

    changePassword(request: UserChangePasswordRequest): Promise<void> {
        return axios
            .post(process.env.REACT_APP_API_URL + "user/change-password", request)
            .then((response) => response.data);
    }

    login = (email: string, password: string): Promise<{ access_token: string, refresh_token: string }> => {
        const formData = new FormData();
        formData.append('username', email);
        formData.append('password', password);

        return axios
            .post(process.env.REACT_APP_AUTH_SERVER_URL + "oauth/token?grant_type=password", formData, {
                auth: {username: 'self-service', password: ''}
            })
            .then((response) => {
                if (response.data.access_token && response.data.refresh_token) {
                    localStorage.setItem("access_token", response.data.access_token);
                    localStorage.setItem("refresh_token", response.data.refresh_token);
                }
                return response.data;
            });
    };

    signInWithAuthCode = (authCode: string): Promise<{ access_token: string, refresh_token: string }> => {
        return axios
            .post(process.env.REACT_APP_AUTH_SERVER_URL + "oauth/token?grant_type=authorization_code&code=" + authCode + "&client_id=self-service", {
                auth: {username: 'self-service', password: ''}
            })
            .then((response) => {
                if (response.data.access_token && response.data.refresh_token) {
                    localStorage.setItem("access_token", response.data.access_token);
                    localStorage.setItem("refresh_token", response.data.refresh_token);
                }
                return response.data;
            });
    };

    logout = () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        this.logoutRequest().then();
    };

    registerNewCompany(request: CompanyRequest): Promise<CompanyResponse> {
        return axios
            .post(process.env.REACT_APP_API_URL + "company", request)
            .then((response) => response.data);
    }

    editCompany(request: CompanyRequest, companyUuid: string) {
        return axios
            .patch(process.env.REACT_APP_API_URL + "company/" + companyUuid, request)
            .then((response) => response.data);
    }

    initRecoverPassword(email: string) {
        return axios
            .post(process.env.REACT_APP_AUTH_SERVER_URL + "api/user/recover-password?email=" + email)
            .then((response) => response.data);
    }

    resetPassword(request: ResetPasswordRequest) {
        return axios
            .post(process.env.REACT_APP_AUTH_SERVER_URL + "api/user/reset-password", request)
            .then((response) => response.data);
    }

    verifyAccount(token: string) {
        return axios
            .post(process.env.REACT_APP_API_URL + "user/external/verify-user?token=" + token)
            .then((response) => response.data);
    }

    logoutRequest() {
        return axios
            .post(process.env.REACT_APP_AUTH_SERVER_URL + "logout", null, {withCredentials: true});
    }

    refreshToken(formData: FormData) {
        return axios
            .post(process.env.REACT_APP_AUTH_SERVER_URL + "oauth/token?grant_type=refresh_token", formData, {
                auth: {username: 'self-service', password: ''}
            });
    }
}

export default new AuthService();

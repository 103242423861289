import React from "react";
import ConfirmationModal from "../../components/modals/ConfirmationModal";


const ProblemWithPaymentModal: React.FC<{
    handleClose: () => void;
    handleConfirmation: () => void;
    showModal: boolean;
    bodyText: string
}> = ({handleClose, handleConfirmation, showModal, bodyText}) => {

    return (
        <ConfirmationModal
            header="Problem with default payment method"
            body={bodyText}
            handleClose={() => handleClose()}
            handleConfirmation={() => handleConfirmation()}
            showModal={showModal}
            confirmationText={"Billing"}
        />
    );
};

export default ProblemWithPaymentModal;

export default interface AlphaAlert {
    type: string;
    body: string;
    id: number;
}

export enum AlertType {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    SUCCESS = "success",
    DANGER = "danger",
    WARNING = "warning",
    INFO = "info",
    LIGHT = "light",
    DARK = "dark"
}

import React from "react";
import {FieldErrors, useWatch} from "react-hook-form";
import NewInput from "./NewInput";
import NewTextArea from "./NewTextArea";
import {useClassifications} from "../store/ClassificationProvider";
import ProductCategoryDropdown from "./ProductCategoryDropdown";
import {ProductRequest} from "../types/product/add-new-products-dtos";
import ProductFileHandler from "./file-handler/ProductFileHandler";
import {getError} from "../utils/ValidationUtil";
import {ProductionType} from "../types/project/production-type";
import {AiMethod} from "../types/project/ai-method-type";

const NewProduct: React.FC<{
    myKey: string;
    name: string;
    index: number;
    isRemovable?: boolean;
    errors?: FieldErrors<ProductRequest>;
    remove: (index: number, productUuid?: string) => void;
    productUuid?: string;
}> = ({myKey, name, index, errors, remove, productUuid, isRemovable = true}) => {
    const {productTypes} = useClassifications();
    const aiMethod = useWatch({name: "aiMethod"})
    const productionType = useWatch({name: "productionType"})
    function isAiGenerated() {
        return productionType && productionType === ProductionType.AI_GENERATED;
    }

    function isAnything() {
        return isAiGenerated() && aiMethod === AiMethod.ANYTHING;
    }

    return (
        <>
            <div className="padded-separator">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <hr className="my-5"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row" key={myKey}>
                    <div className="col-md-5 offset-md-1">
                        <h3 className="mb-4">{isAnything() ? 'Asset' : 'Product'} #{index + 1}</h3>
                    </div>
                    <div className="col-md-5 text-end">
                        {isRemovable &&
                            <button onClick={() => remove(index, productUuid)} className="btn btn-outline-gray">
                                Remove {isAnything() ? 'asset' : 'product'}
                            </button>
                        }
                    </div>
                    <div className="col-md-5 offset-md-1">
                        <NewInput name={`${name}.brandName`} label={"Brand name"}
                                  errorMessage={errors?.brandName?.message}
                                  className={`${isAiGenerated() ? "d-none" : ""}`}/>
                        {aiMethod !== AiMethod.ANYTHING &&
                            <NewInput
                                name={`${name}.modelName`}
                                label={"Product model name"}
                                errorMessage={errors?.modelName?.message}
                            />}
                        <NewInput
                            name={`${name}.idNumber`}
                            label={"Product ID/SKU number"}
                            errorMessage={errors?.idNumber?.message}
                            className={`${isAiGenerated() ? "d-none" : ""}`}
                        />
                        <NewInput name={`${name}.url`} label={"Product URL"} errorMessage={errors?.url?.message}
                                  className={`${isAiGenerated() ? "d-none" : ""}`}/>
                        <div className="col-md-12">
                            {productionType === ProductionType.AI_GENERATED && aiMethod === AiMethod.ANYTHING &&
                                <NewInput
                                    name={`${name}.modelGenerationText`}
                                    label={"Please enter the text describing the 3D model you want to generate. The text should be short and clear."}
                                    errorMessage={errors?.modelGenerationText?.message}
                                />
                            }
                        </div>
                        {productTypes.length > 0 && aiMethod !== AiMethod.ANYTHING &&
                            <ProductCategoryDropdown
                                label={"Category"}
                                name={`${name}.category`}
                                categories={productTypes}
                                errorMessage={getError(errors?.category)}
                                requiredMethod={aiMethod}
                            />
                        }
                        <NewTextArea
                            className={`${isAiGenerated() ? "d-none" : ""}`}
                            name={`${name}.notes`}
                            label="Notes (dimensions, file size, polycount etc.)"
                            errorMessage={errors?.notes?.message}
                        />
                    </div>
                    <div className={aiMethod === AiMethod.ANYTHING ? 'col-md-5 align-self-end' : "col-md-5"}>
                        {aiMethod !== AiMethod.ANYTHING &&
                            <ProductFileHandler
                                name={name}
                                errorMessage={getError(errors?.attachmentUuids)}
                                webpEnabled={productionType === ProductionType.DESIGNER}
                            />
                        }
                        {aiMethod === AiMethod.ANYTHING &&
                            <ProductCategoryDropdown
                                label={"Category"}
                                name={`${name}.category`}
                                categories={productTypes}
                                errorMessage={getError(errors?.category)}
                                requiredMethod={aiMethod}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewProduct;

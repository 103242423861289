import React, {useEffect} from "react";
import {FieldErrors, UseFormResetField, useWatch} from "react-hook-form";
import NewInput from "../../NewInput";
import NewDropdown from "../../NewDropdown";
import RequestResult from "../../RequestResult";
import {useClassifications} from "../../../store/ClassificationProvider";
import CheckBox from "../../CheckBox";
import CompanyRequest from "../../../types/company/company-request";
import {getError} from "../../../utils/ValidationUtil";

const AdminCompanyModal: React.FC<{
    handleClose: () => void;
    handleSubmit: () => void;
    formError: string;
    successful: boolean;
    title: string;
    confirmationText: string;
    errors: FieldErrors<CompanyRequest> | undefined;
    isEditMode?: boolean;
    hasTrelloBoardId?: boolean;
    resetField: UseFormResetField<CompanyRequest>;
}> = ({
          handleClose,
          formError,
          successful,
          errors,
          isEditMode = false,
          hasTrelloBoardId = false,
          handleSubmit,
          title,
          confirmationText,
          resetField
      }) => {
    const {countries} = useClassifications();
    const isCompany = useWatch({name: "isCompany"});
    const isDesignerModelsService = useWatch({name: "services.designerModelsService"});

    useEffect(() => {
        if (!isDesignerModelsService && !hasTrelloBoardId) {
            resetField("trelloBoardId");
        }
    }, [isDesignerModelsService, hasTrelloBoardId, resetField]);

    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title ms-2" id="addNewCompanyModal">
                        {title}
                    </h4>
                    <button
                        onClick={handleClose}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body ms-2">
                    <div className="mb-3">
                        <label className="form-label">
                            Business type
                        </label>
                        <input disabled={true} value={isCompany ? "Business" : "Private"} type="text"
                               className="form-control form-control-lg" id="isCompany"/>
                    </div>
                    <NewInput name={"name"} errorMessage={errors?.name?.message} label="Company name"/>
                    <NewInput
                        disabled={(isEditMode && hasTrelloBoardId) || !isDesignerModelsService}
                        name={"trelloBoardId"}
                        errorMessage={errors?.trelloBoardId?.message}
                        label="Trello ID"
                    />
                    <NewDropdown
                        errorMessage={errors?.country?.message}
                        label={"Country"}
                        name={"country"}
                        values={countries?.map((element) => element.value)}
                    />
                    <NewInput
                        name={"registrationNumber"}
                        errorMessage={errors?.registrationNumber?.message}
                        label="Reg. nr"
                    />
                    <NewInput name={"vatNumber"} errorMessage={errors?.vatNumber?.message} label="VAT nr"/>
                    <NewInput name={"address"} errorMessage={errors?.address?.message} label="Address"/>
                    <NewInput
                        name={"billingEmail"}
                        errorMessage={errors?.billingEmail?.message}
                        label="Billing email"
                        disabled={!isCompany}
                    />
                    <CheckBox
                        name={"services.designerModelsService"}
                        errorMessage={getError(errors?.services)}
                        label={"Designer models"}
                    />
                    <CheckBox
                        name={"services.aiGeneratedModelsService"}
                        errorMessage={getError(errors?.services)}
                        label={"AI generated models"}
                    />
                    <CheckBox
                        name={"automaticInvoicing"}
                        errorMessage={getError(errors?.automaticInvoicing)}
                        label={"Automatic invoicing"}
                    />
                    <RequestResult successfulMessage="Company saved." successful={successful} error={formError}/>
                </div>
                <div className="modal-footer">
                    <button onClick={handleClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                        Cancel
                    </button>
                    <button onClick={handleSubmit} type="button" className="btn btn-primary">{confirmationText}</button>
                </div>
            </div>
        </>
    );
};

export default AdminCompanyModal;

import React, {useEffect, useState} from "react";
import AttachmentClient from "../../services/attachment.client";
import {base64FilesToJSFiles} from "../../utils/FileUtils";
import InnerImageZoom from "react-inner-image-zoom";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import {Image, ProductThumbnailsResponse, Thumbnail} from "../../types/attachment/attachment-response";
import ThumbnailCarousel from "../ThumbnailCarousel";
import LoadingComponent from "../common/LoadingComponent";

interface Attachment {
    image: Image,
    thumbnail: Thumbnail
}

const ProductImageView: React.FC<{
    productUuid?: string
}> = ({productUuid}) => {
    const [attachments, setAttachments] = useState<Attachment[]>();
    const [selectedImage, setSelectedImage] = useState<HTMLImageElement>();
    const [zoomEnabled, setZoomEnabled] = useState<boolean>(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
    const [thumbnails, setThumbnails] = useState<File[]>([]);

    useEffect(() => {
        if (!productUuid) return;
        AttachmentClient.getProductThumbnails(productUuid)
            .then((res) => {
                setThumbnails(base64FilesToJSFiles(res.thumbnails));
                return mapThumbnailsToAttachment(res);
            })
            .then((res) => {
                setAttachments(res);
            })
    }, [productUuid])

    function mapThumbnailsToAttachment(res: ProductThumbnailsResponse) {
        let attachmentsTemp: Attachment[] = []
        res.thumbnails.forEach((it) => {
            attachmentsTemp.push({thumbnail: it} as Attachment);
        })
        return attachmentsTemp;
    }

    useEffect(() => {
        if (!attachments || attachments.length < 1) return;
        if (!attachments[selectedImageIndex].image) {
            setSelectedImage(undefined);
            AttachmentClient.getAttachmentImage(attachments[selectedImageIndex].thumbnail.attachmentUuid)
                .then((res) => {
                    attachments[selectedImageIndex].image = res;
                    setAttachments(attachments);
                    return attachments[selectedImageIndex]
                })
                .then((res) => {
                    setSelectedImage(createImage(res.image));
                });
        } else {
            setSelectedImage(createImage(attachments[selectedImageIndex].image));
        }
    }, [selectedImageIndex, attachments]);

    const createImage = (image: Image): HTMLImageElement => {
        let img = document.createElement("img");
        img.src = "data:image/jpeg;base64," + image.base64;
        img.onload = function () {
            let imageContainer = document.getElementById("image-container");
            if (imageContainer) {
                setZoomEnabled(img.width > imageContainer.clientWidth || img.height > imageContainer.clientHeight);
            } else {
                setZoomEnabled(true);
            }
        }
        return img
    }

    const getImageComponent = () => {
        if (!attachments) return;
        if (selectedImage) {
            if (zoomEnabled) {
                return <InnerImageZoom
                    zoomType={"hover"}
                    src={selectedImage.src}
                    zoomSrc={selectedImage.src}/>
            } else {
                return <img
                    src={selectedImage.src}
                    alt={"product"}/>
            }
        }
        return <LoadingComponent/>
    }

    return <>
        <div id={"image-container"} className="gallery-preview mb-3">
            {getImageComponent()}
            {attachments &&
                <div className="index badge badge-white">
                    {selectedImageIndex + 1}/{attachments?.length}
                </div>
            }
        </div>
        {thumbnails &&
            <ThumbnailCarousel
                onClick={setSelectedImageIndex}
                thumbnails={thumbnails}
                selectedImageIndex={selectedImageIndex}/>
        }
    </>
}

export default ProductImageView;

import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import AuthService from "../services/auth.service";
import {FormProvider, SubmitHandler, useForm, useWatch} from "react-hook-form";
import NewInput from "../components/NewInput";
import {yupResolver} from "@hookform/resolvers/yup";
import {getError} from "../utils/ValidationUtil";
import CheckBox from "../components/CheckBox";
import {externalUserCreationValidation} from "../validation/internal-user-creation-validation";
import ExternalUserCreationRequest from "../types/user/external-user-creation-request";
import RequestResult from "../components/RequestResult";
import NewDropdown from "../components/NewDropdown";
import ClassificationClient from "../services/classification.client";
import {LoginState} from "../types/navigation";
import {ALERT_LIFETIME} from "../components/NewAlert";
import LoadingButton from "../components/product/LoadingButton";
import {useQuery} from "react-query";
import {getAnalytics, logEvent} from "firebase/analytics";
import googleLogo from "../assets/google-logo.png";

const SignupPage: React.FC = () => {
    const [formError, setFormError] = useState<string>("");
    const methods = useForm<ExternalUserCreationRequest>({
            defaultValues: {
                isCompany: false
            },
            resolver: yupResolver(externalUserCreationValidation),
        }
    );
    const isCompany = useWatch({control: methods.control, name: "isCompany"});
    const onSubmit: SubmitHandler<ExternalUserCreationRequest> = (data) => handleRegistration(data);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [isFormInit, setIsFormInit] = useState<boolean>(false);
    const {
        data: countries
    } = useQuery('countriesClassification', ClassificationClient.getCountriesClassifications);

    const analytics = getAnalytics();

    useEffect(() => {
        if (!isCompany) {
            methods.setValue("company", undefined);
        }

        if(!isFormInit && methods.formState.isDirty){
            logEvent(analytics, 'begin_sign_up', {
                method: "Alpha3d"
            });
            setIsFormInit(true);
        }
    }, [methods, isCompany, isFormInit, analytics, methods.formState.isDirty]);

    useEffect(function clearErrorAfterLifeTimeEnd() {
        const timeOut = setTimeout(() => {
            setFormError("");
        }, ALERT_LIFETIME);
        return () => {
            clearTimeout(timeOut)
        }
    }, [formError]);

    const handleRegistration = (formValue: ExternalUserCreationRequest) => {
        setLoading(true);
        AuthService.externalRegisterNewUser(formValue).then(
            (res) => {
                setFormError("");
                navigate(`/login`, {state: {message: "Account created. To access your account, verify your account with the link that was sent to your e-mail."} as LoginState});
                methods.reset();
                setLoading(false);

                logEvent(analytics, 'sign_up', {
                    method: "Alpha3d"
                });
            },
            (err) => {
                setLoading(false);
                setFormError(err.response.data.detail);
            }
        );
    };

    const handleSubmit = () => {
        methods.handleSubmit(onSubmit);
    }

    return (
        <main className="view-content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <FormProvider {...methods}>
                            <div className="card">
                                <div className="card-body p-5">
                                    <h1 className="text-center mb-2">Create account</h1>
                                    <div className="text-center mb-2"><a className="btn btn-block btn-light px-5 social-btn google" href={process.env.REACT_APP_AUTH_SERVER_URL + "oauth/authorize?grant_type=authorization_code&response_type=code&client_id=self-service"}>
                                        <img height={"30px"} src={googleLogo} alt="Google" /> Sign up with Google</a></div>
                                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                                        <NewInput
                                            name="email"
                                            label="Email"
                                            inputClassName="form-control-icon -email"
                                            errorMessage={methods.formState.errors?.email?.message}
                                        />
                                        <NewInput
                                            name="firstName"
                                            label="First name"
                                            inputClassName="form-control-icon -profile"
                                            errorMessage={methods.formState.errors?.firstName?.message}
                                        />
                                        <NewInput
                                            name="lastName"
                                            label="Last name"
                                            inputClassName="form-control-icon -profile"
                                            errorMessage={methods.formState.errors?.lastName?.message}
                                        />
                                        <NewInput
                                            name="password"
                                            label="Password"
                                            type="password"
                                            inputClassName="form-control-icon -password"
                                            errorMessage={methods.formState.errors?.password?.message}
                                        />
                                        <NewInput
                                            name="passwordAgain"
                                            label="Confirm Password"
                                            type="password"
                                            inputClassName="form-control-icon -password"
                                            errorMessage={methods.formState.errors?.passwordAgain?.message}
                                        />
                                        <NewInput
                                            name="referralSource"
                                            label="How did you find out about us?"
                                            inputClassName="form-control-icon -profile"
                                            maxLength={50}
                                        />
                                        <CheckBox
                                            name="isCompany"
                                            errorMessage={getError(methods.formState.errors?.isCompany)}
                                            label={"Sign up as a company"}
                                        />
                                        {isCompany &&
                                            <>
                                                <NewInput
                                                    name="company.name"
                                                    label="Company name"
                                                    inputClassName="form-control-icon -company"
                                                    errorMessage={methods.formState.errors?.company?.name?.message}/>
                                                <NewDropdown
                                                    name="company.country"
                                                    label="Country"
                                                    errorMessage={methods.formState.errors?.company?.country?.message}
                                                    values={countries?.map((element) => element.value)}
                                                />
                                                <NewInput
                                                    name="company.registrationNumber"
                                                    label="Reg. nr"
                                                    inputClassName="form-control-icon -hash"
                                                    errorMessage={methods.formState.errors?.company?.registrationNumber?.message}/>
                                                <NewInput
                                                    name="company.vatNumber"
                                                    label="VAT nr"
                                                    inputClassName="form-control-icon -hash"
                                                    errorMessage={methods.formState.errors?.company?.vatNumber?.message}/>
                                                <NewInput
                                                    name="company.address"
                                                    label="Address"
                                                    inputClassName="form-control-icon -location"
                                                    errorMessage={methods.formState.errors?.company?.address?.message}/>
                                                <NewInput
                                                    name="company.billingEmail"
                                                    label="Billing email"
                                                    inputClassName="form-control-icon -email-send"
                                                    errorMessage={methods.formState.errors?.company?.billingEmail?.message}/>
                                            </>}
                                        <div
                                            className="text-center d-flex justify-content-between pt-2 align-items-center">
                                            <Link to={"/login"}>
                                                Sign in
                                            </Link>
                                            <LoadingButton
                                                onClick={() => handleSubmit()}
                                                buttonText="Create Account"
                                                loading={loading}
                                                loadingTooltip={""}
                                                btnClassName={"btn-light px-4"}
                                                loaderVariant={"dark"}
                                            />
                                        </div>
                                        <div className="mt-2"><RequestResult successfulMessage="" successful={false}
                                                                             error={formError}/></div>

                                    </form>
                                </div>
                            </div>
                        </FormProvider>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default SignupPage;

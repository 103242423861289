import React from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useAuthContext} from "../../store/AuthProvider";
import {UserRole} from "../../types/user/user-role";

const ProductDetailsHeader: React.FC<{
    title: string;
    subTitle?: string;
    price?: number;
}> = ({title, subTitle, price}) => {
    const navigate = useNavigate();
    const {productUuid} = useParams();

    const [searchParams] = useSearchParams();
    const from = searchParams.get("from");
    const version = searchParams.get("version");
    const model = searchParams.get("model");
    const {hasRole} = useAuthContext();

    const getBackDestination = () => {
        let url = "/product/" + productUuid + "?";
        if (from) {
            url = "/admin/" + from + "/" + productUuid + "?";
        }
        if (version) {
            url = url + "version=" + version + "&";
        }
        if (model) {
            url = url + "model=" + model;
        }
        return url;
    }

    return (
        <>
            <header className="view-header">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-auto">
                            <div className={"title"}>
                                <div className="spacer"></div>
                                <div className="sub-title">
                                    <h6>{subTitle}</h6>
                                </div>
                            </div>
                        </div>
                        {price && !hasRole(UserRole.ROLE_COMPANY) &&
                            <div className="col-md-auto">
                                <div className="price">
                                    Total price
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-md-auto">
                            <div className="title">
                                <button className="btn btn-outline-gray btn-icon btn-sm me-3"
                                        onClick={() => navigate(getBackDestination())}>
                                    <i className="bi bi-caret-left-fill"></i>
                                </button>
                                <h1>{title}</h1>
                            </div>
                        </div>
                        {price && !hasRole(UserRole.ROLE_COMPANY) &&
                            <div className="col-md-auto fw-bold">
                                <div>{`${price?.toFixed(2).replace('.', ',')} €`}</div>
                            </div>
                        }
                    </div>
                    <hr />
                </div>
            </header>
        </>
    );
};

export default ProductDetailsHeader;

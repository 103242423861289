import React from "react";
import {DateUtil} from "../../utils/DateUtil";
import {Dropdown} from "react-bootstrap";
import {BadgeStatus, ProductStatus, VIEWABLE_PRODUCT_STATUSES} from "../../types/product/product-status";
import ProductStatusBadge from "../../components/product/ProductStatusBadge";
import {ProjectResponseProduct} from "../../types/project-response";
import {useNavigate} from "react-router-dom";

interface IProductCard {
    product: ProjectResponseProduct;
    index: number;
    isRestorable: boolean;
    deleteProduct: (productUuid: string) => void;
    restoreProduct: (productUuid: string) => void;
}

const ProductCard: React.FC<IProductCard> = ({product, index, deleteProduct, restoreProduct, isRestorable}) => {
    const navigate = useNavigate();
    const isProductViewable = (VIEWABLE_PRODUCT_STATUSES.find(status => status === product.status) !== undefined);

    const handleNavigateToProduct = () => {
        if (isProductViewable) {
            navigate(`/product/${product.uuid}`);
        }
    }

    const isProductDeletable = (product.status === ProductStatus.PENDING_QUOTE ||
        product.status === ProductStatus.DRAFT);

    const isDropdownVisible = (isProductViewable ||
        isProductDeletable ||
        (product.status === ProductStatus.DELETED && isRestorable));

    function getProductOverrideColor(product: ProjectResponseProduct) {
        return product.status === ProductStatus.READY_FOR_QC && product.requiresUserAction ? "badge-pink" : undefined;
    }

    return (
        <div key={index} className="col-3">
            <div className={`project ${product?.status === ProductStatus.APPROVED ? "-approved" : ""}`}
                 onClick={() => handleNavigateToProduct()}>
                <div className="actions" onClick={(e) => e.stopPropagation()}>
                    {isDropdownVisible &&
                        <Dropdown align={"end"}>
                            <Dropdown.Toggle variant="white" className="btn-icon" id={`projectCardDropdownButton-${index}`}>
                                <i className="bi bi-three-dots-vertical"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {isProductDeletable &&
                                    <Dropdown.Item onClick={() => deleteProduct(product.uuid)}>Delete</Dropdown.Item>
                                }
                                {isProductViewable &&
                                    <Dropdown.Item onClick={() => handleNavigateToProduct()}>View</Dropdown.Item>
                                }
                                {product.status === ProductStatus.DELETED && isRestorable &&
                                    <Dropdown.Item onClick={() => restoreProduct(product.uuid)}>Restore</Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>
                <img
                    className="cover"
                    src={`data:image/jpeg;base64,${product?.thumbnail}`}
                    alt="project thumbnail"/>
                <div className="d-flex">
                    <ProductStatusBadge className={"status"} productStatus={product?.status}
                                        overrideColor={getProductOverrideColor(product)} badgeExtraStatus={product.downloadUnlocked? BadgeStatus.SHAREABLE: undefined}/>
                </div>
                {product?.idNumber?.length > 0 && <div className="id">{"#" + product?.idNumber}</div>}
                <div className={`title ${(product.price || product.productionTime) && "mb-0"}`}>
                    {product?.brandName?.length > 0 && `${product.brandName} · `}{product.modelName}
                </div>
                {(product.price || product.productionTime) &&
                    priceInformation(product)
                }
                <div className="date">
                    {DateUtil.getDateOnlyShort(product.creationDate)}
                    <span className="mx-1">·</span>
                    {DateUtil.getTimeOnly(product.creationDate)}
                </div>
            </div>
        </div>
    );
};

const priceInformation = (product: ProjectResponseProduct) => {
    return <>
        <hr className="col-12"/>
        <div className="container">
            <div className="row justify-content-between">
                <div className={"col px-0 text-start"}>
                    {product.price &&
                        <>
                            <div className={"date"}>Quoted price</div>
                            <div>{`${product.price.toFixed(2).replace('.', ',')} €`}</div>
                        </>
                    }
                </div>
                <div className={"col px-0 text-end"}>
                    {product.productionTime &&
                        <>
                            <div className={"date"}>Production time</div>
                            <div>{`${product.productionTime} days`}</div>
                        </>
                    }
                </div>
            </div>
        </div>
        <hr className="col-12"/>
    </>;
}

export default ProductCard;

export class DateUtil {
    static getDate(dateString: string): string {
        const date = new Date(dateString);
        return (
            "Created on " +
            date.toLocaleDateString("et-EE", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "numeric",
                hour12: true,
                minute: "2-digit",
            })
        );
    }

    static getDateWithTime(dateString: string): string {
        const date = new Date(dateString);
        return (
            date.toLocaleDateString("et-EE", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit",
                second: "2-digit"
            })
        );
    }

    static getDateOnly(dateString: string): string {
        const date = new Date(dateString);
        return (
            date.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "long",
                year: "numeric"
            })
        );
    }

    static getDateOnlyShort(dateString: string): string {
        const date = new Date(dateString);
        return (
            date.toLocaleDateString("et-EE", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
            })
        );
    }

    static getTimeOnly(dateString: string): string {
        const date = new Date(dateString);
        return (
            date.toLocaleTimeString("en-GB", {
                hour: "numeric",
                hour12: true,
                minute: "2-digit",
            })
        );
    }

    static getTimeAndTimezone(dateString: string): string {
        const date = new Date(dateString);
        return (
            date.toLocaleDateString("en-GB", {
                hour: "numeric",
                hour12: true,
                minute: "2-digit",
                timeZoneName: "short"
            })
        );
    }
}

import axios from "axios";
import {AlphaPayment} from "../types/payment-price";

export class PaymentTokenClient {
    getTokenPrices = (): Promise<Map<AlphaPayment, number>> => {
        return axios
            .get(process.env.REACT_APP_API_URL + "payment_token/prices")
            .then((res) => {
                const resultMap = new Map<AlphaPayment, number>();

                Object.entries(res.data).forEach(price => resultMap.set(AlphaPayment[price[0] as keyof typeof AlphaPayment], price[1] as number));
                return resultMap;
            });
    };

    getCompanyPaymentTokenAmount = (): Promise<number> => {
        return axios
            .get(process.env.REACT_APP_API_URL + "payment_token")
            .then((res) => {
                return res.data;
            });
    };
}

export default new PaymentTokenClient();

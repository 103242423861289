import React, {useEffect, useState} from "react";
import SubscriptionClient from "../../services/subscription.client";
import {SubscriptionLevel} from "../../types/subscription-info";
import {useAlerts} from "../../store/AlertProvider";
import LoadingButton from "../product/LoadingButton";
import {getPriceInUsd} from "../../utils/TextUtil";
import {Modal} from "react-bootstrap";
import {redirectToStripe} from "../../utils/PayementUtil";
import {ReactComponent as IconTickSquare} from "../../assets/icons/icon-tick-square.svg";
import {usePaymentToken} from "../../store/PaymentTokenProvider";


const CreateSubscriptionModal: React.FC<{
    showModal: boolean;
    handleClose: () => void;
}> = ({showModal = false, handleClose}) => {
    const {addErrorResponseAlert} = useAlerts();
    const [subscriptionLevels, setSubscriptionLevels] = useState<SubscriptionLevel[]>([]);
    const [selectedSub, setSelectedSub] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {refetchTokenAmount} = usePaymentToken();
    const {addErrorAlert, addSuccessAlert} = useAlerts();

    useEffect(() => {
        SubscriptionClient.getAvailableSubscriptions()
            .then((subscriptions: SubscriptionLevel[]) => {
                setSubscriptionLevels(subscriptions);
            });
    }, []);


    const handleActivate = () => {
        setIsLoading(true);
        SubscriptionClient.subscribe(selectedSub)
            .then(() => {
                handleClose();
                setIsLoading(false);
                addSuccessAlert("Subscription successfully created.");

                setTimeout(refetchTokenAmount, 5000);
            })
            .catch(reason => {
                addErrorAlert(reason.response.data.detail);
                setIsLoading(false);
            });
    }

    const handleSelect = (subscriptionName: string) => {
        setSelectedSub(subscriptionName);
    }

    return (
        <Modal size="lg" show={showModal} className="modal fade" id="companyEditModal">
            <div className="modal-header">
                <h4 className="modal-title ms-2" id="addNewUserModal">
                    Choose subscription
                </h4>
                <button
                    onClick={handleClose}
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                />
            </div>
            <div className="modal-body">
                <div className="container">
                    <div className="row mb-2">
                        <div className="col-sm-1 px-2"></div>
                        <div className="col-sm-4 px-2 text-uppercase fs-xs ff-headings">Subscription name</div>
                        <div className="col-sm px-2 text-uppercase fs-xs ff-headings">Token amount</div>
                        <div className="col-sm px-2 text-uppercase fs-xs ff-headings">Price</div>
                        <div className="col-sm px-2 text-uppercase fs-xs ff-headings">Subscription period</div>
                    </div>

                    {subscriptionLevels.map((subscription) => {
                        return <div className="row mb-2 border py-2 rounded-2" key={subscription.subscriptionName}>
                            <div className="col-sm-1 px-2 text-center">
                                <input checked={selectedSub === subscription.subscriptionName}
                                    onChange={() => handleSelect(subscription.subscriptionName)}
                                    name="subscribeRadio" type={"radio"} className="form-check-input" />
                            </div>
                            <div className="col-sm-4 px-2 fs-sm">{subscription.subscriptionName}</div>
                            <div className="col-sm px-2 fs-sm">{subscription.tokenAmount}</div>
                            <div className="col-sm px-2 fs-sm">{getPriceInUsd(subscription.price)}</div>
                            <div className="col-sm px-2 fs-sm">{subscription.period.toLowerCase()}</div>
                        </div>;
                    })}
                </div>
            </div>
            <div className="modal-footer">
                <button onClick={handleClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                </button>
                <button type="button" onClick={() => redirectToStripe(addErrorResponseAlert)}
                        className="btn btn-secondary">
                    Billing
                </button>
                <LoadingButton onClick={handleActivate} loading={isLoading} disabled={selectedSub.length === 0}
                               buttonText="Activate" Icon={IconTickSquare}/>
            </div>
        </Modal>
    );
};

export default CreateSubscriptionModal;

import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {AttachmentInfo} from "../types/attachment/attachment-response";
import {useLocation} from "react-router-dom";

interface AttachmentUploadTask {
    attachment: AttachmentInfo;
    onUpload: (attachment: AttachmentInfo) => Promise<void>;
}

interface UploadQueueContextProps {
    enqueueUpload: (attachment: AttachmentInfo, onUpload: (attachment: AttachmentInfo) => Promise<void>) => void;
}

const UploadQueueContext = createContext<UploadQueueContextProps | null>(null);

export const UploadQueueProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const MAX_CONCURRENT_UPLOADS = 3;
    const [queue, setQueue] = useState<AttachmentUploadTask[]>([]);
    const [currentUploads, setCurrentUploads] = useState<number>(0);
    const location = useLocation();

    const enqueueUpload = useCallback((attachment: AttachmentInfo, onUpload: (attachment: AttachmentInfo) => Promise<void>) => {
        setQueue(prevQueue => {
            const isAlreadyInQueue = prevQueue.some(task => task.attachment.referenceUuid === attachment.referenceUuid);
            if (!isAlreadyInQueue) {
                return [...prevQueue, {attachment, onUpload}];
            } else {
                return prevQueue;
            }
        });
    }, []);


    useEffect(function clearTheQueueWhenUrlChanges() {
        setQueue([]);
    }, [location]);

    useEffect(() => {
        if (currentUploads < MAX_CONCURRENT_UPLOADS && queue.length > 0) {
            const currentTask = queue.shift();
            if (currentTask) {
                setCurrentUploads(c => c + 1);
                currentTask.onUpload(currentTask.attachment)
                    .finally(() => {
                        setCurrentUploads(c => c - 1);
                        setQueue(currentQueue => currentQueue.filter(task => task !== currentTask));
                    });
            }
        }
    }, [queue, currentUploads]);


    const contextValue = useMemo(() => {
        return {enqueueUpload};
    }, [enqueueUpload]);

    return (
        <UploadQueueContext.Provider value={contextValue}>
            {children}
        </UploadQueueContext.Provider>
    );
};

export const useUploadQueue = () => {
    const context = useContext(UploadQueueContext);

    if (!context) {
        throw new Error('useUploadQueue must be used within a UploadQueueProvider');
    }

    return context;
};

import React from "react";
import {PROJECT_STATUS_TRANSLATIONS, ProjectStatus} from "../../types/project/project-status";

interface IProjectStatusBadge {
    status?: ProjectStatus;
    className?: string;
    overrideColor?: string;
}

const ProjectStatusBadge: React.FC<IProjectStatusBadge> = ({status, className, overrideColor}) => {

    const BADGE_COLOR: { [key in ProjectStatus]: string } = {
        [ProjectStatus.DRAFT]: "badge-white",
        [ProjectStatus.PENDING_QUOTES]: "badge-white",
        [ProjectStatus.IN_PRODUCTION]: "badge-black",
        [ProjectStatus.COMPLETED]: "badge-green",
        [ProjectStatus.DELETED]: "badge-white",

        [ProjectStatus.ERROR]: "badge-red",
    };

    return (
        <React.Fragment>
            {status &&
                <span className={`badge ${overrideColor ? overrideColor : BADGE_COLOR[status]} ${className}`}>
                    {`${PROJECT_STATUS_TRANSLATIONS[status]}`}
                </span>
            }
        </React.Fragment>
    );
}

export default ProjectStatusBadge;

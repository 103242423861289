import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import CompanyResponse from "../../types/company/company-response";
import AdminAction from "./AdminAction";
import AdminList from "./AdminList";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";

import CompanyRequest from "../../types/company/company-request";
import {companyCreationValidation} from "../../validation/company-creation-validation";
import AuthService from "../../services/auth.service";
import AdminCompanyModal from "./modals/AdminCompanyModal";
import {ALERT_LIFETIME} from "../NewAlert";
import ConfirmationModal from "../modals/ConfirmationModal";
import {useAlerts} from "../../store/AlertProvider";
import {useClassifications} from "../../store/ClassificationProvider";
import AdminGenerateReportModal from "./modals/AdminGenerateReportModal";
import GenerateReportRequest from "../../types/company/generate-report-request";
import {generateReportValidation} from "../../validation/generate-report-validation";
import companyClient from "../../services/company.client";
import SubscriptionClient from "../../services/subscription.client";
import {useQueryClient} from "react-query";
import PageableCompanyResponse from "../../types/user/pageable-company-response";


const CompanyDashBoard: React.FC<{
    pageableCompanyResponse?: PageableCompanyResponse;
    setCompanyPageNumber?: (page: number) => void
    setCompanySearchName?: (fullName: string) => void
    companySearchName?: string
}> = ({pageableCompanyResponse, setCompanyPageNumber, setCompanySearchName, companySearchName}) => {
    const [showCompanyCreation, setShowCompanyCreation] = useState(false);
    const [showCompanyEdit, setShowCompanyEdit] = useState(false);
    const [showGenerateReportEdit, setShowGenerateReportEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [formError, setFormError] = useState<string>("");
    const [successful, setSuccessful] = useState<boolean>(false);
    const [companyUuid, setCompanyUuid] = useState<string | null>(null);
    const [isEditAiService, setIsEditAiService] = useState(false);
    const {reFetchProductionTypes} = useClassifications();
    const queryClient = useQueryClient();
    const methods = useForm<CompanyRequest>({
        defaultValues: {
            isCompany: true,
            automaticInvoicing: true,
            vatNumber: undefined,
        },
        resolver: yupResolver(companyCreationValidation)
    });
    const reportMethods = useForm<GenerateReportRequest>({
        resolver: yupResolver(generateReportValidation)
    });
    const {addSuccessAlert, addErrorAlert} = useAlerts();

    const [hasTrelloBoardId, setHasTrelloBoardId] = useState<boolean>(false);

    const handleCreateCompanyClose = () => {
        setShowCompanyCreation(false);
        setSuccessful(false);
        methods.reset();
    };
    const handleShowCompany = () => setShowCompanyCreation(true);

    const handleEditCompanyClose = () => {
        setShowCompanyCreation(false);
        setSuccessful(false);
        setShowCompanyEdit(false);
        setCompanyUuid(null);
        methods.reset();
    };

    const onCreateSubmit: SubmitHandler<CompanyRequest> = (data) => handleNewCompany(data);
    const onEditSubmit: SubmitHandler<CompanyRequest> = (data) => handleEditCompany(data);
    const onGenerateReportSubmit: SubmitHandler<GenerateReportRequest> = (data) => handleGenerateReport(data);

    useEffect(function clearErrorAfterLifeTimeEnd() {
        const timeOut = setTimeout(() => {
            setSuccessful(false);
            setFormError("");
        }, ALERT_LIFETIME);
        return () => {
            clearTimeout(timeOut)
        }
    }, [formError]);

    const handleNewCompany = (formValue: CompanyRequest) => {
        setFormError("");
        setSuccessful(false);

        AuthService.registerNewCompany(formValue).then(
            (res) => {
                setSuccessful(true);
                methods.reset();
                queryClient.invalidateQueries('getAllCompanies').then();
            },
            (err) => {
                setFormError(err.response.data.detail);
            }
        );
    };

    const cancelSubscription = (formValue: CompanyRequest, companyUuid: string) => {
        if (isEditAiService && !formValue.services.aiGeneratedModelsService) {
            SubscriptionClient.cancelSubscription(companyUuid)
                .then(() => {
                    addSuccessAlert("Customer's subscription cancelled in Stripe successfully.");
                }, (err) => {
                    addErrorAlert(err.response.data.detail);
                });
        }
    }

    const handleEditCompany = (formValue: CompanyRequest) => {
        setFormError("");
        setSuccessful(false);
        if (!companyUuid) return;

        setLoading(true);
        AuthService.editCompany(formValue, companyUuid)
            .then((res) => {
                addSuccessAlert("Company updated successfully");
                queryClient.invalidateQueries('getAllCompanies').then();
                setShowCompanyEdit(false);
                methods.reset();
                reFetchProductionTypes();
                cancelSubscription(formValue, companyUuid);
            })
            .catch((err) => {
                setFormError(err.response.data.detail);
            })
            .finally(() => {
                setLoading(false);
                setShowConfirmation(false);
            });
    };

    const handleOpenEdit = (company: CompanyResponse) => {
        setFormError("");

        methods.setValue("name", company.name);
        methods.setValue("trelloBoardId", company.trelloBoardId);
        methods.setValue("country", company.country);
        methods.setValue("registrationNumber", company.registrationNumber);
        methods.setValue("vatNumber", company.vatNumber);
        methods.setValue("address", company.address);
        methods.setValue("billingEmail", company.billingEmail);
        methods.setValue("services", company.services);
        methods.setValue("automaticInvoicing", company.automaticInvoicing);
        methods.setValue("isCompany", company.isCompany);

        setHasTrelloBoardId(company.trelloBoardId?.length > 0);
        setCompanyUuid(company.uuid);
        setShowCompanyEdit(true);
        setIsEditAiService(company.services.aiGeneratedModelsService);
    };

    const handleOpenGenerateReport = (company: CompanyResponse) => {
        setFormError("");
        setCompanyUuid(company.uuid);
        reportMethods.setValue("name", company.name);
        setShowGenerateReportEdit(true);
    };

    const handleCloseGenerateReport = () => {
        setShowGenerateReportEdit(false);
        setLoading(false);
        reportMethods.reset();
    }

    const handleGenerateReport = (formValue: GenerateReportRequest) => {
        setFormError("");
        setSuccessful(false);
        if (!companyUuid) return;

        setLoading(true);
        companyClient.generateInvoiceReport(companyUuid, formValue.startDate, formValue.endDate, formValue.name)
            .then(() => {
                reportMethods.reset();
            })
            .catch((_err) => {
                addErrorAlert("Error while generating report.");
            })
            .finally(() => {
                setShowGenerateReportEdit(false);
                setLoading(false);
            });
    };

    const handleShowConfirmation = () => {
        methods.trigger()
            .then(validationSuccessful => {
                if (validationSuccessful) {
                    setShowConfirmation(true);
                }
            })
    }

    return (
        <>
            <main className="view-content">
                <AdminAction text="Add new company" handleShow={handleShowCompany} searchEnabled={true}
                             searchQuery={companySearchName} setSearchQuery={setCompanySearchName} />
                <AdminList pageableCompanyResponse={pageableCompanyResponse} editCompany={handleOpenEdit}
                           generateReport={handleOpenGenerateReport} setCompanyPageNumber={setCompanyPageNumber}/>
            </main>

            <FormProvider {...methods}>
                <Modal show={showCompanyCreation} className="modal fade" id="addNewCompanyModal">
                    <form>
                        <AdminCompanyModal
                            handleSubmit={methods.handleSubmit(onCreateSubmit)}
                            formError={formError}
                            successful={successful}
                            handleClose={handleCreateCompanyClose}
                            errors={methods.formState.errors}
                            title={"Add new company"}
                            confirmationText={"Add new company"}
                            resetField={methods.resetField}
                        />
                    </form>
                </Modal>
            </FormProvider>
            <FormProvider {...methods}>
                <Modal show={showCompanyEdit} className="modal fade" id="companyEditModal">
                    <form>
                        <AdminCompanyModal
                            handleSubmit={() => handleShowConfirmation()}
                            formError={formError}
                            successful={successful}
                            handleClose={handleEditCompanyClose}
                            errors={methods.formState.errors}
                            title={"Edit company"}
                            confirmationText={"Edit company"}
                            isEditMode
                            hasTrelloBoardId={hasTrelloBoardId}
                            resetField={methods.resetField}
                        />
                    </form>
                </Modal>
            </FormProvider>
            <FormProvider {...reportMethods}>
                <Modal show={showGenerateReportEdit} className="modal fade" id="companyGenerateReportModal">
                    <form>
                        <AdminGenerateReportModal
                            handleSubmit={reportMethods.handleSubmit(onGenerateReportSubmit)}
                            formError={formError}
                            successful={successful}
                            handleClose={handleCloseGenerateReport}
                            errors={reportMethods.formState.errors}
                            loading={loading}
                        />
                    </form>
                </Modal>
            </FormProvider>
            <ConfirmationModal
                id={"confirmEditCompanyModal"}
                handleClose={() => setShowConfirmation(false)}
                handleConfirmation={methods.handleSubmit(onEditSubmit)}
                header={"Edit company"}
                body={"Are you sure you want to edit company data? \n" +
                    "Stripe customer will be updated."}
                loading={loading}
                showModal={showConfirmation}
                onTop
            />
        </>
    );
};

export default CompanyDashBoard;

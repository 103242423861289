import React, {useEffect, useState} from "react";
import iconDownload from "../../assets/icon-download.svg";

const ImageThumbnail: React.FC<{
    image: File;
    remove?: (index: number) => void;
    download?: (index: number) => void;
    onClick?: () => void;
    index: number;
    selected?: boolean;
    className?: string;
}> = ({image, remove, index, onClick, selected, className, download}) => {
    const [imageBase64, setImageBase64] = useState<string | null>(null);

    useEffect(() => {
        base64(image).then(setImageBase64);
    }, [image]);

    if (!imageBase64) {
        return null;
    }

    const handleRemove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        remove!(index);
    }

    const handleDownload = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        download!(index);
    }

    return (
        <div
            className={`thumbnail -has-content ${className ? className : ""}${selected ? " -selected" : ""}`}
            onClick={onClick}>
            <div className="actions">
                {download &&
                    <button type="button" onClick={handleDownload}
                            className={`btn btn-white shadow d-inline-block ${(remove) ? "me-2" : ""}`}>
                        <img src={iconDownload} alt="" className={"p-1"}/>
                    </button>
                }
                {remove &&
                    <button type="button" onClick={handleRemove} className={`btn btn-white shadow d-inline-block`}>
                        <i className="bi bi-x-lg"/>
                    </button>
                }
            </div>
            <img src={imageBase64} alt={image.name}/>
        </div>
    );
};

const base64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
};

export default ImageThumbnail;

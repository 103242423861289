import React, {useState} from 'react';
import AccountHeader from "../components/account-settings/AccountHeader";
import PageFooter from "../components/account-settings/PageFooter";
import {useNavigate} from "react-router-dom";
import LoadingButton from "../components/product/LoadingButton";
import AccountForm from "../components/account-settings/AccountForm";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {userChangePasswordRequestValidation} from "../validation/user-change-password-request-validation";
import AuthService from "../services/auth.service";
import UserChangePassword from "../types/user/user-change-password";
import {useQuery} from "react-query";
import UserClient from "../services/user.client";
import LoadingComponent from "../components/common/LoadingComponent";
import AccountSettings from "../types/user/account-settings";

const AccountPage = () => {
    const [isSuccessful, setIsSuccesful] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formError, setFormError] = useState<string>("");
    const navigate = useNavigate();

    const updateMethods = useForm<UserChangePassword>({
        resolver: yupResolver(userChangePasswordRequestValidation)
    });

    const onUpdatePasswordSubmit: SubmitHandler<UserChangePassword> = (data) => handleUpdatePassword(data);

    const handleBackButtonClicked = () => {
        const hasHistory = window.history.state && window.history.state.idx > 0;
        if (hasHistory) {
            navigate(-1);
        } else {
            navigate("/");
        }

    }

    const handleUpdatePassword = (formValue: UserChangePassword) => {
        setIsLoading(true);
        AuthService.changePassword({
            oldPassword: formValue.oldPassword,
            newPassword: formValue.newPassword
        }).then(() => {
            setIsSuccesful(true);
            setIsLoading(false);
            setFormError("");
        }, (err) => {
            setIsLoading(false);
            setFormError(err.response.data.detail);
        })
    }

    const {
        data: accountSettings,
        isLoading: isAccountSettingsLoading
    } = useQuery<AccountSettings>('getAccountSettings', UserClient.getAccountSettings, {
        onSuccess: data => {
            updateMethods.setValue("hasOldPassword", data.hasOldPassword);
        }
    });

    return (
        <>{isAccountSettingsLoading ?
            <div className="view-content d-flex flex-column justify-content-center">
                <div className="text-center align-content-center "><LoadingComponent/></div>

            </div>
            :
            <>
                <AccountHeader
                    onUpdate={updateMethods.handleSubmit(onUpdatePasswordSubmit)}
                    onCancel={handleBackButtonClicked}
                    title={"Account settings"}
                    isLoading={isLoading}
                />
                <AccountForm
                    updateMethods={updateMethods}
                    isSuccessful={isSuccessful}
                    errorMessage={formError}
                    hasOldPassword={accountSettings!.hasOldPassword}
                />
                <PageFooter onCancel={handleBackButtonClicked}>
                    <LoadingButton
                        buttonText="Update"
                        btnClassName={"btn-primary"}
                        loaderVariant={"light"}
                        onClick={updateMethods.handleSubmit(onUpdatePasswordSubmit)}
                        loading={isLoading}
                    />
                </PageFooter>
            </>}
        </>

    );
};

export default AccountPage;

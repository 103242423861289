import React, {useState} from "react";
import LoadingButton from "../product/LoadingButton";

const GenerateImage: React.FC<{
    hide?: boolean;
    imageUrls?: Array<string>;
    handleClose: () => void;
    selectImageHandler: (selectedImage: string) => void;
    confirmationText?: string;
    confirmationHandler: () => void;
    closeModalText?: string;
    loading?: boolean;

}> = ({
          hide,
          imageUrls,
          selectImageHandler,
          confirmationHandler,
          confirmationText = "Select",
          closeModalText = "Cancel",
          loading = false

      }) => {


    const [selectedImage, setSelectedImage] = useState("");

    const onSelectImageHandler = (imageUrl: string) => {
        setSelectedImage(imageUrl);
    }
    return (
        <div style={{display: hide ? 'none' : 'block'}}>
            <div className="row row-cols-1 row-cols-md-2 g-5">
                {imageUrls && imageUrls.map((imageUrl, index) => {
                    let isSelected = imageUrl === selectedImage;
                    return (
                        <div className="col" key={index}>
                            <div className="card">
                                <img className={`w-100 card-img-top card-img-bottom ${isSelected ? "selected" : ""}`}
                                     onClick={() => onSelectImageHandler(imageUrl)} src={imageUrl} alt={"Select"}/>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <br/><br/>
            <br/><br/>

            <div className="modal-footer mt-n5">
                <button
                    onClick={confirmationHandler}
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                >
                    {closeModalText}</button>

                <LoadingButton
                    onClick={() => selectImageHandler(selectedImage)}
                    loading={loading}
                    loadingTooltip={""}
                    buttonText={confirmationText}/>
            </div>


        </div>
    );
};

export default GenerateImage;

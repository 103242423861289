import LoadingButton from "../product/LoadingButton";

const GenerateImage: React.FC<{
    hide?: boolean;
    onPromptChangeHandler: (e: any) => void;
    generateImageHandler: () => void;
    handleClose: () => void;
    confirmationText?: string;
    closeModalText?: string;
    limit?: string;
    loading?: boolean;
}> = ({
          hide,
          onPromptChangeHandler,
          generateImageHandler,
          handleClose,
          limit,
          closeModalText = "Cancel",
          confirmationText = "Import",
          loading = false,

      }) => {

    const closeHandler = () => {
        handleClose();
    }
    return (

        <div style={{display: hide ? 'none' : 'block'}}>
            <div className="input-group">
                        <textarea className="form-control" onChange={onPromptChangeHandler}
                                  placeholder="Please enter the text"></textarea>
            </div>
            <br/><br/>
            {limit}
            <br/><br/>

            <div className="modal-footer mt-n5">

                <button
                    onClick={closeHandler}
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                >
                    {closeModalText}</button>

                <LoadingButton
                    onClick={generateImageHandler}
                    loading={loading}
                    loadingTooltip={""}
                    buttonText={confirmationText}/>
            </div>

        </div>
    );
};

export default GenerateImage;


import React from "react";

const IconProvider: React.FC<{
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>,
    size: number,
    className?: string
}> = ({
          Icon,
          size,
          className = ''
      }) => {

    return (
        <Icon className={className} height={size} width={size}/>
    );
};

export default IconProvider;

import React from "react";
import InputError from "../InputError";
import threeDeeModel from "../../assets/icon-3d-model.svg";
import {truncate} from "../../utils/TextUtil";
import {InternalFileInputActions} from "../../utils/FileUtils";


const ModelFileInput: React.FC<{
    name: string;
    errorMessage?: string;
}> = ({name, errorMessage}) => {
    const {getValues, values, getRootProps, getInputProps, remove} = InternalFileInputActions(name);

    return (
        <>
            <div className="file-upload">
                <label className="w-100" htmlFor={name}>
                    <div {...getRootProps()} className="droparea text-center">
                        <img className="icon" src={threeDeeModel} width="48" height="48" alt=""/>
                        <div className="title">
                            Click <span className="text-decoration-underline">here</span> to upload a 3D model
                        </div>
                        <input hidden {...getInputProps()} />
                        <div className="subtitle">Please drag and drop here your usd, glb or gltf files</div>
                        <InputError errorMessage={errorMessage}></InputError>
                    </div>
                </label>
            </div>

            <div className="container ps-2">
                {Array.isArray(getValues(name)) && values.length > 0 &&
                    values.map((file, index) => {
                        return (
                            <div key={index} className="mt-1 text-success row">
                                <div className="col">
                                    {truncate(file.name, 25)}
                                </div>
                                <div onClick={() => remove(index)}
                                     className="btn-xs btn btn-white me-2 float-end col-1">
                                    <i className="bi bi-x-lg"/>
                                </div>

                            </div>
                        );
                    })}
            </div>
        </>

    );
};

export default ModelFileInput;

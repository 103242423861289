import React, {useEffect, useState} from "react";
import AlphaAlert from "../types/alpha-alert";
import {Alert, Fade} from "react-bootstrap";

export const ALERT_LIFETIME: number = 5000;

export const NewAlert: React.FC<{
    alert: AlphaAlert;
}> = ({alert}) => {

    const [show, setShow] = useState<boolean>(true);

    useEffect(() => {
        setTimeout(() => setShow(false), ALERT_LIFETIME);
    });

    const onClose = (() => {
        setShow(false)
    });

    return (
        <Alert show={show} variant={alert.type} onClose={() => onClose()} dismissible transition={Fade}>
            <i className="bi bi-exclamation-circle-fill me-3"></i> {alert.body}
        </Alert>);
}

export default NewAlert;

import React from "react";
import {useFormContext} from "react-hook-form";
import InputError from "./InputError";

const NewTextArea: React.FC<{
    name: string;
    label: string;
    errorMessage?: string;
    className?: string;
}> = ({name, label, errorMessage, className}) => {
    const {register} = useFormContext();

    return (
        <div className={`mb-3 ${className ? className : ""}`}>
            <label htmlFor={name} className={"form-label"}>{label}</label>
            <textarea className="form-control form-control-lg" rows={4} {...register(name)} />
            <InputError errorMessage={errorMessage}/>
        </div>
    );
};

export default NewTextArea;

import React, {useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";

import ProjectClient from "../../services/project.client";
import ProjectRequest from "../../types/project/project-request";
import {useNavigate, useParams} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup";
import {newProjectValidationSchema} from "../../validation/new-project-validation";
import NewProjectFooter from "../../components/new-project/project/NewProjectFooter";
import NewProjectHeader from "../../components/new-project/project/NewProjectHeader";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import NewProjectForm from "../../components/new-project/NewProjectForm";
import {useAlerts} from "../../store/AlertProvider";
import AttachmentClient from "../../services/attachment.client";
import {formatErrorResponse} from "../../services/product.client";
import LoadingModal, {LoadingModalEffects} from "../../components/modals/LoadingModal";
import {AxiosResponse} from "axios";
import NewProjectSaveResponse from "../../types/project/new-project-save-response";
import {ProductRequest} from "../../types/product/add-new-products-dtos";
import {ProjectStatus} from "../../types/project/project-status";
import {checkIfAnyFileUploading} from "../../utils/SharedUseEffects";
import {createProjectRequest} from "../../utils/ValidationUtil";
import {
    getProjectConfirmationBody,
    getProjectConfirmationHeader,
    getProjectStatusForProductionType
} from "../../utils/ProjectUtils";
import {ProductionType} from "../../types/project/production-type";
import {usePaymentToken} from "../../store/PaymentTokenProvider";
import {AlphaPayment} from "../../types/payment-price";
import GetMoreTokensModal from "../../components/modals/GetMoreTokensModal";
import {SubscriptionInfo} from "../../types/subscription-info";
import {useQuery} from "react-query";
import SubscriptionClient from "../../services/subscription.client";
import CreateSubscriptionModal from "../../components/modals/CreateSubscriptionModal";
import {redirectToStripe} from "../../utils/PayementUtil";

const SENDING_QUOTE = "Sending draft for quote";
const SAVING_DRAFT = "Saving draft";
const SAVING_PROJECT_INFO = "Saving project info";
const SAVING_DRAFT_INFO = "Saving draft info";

const EditDraftPage: React.FC = () => {
    const navigate = useNavigate();
    const saveRequestMethods = useForm<ProjectRequest>({
        resolver: yupResolver(newProjectValidationSchema)
    });

    const onValidate: SubmitHandler<ProjectRequest> = () => {
        setShowConfirmationModal(true);
    };
    const onDraft: SubmitHandler<ProjectRequest> = (data) => sendForDraft(data);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [showOutOfTokensModal, setShowOutOfTokensModal] = useState<boolean>(false);
    const [showCreateSubModal, setShowCreateSubModal] = useState<boolean>(false);
    const {addSuccessAlert, addErrorResponseAlert} = useAlerts();
    const {projectUuid} = useParams();
    const {prices, tokens, refetchTokenAmount} = usePaymentToken();

    const {
        showLoadingModal, setShowLoadingModal,
        loadingModalHeader, setLoadingModalHeader,
        loadingModalBody, setLoadingModalBody,
        emptyLoadingModal
    } = LoadingModalEffects();

    const request: ProjectRequest = saveRequestMethods.watch();
    const [isAnyFileUploading, setIsAnyFileUploading] = useState<boolean>(false);

    useEffect(() => {
        checkIfAnyFileUploading(request, setIsAnyFileUploading);
    }, [request])

    const {data: subscriptionInfo} = useQuery<SubscriptionInfo>('subscriptionInfo', SubscriptionClient.getSubscriptionInfo, {placeholderData: {activeSubscription: ""}});

    const handleFail = (err: { response: AxiosResponse; }) => {
        emptyLoadingModal();
        addErrorResponseAlert(formatErrorResponse(err.response));
        return true;
    }

    const editDraft = async (formValue: ProjectRequest, status: ProjectStatus): Promise<NewProjectSaveResponse | undefined> => {
        let failed = false;

        let request = createProjectRequest(formValue);
        let editDraftResponse = await ProjectClient.editDraft(projectUuid!, request, status)
            .catch(res => {
                failed = handleFail(res);
            });
        if (failed || !editDraftResponse) return undefined;

        return editDraftResponse;
    }

    const confirmDraft = async (formValue: ProjectRequest) => {
        if (!projectUuid) return;
        setShowConfirmationModal(false);
        setLoadingModalHeader(SENDING_QUOTE);
        setLoadingModalBody(SAVING_PROJECT_INFO);
        setShowLoadingModal(true);

        if (request.productionType === ProductionType.AI_GENERATED && getCost() > tokens) {
            emptyLoadingModal();
            setShowOutOfTokensModal(true);
            return;
        }

        let editDraftResponse = await editDraft(formValue, getProjectStatusForProductionType(request.productionType));
        if (!editDraftResponse) return;

        emptyLoadingModal();
        if (request.productionType === ProductionType.DESIGNER) {
            addSuccessAlert("Draft sent for quote");
        } else if (request.productionType === ProductionType.AI_GENERATED) {
            refetchTokenAmount();
            addSuccessAlert("Draft ordered");
        }
        navigate("/");
    };

    const sendForDraft = async (formValue: ProjectRequest) => {
        if (!projectUuid) return;
        setShowConfirmationModal(false);
        setLoadingModalHeader(SAVING_DRAFT);
        setLoadingModalBody(SAVING_DRAFT_INFO);
        setShowLoadingModal(true);

        let editDraftResponse = await editDraft(formValue, ProjectStatus.DRAFT);
        if (!editDraftResponse) return;
        emptyLoadingModal();
        addSuccessAlert("Draft changed");
        navigate("/");
    };

    function handleClose() {
        setShowConfirmationModal(false);
    }

    function handleOutOfTokens() {
        setShowOutOfTokensModal(false);
        if (subscriptionInfo?.activeSubscription) {
            redirectToStripe(addErrorResponseAlert);
            return;
        }
        setShowCreateSubModal(true);
    }

    function addProductUuidToRemovedProductUuids(productUuid: string) {
        let removedProductUuidsCopy = saveRequestMethods.getValues("removedProductUuids");
        removedProductUuidsCopy.push(productUuid);

        saveRequestMethods.setValue("removedProductUuids", removedProductUuidsCopy);
    }

    async function handleProductRemove(index: number, productUuid?: string) {
        if (productUuid) {
            addProductUuidToRemovedProductUuids(productUuid);
        }

        let removedProduct: ProductRequest = saveRequestMethods.getValues("products")[index];
        let stragglingAttachmentUuids: string[] = [];
        removedProduct.notSavedAttachmentUuids.forEach(notSavedAttachmentUuid => {
            stragglingAttachmentUuids.push(notSavedAttachmentUuid);
        });
        await AttachmentClient.deleteStragglingThumbnails(stragglingAttachmentUuids);
    }

    async function handleCancel() {
        let products: ProductRequest[] = saveRequestMethods.getValues("products")
        let stragglingAttachmentUuids: string[] = [];
        products.forEach(product => {
            product.notSavedAttachmentUuids.forEach(attachmentUuid => {
                stragglingAttachmentUuids.push(attachmentUuid);
            })
        })

        await AttachmentClient.deleteStragglingThumbnails(stragglingAttachmentUuids);
        navigate("/");
    }

    const getCost = () => {
        if (prices.get(AlphaPayment.GENERATE_MODEL) === undefined || request.products === undefined) {
            return 0;
        }
        return prices.get(AlphaPayment.GENERATE_MODEL)! * request.products.length;
    }

    return (
        <>
            <NewProjectHeader
                filesUploading={isAnyFileUploading}
                showCancelModal={() => setShowCancelModal(true)}
                confirmProject={saveRequestMethods.handleSubmit(onValidate)}
                saveAsDraft={saveRequestMethods.handleSubmit(onDraft)}
                productionType={request.productionType}
                title={"Edit draft"}
                uploadDisabled={false}
            />
            <LoadingModal header={loadingModalHeader}
                          body={loadingModalBody}
                          showModal={showLoadingModal}/>
            <ConfirmationModal
                size={"lg"}
                header={getProjectConfirmationHeader(request.productionType)}
                body={getProjectConfirmationBody(request.productionType, getCost(), request.aiMethod)}
                handleClose={handleClose}
                handleConfirmation={saveRequestMethods.handleSubmit(confirmDraft)}
                showModal={showConfirmationModal}
            />
            <ConfirmationModal
                header="Do you want to discard changes?"
                handleClose={() => setShowCancelModal(false)}
                handleConfirmation={handleCancel}
                showModal={showCancelModal}
            />
            <GetMoreTokensModal handleClose={() => setShowOutOfTokensModal(false)}
                                handleConfirmation={handleOutOfTokens}
                                showModal={showOutOfTokensModal} cost={getCost()} tokens={tokens}/>
            <CreateSubscriptionModal showModal={showCreateSubModal}
                                     handleClose={() => setShowCreateSubModal(false)}/>
            <NewProjectForm
                onProductRemove={handleProductRemove}
                projectUuid={projectUuid}
                setShowModal={setShowConfirmationModal}
                saveRequestMethods={saveRequestMethods}
                hasSubscription={subscriptionInfo?.activeSubscription != null}
            />
            <NewProjectFooter
                filesUploading={isAnyFileUploading}
                showCancelModal={() => setShowCancelModal(true)}
                saveAsDraft={saveRequestMethods.handleSubmit(onDraft)}
                sendForQuote={saveRequestMethods.handleSubmit(onValidate)}
                productionType={request.productionType}
                uploadDisabled={false}
            />
        </>
    );
};

export default EditDraftPage;

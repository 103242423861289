import axios from "axios";
import {SubscriptionInfo, SubscriptionLevel} from "../types/subscription-info";


export class SubscriptionClient {
    getSubscriptionInfo = (): Promise<SubscriptionInfo> => {
        return axios.get(process.env.REACT_APP_API_URL + "subscription/subscription-info").then((res) => {
            return res.data;
        });
    };
    getAvailableSubscriptions = (): Promise<SubscriptionLevel[]> => {
        return axios.get(process.env.REACT_APP_API_URL + "subscription/available-subscriptions").then((res) => {
            return res.data;
        });
    };

    subscribe = (subscriptionName: string) => {
        return axios.post(process.env.REACT_APP_API_URL + "subscription/subscribe?subscriptionName=" + subscriptionName, null).then((res) => {
            return res.data;
        });
    }

    cancelSubscription = (companyUuid: string) => {
        return axios.post(process.env.REACT_APP_API_URL + "subscription/cancel-subscription?companyUuid=" + companyUuid, null).then((res) => {
            return res.data;
        });
    }
}

export default new SubscriptionClient();

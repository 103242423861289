import React from "react";
import InputError from "../../InputError";
import {useDropzone} from "react-dropzone";
import {AttachmentInfo} from "../../../types/attachment/attachment-response";
import {useAlerts} from "../../../store/AlertProvider";
import {handleFileHandlerDropzone} from "../../file-handler/BulkImport";

const BulkUploadFileInput: React.FC<{
    name: string;
    errorMessage?: string;
    updateFiles: (files: AttachmentInfo[]) => void;
}> = ({name, errorMessage, updateFiles}) => {
    const {addErrorAlert} = useAlerts();

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            "text/csv": [".csv", ".CSV"],
            "application/vnd.ms-excel": [".xls", ".XLS", ".xlsx", ".XLSX"],
        },
        maxFiles: 1,
        onDrop: (acceptedFiles: File[]) => {
            handleFileHandlerDropzone(acceptedFiles, addErrorAlert, updateFiles);
        }
    })

    return (
        <div className="file-upload">
            <label className="w-100" htmlFor={name}>
                <div {...getRootProps()} className="droparea text-center">

                    <div className="title">
                        Click <span className="text-decoration-underline">here</span> to upload an attachment
                    </div>
                    <input hidden {...getInputProps()}/>
                    <div className="subtitle">Please drag and drop here your xls or csv files.</div>
                    <InputError errorMessage={errorMessage}></InputError>
                </div>
            </label>
        </div>
    );
};

export default BulkUploadFileInput;

import {useCallback, useEffect, useState} from "react";
import {AttachmentInfo} from "../../types/attachment/attachment-response";

import {fileToAttachmentInfo, handleAsyncDropzoneFileList} from "../../utils/DropzoneUtils";
import {useFormContext} from "react-hook-form";

export function useFileHandler(name?: string, loaded?: boolean) {
    const [attachments, setAttachments] = useState<AttachmentInfo[]>([]);
    const {trigger, setValue, getValues} = useFormContext();
    const [triggerCount, setTriggerCount] = useState<number>(0);

    const [attachmentInfoListName, setAttachmentInfoListName] = useState(name ? name + ".attachmentInfoList" : "attachmentInfoList");
    const [removedAttachmentUuidsName, setRemovedAttachmentUuidsName] = useState(name ? name + ".removedAttachmentUuids" : "removedAttachmentUuids");
    const [attachmentUuidsName, setAttachmentUuidsName] = useState(name ? name + ".attachmentUuids" : "attachmentUuids");
    const [notSavedAttachmentUuidsName, setNotSavedAttachmentUuidsName] = useState(name ? name + ".notSavedAttachmentUuids" : "notSavedAttachmentUuids");
    const [unProcessedAttachmentsName, setUnProcessedAttachmentsName] = useState(name ? name + ".unProcessedAttachments" : "unProcessedAttachments");

    useEffect(function moveExistingAttachmentsToFileHandler() {
        if (attachments.length > 0) return;
        let existingAttachments = getValues(attachmentInfoListName);
        if (existingAttachments?.length > 0) {
            setAttachments(existingAttachments);
            setValue(attachmentInfoListName, []);
        }
    }, [loaded, setValue, getValues, attachments.length, attachmentInfoListName])

    const removeAttachment = useCallback((attachment: AttachmentInfo) => {
        setAttachments((currentAttachments) => {
            return currentAttachments.filter(file => file.referenceUuid !== attachment.referenceUuid)
        });
        setTriggerCount((prev) => {
            return prev + 1
        });
    }, []);

    useEffect(() => {
        if (triggerCount === 0) return;
        trigger(attachmentUuidsName).then();
    }, [triggerCount, attachmentUuidsName, trigger])

    const removeExistingAttachment = (attachment: AttachmentInfo) => {
        removeAttachment(attachment);
        let removeFileList = getValues(removedAttachmentUuidsName);
        setValue(removedAttachmentUuidsName, [...removeFileList, attachment.attachmentUuid])
        setTriggerCount((prev) => {
            return prev + 1
        });
    }

    const updateFiles = (newFiles: AttachmentInfo[]) => {
        setAttachments(handleAsyncDropzoneFileList(attachments, newFiles));
        setTriggerCount((prev) => {
            return prev + 1
        });
    }

    const updateFile = useCallback((attachment: AttachmentInfo) => {
        setAttachments((currentAttachments) => currentAttachments.map(currentAttachment =>
            currentAttachment.referenceUuid === attachment.referenceUuid
                ? attachment
                : currentAttachment
        ));
        setTriggerCount((prev) => {
            return prev + 1
        });
    }, []);

    function updateFileHandlerFields() {
        const attachmentInfoListNameTemp = name ? name + ".attachmentInfoList" : "attachmentInfoList";
        const removedAttachmentUuidsNameTemp = name ? name + ".removedAttachmentUuids" : "removedAttachmentUuids";
        const attachmentUuidsNameTemp = name ? name + ".attachmentUuids" : "attachmentUuids";
        const notSavedAttachmentUuidsNameTemp = name ? name + ".notSavedAttachmentUuids" : "notSavedAttachmentUuids";
        const unProcessedAttachmentsNameTemp = name ? name + ".unProcessedAttachments" : "unProcessedAttachments";

        setAttachmentInfoListName(attachmentInfoListNameTemp);
        setRemovedAttachmentUuidsName(removedAttachmentUuidsNameTemp);
        setAttachmentUuidsName(attachmentUuidsNameTemp);
        setNotSavedAttachmentUuidsName(notSavedAttachmentUuidsNameTemp);
        setUnProcessedAttachmentsName(unProcessedAttachmentsNameTemp);

        setValue(attachmentUuidsNameTemp, attachments.map(attachment => attachment.attachmentUuid));
        setValue(notSavedAttachmentUuidsNameTemp, attachments
            .filter(file => !file.productUuid)
            .map(file => file.attachmentUuid));
        setValue(unProcessedAttachmentsNameTemp, attachments.filter(attachment => !attachment.attachmentUuid).length);
    }

    useEffect(updateFileHandlerFields, [attachments, name, setValue, attachmentUuidsName, notSavedAttachmentUuidsName, unProcessedAttachmentsName])

    return {
        removeExistingAttachment: removeExistingAttachment,
        updateFiles,
        updateFile,
        attachments,
        setValue,
        getValues,
        removeFile: removeAttachment
    }
}

export function handleFileHandlerDropzone(acceptedFiles: File[], addErrorAlert: (body: string) => void, updateFiles: (files: AttachmentInfo[]) => void) {
    let files: AttachmentInfo[] = [];
    for (let acceptedFile of acceptedFiles) {
        let attachment = fileToAttachmentInfo(acceptedFile, addErrorAlert);
        if (attachment) {
            files.push(attachment);
        }
    }
    updateFiles(files);
}

import * as Yup from "yup";

export const ATTACHMENT_MAX_FILE_SIZE: number = 419430400; // 400MB: convert bytes to mb

export function checkMaximumFileSize(files: FileList) {
    if (files === undefined || files.length === 0) {
        return false;
    }
    return !Array.from(files).some((file) => file.size > ATTACHMENT_MAX_FILE_SIZE);
}

export function checkMaximumAttachmentSize(files: FileList) {
    if (files === undefined || files.length === 0) {
        return true;
    }
    return checkMaximumFileSize(files);
}

export const modelAndAttachmentValidation = Yup.object().shape({
    attachments: Yup.mixed()
        .test("fileSize", "File must be less than 400MB", (files) => checkMaximumAttachmentSize(files)),
    models: Yup.mixed()
        .required("Please add at least one model file")
        .test("fileSize", "File must be less than 200MB", (files) => checkMaximumFileSize(files))
        .test("onlyModels", "Only glb, gltf or usd files allowed", (files: File[] | undefined) => {
            if (files === undefined || files.length === 0) {
                return true;
            }
            return !Array.from(files).some((file) => {
                const extension: string | undefined = file.name.split(".").pop();
                return extension == null || !["glb", "usd", "gltf"].includes(extension);
            });
        })
        .test("glbRequired", "At least one glb or gltf file is required", (files: File[]) => {
            return files.some(file => {
                const extension = file.name.split(".").pop();
                return extension === "glb" || extension === "gltf";
            })
        })
});

import axios, {AxiosResponse} from "axios";
import GenericErrorResponse from "../types/generic-error-response";
import {
    Attachment,
    Image,
    ProductThumbnailsResponse,
    ProjectThumbnailsResponse,
    Thumbnail
} from "../types/attachment/attachment-response";
import {ProductRequest} from "../types/product/add-new-products-dtos";
import {userDownloadFile} from "../utils/FileUtils";


const ATTACHMENT_ENDPOINT = process.env.REACT_APP_API_URL + "attachment/";
const INTERNAL_ATTACHMENT_ENDPOINT = process.env.REACT_APP_API_URL + "attachment/internal/";
const CLIENT_ATTACHMENT_ENDPOINT = process.env.REACT_APP_API_URL + "attachment/client/";

export class AttachmentClient {

    getProjectThumbnails(productUuid: string): Promise<ProjectThumbnailsResponse> {
        return axios
            .get(ATTACHMENT_ENDPOINT + "project/" + productUuid.toString() + "/thumbnails")
            .then(res => {
                return res.data;
            })
            .catch(res => formatErrorResponse(res.response));
    }

    getModels(productUuid: string) {
        return axios
            .get(ATTACHMENT_ENDPOINT + "product/" + productUuid + "/model/download", {
                responseType: "blob"
            })
            .then(res => {
                userDownloadFile(res.data, "models.zip");
            })
    }

    getProductThumbnails(productUuid: string): Promise<ProductThumbnailsResponse> {
        return axios
            .get(ATTACHMENT_ENDPOINT + "product/" + productUuid + "/thumbnails")
            .then(res => {
                return res.data;
            })
            .catch(res => formatErrorResponse(res.response));
    }

    getAttachmentFile(thumbnail: Thumbnail | Attachment) {
        return axios
            .get(ATTACHMENT_ENDPOINT + thumbnail.attachmentUuid + "/download", {
                responseType: "blob"
            })
            .then(res => {
                userDownloadFile(res.data, thumbnail.fileName);
            })
            .catch(res => formatErrorResponse(res.response));
    }

    getAttachmentImage(attachmentId: string): Promise<Image> {
        return axios
            .get(ATTACHMENT_ENDPOINT + attachmentId, )
            .then(res => {
                return res.data;
            });
    }

    getInternalAttachments(productUuid: string, version?: string): Promise<Image[]> {
        return axios
            .get(INTERNAL_ATTACHMENT_ENDPOINT + "product/" + productUuid + "/attachments", {
                params: {version: version}
            })
            .then(res => {
                return res.data;
            });
    }

    getClientAttachments(productUuid: string, version?: string): Promise<Image[]> {
        return axios
            .get(CLIENT_ATTACHMENT_ENDPOINT + "product/" + productUuid + "/attachments", {
                params: {version: version}
            })
            .then(res => {
                return res.data;
            });
    }

    getInternalModels(productUuid: string, version?: string): Promise<Image[]> {
        return axios
            .get(INTERNAL_ATTACHMENT_ENDPOINT + "product/" + productUuid + "/models", {
                params: {version: version}
            })
            .then(res => {
                return res.data;
            });
    }

    getClientModels(productUuid: string, version?: string, extensions?: string[]): Promise<Image[]> {
        return axios
            .get(CLIENT_ATTACHMENT_ENDPOINT + "product/" + productUuid + "/models", {
                params: {version: version, extensions: extensions?.toString()}
            })
            .then(res => {
                return res.data;
            });
    }

    saveInternalModelsAndAttachments(productUuid: string, formData: FormData, setLoadProgress: (value: (((prevState: number) => number) | number)) => void): Promise<Image[]> {
        return axios
            .post(INTERNAL_ATTACHMENT_ENDPOINT + "product/" + productUuid + "/upload", formData, {
                onUploadProgress: getOnUploadProgress(setLoadProgress)
            })
            .then(res => {
                return res.data;
            });
    }

    saveClientModelsAndAttachments(productUuid: string, formData: FormData, setLoadProgress: (value: (((prevState: number) => number) | number)) => void): Promise<Image[]> {
        return axios
            .post(CLIENT_ATTACHMENT_ENDPOINT + "product/" + productUuid + "/upload", formData, {
                onUploadProgress: getOnUploadProgress(setLoadProgress)
            })
            .then(res => {
                return res.data;
            });
    }

    getInternalVersionNumbers(productUuid: string): Promise<number[]> {
        return axios
            .get(INTERNAL_ATTACHMENT_ENDPOINT + "product/" + productUuid + "/versions", {
                params: {versionType: "INTERNAL"}
            })
            .then(res => {
                return res.data;
            });
    }

    getClientVersionNumbers(productUuid: string): Promise<number[]> {
        return axios
            .get(CLIENT_ATTACHMENT_ENDPOINT + "product/" + productUuid + "/versions", {
                params: {versionType: "CLIENT"}
            })
            .then(res => {
                return res.data;
            });
    }

    uploadAttachment(file: File, signal: AbortSignal, isProjectFile: boolean): Promise<Thumbnail> {
        return axios
            .post(ATTACHMENT_ENDPOINT, {file: file}, {
                    signal: signal,
                    params: {isProjectFile: isProjectFile},
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            )
            .then(res => {
                return res.data;
            })
    }

    getThumbnail(attachmentId: string, signal: AbortSignal): Promise<Thumbnail> {
        return axios
            .get(ATTACHMENT_ENDPOINT + attachmentId + "/thumbnail", {
                    signal: signal
                }
            )
            .then(res => {
                return res.data;
            })
    }

    deleteAttachment(attachmentId: string) {
        return axios
            .delete(ATTACHMENT_ENDPOINT + attachmentId, )
            .then(res => {
                return res.data;
            })
    }

    deleteStragglingThumbnails(stragglingAttachmentUuids: string[]) {
        return axios
            .post(ATTACHMENT_ENDPOINT + "batch-delete", stragglingAttachmentUuids, )
            .then(res => {
                return res.data;
            })
    }

    bulkUploadFromFile(file: File): Promise<ProductRequest[]> {
        return axios
            .post(ATTACHMENT_ENDPOINT + "bulk-upload", {file: file}, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(res => {
                return res.data;
            })
    }

    uploadAttachmentByUrl(url: string, fileType: string, signal: AbortSignal, isProjectFile: boolean): Promise<Thumbnail> {
        return axios
            .post(ATTACHMENT_ENDPOINT + "url-upload", {url: url}, {
                    signal: signal,
                    params: {fileType: fileType, isProjectFile: isProjectFile},
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            )
            .then(res => {
                return res.data;
            })
    }
}

function getOnUploadProgress(setLoadProgress: (value: (((prevState: number) => number) | number)) => void) {
    return (progressEvent: { loaded: number; }) => {
        setLoadProgress(() => {
            return progressEvent.loaded
        })
    };
}


export const formatErrorResponse = (response: AxiosResponse): GenericErrorResponse => {
    return {
        status: response.data.status,
        message: response.data.detail
    };
};

export default new AttachmentClient();

import React, {useState} from "react";

const AdminAction: React.FC<{
    text: string,
    handleShow: () => void,
    searchEnabled?: boolean,
    setSearchQuery?: (searchQuery: string) => void,
    searchQuery?: string }
> = ({text, handleShow, searchEnabled=false, setSearchQuery, searchQuery}) => {
    const [query, setQuery] = useState(searchQuery || "");
    const handleQueryAndSearch = (event: { target: { value: any; }; }) => {
        const value = event.target.value;
        setQuery(value);

        if (value.length >= 3) {
            setSearchQuery && setSearchQuery(value);
        } else {
            setSearchQuery && setSearchQuery("");
        }
    };

    return (
        <>
            <div className="projects-header mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            {
                                searchEnabled &&
                                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}} className="col-md-12">
                                        <input  className={"form-control form-control-lg float-start"} value={query} onChange={handleQueryAndSearch} placeholder={"Search..."}/>
                                        <span style={{display: "contents"}}>
                                            <i className="bi bi-search" style={{position: "relative", right: "30px"}}></i>
                                        </span>
                                    </div>
                            }
                        </div>
                        <div className="col-md-6">
                            <div className="">
                                <button onClick={handleShow} className="btn btn-dark btn-icon float-end">
                                    <i className="bi bi-plus-circle me-2"></i> {text}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminAction;

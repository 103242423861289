import axios from "axios";


export class StripeClient {
    createCheckoutSession(returnUrl: string): Promise<string> {
        return axios
            .post(process.env.REACT_APP_API_URL + "stripe/customer-portal", null, {
                params: {
                    "returnUrl": returnUrl
                }
            })
            .then(res => {
                return res.data
            })
    }
}

export default new StripeClient();

import {useAuthContext} from "../store/AuthProvider";
import logo from "../assets/logo-alpha.svg";
import {Link, useMatch} from "react-router-dom";
import {UserRole} from "../types/user/user-role";
import React from "react";
import {useAlerts} from "../store/AlertProvider";
import {redirectToStripe} from "../utils/PayementUtil";
import {UserAccount} from "../types/user/user-account";
import {useClassifications} from "../store/ClassificationProvider";
import {ProductionType} from "../types/project/production-type";
import {usePaymentToken} from "../store/PaymentTokenProvider";

const AppHeader: React.FC<{
    compact: boolean,
    logOut: () => void,
    user: UserAccount
}> = ({compact = false, logOut, user}) => {
    const {hasRole} = useAuthContext();
    const {addErrorResponseAlert} = useAlerts();
    const {productionTypes} = useClassifications();
    const {tokens} = usePaymentToken();

    const myProjectsMatch = useMatch("/");
    const usersMatch = useMatch("/admin/users");
    const companiesMatch = useMatch("/admin/companies");
    const accountMatch = useMatch("/account");

    const canNavigateToProjects = (hasRole(UserRole.ROLE_ADMIN) || hasRole(UserRole.ROLE_USER));
    const isShowBilling = productionTypes.includes(ProductionType.AI_GENERATED) || user.automaticInvoicing;

    const adminButtons = <>
        <Link to={"/admin/users"}
              className={`btn btn-link me-2 ${(usersMatch || companiesMatch) ? "text-decoration-underline" : ""}`}>
            Admin dashboard
        </Link>
    </>;


    const userButtons = <>
        <Link to="/" className={`btn btn-link btn-icon me-2 ${(myProjectsMatch) ? "text-decoration-underline" : ""}`}>
            <svg fill="none" className="me-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 10.75c-1.52 0-2.75-1.23-2.75-2.75S7.48 5.25 9 5.25 11.75 6.48 11.75 8 10.52 10.75 9 10.75Zm0-4a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z" fill="#07031B" /><path d="M15 22.75H9c-5.43 0-7.75-2.32-7.75-7.75V9c0-5.43 2.32-7.75 7.75-7.75h4c.41 0 .75.34.75.75s-.34.75-.75.75H9C4.39 2.75 2.75 4.39 2.75 9v6c0 4.61 1.64 6.25 6.25 6.25h6c4.61 0 6.25-1.64 6.25-6.25v-5c0-.41.34-.75.75-.75s.75.34.75.75v5c0 5.43-2.32 7.75-7.75 7.75Z" fill="#07031B" /><path d="M15.67 9.75c-.39 0-.75-.14-1.01-.41-.31-.31-.46-.77-.39-1.24l.2-1.39c.05-.35.26-.78.52-1.03l3.63-3.63c1.45-1.45 2.71-.62 3.33 0 .59.59.86 1.21.8 1.85-.05.51-.31 1-.8 1.48l-3.63 3.63c-.25.25-.68.46-1.03.52l-1.39.2c-.08.02-.16.02-.23.02Zm4-6.63-3.63 3.63c-.03.03-.08.14-.09.19l-.19 1.3 1.31-.18c.04-.01.15-.06.19-.1l3.63-3.63c.22-.22.35-.42.36-.57.02-.21-.19-.47-.36-.64-.52-.52-.76-.46-1.22 0Z" fill="#07031B" /><path d="M20.9 6.13c-.07 0-.14-.01-.2-.03a4.077 4.077 0 0 1-2.79-2.79c-.11-.4.12-.81.52-.92.4-.11.81.12.92.52.24.84.91 1.52 1.76 1.76.4.11.63.53.52.92a.79.79 0 0 1-.73.54ZM2.67 19.7a.751.751 0 0 1-.42-1.37l4.93-3.31c1.08-.72 2.57-.64 3.55.19l.33.29c.5.43 1.35.43 1.84 0l4.16-3.57c1.06-.91 2.73-.91 3.8 0l1.63 1.4c.31.27.35.74.08 1.06-.27.31-.74.35-1.06.08l-1.63-1.4c-.5-.43-1.35-.43-1.84 0l-4.16 3.57c-1.06.91-2.73.91-3.8 0l-.33-.29c-.46-.39-1.22-.43-1.73-.08L3.1 19.58c-.14.08-.29.12-.43.12Z" fill="currentColor" /></svg>
            My projects
        </Link>
        {isShowBilling &&
            <button onClick={() => redirectToStripe(addErrorResponseAlert)}
                    className="btn btn-link btn-icon me-2">
                <svg fill="none" className="me-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.54 21.94c-.23 0-.45-.1-.6-.3l-1.01-1.35c-.21-.28-.49-.44-.79-.46-.31-.02-.6.12-.84.37-1.45 1.55-2.55 1.42-3.08 1.22-.54-.21-1.45-.9-1.45-3.12V7.04c0-4.44 1.28-5.79 5.47-5.79h7.55c4.19 0 5.47 1.35 5.47 5.79v4.26c0 .41-.34.75-.75.75s-.75-.34-.75-.75V7.04c0-3.61-.63-4.29-3.97-4.29H8.24c-3.34 0-3.97.68-3.97 4.29V18.3c0 1.05.26 1.63.5 1.72.18.07.67-.03 1.43-.84.55-.58 1.27-.89 2.02-.85.74.04 1.44.43 1.91 1.06l1.02 1.35a.75.75 0 0 1-.61 1.2Z" fill="#07031B" /><path d="M16 7.75H8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75ZM15 11.75H9c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h6c.41 0 .75.34.75.75s-.34.75-.75.75ZM14.82 21.78c-.38 0-.74-.14-1-.4-.31-.31-.45-.76-.38-1.23l.19-1.35c.05-.35.26-.77.51-1.02l3.54-3.54c.48-.48.95-.73 1.46-.78.62-.06 1.24.2 1.82.78.58.58.84 1.19.78 1.82-.05.5-.31.98-.78 1.46l-3.54 3.54c-.25.25-.67.46-1.02.51l-1.35.19c-.08.01-.15.02-.23.02Zm4.49-6.83h-.03c-.14.01-.33.14-.54.35l-3.54 3.54a.38.38 0 0 0-.08.17l-.18 1.25 1.25-.18c.04-.01.14-.06.17-.09l3.54-3.54c.21-.22.34-.4.35-.54.02-.2-.18-.44-.35-.61-.16-.16-.39-.35-.59-.35Z" fill="#07031B" /><path d="M19.92 18.25c-.07 0-.14-.01-.2-.03a3.977 3.977 0 0 1-2.74-2.74.76.76 0 0 1 .52-.93c.4-.11.81.12.92.52.23.82.88 1.47 1.7 1.7a.755.755 0 0 1-.2 1.48Z" fill="currentColor" /></svg>
                Billing
            </button>
        }
        <Link to="/account" className={`btn btn-link btn-icon me-2 ${(accountMatch) ? "text-decoration-underline" : ""}`}>
            <svg fill="none" className="me-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"><path d="M8.08 9.02h-.113C6.454 8.973 5.32 7.793 5.32 6.34a2.692 2.692 0 0 1 2.687-2.687 2.692 2.692 0 0 1 2.687 2.687A2.676 2.676 0 0 1 8.1 9.02h-.02ZM8 4.647a1.689 1.689 0 0 0-.066 3.373.56.56 0 0 1 .153 0A1.689 1.689 0 0 0 8 4.647ZM8 15.167a7.14 7.14 0 0 1-4.833-1.88.503.503 0 0 1-.16-.42c.087-.794.58-1.534 1.4-2.08 1.987-1.32 5.207-1.32 7.187 0 .82.553 1.313 1.286 1.4 2.08.02.16-.04.313-.16.42A7.14 7.14 0 0 1 8 15.167Zm-3.946-2.434A6.139 6.139 0 0 0 8 14.167c1.447 0 2.84-.507 3.947-1.434-.12-.406-.44-.8-.913-1.12-1.64-1.093-4.42-1.093-6.074 0-.473.32-.786.714-.906 1.12Z" fill="currentColor" /><path d="M8 15.167A7.173 7.173 0 0 1 .833 8 7.173 7.173 0 0 1 8 .833 7.173 7.173 0 0 1 15.166 8 7.173 7.173 0 0 1 8 15.167ZM8 1.833A6.174 6.174 0 0 0 1.833 8c0 3.4 2.767 6.167 6.167 6.167S14.166 11.4 14.166 8 11.4 1.833 8 1.833Z" fill="currentColor" /></svg>
            Account
        </Link>
    </>;

    return (
        <header className={`app-header ${compact ? "-compact" : ""}`}>
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col-md-auto">
                        {canNavigateToProjects
                            ? <Link to="/">
                                <img src={logo} width="143" alt="Alpha AR"/>
                            </Link>
                            : <div>
                                <img src={logo} width="143" alt="Alpha AR"/>
                            </div>
                        }
                    </div>
                    <div className="col-md-auto me-2">
                        <div className="btn btn-icon me-2 pe-none"><span className="fw-bolder">{tokens} tokens</span></div>
                        {hasRole(UserRole.ROLE_ADMIN) &&
                            <>
                                {adminButtons}
                                {userButtons}
                            </>
                        }
                        {hasRole(UserRole.ROLE_USER) &&
                            <>
                                {userButtons}
                            </>
                        }
                        <Link to={"/logout"}>
                            <button onClick={logOut} className="btn btn-outline-gray">
                                Log out
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default AppHeader

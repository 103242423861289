import axios from "axios";
import {ClassificationResponse} from "../types/classificationResponse";
import ProductCategoryResponse from "../types/product/product-category-response";

export class ClassificationClient {
    getClassificationsByType = (classificationType: string): Promise<ClassificationResponse[]> => {
        return axios
            .get(process.env.REACT_APP_API_URL + "classification?classificationType=" + classificationType)
            .then((res) => {
                return res.data;
            });
    };

    getCountriesClassifications = (): Promise<ClassificationResponse[]> => {
        return axios
            .get(process.env.REACT_APP_API_URL + "classification/company_country",)
            .then((res) => {
                return res.data;
            });
    };

    getProductTypesClassifications = (): Promise<ProductCategoryResponse[]> => {
        return axios
            .get(process.env.REACT_APP_API_URL + "classification/product-types")
            .then((res) => {
                return res.data;
            });
    };

    getTriangleCounts = (): Promise<number[]> => {
        return axios
            .get(process.env.REACT_APP_API_URL + "classification/triangle-counts")
            .then((res) => {
                return res.data;
            });
    };
}

export default new ClassificationClient();

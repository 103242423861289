import React, {useEffect, useState} from "react";
import echo3DLogo from "../assets/echo3D_logo.png";
import nvidiaLogo from "../assets/nvidia_logo.png";
import PartnersPageHeader from "../components/partner/PartnersPageHeader";
import PartnerList from "../components/partner/PartnerList";
import PartnerDetails from "../components/partner/PartnerDetails";
import {IPartnerCard} from "../types/partner";

const PartnersPage: React.FC = () => {
    const partners = [
        {
            partnerName: "echo3D",
            imagePath: echo3DLogo,
            shortDescription: "Echo3D is a 3D asset management platform designed for developers working on 3D, AR/VR, Metaverse, and Spatial Computing applications.",
            longDescription: "Echo3D is a 3D asset management platform designed for developers working on 3D, AR/VR, Metaverse, and Spatial Computing applications.|||The platform provides tools and network infrastructure to help developers and companies quickly build and deploy 3D apps, games, and content.|||You can use Alpha3D AI generated 3D directly on echo3D, either by generating them yourself and uploading them to echo3D or picking from an array of our Alpha3D pre-generated 3D models readily available on echo3D platform.",
            websiteLink: "https://www.echo3d.com/",
            websiteName: "echo3d",
            category: "Asset management",
        } as IPartnerCard,
        {
            partnerName: "Nvidia",
            imagePath: nvidiaLogo,
            shortDescription: "NVIDIA Omniverse accelerates the workflow of creating virtual 3D worlds, scaling from workstation to cloud to enable developers to build advanced, scalable solutions with minimal coding.",
            longDescription: "NVIDIA Omniverse is a platform for connecting and developing Universal Scene Description (OpenUSD)-based tools and applications. It accelerates the creation of virtual worlds and advanced 3D workflows for industrial digitalization.|||Built on OpenUSD, NVIDIA RTX, and AI technologies, Omniverse is a modular, fully composable software platform that scales from workstation to cloud, enabling developers to build advanced, scalable solutions with minimal coding.|||The Alpha3D extension for Omniverse streamlines the 3D model generation process by integrating Alpha3D directly into the Omniverse platform. With this extension, designers, artists, and developers can import their 3D assets from Alpha3D directly into their USD-based workflows and applications in Omniverse.",
            websiteLink: "https://www.nvidia.com/en-us/omniverse/creators/",
            viewTutorialLink: "https://www.alpha3d.io/partners/alpha3d-for-nvidia-omniverse/",
            websiteName: "nvidia",
            category: "Collaboration platform",
        } as IPartnerCard,
    ];

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const [detailsVisible, setDetailsVisible] = useState<boolean>(false);
    const [selectedPartner, setSelectedPartner] = useState<IPartnerCard | null>(null);

    const filteredPartners = partners.filter((partner) => (
        partner.partnerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        partner.shortDescription.toLowerCase().includes(searchTerm.toLowerCase())
    ));


    const [filteredPartnersWithCategory, setFilteredPartnersWithCategory] = useState<IPartnerCard[] | null>(null);

    useEffect(() => {
        if (selectedCategory === "") {
            setFilteredPartnersWithCategory(filteredPartners);
        } else {
            const filtered = filteredPartners.filter((partner) => partner.category === selectedCategory);
            setFilteredPartnersWithCategory(filtered);
        }
    }, [selectedCategory, filteredPartners]);

    return (
        <>
            <PartnersPageHeader
                partners={filteredPartnersWithCategory}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
            />

            <main className={`view-content -partners ${detailsVisible ? "-details-visible" : ""}`}>
                <div className="partners-list">
                    <div className="container-fluid">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">
                            {filteredPartnersWithCategory && filteredPartnersWithCategory.map((partner, index) => (
                                <PartnerList
                                    key={index}
                                    partner={partner}
                                    detailsVisible={detailsVisible}
                                    setDetailsVisible={setDetailsVisible}
                                    setSelectedPartner={setSelectedPartner}
                                />))}
                        </div>
                    </div>
                </div>
                {filteredPartnersWithCategory && filteredPartnersWithCategory.map((partner, index) => (
                    <PartnerDetails
                        key={index}
                        partner={selectedPartner}
                        detailsVisible={detailsVisible}
                        setDetailsVisible={setDetailsVisible}
                    />))}
            </main>
        </>
    );
};

export default PartnersPage;

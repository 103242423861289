import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import Tab from "./Tab";
import {PROJECT_STATUS_TRANSLATIONS, ProjectStatus} from "../../../types/project/project-status";
import ProjectCounterResponse from "../../../types/project/project-counter-response";
import ProjectClient from "../../../services/project.client";
import {useClassifications} from "../../../store/ClassificationProvider";
import {isOnlyAiGeneratedProductionType} from "../../../utils/ProjectUtils";

const STATUS_TABS: (ProjectStatus.DRAFT | ProjectStatus.PENDING_QUOTES | ProjectStatus.IN_PRODUCTION | ProjectStatus.COMPLETED | ProjectStatus.DELETED)[] = [
    ProjectStatus.DRAFT,
    ProjectStatus.PENDING_QUOTES,
    ProjectStatus.IN_PRODUCTION,
    ProjectStatus.COMPLETED,
    ProjectStatus.DELETED
];

const AI_STATUS_PROJECT_TABS: (ProjectStatus.DRAFT | ProjectStatus.IN_PRODUCTION | ProjectStatus.COMPLETED)[] = [
    ProjectStatus.DRAFT,
    ProjectStatus.IN_PRODUCTION,
    ProjectStatus.COMPLETED,
];

const HomeHeader: React.FC<{
    setReloadFalse: () => void;
    reloadData: boolean;
    projectSearchName: string,
    pendingQuoteAmount: number | null,
    anyProductReadyForQCOrHasNoClientComments: boolean
}> =
    ({
         setReloadFalse,
         reloadData,
         projectSearchName,
         pendingQuoteAmount,
         anyProductReadyForQCOrHasNoClientComments
     }) => {
        const [projectsCount, setProjectsCount] = React.useState<ProjectCounterResponse[]>();
        const [totalProjectsCountExceptDeleted, setTotalProjectsCountExceptDeleted] = React.useState<number>();
        const destinationConstant = "?projectStatuses=";
        const [searchParams] = useSearchParams();
        const activeTab = searchParams.get("projectStatuses");
        const {productionTypes} = useClassifications();

        useEffect(() => {
            if (reloadData) {
                ProjectClient.getProjectCounts(projectSearchName).then((res) => setProjectsCount(res));
                setReloadFalse();
            }
            // TODO: Jörgen look this over changed projectsCount to default undefined, length check causes loop with empty projects page
            if (!projectsCount) {
                ProjectClient.getProjectCounts(projectSearchName).then((res) => setProjectsCount(res));
            } else {
                let totalSum = 0;
                projectsCount.filter(projectCount => projectCount.status !== ProjectStatus.DELETED).forEach((projectCount) => {
                    totalSum += projectCount.count;
                });
                setTotalProjectsCountExceptDeleted(totalSum);
            }
        }, [projectsCount, reloadData, projectSearchName, setReloadFalse]);

        function findByStatus(byStatus: ProjectStatus) {
            return projectsCount?.find((projectCount) => projectCount.status === byStatus)?.count ?? 0;
        }

        const getTabColor = (statusTab: ProjectStatus.DRAFT | ProjectStatus.PENDING_QUOTES | ProjectStatus.IN_PRODUCTION | ProjectStatus.COMPLETED | ProjectStatus.DELETED) => {
            switch (statusTab) {
                case ProjectStatus.PENDING_QUOTES:
                    return pendingQuoteAmount && pendingQuoteAmount > 0 ? "badge-pink" : undefined;
                case ProjectStatus.IN_PRODUCTION:
                    return anyProductReadyForQCOrHasNoClientComments ? "badge-pink" : undefined;
                default:
                    return undefined
            }
        }

        return (
            <header className="view-header">
                <div className="container-fluid">
                    <div className="subtitle-spacer"/>
                    {/* TODO: rework header so this hack isnt needed*/}
                    <div className="row justify-content-between">
                        <div className="col-md-auto">
                            <div className="title">
                                <h1>My Projects</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col">
                            <ul className="nav view-nav mb-n3">
                                <Tab
                                    name={"All projects"}
                                    count={totalProjectsCountExceptDeleted}
                                    destination={"/"}
                                    active={activeTab === null}
                                />
                                {(isOnlyAiGeneratedProductionType(productionTypes) ? AI_STATUS_PROJECT_TABS : STATUS_TABS).map((statusTab) => (
                                    <Tab
                                        key={statusTab}
                                        name={PROJECT_STATUS_TRANSLATIONS[statusTab]}
                                        count={findByStatus(statusTab)}
                                        destination={destinationConstant + statusTab}
                                        active={activeTab === statusTab}
                                        badgeColor={getTabColor(statusTab)}
                                    />
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        );
    };
export default HomeHeader;

import React from "react";
import ImageThumbnail from "../new-project/ImageThumbnail";
import {Attachment, ProductThumbnailsResponse, Thumbnail} from "../../types/attachment/attachment-response";
import {base64FileToJSFile} from "../../utils/FileUtils";
import AttachmentClient from "../../services/attachment.client";

const DownloadableImageThumbnails: React.FC<{
    name: string;
    files?: ProductThumbnailsResponse;
}> = ({files}) => {

    const downloadAttachment = async (attachment: Attachment) => {
        await AttachmentClient.getAttachmentFile(attachment);
    };

    return (
        <React.Fragment>
            {Array.isArray(files?.thumbnails) && files!.thumbnails.length > 0 && files?.thumbnails.map((file: Thumbnail, index) => (
                <ImageThumbnail onClick={() => downloadAttachment(file)} index={index} key={"image-" + index}
                                download={() => downloadAttachment(file)}
                                image={base64FileToJSFile(file)}/>
            ))}
        </React.Fragment>
    );
};

export default DownloadableImageThumbnails;

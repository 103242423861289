import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import AuthService from "../services/auth.service";
import {useAuthContext} from "../store/AuthProvider";
import jwtDecode from "jwt-decode";
import {JwtCustomPayload} from "../common/JwtCustomPayload";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import NewInput from "../components/NewInput";
import {UserAccount} from "../types/user/user-account";
import {yupResolver} from "@hookform/resolvers/yup";
import {loginValidationSchema} from "../validation/login-validation";
import {LoginState} from "../types/navigation";
import RequestResult from "../components/RequestResult";
import googleLogo from '../assets/google-logo.png';

type UserSubmitForm = {
    email: string;
    password: string;
};

const Login: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [successMessage, setSuccessMessage] = useState<string>("");
    const {user, setUser} = useAuthContext();
    const location = useLocation();

    const methods = useForm<UserSubmitForm>({
            resolver: yupResolver(loginValidationSchema),
        }
    );
    const onSubmit: SubmitHandler<UserSubmitForm> = (data) => handleLogin(data);

    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user, navigate]);


    const checkNavigatedFromSignup = () => {
        const loginState = location.state as LoginState;
        if (loginState?.message && successMessage.length === 0) {
            setSuccessMessage(loginState.message);
        }

        if (loginState?.errorMessage && errorMessage.length === 0) {
            setErrorMessage(loginState.errorMessage);
        }
    }

    checkNavigatedFromSignup();

    const handleLogin = (formValue: UserSubmitForm) => {
        const {email, password} = formValue;
        setErrorMessage("");

        AuthService.login(email, password).then(
            (response) => {
                const expires = jwtDecode<JwtCustomPayload>(response.access_token).exp!;
                setUser(new UserAccount(jwtDecode<JwtCustomPayload>(response.access_token), expires));
                navigate("/");

            },
            (error) => {
                const status = error.response.status;
                if (status === 401 || status === 400) {
                    setErrorMessage("Incorrect email or password.");
                } else {
                    setErrorMessage(error.response.data.error_description);
                }
            }
        );
    };

    return (
        <main className="view-content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <FormProvider {...methods}>
                            <div className="card">
                                <div className="card-body p-5">
                                    <h1 className="text-center mb-2">Welcome back</h1>
                                    <div className="text-center mb-2"><a className="btn btn-block btn-light px-5 social-btn google" href={process.env.REACT_APP_AUTH_SERVER_URL + "oauth/authorize?grant_type=authorization_code&response_type=code&client_id=self-service"}>
                                        <img height={"30px"} src={googleLogo} alt="Google" /> Sign in with Google</a></div>

                                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                                        <NewInput
                                            name="email"
                                            label="Your email"
                                            errorMessage={methods.formState.errors?.email?.message}
                                        />
                                        <NewInput
                                            name="password"
                                            label="Your password"
                                            type="password"
                                            errorMessage={methods.formState.errors?.password?.message}
                                        />
                                        <div className="row justify-content-between align-items-center">
                                            <div className="col-8">
                                                <Link className={"pe-1"} to={"/forgot"}>
                                                    Recover password
                                                </Link>
                                                <span>·</span>
                                                <Link className={"ps-1"} to={"/signup"}>
                                                    Sign up
                                                </Link>
                                            </div>

                                            <div className="col-4">
                                                <button className="btn btn-light px-4" type="submit">
                                                    Log in
                                                </button>
                                            </div>
                                        </div>

                                        <div className="mt-2"><RequestResult successfulMessage={successMessage}
                                                                             successful={successMessage.length > 0}
                                                                             error={errorMessage}/></div>
                                    </form>
                                </div>
                            </div>
                        </FormProvider>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Login;

import React from "react";

import {ASSET_GENERATION_COST} from "../common-texts";
import {usePaymentToken} from "../store/PaymentTokenProvider";
import {AlphaPayment} from "../types/payment-price";

const PaymentTokenInfoComponent: React.FC<{
    setShowCreateSubModal: (show: boolean) => void,
    hasSubscription: boolean
}> = ({setShowCreateSubModal, hasSubscription}) => {
    const {prices} = usePaymentToken();
    let infoText = ASSET_GENERATION_COST.replace("{0}", prices.get(AlphaPayment.GENERATE_MODEL)?.toString() ?? "0");
    const buttonText = "Choose subscription";


    return (
        <header className={"app-header -alert"}>
            <div className="container-fluid">
                <div className="row align-items-center justify-content-center">
                    {!hasSubscription && <div className="col-md-2"/>}
                    <div className="col-md text-center text-white">
                        {infoText}
                    </div>
                    {!hasSubscription && <div className="col-md-2 text-end">
                        <button
                                onClick={() => setShowCreateSubModal(true)}
                                className="btn btn-sm btn-white">{buttonText}
                            </button>
                    </div>}
                </div>
            </div>
        </header>
    );
};

export default PaymentTokenInfoComponent;

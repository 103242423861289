import React, {createContext, FC, useContext, useEffect, useState} from "react";
import {ClassificationResponse} from "../types/classificationResponse";
import {useAuthContext} from "./AuthProvider";
import ClassificationClient from "../services/classification.client";
import ProductCategoryResponse from "../types/product/product-category-response";
import {ProductionType} from "../types/project/production-type";
import CompanyClient from "../services/company.client";

interface IClassificationProvider {
    countries: ClassificationResponse[];
    projectEndPoints: ClassificationResponse[];
    productTypes: ProductCategoryResponse[];
    triangleCounts: number[];
    productionTypes: ProductionType[];
    reFetchProductionTypes: () => void;
}

interface ProviderProps {
    children?: React.ReactNode;
}

const ClassificationContext = createContext<IClassificationProvider | null>(null);

export const ClassificationProvider: FC<ProviderProps> = ({children}) => {
    const [countries, setCountries] = useState<ClassificationResponse[]>([]);
    const [projectEndPoints, setProjectEndPoints] = useState<ClassificationResponse[]>([]);
    const [productTypes, setProductTypes] = useState<ProductCategoryResponse[]>([]);
    const [triangleCounts, setTriangleCounts] = useState<number[]>([]);
    const [productionTypes, setProductionTypes] = useState<ProductionType[]>([]);
    const {user} = useAuthContext();

    useEffect(() => {
        if (!user?.name) return;
        if (productTypes.length > 0) return;
        ClassificationClient.getProductTypesClassifications().then(res => setProductTypes(res))
    }, [user?.name, productTypes.length])

    useEffect(() => {
        if (!user?.name) return;
        if (projectEndPoints.length > 0) return;
        ClassificationClient.getClassificationsByType("project_end_point").then(res => setProjectEndPoints(res))
    }, [user?.name, projectEndPoints.length])

    useEffect(() => {
        if (!user?.name) return;
        if (countries.length > 0) return;
        ClassificationClient.getClassificationsByType("company_country").then(res => setCountries(res))
    }, [user?.name, countries.length])

    useEffect(() => {
        if (!user?.name) return;
        if (triangleCounts.length > 0) return;
        ClassificationClient.getTriangleCounts().then(res => setTriangleCounts(res))
    }, [user?.name, triangleCounts.length])

    const fetchProductionTypes = () => {
        CompanyClient.getCompanyProductionTypes()
            .then((res) => {
                setProductionTypes(res);
            })
            .catch((err) => {
                console.error("Error fetching production types:", err);
            });
    };

    useEffect(() => {
        if (!user?.name) return;
        if (productionTypes.length > 0) return;
        fetchProductionTypes();
    }, [user?.name, productionTypes.length]);

    function reFetchProductionTypes () {
        fetchProductionTypes();
    }

    return <ClassificationContext.Provider
        value={{
            countries: countries,
            projectEndPoints: projectEndPoints,
            productTypes: productTypes,
            triangleCounts: triangleCounts,
            productionTypes: productionTypes,
            reFetchProductionTypes: reFetchProductionTypes
        }}>{children}</ClassificationContext.Provider>;
};

export const useClassifications = () => {
    const context = useContext(ClassificationContext);

    if (!context) {
        throw new Error('Missing ClassificationContext.');
    }

    return context;
}

import React, {useEffect, useState} from "react";
import {Modal, ProgressBar} from "react-bootstrap";

const LoadingModal: React.FC<{
    header: string;
    body: string;
    showModal: boolean;
    currentProgress?: number;
    maxProgress?: number;
}> = ({header, body, showModal, currentProgress, maxProgress}) => {
    const [now, setNow] = useState<number>();

    useEffect(() => {
        if (!currentProgress || !maxProgress) return;
        let temp = Math.ceil((currentProgress / maxProgress) * 100);
        if (temp > 100) temp = 100;
        setNow(temp);
    }, [currentProgress, maxProgress]);

    return (
        <Modal show={showModal} className="modal fade" id="addNewCompanyModal">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" id="addNewUserModal">
                        {header}
                    </h4>
                </div>
                <div className="modal-body">
                    <div style={{wordWrap: "break-word", whiteSpace: "pre-wrap"}}>
                        {body}
                    </div>
                    {maxProgress && maxProgress > 0 &&
                        <ProgressBar now={now} label={`${now}%`}></ProgressBar>
                    }
                </div>
            </div>
        </Modal>
    );
};

export default LoadingModal;

export function LoadingModalEffects() {
    const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
    const [loadingModalHeader, setLoadingModalHeader] = useState<string>("");
    const [loadingModalBody, setLoadingModalBody] = useState<string>("");
    const [progress, setProgress] = useState<number>(0);
    const [maxProgress, setMaxProgress] = useState<number>(0);

    const emptyLoadingModal = () => {
        setShowLoadingModal(false);
        setLoadingModalHeader("")
        setLoadingModalBody("")
        setProgress(0)
        setMaxProgress(0)
    }
    return {
        showLoadingModal, setShowLoadingModal,
        loadingModalHeader, setLoadingModalHeader,
        loadingModalBody, setLoadingModalBody,
        progress, setProgress,
        maxProgress, setMaxProgress,
        emptyLoadingModal
    };
}

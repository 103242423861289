import axios from "axios";
import {VersionInfo} from "../types/version-info";


export class VersionClient {
    getVersionInfo = (productUuid: string, versionNumber: number, versionType: string): Promise<VersionInfo> => {
        return axios.get(process.env.REACT_APP_API_URL + "version/" + productUuid, {
            params: {versionType: versionType, versionNumber: versionNumber}
        }).then((res) => {
            return res.data;
        });
    };
}

export default new VersionClient();

import React from "react";
import logo from "../assets/logo-alpha.svg";
import {redirectToStripe} from "../utils/PayementUtil";
import PaymentFailedHeader from "../components/PaymentFailedHeader";


const PaymentFailedPage: React.FC<{
    logOut: () => void
}> = ({logOut}) => {
    return (
        <>
            <PaymentFailedHeader logOut={logOut}/>
            <div className="payment-failed">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-auto">
                            <img src={logo} height="40" alt="Alpha AR" className="mb-5"/>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body text-center px-4 py-5 p-md-5">
                                    <h4 className="mb-4">Payment failed</h4>
                                    <p className="mb-4">Payment for the previous period has failed.
                                        To continue using Alpha 3D platform,
                                        update the payment method or edit your card limits.</p>
                                    <button onClick={() => redirectToStripe(null)} className="btn btn-success">
                                        Update payment method
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentFailedPage;

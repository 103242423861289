import React, {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import ProductInfoResponse from "../../types/product/product-info-response";
import {useForm} from "react-hook-form";
import {useAlerts} from "../../store/AlertProvider";
import InternalUploadHeader from "../../components/internal-upload/InternalUploadHeader";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {modelAndAttachmentValidation} from "../../validation/model-and-attachment-validation";
import AttachmentClient from "../../services/attachment.client";
import InternalUploadForm from "../../components/internal-upload/InternalUploadForm";
import {Attachment} from "../../types/attachment/attachment-response";
import ModelView from "../../components/model/ModelView";
import {appendFilesAttachmentsToFormData, getModelsAndAttachmentFilesSize} from "../../utils/FileUtils";
import {getInternalModelsAndAttachments, getInternalVersionNumbers, getProduct} from "../../utils/SharedUseEffects";
import {NOT_ALLOWED_INTERNAL_STATUS} from "../../types/product/product-status";
import LoadingModal, {LoadingModalEffects} from "../../components/modals/LoadingModal";
import {ModelsAttachmentsCommentsForm} from "../../types/models-attachments-comments-form";

const ADDING_VERSION = "Adding new internal version";
const PROCESSING_FILES = "Processing files";
const UPLOADING_FILES = "Uploading files";

const InternalUploadPage: React.FC = () => {
    const {productUuid} = useParams();
    const [product, setProduct] = useState<ProductInfoResponse>();
    const {addErrorResponseAlert, addSuccessAlert} = useAlerts();
    const [searchParams] = useSearchParams();
    const versionNumber: string | null = searchParams.get("version");
    const modelNumber: string | null = searchParams.get("model");
    const [versions, setVersions] = useState<number[]>();
    const navigate = useNavigate();
    const [versionModels, setVersionModels] = useState<Attachment[]>([]);
    const [versionAttachments, setVersionAttachments] = useState<Attachment[]>([]);
    const {
        showLoadingModal, setShowLoadingModal,
        loadingModalHeader, setLoadingModalHeader,
        loadingModalBody, setLoadingModalBody,
        progress, setProgress,
        maxProgress, setMaxProgress,
        emptyLoadingModal
    } = LoadingModalEffects();

    const saveRequestMethods = useForm<ModelsAttachmentsCommentsForm>({
        resolver: yupResolver(modelAndAttachmentValidation)
    });

    useEffect(() => {
        getProduct(productUuid, setProduct);
    }, [productUuid]);

    useEffect(() => {
        getInternalVersionNumbers(productUuid, versions, setVersions);
    }, [setVersions, productUuid, versions]);

    useEffect(() => {
        getInternalModelsAndAttachments(versionNumber, productUuid, setVersionModels, setVersionAttachments, saveRequestMethods);
    }, [versionNumber, saveRequestMethods, productUuid]);

    const versionChangedByUserFetchNewModelsAndShowFirstModel = useCallback(() => {
        setVersionModels([]);
        setVersionAttachments([]);
        AttachmentClient.getInternalAttachments(productUuid!, versionNumber!).then(res => {
            setVersionAttachments(res);
        });
        AttachmentClient.getInternalModels(productUuid!, versionNumber!).then(res => {
            setVersionModels(res);
            navigate("/admin/internal-upload/" + productUuid + "?version=" + versionNumber + "&model=" + res.at(0)!.attachmentUuid);
        });
    }, [navigate, productUuid, versionNumber]);

    useEffect(() => {
        if (modelNumber === "" && versionNumber) {
            versionChangedByUserFetchNewModelsAndShowFirstModel();
        }
    }, [versionNumber, modelNumber, versionChangedByUserFetchNewModelsAndShowFirstModel]);

    const save = async () => {
        setLoadingModalHeader(ADDING_VERSION);
        setLoadingModalBody(PROCESSING_FILES);
        setShowLoadingModal(true);
        const modelFormData = new FormData();
        const models = saveRequestMethods.getValues("models");
        const attachments = saveRequestMethods.getValues("attachments");
        appendFilesAttachmentsToFormData(models, attachments, null, modelFormData);
        let filesSize = getModelsAndAttachmentFilesSize(models, attachments);
        setMaxProgress(filesSize);
        setLoadingModalBody(UPLOADING_FILES);

        await AttachmentClient.saveInternalModelsAndAttachments(productUuid!, modelFormData, setProgress)
            .then(() => {
                const newVersionNumber = versions?.at(0) ? (versions.at(0)! + 1) : 1;
                versions?.unshift(newVersionNumber);
                setVersions(versions);
                saveRequestMethods.reset();
                addSuccessAlert("Models successfully saved");
                emptyLoadingModal();
                navigate("/admin/internal-upload/" + productUuid + "?version=" + newVersionNumber);
            })
            .catch(res => addErrorResponseAlert(res));
        emptyLoadingModal();
    };

    function showModel() {
        return <ModelView productUuid={productUuid}
                          product={product}
                          modelNumber={modelNumber}
                          versionModels={versionModels}
                          versionAttachments={versionAttachments}
                          versionNumber={versionNumber}
                          versions={versions}
                          urlPrefix={"/admin/internal-upload/"}/>;
    }

    function showUpload() {
        return <>
            <InternalUploadHeader productUuid={productUuid!}
                                  canSave={!versionNumber && product?.status !== NOT_ALLOWED_INTERNAL_STATUS}
                                  saveNewVersion={saveRequestMethods.handleSubmit(save)}
                                  title={"Internal upload"} versions={versions}/>
            <InternalUploadForm versionModels={versionModels} versionAttachments={versionAttachments}
                                saveRequestMethods={saveRequestMethods} product={product}
                                versionNumber={versionNumber}/>
        </>;
    }

    return (
        <>
            <LoadingModal header={loadingModalHeader}
                          body={loadingModalBody}
                          showModal={showLoadingModal}
                          currentProgress={progress}
                          maxProgress={maxProgress}/>
            {modelNumber != null ? showModel() : showUpload()}
        </>
    );
};

export default InternalUploadPage;

import {FieldError, FieldErrors} from "react-hook-form";
import ProjectRequest from "../types/project/project-request";
import {cloneDeep} from "lodash";
import {ProductionType} from "../types/project/production-type";

export function getError(fieldError: FieldErrors | FieldError[] | FieldError | undefined): string | undefined {
    if (!fieldError) return undefined;
    if (Array.isArray(fieldError)) {
        return fieldError.filter(e => e !== undefined).at(0)!.message;
    } else return fieldError.message;
}

export function createProjectRequest(formValue: ProjectRequest) {
    let request = cloneDeep(formValue);
    if (formValue.productionType === ProductionType.AI_GENERATED) {
        request.specification = "";
        request.attachmentUuids = [];
        let temp: string[] = [];
        if (formValue.removedAttachmentUuids) {
            formValue.removedAttachmentUuids.forEach(uuid => {
                temp.push(uuid);
            });
        }
        if (formValue.attachmentUuids) {
            formValue.attachmentUuids.forEach(uuid => {
                temp.push(uuid);
            });
        }
        request.removedAttachmentUuids = temp;
        request.products.forEach(product => {
            product.notes = null;
        })
    } else if (formValue.productionType === ProductionType.DESIGNER) {
        request.aiMethod = null;
        request.triangleCount = null;
    }
    return request;
}

import AdminHeader from "../../components/admin/AdminHeader";
import UserDashBoard from "../../components/admin/UserDashBoard";
import CompanyDashBoard from "../../components/admin/CompanyDashBoard";
import {useMatch} from "react-router-dom";
import genericClient from "../../services/generic.client";
import {AdminPage} from "../../types/admin-page";
import {useQuery} from "react-query";
import PageableUserResponse from "../../types/user/pageable-user-response";
import {useState} from "react";
import PageableCompanyResponse from "../../types/user/pageable-company-response";
import CompanyResponse from "../../types/company/company-response";

const AdminDashboardPage: React.FC = () => {
    const productMatch = useMatch("/admin/:pageName");

    const [userPageNumber, setUserPageNumber] = useState(0);
    const [userSearchFullName, setUserSearchFullName] = useState("");
    const {data: pageableUserResponse} = useQuery<PageableUserResponse>(
        ['getUsers', userPageNumber, userSearchFullName],
        () => genericClient.getUsers({page: userPageNumber, fullName: userSearchFullName}),
        {cacheTime:0}); //TODO: react-query has no solution for this, it may be edited in the future

    const [companyPageNumber, setCompanyPageNumber] = useState(0);
    const [companySearchName, setCompanySearchName] = useState("");
    const {data: pageableCompanyResponse} = useQuery<PageableCompanyResponse>(['getPaginatedCompaniesResponse', companyPageNumber, companySearchName], () => genericClient.getPaginatedCompaniesResponse({page: companyPageNumber, name: companySearchName}));

    const { data: companiesData } = useQuery<CompanyResponse[]>('getAllCompanies', genericClient.getAllCompaniesResponse);
    return (
        <>
            <AdminHeader
                pageName={productMatch?.params.pageName}
                pageableUserResponse={pageableUserResponse}
                pageableCompanyResponse={pageableCompanyResponse}
            />

            {productMatch?.params.pageName === AdminPage.USERS && (
                <UserDashBoard setUserPageNumber={setUserPageNumber}
                               userSearchFullName={userSearchFullName}
                               setUserSearchFullName={setUserSearchFullName}
                               pageableUserResponse={pageableUserResponse}
                               companies={companiesData}/>
            )}
            {productMatch?.params.pageName === AdminPage.COMPANIES && (
                <CompanyDashBoard setCompanyPageNumber={setCompanyPageNumber}
                                  companySearchName={companySearchName}
                                  setCompanySearchName={setCompanySearchName}
                                  pageableCompanyResponse={pageableCompanyResponse} />
            )}
        </>
    );
};

export default AdminDashboardPage;

import React, {useEffect, useState} from "react";
import ProjectDetailsHeader from "../../components/new-project/project/ProjectDetailsHeader";
import ProjectDetails from "../../components/new-project/project/ProjectDetails";
import ProjectInfoResponse from "../../types/project/project-info-response";
import ProjectClient from "../../services/project.client";
import {useParams} from "react-router-dom";

const ProjectDetailsPage: React.FC = () => {
    const {projectUuid} = useParams();
    const [project, setProject] = useState<ProjectInfoResponse>();

    useEffect(() => {
        if (!projectUuid) return;
        ProjectClient.getProjectInfo(projectUuid).then((res) => {
            setProject(res);
        });
    }, [projectUuid])

    return (
        <>
            <ProjectDetailsHeader
                title={"Details"}
                subTitle={project?.name}
            />
            <ProjectDetails project={project}/>
        </>
    )
}

export default ProjectDetailsPage;

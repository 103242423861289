import React from "react";
import {Link} from "react-router-dom";

const PaymentFailedHeader: React.FC<{
    logOut: () => void
}> = ({logOut}) => {
    return (
        <header className="app-header">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col-md-auto"/>
                    <div className="col-md-auto">
                        <Link to={"/logout"}>
                            <button onClick={logOut} className="btn btn-white">
                                Log out
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default PaymentFailedHeader

import React from "react";
import {truncate} from "../../utils/TextUtil";
import {Attachment} from "../../types/attachment/attachment-response";
import AttachmentClient from "../../services/attachment.client";
import {useLocation, useNavigate} from "react-router-dom";


const VersionAttachments: React.FC<{
    models: Attachment[];
    attachments: Attachment[];
}> = ({models, attachments}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const downloadAttachment = async (attachment: Attachment) => {
        await AttachmentClient.getAttachmentFile(attachment);
    };

    function isModelViewerSupported(file: Attachment) {
        const ext = file.fileName.split(".").pop();
        return ext === "glb" || ext === "gltf";
    }

    return (
        <>
            <div className={"container ps-2"}>
                <label className="form-label">Models</label>
                {Array.isArray(models) &&
                    models.map((file, index) => {
                        return (
                            <div key={index} className={"row align-items-center text-success attachment my-2"}>
                                <div className="col">
                                    {isModelViewerSupported(file)
                                        ? <span role="button" onClick={() => {
                                            navigate(location.pathname + location.search + "&model=" + file.attachmentUuid);
                                        }}>
                                        {truncate(file.fileName, 32)}
                                            <span className="badge float-end badge-green col-2">view 3D</span>
                                    </span>
                                        : <span> {truncate(file.fileName, 32)}</span>
                                    }
                                </div>
                                <span role="button" id={`download-${index}-attachment-${index}-button`}
                                      onClick={() => downloadAttachment(file)}
                                      className="btn-white me-2 float-end col-1">
                                    <i className="bi-download"/>
                                </span>
                            </div>
                        );
                    })}
                <label className="form-label mt-2">Attachments</label>
                {Array.isArray(attachments) &&
                    attachments.map((file, index) => {
                        return (
                            <div key={index} className={"row align-items-center text-success attachment my-2"}>
                                <div className="col">
                                    {truncate(file.fileName, 32)}
                                </div>
                                <span role="button" id={`download-${index}-attachment-${index}-button`}
                                      onClick={() => downloadAttachment(file)}
                                      className="btn-white me-2 float-end col-1">
                                    <i className="bi-download"/>
                                </span>
                            </div>
                        );
                    })}

            </div>
        </>
    );
};

export default VersionAttachments;

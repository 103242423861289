import React from "react";
import {ProductStatus} from "../../types/product/product-status";
import {useSearchParams} from "react-router-dom";

const ClientUploadFooter: React.FC<{
    sendOffer: () => void;
    updateOffer: () => void;
    addNewVersion: () => void;
    status?: ProductStatus;
    isAttachmentButtonVisible?: boolean;
}> = ({sendOffer, updateOffer, addNewVersion, status, isAttachmentButtonVisible}) => {
    const [searchParams] = useSearchParams();
    const versionNumber = searchParams.get("version");

    return (
        <div className="app-footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <hr className="mt-0" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5 offset-md-1"/>
                    <div className="col-md-5 text-end">
                        {status && status === ProductStatus.IN_PRODUCTION
                            ? <button onClick={() => updateOffer()} className="btn btn-black me-2">
                                Update offer
                            </button>
                            : <button onClick={() => sendOffer()} className="btn btn-black me-2">
                                Send offer
                            </button>
                        }
                        {!versionNumber && status && isAttachmentButtonVisible && (
                            <button onClick={() => addNewVersion()} className="btn btn-primary">
                                Send files to client
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientUploadFooter;

import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ProductClient, {formatErrorResponse} from "../../services/product.client";
import ProductInfoResponse from "../../types/product/product-info-response";
import Product from "../../components/product/Product";
import {
    APPROVABLE_PRODUCT_STATUSES,
    ProductStatus,
    VIEWABLE_PRODUCT_STATUSES
} from "../../types/product/product-status";
import {useAlerts} from "../../store/AlertProvider";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {AddProductCommentRequest, CommentDTO} from "../../types/product/product-comments-dtos";
import {newCommentValidation} from "../../validation/new-comment-validation";
import ProductHeader from "../../components/product/ProductHeader";
import {Attachment} from "../../types/attachment/attachment-response";
import AttachmentClient from "../../services/attachment.client";
import useUrlState from "@ahooksjs/use-url-state";
import {getProduct} from "../../utils/SharedUseEffects";
import {
    fetchModel,
    fetchVersionModelsAndAttachments,
    setCommentReplyVisibleIfLatestVersion,
    setDefaultVersionWhenNoneProvided
} from "../../utils/ProductPageUseEffects";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import {useAuthContext} from "../../store/AuthProvider";
import {UserRole} from "../../types/user/user-role";
import PaymentClient from "../../services/company.client";
import {AlphaModel} from "../../components/model/ModelViewer";
import {redirectToStripe} from "../../utils/PayementUtil";
import ProblemWithPaymentModal from "../project/ProblemWithPaymentModal";
import {PRODUCT_APPROVAL, READY_TO_DOWNLOAD_CONFIRMATION_BODY} from "../../common-texts";
import {ProductionType} from "../../types/project/production-type";
import {getAnalytics, logEvent} from "firebase/analytics";
import GetMoreTokensModal from "../../components/modals/GetMoreTokensModal";
import {AlphaPayment} from "../../types/payment-price";
import {usePaymentToken} from "../../store/PaymentTokenProvider";
import CreateSubscriptionModal from "../../components/modals/CreateSubscriptionModal";
import {useQuery} from "react-query";
import {SubscriptionInfo} from "../../types/subscription-info";
import SubscriptionClient from "../../services/subscription.client";

const ProductPage: React.FC = () => {
    const {productUuid} = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState<ProductInfoResponse>();
    const [comments, setComments] = useState<CommentDTO[]>([]);
    const [totalBalance, setTotalBalance] = useState<number>();
    const {addSuccessAlert, addErrorAlert, addErrorResponseAlert} = useAlerts();
    const addCommentRequestMethods = useForm<AddProductCommentRequest>({
        resolver: yupResolver(newCommentValidation),
    });
    const [versionModels, setVersionModels] = useState<Attachment[]>();
    const [versionAttachments, setVersionAttachments] = useState<Attachment[]>();
    const [versions, setVersions] = useState<number[]>();
    const [model, setModel] = useState<AlphaModel>();
    const [isModelLoading, setIsModelLoading] = useState<boolean>(false);
    const [showPaymentFailedModal, setShowPaymentFailedModal] = React.useState<boolean>(false);

    const [versionNumber, setVersionNumber] = useUrlState({"version": ""}, {navigateMode: "replace"});
    const [modelNumber, setModelNumber] = useUrlState({"model": ""}, {navigateMode: "replace"});
    const [isReplyVisible, setIsReplyVisible] = useState<boolean>(false);
    const [showApproveConfirmationModal, setShowApproveConfirmationModal] = useState<boolean>(false);
    const [showDownloadConfirmationModal, setShowDownloadConfirmationModal] = useState<boolean>(false);
    const [showOutOfTokensModal, setShowOutOfTokensModal] = useState<boolean>(false);
    const [showCreateSubModal, setShowCreateSubModal] = useState<boolean>(false);
    const [show3DToggleConfirmationModal, setShow3DToggleConfirmationModal] = useState<boolean>(false);
    const {hasRole} = useAuthContext();
    const [downloading, setDownloading] = useState<boolean>(false);
    const [isConfirmationLoading, setIsConfirmationLoading] = useState<boolean>(false);
    const [showMoveToEditingConfirmationModal, setShowMoveToEditingConfirmationModal] = React.useState<boolean>(false);
    const analytics = getAnalytics();
    const {prices, tokens, refetchTokenAmount} = usePaymentToken();
    const {data: subscriptionInfo} = useQuery<SubscriptionInfo>('subscriptionInfo', SubscriptionClient.getSubscriptionInfo);

    useEffect(() => {
        setCommentReplyVisibleIfLatestVersion(versionNumber.version, versions, setIsReplyVisible);
    }, [versions, versionNumber.version])

    const modelNumberRef = useRef(modelNumber.model);
    useLayoutEffect(() => {
        modelNumberRef.current = modelNumber.model
    }, [modelNumber.model]);

    useEffect(() => {
        getProduct(productUuid, setProduct);
    }, [productUuid, setProduct]);

    useEffect(() => {
        if (!product?.status || product?.projectUuid) return;
        if (VIEWABLE_PRODUCT_STATUSES.find(status => status === product?.status) === undefined) {
            addErrorAlert("Can't view product which is pending quote");
            navigate("/project/" + product.projectUuid);
        }
    }, [product?.status, product?.projectUuid, addErrorAlert, navigate])

    useEffect(() => {
        if (!product?.productUuid) return;
        AttachmentClient.getClientVersionNumbers(product.productUuid).then((res) => {
            setVersions(res);
        });
    }, [product?.productUuid]);

    useEffect(() => {
        fetchVersionModelsAndAttachments(product?.productUuid, versionNumber.version, setVersionModels, modelNumberRef, setModelNumber, setVersionAttachments);
    }, [versionNumber.version, product?.productUuid, setModelNumber]);

    useEffect(() => {
        setDefaultVersionWhenNoneProvided(versionNumber.version, versions, setVersionNumber, setVersionModels);
    }, [versionNumber.version, versions, setVersionNumber])

    useEffect(() => {
        if (!product?.modelViewerEnabled) return
        fetchModel(versionModels, modelNumber.model, addErrorAlert, setModelNumber, setModel, setIsModelLoading);
    }, [product?.modelViewerEnabled, modelNumber.model, versionModels, addErrorAlert, setModelNumber]);

    useEffect(() => {
        if (!product?.productUuid) return;
        ProductClient.getProductComments(product.productUuid).then(res => {
            setComments(res);
        });
    }, [product?.productUuid]);

    useEffect(() => {
        if (totalBalance) return;
        PaymentClient.getCompanyBalance().then(res => {
            setTotalBalance(res);
        })
    }, [totalBalance]);

    const closeConfirmationHandler = () => {
        setShowMoveToEditingConfirmationModal(false);
    }

    const handleConfirmation = () => {
        setShowMoveToEditingConfirmationModal(true);
    }
    const approveProduct = async () => {
        if (!product) return;
        setIsConfirmationLoading(true);
        ProductClient.approveProduct(product.productUuid)
            .then(() => {
                addSuccessAlert("Product approved");
                product.status = ProductStatus.APPROVED;
            })
            .catch(() => {
                setShowPaymentFailedModal(true);
            })
            .finally(() => {
                setShowApproveConfirmationModal(false)
                setIsConfirmationLoading(false);
            })
    }

    const downloadModels = async () => {
        if (!product) return;
        setDownloading(true);
        logEvent(analytics, "download_models_button_clicked");
        AttachmentClient.getModels(product.productUuid)
            .catch((err) => addErrorResponseAlert(formatErrorResponse(err)))
            .finally(() => setDownloading(false));
    }

    const addComment = async (request: AddProductCommentRequest) => {
        if (!productUuid) return;

        const commentFormData = new FormData();
        Array.from(request.files).forEach((file) => {
            commentFormData.append("file", file, file.name);
        });
        commentFormData.append("text", request.text);

        ProductClient.addComment(productUuid, commentFormData)
            .then(res => {
                let temp: CommentDTO[] = comments.splice(0);
                temp.push(res);
                setComments(temp);
            })
            .catch(res => addErrorResponseAlert(formatErrorResponse(res.response)));
    }

    const moveToEditing = () => {
        ProductClient.moveToEditing(productUuid!).then(() => {
            setProduct({...product!, status: ProductStatus.IN_EDITING});
        }).catch(res => addErrorResponseAlert(res));
        closeConfirmationHandler();
    }

    const getHeaderTitle = () => {
        if (!product) return ""
        return `${product.brandName} · ${product.modelName}`
    }

    const isApproveProductButtonVisible = () => {
        if (!product || !product.status || !versions || versions.length === 0 || (versionNumber.version !== versions[0].toString())) return false;
        if (!hasRole(UserRole.ROLE_USER)) return false;
        return (APPROVABLE_PRODUCT_STATUSES.find((status: ProductStatus) => status === product.status) !== undefined);
    }

    const isMoveToEditingButtonVisible = () => {
        if (product?.status !== ProductStatus.READY_FOR_QC) return false;
        if (!hasRole(UserRole.ROLE_ADMIN)) return false;
        if (versions == null || versionNumber.version !== versions[0].toString()) return false;

        return true;
    }

    const getApproveProductText = () => {
        const balance = Math.abs(totalBalance!);

        return "Once approved, you can no longer make edits to the 3D model. You will be able to download the final 3D model on the next page." +
            "\n\n" +
            "The fee of " + product?.price + " € will automatically be added to your balance. Your current balance is " + balance + " €.";
    }

    const toggleModelViewerStatus = async () => {
        if (!product) return;

        ProductClient.toggleModelViewer(product.productUuid, !product.modelViewerEnabled)
            .then(() => {
                let p2 = Object.assign({}, product);
                p2.modelViewerEnabled = !product.modelViewerEnabled;
                setProduct(p2);
                addSuccessAlert("Model viewer " + (product.modelViewerEnabled ? "disabled" : "enabled"));
            })
            .catch((res) => addErrorResponseAlert(formatErrorResponse(res.response)));
        setShow3DToggleConfirmationModal(false);
    };

    const hasModels = (): boolean => {
        return !!(versions && versions.length > 0)
    }

    const isAiProject = (): boolean => {
        return !!(product && product.productionType === ProductionType.AI_GENERATED);
    }

    const isModelViewEnabled = (): boolean => {
        return !!(product && product.modelViewerEnabled && hasModels());
    }

    const isCommentsVisible = (): boolean => {
        return !isAiProject() && hasModels();
    }

    const isGalleryVisible = (): boolean => {
        if (isAiProject()) {
            if (product && product.status === ProductStatus.APPROVED) {
                return false;
            }
        }
        return true;
    }

    const unlockModelDownload = () => {
        ProductClient.unlockProductDownload(productUuid!)
            .then(() => {
                setShowDownloadConfirmationModal(false);
                refetchTokenAmount();
                downloadModels();
                product!.downloadUnlocked = true;
            })
            .catch((err) => {
                setShowDownloadConfirmationModal(false);
                addErrorResponseAlert(formatErrorResponse(err.response));
            })
    }

    function handleOutOfTokens() {
        setShowOutOfTokensModal(false);
        if (subscriptionInfo?.activeSubscription) {
            redirectToStripe(addErrorResponseAlert);
            return;
        }
        setShowCreateSubModal(true);
    }

    const getCost = () => {
        return prices.get(AlphaPayment.DOWNLOAD_MODEL)!
    }

    const readyToDownloadModelBody = () => {
        let modelsText = "";

        versionModels?.forEach(model => {
            modelsText += "." + model.fileName.split(".").pop() + " file";
        })

        return READY_TO_DOWNLOAD_CONFIRMATION_BODY.replace("{0}", modelsText).replace("{1}", prices.get(AlphaPayment.DOWNLOAD_MODEL)?.toString()!);
    }

    function checkShowUnlockDownloadModel() {
        if (getCost() < tokens) {
            setShowDownloadConfirmationModal(true);
            return;
        }

        setShowOutOfTokensModal(true);
    }

    return (
        <>
            <ConfirmationModal
                header="Approve 3D model?"
                body={getApproveProductText()}
                handleClose={() => setShowApproveConfirmationModal(false)}
                handleConfirmation={() => approveProduct()}
                showModal={showApproveConfirmationModal}
                id={"approve-product-modal"}
                loading={isConfirmationLoading}
            />
            <ConfirmationModal
                header="Ready to download your 3D model?"
                body={readyToDownloadModelBody()}
                handleClose={() => setShowDownloadConfirmationModal(false)}
                handleConfirmation={() => unlockModelDownload()}
                showModal={showDownloadConfirmationModal}
                id={"approve-product-modal"}
                loading={isConfirmationLoading}
            />
            <GetMoreTokensModal handleClose={() => setShowOutOfTokensModal(false)}
                                handleConfirmation={handleOutOfTokens}
                                showModal={showOutOfTokensModal} cost={getCost()} tokens={tokens}/>
            <CreateSubscriptionModal showModal={showCreateSubModal}
                                     handleClose={() => setShowCreateSubModal(false)}/>
            <ProblemWithPaymentModal
                handleClose={() => setShowPaymentFailedModal(false)}
                handleConfirmation={() => redirectToStripe(addErrorResponseAlert)}
                showModal={showPaymentFailedModal}
                bodyText={PRODUCT_APPROVAL}
            />
            <ConfirmationModal
                header={product?.modelViewerEnabled ? "Turning off 3D viewer" : "Turning on 3D viewer"}
                body={product?.modelViewerEnabled ? "Are you sure you want to turn off 3D viewer?" : "Are you sure you want to turn on 3D viewer?"}
                handleClose={() => setShow3DToggleConfirmationModal(false)}
                handleConfirmation={() => toggleModelViewerStatus()}
                showModal={show3DToggleConfirmationModal}
            />
            <ProductHeader
                versionNumber={versionNumber.version}
                versions={versions}
                title={getHeaderTitle()}
                product={product}
                modelNumber={modelNumber.model}
                versionAttachments={versionAttachments}
                versionModels={versionModels}
                downloadModels={() => downloadModels()}
                showUnlockDownloadModel={checkShowUnlockDownloadModel}
                isDownloading={downloading}
                approveProduct={() => setShowApproveConfirmationModal(true)}
                isAiProject={isAiProject()}
                isApproveProductButtonVisible={isApproveProductButtonVisible()}
                isMoveToEditingButtonVisible={isMoveToEditingButtonVisible()}
                confirmationHandler={handleConfirmation}/>
            <Product
                comments={comments}
                product={product}
                addComment={addCommentRequestMethods.handleSubmit(addComment)}
                addCommentRequestMethods={addCommentRequestMethods}
                productUuid={productUuid}
                model={model}
                isReplyVisible={isReplyVisible}
                toggleModelViewerStatus={toggleModelViewerStatus}
                approveProduct={() => setShowApproveConfirmationModal(true)}
                toggle3DViewerOnOff={() => setShow3DToggleConfirmationModal(true)}
                isModelViewVisible={isModelViewEnabled()}
                isApproveProductButtonVisible={isApproveProductButtonVisible()}
                isCommentsVisible={isCommentsVisible()}
                isGalleryVisible={isGalleryVisible()}
                isModelLoading={isModelLoading}
            />
            <ConfirmationModal
                header="Are you sure?"
                body={
                    "Are you sure you want to move this product to editing?"}
                handleClose={() => setShowMoveToEditingConfirmationModal(false)}
                handleConfirmation={moveToEditing}
                showModal={showMoveToEditingConfirmationModal}
            />
        </>
    )
}

export default ProductPage;

import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ProjectInfoResponse from "../../types/project/project-info-response";
import ProjectClient from "../../services/project.client";
import {formatErrorResponse} from "../../services/product.client"
import AddNewProductHeader from "../../components/new-project/AddNewProductHeader";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import AddNewProductFooter from "../../components/new-project/AddNewProductFooter";
import AddNewProduct from "../../components/new-project/AddNewProduct";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {AddNewProductsRequest, ProductRequest} from "../../types/product/add-new-products-dtos";
import {newProductValidationSchema} from "../../validation/new-product-validation";
import {useAlerts} from "../../store/AlertProvider";
import {ProjectStatus} from "../../types/project/project-status";
import LoadingModal, {LoadingModalEffects} from "../../components/modals/LoadingModal";
import {useAuthContext} from "../../store/AuthProvider";
import {AxiosResponse} from "axios";
import AttachmentClient from "../../services/attachment.client";
import {checkIfAnyFileUploading} from "../../utils/SharedUseEffects";

const ADDING_PRODUCTS_HEADER = "Adding products";
const ADDING_PRODUCT_INFO = "Adding product info";

const CONF_MODAL_HEAD = "Are you sure you want to add new products?";
const CONF_MODAL_BODY = "We will review the request and provide you the production time and cost soon under the Pending quotes page.";

const AddNewProductPage: React.FC = () => {
    const {projectUuid} = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState<ProjectInfoResponse>();
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const saveRequestMethods = useForm<AddNewProductsRequest>({
        resolver: yupResolver(newProductValidationSchema)
    });
    const {addSuccessAlert, addErrorResponseAlert} = useAlerts();
    const {emptyPendingQuote} = useAuthContext();
    const request: AddNewProductsRequest = saveRequestMethods.watch();
    const [isAnyFileUploading, setIsAnyFileUploading] = useState<boolean>(false)


    const onAddProduct: SubmitHandler<AddNewProductsRequest> = (data) => handleAddProduct(data);

    const onValidate: SubmitHandler<AddNewProductsRequest> = () => {
        setShowConfirmationModal(true);
    };

    useEffect(() => {
        checkIfAnyFileUploading(request, setIsAnyFileUploading);
    }, [request])

    const {
        showLoadingModal, setShowLoadingModal,
        loadingModalHeader, setLoadingModalHeader,
        loadingModalBody, setLoadingModalBody,
        emptyLoadingModal
    } = LoadingModalEffects();

    useEffect(() => {
        if (!projectUuid) return;
        ProjectClient.getProjectInfo(projectUuid, ProjectStatus.PENDING_QUOTES).then(res => {
            setProject(res);
            saveRequestMethods.setValue("productionType", res.productionType);
        });
    }, [projectUuid, saveRequestMethods])

    const handleFail = (err: { response: AxiosResponse; }) => {
        emptyLoadingModal();
        addErrorResponseAlert(formatErrorResponse(err.response));
        return true;
    }

    const handleAddProduct = async (formValue: AddNewProductsRequest) => {
        if (!projectUuid) return;

        setShowConfirmationModal(false);
        setLoadingModalHeader(ADDING_PRODUCTS_HEADER);
        setLoadingModalBody(ADDING_PRODUCT_INFO);
        setShowLoadingModal(true);
        let failed = false;

        const response = await ProjectClient.addProducts(projectUuid, formValue.products)
            .catch(res => {
                failed = handleFail(res);
            });
        if (failed || !response) return;
        emptyPendingQuote();

        emptyLoadingModal();

        addSuccessAlert("Products added successfully");
        navigate("/project/" + projectUuid);
    }

    async function handleCancel() {
        let products: ProductRequest[] = saveRequestMethods.getValues("products")
        let stragglingAttachmentUuids: string[] = [];
        products.forEach(product => {
            product.notSavedAttachmentUuids.forEach(attachmentUuid => {
                stragglingAttachmentUuids.push(attachmentUuid);
            })
        })

        await AttachmentClient.deleteStragglingThumbnails(stragglingAttachmentUuids);
        navigate("/project/" + projectUuid);
    }

    async function handleProductRemove(index: number) {
        let removedProduct: ProductRequest = saveRequestMethods.getValues("products")[index];
        let stragglingAttachmentUuids: string[] = [];
        removedProduct.notSavedAttachmentUuids.forEach(notSavedAttachmentUuid => {
            stragglingAttachmentUuids.push(notSavedAttachmentUuid);
        });
        await AttachmentClient.deleteStragglingThumbnails(stragglingAttachmentUuids);
    }

    return <>
        <ConfirmationModal
            header={CONF_MODAL_HEAD}
            body={CONF_MODAL_BODY}
            size={"lg"}
            handleClose={() => setShowConfirmationModal(false)}
            handleConfirmation={saveRequestMethods.handleSubmit(onAddProduct)}
            showModal={showConfirmationModal}
        />
        <LoadingModal header={loadingModalHeader}
                      body={loadingModalBody}
                      showModal={showLoadingModal}/>
        <AddNewProductHeader
            filesUploading={isAnyFileUploading}
            projectName={project?.name}
            addProduct={saveRequestMethods.handleSubmit(onValidate)}
            cancel={handleCancel}/>
        <AddNewProduct
            removeProduct={handleProductRemove}
            saveRequestMethods={saveRequestMethods}
            onSubmit={saveRequestMethods.handleSubmit(onValidate)}/>
        <AddNewProductFooter
            filesUploading={isAnyFileUploading}
            addProduct={saveRequestMethods.handleSubmit(onValidate)}
            cancel={handleCancel}/>
    </>
}

export default AddNewProductPage;

import * as Yup from "yup";
import {UserRole} from "../types/user/user-role";

export const internalUserCreationValidation = Yup.object().shape({
    email: Yup.string().email("This is not a valid email.").required("This field is required!"),
    firstName: Yup.string().required("This field is required!"),
    lastName: Yup.string().required("This field is required!"),
    password: Yup.string()
        .test(
            "Len",
            "The password must be between 8 and 30 characters.",
            (val: any) => val.toString().length >= 8 && val.toString().length <= 30
        )
        .required("This field is required!")
        .test("uppercase", "The password must contain one uppercase character.", (val: any) => {
            return /[A-Z]/.test(val.toString());
        })
        .test("lowercase", "The password must contain one lowercase character.", (val: any) => {
            return /[a-z]/.test(val.toString());
        })
        .test("special character", "The password must contain one special character.", (val: any) => {
            return /[!@#$%^&*(),.?":{}|<>]/.test(val.toString());
        })
        .test("number", "The password must contain one number.", (val: any) => {
            return /\d/.test(val.toString());
        }),
    role: Yup.string().required("This field is required!"),
    company: Yup.string().when("role", {
        is: (role: UserRole) => role === UserRole.ROLE_USER,
        then: Yup.string()
            .label("Company")
            .required("This field is required!")
    })
});

export const companyCreationValidation = Yup.object().shape({
    name: Yup.string().required("This field is required!"),
    country: Yup.string().required("This field is required!"),
    registrationNumber: Yup.string().required("This field is required"),
    address: Yup.string().required("This field is required!"),
    billingEmail: Yup.string().required("This field is required!").email("This is not a valid email."),
    vatNumber: Yup.string().nullable(),
});

export const externalUserCreationValidation = Yup.object().shape({
    email: Yup.string().email("This is not a valid email.").required("This field is required!"),
    firstName: Yup.string().required("This field is required!"),
    lastName: Yup.string().required("This field is required!"),
    password: Yup.string()
        .test(
            "Len",
            "The password must be between 8 and 30 characters.",
            (val: any) => val.toString().length >= 8 && val.toString().length <= 30
        )
        .required("This field is required!")
        .test("uppercase", "The password must contain one uppercase character.", (val: any) => {
            return /[A-Z]/.test(val.toString());
        })
        .test("lowercase", "The password must contain one lowercase character.", (val: any) => {
            return /[a-z]/.test(val.toString());
        })
        .test("special character", "The password must contain one special character.", (val: any) => {
            return /[!@#$%^&*(),.?":{}|<>]/.test(val.toString());
        })
        .test("number", "The password must contain one number.", (val: any) => {
            return /\d/.test(val.toString());
        }),
    passwordAgain: Yup.string()
        .required("This field is required!")
        .test('passwords-match', 'Inserted passwords do not match.', function (value) {
            return this.parent.password === value
        }),
    isCompany: Yup.boolean(),
    company: Yup.object().when("isCompany", {
        is: true,
        then: companyCreationValidation
    })
});

export const newUserCompanyCreationValidation = Yup.object().shape({
    companyInfo: Yup.object().when("isCompany", {
        is: true,
        then: companyCreationValidation
    })
});

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import PrefilledInput from "../../common/PrefilledInput";
import PrefilledTextArea from "../../common/PrefilledTextArea";
import PrefilledDropdown from "../../common/PrefilledDropdown";
import ProjectInfoResponse from "../../../types/project/project-info-response";
import AttachmentClient from "../../../services/attachment.client";
import {Attachment} from "../../../types/attachment/attachment-response";
import FileListItem from "../../FileListItem";
import {PRODUCTION_TYPE_TRANSLATIONS, ProductionType} from "../../../types/project/production-type";
import {AI_METHOD_TRANSLATIONS, AiMethod} from "../../../types/project/ai-method-type";

const ProjectDetails: React.FC<{ project: ProjectInfoResponse | undefined }> = ({project}) => {
    const {projectUuid} = useParams();
    const [downloadedFiles, setDownloadedFiles] = useState<Attachment[]>();

    useEffect(() => {
        if (!projectUuid) return;
        AttachmentClient.getProjectThumbnails(projectUuid)
            .then((res) => {
                setDownloadedFiles(res.thumbnails);
            })
    }, [projectUuid])

    const downloadAttachment = async (attachment: Attachment) => {
        await AttachmentClient.getAttachmentFile(attachment);
    };

    function getSpecificationAndFileList() {
        return <div className="col-md-5">
            <div className="mb-3">
                <PrefilledTextArea
                    name={"specification"}
                    label={"Project specification"}
                    value={project?.specification}
                />
            </div>

            <div className="mb-0">
                <div className="attachments-list">
                    {Array.isArray(downloadedFiles) && downloadedFiles.length > 0 &&
                        downloadedFiles.map((file, index) => {
                            return <FileListItem
                                key={index}
                                fileName={file.fileName}
                                downloadAttachment={() => downloadAttachment(file)}/>
                        })}
                </div>
            </div>
        </div>;
    }

    function getAiFields() {
        return <>
            <div className="mb-0">
                <PrefilledDropdown name={"aiMethod"} label={"Category"} value={project && project.aiMethod && AI_METHOD_TRANSLATIONS[project.aiMethod]}/>
            </div>
            {project?.aiMethod === AiMethod.MESH
                ? <div className="mb-0">
                    <PrefilledDropdown name={"triangleCount"} label={"Expected triangle count"}
                                       value={project?.triangleCount}/>
                </div>
                : <></>
            }
        </>;
    }

    return (
        <main className="view-content -with-footer container">
            <div className="row">
                <div className="col-md-5 offset-md-1">
                    <div className="mb-3">
                        <PrefilledInput name={"projectName"} label={"Project name"} value={project?.name}/>
                    </div>
                    <div className="mb-0">
                        <PrefilledDropdown name={"endPoint"} label={"End point"} value={project?.endPoint}/>
                    </div>
                    <div className="mb-0">
                        <PrefilledDropdown name={"productionType"} label={"Production type"} value={project && PRODUCTION_TYPE_TRANSLATIONS[project.productionType]}/>
                    </div>
                    {project && project.productionType === ProductionType.AI_GENERATED &&
                        getAiFields()
                    }
                </div>
                {project && project.productionType === ProductionType.DESIGNER &&
                    getSpecificationAndFileList()
                }
            </div>
        </main>
    )
}

export default ProjectDetails;

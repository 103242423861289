import {BrowserRouter} from "react-router-dom";
import "./scss/app.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {AuthProvider} from "./store/AuthProvider";
import {createRoot} from "react-dom/client";
import {StrictMode} from "react";
import {AlertProvider} from "./store/AlertProvider";
import "@google/model-viewer";
import {ClassificationProvider} from "./store/ClassificationProvider";
import {QueryClient, QueryClientProvider} from "react-query";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import {UploadQueueProvider} from "./store/UploadQueueProvider";
import {PaymentTokenProvider} from "./store/PaymentTokenProvider";

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);
const queryClient = new QueryClient();

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'model-viewer': MyElementAttributes;
        }

        interface MyElementAttributes {
            src: string;
            ar: boolean;
        }
    }
}

root.render(
    <StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <AuthProvider>
                    <AlertProvider>
                        <ClassificationProvider>
                            <PaymentTokenProvider>
                            <UploadQueueProvider>
                                <App/>
                            </UploadQueueProvider>
                            </PaymentTokenProvider>
                        </ClassificationProvider>
                    </AlertProvider>
                </AuthProvider>
            </BrowserRouter>
        </QueryClientProvider>
    </StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import {Spinner} from "react-bootstrap";
import {AsyncAttachmentProps} from "./ExistingAsyncAttachment";

const FileHandlerListItem: React.FC<AsyncAttachmentProps> = ({attachment, loading, handleRemove}) => {
    return (
        <div className="attachment">
        <span className={"filename"}>
            {attachment?.fileName}
        </span>
            <div className={"actions"}>
                {!loading &&
                    <button type="button" onClick={handleRemove} className={`btn btn-white btn-icon p-1 px-2 shadow ms-2`}>
                        <i className="bi bi-trash3"></i>
                    </button>
                }
                {loading &&
                    <Spinner animation={"border"} className={"file-upload-list-item-loading align-middle"}/>
                }
            </div>
        </div>
    );
};

export default FileHandlerListItem;

import {formatErrorResponse} from "../services/product.client";
import StripeClient from "../services/stripe.client";
import {useAlerts} from "../store/AlertProvider";


export function redirectToStripe(addErrorResponseAlert: ReturnType<typeof useAlerts>["addErrorResponseAlert"] | null) {
    StripeClient.createCheckoutSession(window.location.href)
        .then(res => {
            window.location.href = res
        })
        .catch(
            res => {
                if (addErrorResponseAlert) {
                    addErrorResponseAlert(formatErrorResponse(res.response))
                }
            }
        )
}

import {JwtCustomPayload} from "../../common/JwtCustomPayload";

export class UserAccount {
    name: string;
    email: string;
    company: string;
    roles: Array<string> | null;
    expires: number;
    pendingQuotes: number | null;
    paymentFailure: boolean | null;
    automaticInvoicing: boolean | null;
    status: string;

    constructor(jwtToken: JwtCustomPayload, expires: number) {
        this.name = jwtToken.sub ?? "";
        this.email = jwtToken.email ?? "";
        this.company = jwtToken.company ?? "";
        this.roles = jwtToken.roles ?? [];
        this.expires = expires;
        this.status = jwtToken.status?? "";
        this.pendingQuotes = null;
        this.paymentFailure = null;
        this.automaticInvoicing = null;
    }
}

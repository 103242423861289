import React, {useEffect} from "react";
import {FieldError, useWatch} from "react-hook-form";
import NewInput from "../../NewInput";
import NewDropdown from "../../NewDropdown";
import RequestResult from "../../RequestResult";
import {UserRole} from "../../../types/user/user-role";
import RolesExplained from "../RolesExplained";
import CompanyDropdown from "../../CompanyDropdown";
import CompanyResponse from "../../../types/company/company-response";

//TODO: weird error declaration
const AdminUserModal: React.FC<{
    handleClose: () => void;
    formError: string;
    successful: boolean;
    errors:
        | {
        email?: FieldError;
        firstName?: FieldError;
        lastName?: FieldError;
        password?: FieldError;
        role?: FieldError;
        company?: FieldError;
    }
        | undefined;
    companies: CompanyResponse[] | undefined;
    clearCompanyValue: () => void;
}> = ({handleClose, formError, successful, errors, companies, clearCompanyValue}) => {
    const selectedRole = useWatch({name: "role"});

    useEffect(() => {
        if (selectedRole !== UserRole.ROLE_USER) {
            clearCompanyValue();
        }
    }, [selectedRole, clearCompanyValue]);

    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title ms-2" id="addNewUserModal">
                        Add new user
                    </h4>
                    <button
                        onClick={handleClose}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
                <div className="modal-body ">
                    <div className="row">
                        <div className="col ms-2">
                            <NewInput name={"email"} errorMessage={errors?.email?.message} label="Email"/>
                            <NewInput name={"firstName"} errorMessage={errors?.firstName?.message} label="First name"/>
                            <NewInput name={"lastName"} errorMessage={errors?.lastName?.message} label="Last name"/>
                            <NewInput name={"password"} errorMessage={errors?.password?.message} label="Password"/>
                            <NewDropdown
                                values={Object.values(UserRole)}
                                label="User role"
                                name={"role"}
                                errorMessage={errors?.role?.message}
                            />
                            {selectedRole === UserRole.ROLE_USER && companies && companies.length > 0 && (
                                <CompanyDropdown
                                    companies={companies}
                                    label="Company"
                                    name={"company"}
                                    errorMessage={errors?.company?.message}
                                />
                            )}
                        </div>
                        <div className="col pt-4">
                            <RolesExplained/>
                        </div>
                    </div>
                    <RequestResult successfulMessage="User created." error={formError} successful={successful}/>
                </div>
                <div className="modal-footer">
                    <button onClick={handleClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                        Cancel
                    </button>
                    <button className="btn btn-primary">Add new user</button>
                </div>
            </div>
        </>
    );
};

export default AdminUserModal;

import {ProductionType} from "../types/project/production-type";
import {Analytics, logEvent} from "firebase/analytics";
import {AiMethod} from "../types/project/ai-method-type";


export class AnalyticsService {
    logCreateProjectEvent(analytics: Analytics, productionType: ProductionType, productsAmount: number, projectsGeneratedCount: Map<ProductionType, number> | undefined, aiMethod: AiMethod | null): void {
        try {
            let count = 0;
            if (productionType === ProductionType.DESIGNER) {
                count = (projectsGeneratedCount?.get(ProductionType.DESIGNER) || 0) + 1;
                logEvent(analytics, 'order_designer_project', {
                    productionType: productionType,
                    productsAmount: productsAmount,
                    projectsGeneratedCount: count
                });
            } else if (productionType === ProductionType.AI_GENERATED) {
                count = (projectsGeneratedCount?.get(ProductionType.AI_GENERATED) || 0) + 1;
                logEvent(analytics, 'generate_ai_project', {
                    productionType: productionType,
                    productsAmount: productsAmount,
                    projectsGeneratedCount: count,
                    aiMethod: aiMethod
                });
            }
            else if (productionType === ProductionType.AI_GENERATED && ProductionType.DESIGNER) {
                count = (projectsGeneratedCount?.get(ProductionType.AI_GENERATED) || 0) + 1;
                logEvent(analytics, 'total_3d_models_generated', {
                    productionType: productionType,
                    productsAmount: productsAmount,
                    projectsGeneratedCount: count,
                    aiMethod: aiMethod
                });
            }
        } catch (err) {
            console.error(err);
        }

    }
}

export default new AnalyticsService();

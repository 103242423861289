import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AuthService from "../services/auth.service";
import {FormProvider, SubmitHandler, useForm, useWatch} from "react-hook-form";
import NewInput from "../components/NewInput";
import {getError} from "../utils/ValidationUtil";
import CheckBox from "../components/CheckBox";
import RequestResult from "../components/RequestResult";
import NewDropdown from "../components/NewDropdown";
import ClassificationClient from "../services/classification.client";
import {ALERT_LIFETIME} from "../components/NewAlert";
import LoadingButton from "../components/product/LoadingButton";
import {useQuery} from "react-query";
import EventBus from "../common/EventBus";
import {useAuthContext} from "../store/AuthProvider";
import {UserAccount} from "../types/user/user-account";
import jwtDecode from "jwt-decode";
import {JwtCustomPayload} from "../common/JwtCustomPayload";
import NewUserCompanyCreationRequest from "../types/user/new-user-company-creation-request";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {newUserCompanyCreationValidation} from "../validation/internal-user-creation-validation";
import UserClient from "../services/user.client";
import {useClassifications} from "../store/ClassificationProvider";


const CompanySetup: React.FC = () => {
    const [formError, setFormError] = useState<string>("");
    const methods = useForm<NewUserCompanyCreationRequest>({
            defaultValues: {
                isCompany: false
            },
            resolver: yupResolver(newUserCompanyCreationValidation)
        }
    );
    const isCompany = useWatch({control: methods.control, name: "isCompany"});
    const onSubmit: SubmitHandler<NewUserCompanyCreationRequest> = (data) => handleRegistration(data);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const {setUser} = useAuthContext();
    const {reFetchProductionTypes} = useClassifications();

    const {
        data: countries
    } = useQuery('countriesClassification', ClassificationClient.getCountriesClassifications);

    useEffect(() => {
        if (!isCompany) {
            methods.setValue("companyInfo", undefined);
        }
    }, [methods, isCompany]);

    useEffect(function clearErrorAfterLifeTimeEnd() {
        const timeOut = setTimeout(() => {
            setFormError("");
        }, ALERT_LIFETIME);
        return () => {
            clearTimeout(timeOut)
        }
    }, [formError]);

    const handleRegistration = (formValue: NewUserCompanyCreationRequest) => {
        setLoading(true);
        AuthService.registerNewUserCompany(formValue).then(
            (res) => {
                refreshToken().then();
                setFormError("");
                methods.reset();
                reFetchProductionTypes();
                setLoading(false);
            },
            (err) => {
                setLoading(false);
                setFormError(err.response.data.detail);
            }
        );
    };

    function handleCancelRegistration() {
        UserClient.cancelRegistration().then(() => {
            EventBus.dispatch("logout");
        })
    }

    const refreshToken = async () => {
        const refreshToken: string | null = localStorage.getItem("refresh_token");
        if (!refreshToken) {
            return;
        }
        const formData = new FormData();
        formData.append('refresh_token', refreshToken);

        return AuthService.refreshToken(formData).then(response => {
            localStorage.setItem("access_token", response.data.access_token);
            localStorage.setItem("refresh_token", response.data.refresh_token);
            const jwt = jwtDecode<JwtCustomPayload>(response.data.access_token);
            setUser(new UserAccount(jwt, jwt.exp!));
            navigate("/");
        }).catch(error => {
            handleCancelRegistration();
        })
    }
    const handleSubmit = () => {
        methods.handleSubmit(onSubmit);
    }

    return (
        <main className="view-content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <FormProvider {...methods}>
                            <div className="card">
                                <div className="card-body p-5">
                                    <h1 className="text-center mb-2">Create account</h1>
                                    <div className="mt-2"><RequestResult
                                        successfulMessage={"Authentication with Google successful."}
                                        successful={true}/></div>

                                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                                        <NewInput
                                            name="referralSource"
                                            label="How did you find out about us?"
                                            inputClassName="form-control-icon -profile"
                                            maxLength={50}
                                        />
                                        <CheckBox
                                            name="isCompany"
                                            errorMessage={getError(methods.formState.errors?.isCompany)}
                                            label={"Sign up as a company"}
                                        />
                                        {isCompany &&
                                            <>
                                                <NewInput
                                                    name="companyInfo.name"
                                                    label="Company name"
                                                    inputClassName="form-control-icon -company"
                                                    errorMessage={methods.formState.errors?.companyInfo?.name?.message}/>
                                                <NewDropdown
                                                    name="companyInfo.country"
                                                    label="Country"
                                                    errorMessage={methods.formState.errors?.companyInfo?.country?.message}
                                                    values={countries?.map((element) => element.value)}
                                                />
                                                <NewInput
                                                    name="companyInfo.registrationNumber"
                                                    label="Reg. nr"
                                                    inputClassName="form-control-icon -hash"
                                                    errorMessage={methods.formState.errors?.companyInfo?.registrationNumber?.message}/>
                                                <NewInput
                                                    name="companyInfo.vatNumber"
                                                    label="VAT nr"
                                                    inputClassName="form-control-icon -hash"
                                                    errorMessage={methods.formState.errors?.companyInfo?.vatNumber?.message}/>
                                                <NewInput
                                                    name="companyInfo.address"
                                                    label="Address"
                                                    inputClassName="form-control-icon -location"
                                                    errorMessage={methods.formState.errors?.companyInfo?.address?.message}/>
                                                <NewInput
                                                    name="companyInfo.billingEmail"
                                                    label="Billing email"
                                                    inputClassName="form-control-icon -email-send"
                                                    errorMessage={methods.formState.errors?.companyInfo?.billingEmail?.message}/>
                                            </>}
                                        <div
                                            className="text-center d-flex justify-content-between pt-2 align-items-center">
                                            <button type="button" className="btn btn-link" onClick={() => handleCancelRegistration()}>
                                                Cancel
                                            </button>
                                            <LoadingButton
                                                onClick={handleSubmit}
                                                buttonText="Create Account"
                                                loading={loading}
                                                loadingTooltip={""}
                                                btnClassName={"btn-light px-4"}
                                                loaderVariant={"dark"}
                                            />
                                        </div>
                                        <div className="mt-2"><RequestResult successfulMessage="" successful={false}
                                                                             error={formError}/></div>
                                    </form>
                                </div>
                            </div>
                        </FormProvider>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default CompanySetup;

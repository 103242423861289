import React, {useEffect, useState} from "react";
import ProductFileInput from "../new-project/file-input/ProductFileInput";
import NewAsyncAttachment from "./NewAsyncAttachment";
import FileHandlerImageThumbnail from "./FileHandlerImageThumbnail";
import ExistingAsyncAttachment from "./ExistingAsyncAttachment";
import {useFileHandler} from "./FileHandler";
import GenerateImageModal from "../modals/GenerateImageModal";
import {useWatch} from "react-hook-form";
import {ProductionType} from "../../types/project/production-type";

const ProductFileHandler: React.FC<{
    name: string;
    errorMessage?: string;
    webpEnabled?: boolean;
}> = ({name, errorMessage, webpEnabled}) => {

    const [showGenerateImageModal, setShowGenerateImageModal] = useState<boolean>(false);
    const [showGenerateImageButton, setShowGenerateImageButton] = useState<boolean>(true);

    const productionType = useWatch({name: "productionType"});
    const aiMethodType = useWatch({name: "aiMethod"});


    const {
        removeExistingAttachment,
        updateFiles,
        updateFile,
        attachments,
        removeFile
    } = useFileHandler(name);

    useEffect(() => {
        if (
            productionType === ProductionType.AI_GENERATED && attachments.length === 0) {
            setShowGenerateImageButton(true)
        } else {
            setShowGenerateImageButton(false)
        }
    }, [attachments.length, productionType]);


    const handleGenerateImageButton = () => {
        setShowGenerateImageModal(true);
    }

    const closeGenerateImageModalHandler = (isUploaded: boolean) => {
        setShowGenerateImageModal(false);
        setShowGenerateImageButton(!isUploaded);
    }

    return (
        <>
            <ProductFileInput updateFiles={updateFiles}
                              name={`${name}.attachmentUuids`}
                              errorMessage={errorMessage}
                              webpEnabled={webpEnabled}
            />
            {
                showGenerateImageButton && productionType === ProductionType.AI_GENERATED &&
                <div className="text-center mb-3">
                    <button
                        type="button"
                        className="text-center btn btn-link mt-1" onClick={() => handleGenerateImageButton()}>
                        Or generate image with text
                    </button>
                </div>
            }
            <div className="thumbnail-gallery">
                {
                    attachments.map((attachment) => {
                        if (attachment.productUuid) {
                            return <ExistingAsyncAttachment
                                requiresThumbnailDownload
                                key={attachment.referenceUuid}
                                attachment={attachment}
                                remove={removeExistingAttachment}
                                updateFile={updateFile}
                                displayElement={<FileHandlerImageThumbnail/>}/>
                        } else {
                            return <NewAsyncAttachment
                                key={attachment.referenceUuid}
                                attachment={attachment}
                                remove={removeFile}
                                updateFile={updateFile}
                                displayElement={<FileHandlerImageThumbnail/>}/>
                        }
                    })}
            </div>

            <GenerateImageModal updateFiles={updateFiles}
                                showModal={showGenerateImageModal}
                                handleClose={closeGenerateImageModalHandler}
                                handleConfirmation={() => handleGenerateImageButton()}
                                aiMethodType={aiMethodType}/>
        </>
    );
};

export default ProductFileHandler;
